import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Divider from "@mui/material/Divider";
import Loader from "../../components/Loader/Loader";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Fragment, useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import Empty from "../../components/Empty";
import RewardsItem from "./RewardsItem";
import { RewardInfo } from "../../types";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import useGetAllRewards from "../../hooks/queries/rewards/state/useGetAllRewards";
import LinkBehavior from "../../components/LinkBehavior";

const Rewards = () => {
  const { data: rewardsList, isLoading: rewardsListLoading } =
    useGetAllRewards();

  const [campaignFilter, setCampaignFilter] = useState("all");
  const [filteredList, setFilteredList] = useState<RewardInfo[]>([]);

  const handleChangeStatus = (
    event: React.MouseEvent<HTMLElement>,
    filter: string
  ) => {
    if (filter === null) return;
    setCampaignFilter(filter);
  };

  useEffect(() => {
    if (!rewardsList) return;
    setFilteredList(
      campaignFilter === "all"
        ? rewardsList
        : rewardsList.filter((reward) => reward?.status === campaignFilter)
    );
  }, [campaignFilter, rewardsList]);

  return (
    <>
      <Typography variant="h4" fontWeight={700} mb={1}>
        Rewards
      </Typography>
      <Typography color="text.secondary">
        Rewards campaigns allow you to reward those loyal community members of
        yours, you are able to give them irl and online rewards, with
        integrations into apple and google pay, its never been easier to show
        those who care about your brand, that you care about them.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <Typography variant="h5" fontWeight={700}>
          Campaigns
        </Typography>
        <Button
          LinkComponent={LinkBehavior}
          href="create-new"
          startIcon={<DataSaverOnRoundedIcon />}
          variant="base"
        >
          Create campaign
        </Button>
      </Stack>
      {!rewardsListLoading && (
        <ToggleButtonGroup
          color="primary"
          value={campaignFilter}
          exclusive
          onChange={handleChangeStatus}
          aria-label="Platform"
        >
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value="live">Live</ToggleButton>
          <ToggleButton value="ended">Ended</ToggleButton>
          <ToggleButton value="draft">Draft</ToggleButton>
        </ToggleButtonGroup>
      )}
      {rewardsListLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : filteredList?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
        >
          <Empty
            text={`You don't have any${
              campaignFilter === "all" ? " " : ` ${campaignFilter} `
            }campaigns`}
          />
        </Stack>
      ) : (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            borderRadius: "5px",
            overflow: "hidden",
            mt: 2,
          }}
        >
          <List disablePadding dense>
            {filteredList?.map((reward, index) => (
              <Fragment key={reward?._id}>
                {index !== 0 && <Divider component="li" />}
                <RewardsItem reward={reward} />
              </Fragment>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default Rewards;
