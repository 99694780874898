import Typography from "@mui/material/Typography";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import RocketLaunchRoundedIcon from "@mui/icons-material/RocketLaunchRounded";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import TokensDelegationPhase from "./TokensDelegationPhase";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import { Fragment } from "react";
import Divider from "@mui/material/Divider";
import { useGetAllTokens } from "../../hooks/queries/tokens/state/useGetAllTokens";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Empty from "../../components/Empty";
import LinkBehavior from "../../components/LinkBehavior";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import { CreateNewTokenCampaignPayload, TokenCampaignPhase } from "../../types";
import useCreateTokenCampaign from "../../hooks/queries/token-campaign/mutations/useCreateTokenCampaign";
import { useGettAllRules } from "../../hooks/queries/rules/state/useGetAllRules";

const BLANK_PHASE: TokenCampaignPhase = {
  dailyReward: 1,
  duration: 30,
  rule: "",
  ruleDescription: "",
  maxSubscriptions: 100,
  maxSubscriptionsPerWallet: undefined,
  entryRule: undefined,
};

const TokensDelegationCreate = () => {
  const { mutateAsync: create, isLoading: createLoading } =
    useCreateTokenCampaign();
  const { isLoading: rulesListLoading } = useGettAllRules();
  const { data: tokens, isLoading: tokensLoading } = useGetAllTokens();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<Omit<CreateNewTokenCampaignPayload, "communityId">>({
    defaultValues: {
      name: "",
      title: "",
      description: "",
      tokenId: "",
      phases: [BLANK_PHASE],
    },
  });
  const phases = watch("phases");

  const handleAddExtraPhase = () => {
    setValue("phases", [
      ...phases,
      { ...phases[phases.length - 1], entryRule: "", entryRuleDescription: "" },
    ]);
  };

  const handleRemovePhase = (index: number) => {
    setValue(
      "phases",
      phases.filter((_, i) => i !== index)
    );
  };

  const onSubmit = async (
    data: Omit<CreateNewTokenCampaignPayload, "communityId">
  ) => {
    create(data);
  };

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Token booster",
            Icon: RocketLaunchRoundedIcon,
            path: "/token-booster",
          },
          {
            label: "Create campaign",
          },
        ]}
      />
      <Typography variant="h5" fontWeight={700}>
        New token booster campaign
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("name", {
            required: "This field is required",
            minLength: {
              value: 3,
              message: "Minimum length is 3",
            },
            maxLength: {
              value: 50,
              message: "Max length is 50",
            },
          })}
          error={!!errors.name}
          helperText={!!errors.name && errors.name.message}
          required
          fullWidth
          label="Campaign name (internal use)"
          margin="dense"
          disabled={createLoading}
        />
        <Typography variant="h6" fontWeight={700} mt={2}>
          Communities contract info
        </Typography>
        <Typography color="text.secondary" mb={1}>
          Complete the essential details for the contract. The information you
          input will be replicated and used in all contracts displayed within
          the communities on the Anybodies platform.
        </Typography>
        <TextField
          {...register("title", {
            required: "This field is required",
            minLength: {
              value: 3,
              message: "Minimum length is 3",
            },
            maxLength: {
              value: 50,
              message: "Max length is 50",
            },
          })}
          error={!!errors.title}
          helperText={!!errors.title && errors.title.message}
          required
          fullWidth
          label="Contract title"
          margin="dense"
          disabled={createLoading}
        />
        <TextField
          {...register("description", {
            required: "This field is required",
            minLength: {
              value: 3,
              message: "Minimum length is 3",
            },
            maxLength: {
              value: 150,
              message: "Max length is 150",
            },
          })}
          error={!!errors.description}
          helperText={
            errors.description
              ? errors.description.message
              : "Ensure you provide comprehensive details about the contract and campaign"
          }
          required
          fullWidth
          label="Contract description"
          multiline
          rows={3}
          margin="dense"
          disabled={createLoading}
        />
        <Typography variant="h6" fontWeight={700} mt={2}>
          Phases
        </Typography>
        <Typography color="text.secondary" mb={1}>
          Every contract has a fixed limit and a specified expiration date. You
          have the option to set milestones within the contract's duration. At
          the conclusion of each phase, the system will automatically verify if
          the community has achieved the set milestone before progressing to the
          next phase. For instance, you could design a contract with two phases,
          each lasting 30 days and distributing your token rewards. After the
          initial 30 days, the system checks if there are at least 100 unique
          wallets engaged with the contract. If this criterion is met, the
          contract proceeds to the final 30 days and continues the token
          distribution. However, if the first milestone is not met, the contract
          will terminate, and the distribution of tokens will cease immediately
        </Typography>
        <Stack alignItems="center" justifyContent="flex-start" spacing={0.75}>
          {phases?.map((phase, index) => (
            <Fragment key={index}>
              <TokensDelegationPhase
                disabled={createLoading}
                control={control}
                errors={errors}
                register={register}
                phases={phases}
                index={index}
                removePhase={handleRemovePhase}
              />
              <Box
                sx={{ height: 16, backgroundColor: "divider", width: "1px" }}
              />
            </Fragment>
          ))}
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<AddCircleOutlineIcon />}
            disabled={createLoading}
            onClick={handleAddExtraPhase}
          >
            Add extra phase
          </Button>
        </Stack>
        <Typography variant="h6" fontWeight={700} mt={2}>
          Token
        </Typography>
        <Typography color="text.secondary" mb={1}>
          Select the token you are going to boost
        </Typography>
        <>
          {tokensLoading ? (
            <Stack
              sx={{ my: 2 }}
              direction="row"
              spacing={1.5}
              alignItems="center"
            >
              <Box>
                <CircularProgress />
              </Box>
              <Typography>
                In the process of getting a list of tokens. Please, wait..
              </Typography>
            </Stack>
          ) : (
            <FormControl fullWidth margin="dense" disabled={createLoading}>
              <InputLabel>Select token</InputLabel>
              <Controller
                control={control}
                name="tokenId"
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    disabled={createLoading}
                    label="Select token"
                    defaultValue=""
                  >
                    {tokens?.length === 0 && (
                      <Box my={2}>
                        <Empty text="No tokens available">
                          <Button
                            LinkComponent={LinkBehavior}
                            href="/tokens"
                            variant="gradient"
                            sx={{ mt: 1.5 }}
                          >
                            Connect new token
                          </Button>
                        </Empty>
                      </Box>
                    )}
                    {tokens
                      ?.sort((a, b) => b.balance - a.balance)
                      ?.map((token) => (
                        <MenuItem key={token?.tokenId} value={token?.tokenId}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            width="100%"
                            spacing={1}
                          >
                            <Avatar
                              alt={token?.TokenName}
                              src={token?.tokenMetadata?.uri}
                              variant="rounded"
                              sx={{ width: 36, height: 36 }}
                            >
                              <InsertPhotoRoundedIcon />
                            </Avatar>
                            <Box lineHeight={1} whiteSpace="normal">
                              <Typography fontWeight={700}>
                                ${token?.tokenMetadata?.symbol}
                              </Typography>
                              <Typography
                                color="text.secondary"
                                variant="caption"
                              >
                                {token?.balance < 0
                                  ? 0
                                  : token?.balance.toLocaleString()}
                              </Typography>
                            </Box>
                          </Stack>
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          )}
        </>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" fontWeight={700}>
          Community activation calculator
        </Typography>
        <Typography color="text.secondary" mb={1}>
          This section shows the quantity of tokens that will be allocated from
          your fund for a single community activation as part of this campaign.
          Any remaining tokens at the campaign's end will be returned to your
          community account.
        </Typography>
        <Typography>
          Total:{" "}
          <span style={{ fontWeight: 700 }}>
            {phases?.reduce((acc, phase) => {
              const { dailyReward, duration, maxSubscriptions } = phase;
              if (!dailyReward || !duration || !maxSubscriptions) return acc;
              const total = dailyReward * duration * maxSubscriptions;
              return acc + total;
            }, 0)}
          </span>{" "}
          tokens
        </Typography>
        <Typography>
          Campaign duration:{" "}
          <span style={{ fontWeight: 700 }}>
            {phases?.reduce((acc, phase) => {
              const { duration } = phase;
              if (!duration) return acc;
              return acc + duration;
            }, 0)}
          </span>{" "}
          days
        </Typography>
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={tokensLoading || rulesListLoading}
          loading={createLoading}
          sx={{ mt: 2 }}
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default TokensDelegationCreate;
