import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { RewardSaveStepPayload } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const saveRewardStep = async (data: RewardSaveStepPayload) => {
  return await axios.post<any>(API_PATHS.rewards.saveStep, { data });
};

const useSaveRewardStep = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: RewardSaveStepPayload) => {
      const { data } = await saveRewardStep(payload);

      if (isSuccessResponse(data?.data)) {
        await queryClient.invalidateQueries({
          queryKey:
            data?.action === "create-campaign"
              ? [API_PATHS.rewards.list, payload?.form?.communityId]
              : [API_PATHS.rewards.byId, payload?.form?.campaignId],
          exact: true,
          refetchType: "active",
        });
        return data?.data?.data;
      }

      throw new Error(
        `${data?.data?.error?.title}\n${data?.data?.error?.description}`
      );
    },
    {
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useSaveRewardStep;
