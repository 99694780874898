import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import {
  CreateNewTokenCampaignPayload,
  CreateNewTokenCampaignResponse,
} from "../../../../types";
import { isSuccessResponse } from "../../../../utils";
import { useNavigate } from "react-router-dom";

const createTokenCampaign = async (data: CreateNewTokenCampaignPayload) => {
  return await axios.post<CreateNewTokenCampaignResponse>(
    API_PATHS.tokenCampaigns.create,
    data
  );
};

const useCreateTokenCampaign = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    async (payload: Omit<CreateNewTokenCampaignPayload, "communityId">) => {
      if (!selectedCommunityDocId) return;
      const { data } = await createTokenCampaign({
        communityId: selectedCommunityDocId,
        ...payload,
      });

      if (isSuccessResponse(data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.tokenCampaigns.list, selectedCommunityDocId],
          exact: true,
          refetchType: "active",
        });
        return data.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar({
          variant: "success",
          message: `New campaign "${data?.name}" created`,
        });
        navigate(`/token-booster/${data?._id}`);
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useCreateTokenCampaign;
