import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { LoginResponse, SignUpPayload } from "../../../types";
import axios from "axios";
import { useAuth } from "../../../services/AuthService";
import { API_PATHS } from "../../../constants";

async function signUp(data: SignUpPayload) {
  return await axios.post<LoginResponse>(API_PATHS.auth.signUp, data);
}

export function useSignUp() {
  const { enqueueSnackbar } = useSnackbar();
  const { updateTokens } = useAuth();

  return useMutation(
    async (payload: SignUpPayload) => {
      const { data } = await signUp(payload);
      if (!data?.success) {
        throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
      }
      return data;
    },
    {
      onSuccess: (res) => {
        if (!updateTokens) return;
        updateTokens(
          res?.data?.idToken,
          res?.data?.refreshToken,
          res?.data?.expiresIn
        );
        enqueueSnackbar({
          variant: "success",
          message: "Welcome to Anybodies Platform!",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign up. Try again!",
        });
      },
    }
  );
}
