import Typography from "@mui/material/Typography";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  SUBSCRIPTION_CONTRACT_TYPES,
  SUBSCRIPTION_DURATIONS,
} from "../../constants";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useGetAllTokens } from "../../hooks/queries/tokens/state/useGetAllTokens";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCreateContract } from "../../hooks/queries/subscriptions/mutations/useCreateContract";
import { CreateContractPayload } from "../../types";
import FormHelperText from "@mui/material/FormHelperText";

const CreateSubscriptionContract = () => {
  const { data: tokens, isLoading: tokensLoading } = useGetAllTokens();
  const { mutateAsync: createContract, isLoading: createContractLoading } =
    useCreateContract();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Omit<CreateContractPayload, "communityId">>({
    defaultValues: {
      stakingDuration: "",
      rewardProgram: "",
      tokenId: "",
    },
  });

  const onSubmit = async (data: Omit<CreateContractPayload, "communityId">) => {
    createContract(data);
  };

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Contracts",
            path: "/subscription/contracts",
          },
          {
            label: "Create contract",
          },
        ]}
      />
      <Typography variant="h5" fontWeight={700} mb={1}>
        Create contract
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("planTitle", {
            required: "This field is required",
            minLength: {
              value: 3,
              message: "Minimum length is 3",
            },
            maxLength: {
              value: 50,
              message: "Max length is 50",
            },
          })}
          error={!!errors.planTitle}
          helperText={!!errors.planTitle && errors.planTitle.message}
          required
          fullWidth
          label="Title"
          margin="dense"
          disabled={createContractLoading}
        />
        <TextField
          {...register("planDescription", {
            required: "This field is required",
            minLength: {
              value: 3,
              message: "Minimum length is 3",
            },
            maxLength: {
              value: 150,
              message: "Max length is 150",
            },
          })}
          error={!!errors.planDescription}
          helperText={
            errors.planDescription
              ? errors.planDescription.message
              : "Make sure to give as much information about the plan in this field. For example, How many tokens per day an NFT will earn if staked"
          }
          required
          fullWidth
          label="Description"
          multiline
          rows={3}
          margin="dense"
          disabled={createContractLoading}
        />
        <FormControl
          fullWidth
          required
          margin="dense"
          disabled={createContractLoading}
        >
          <InputLabel>Duration</InputLabel>
          <Controller
            control={control}
            name="stakingDuration"
            render={({ field }) => (
              <Select
                {...field}
                variant="outlined"
                label="Duration"
                defaultValue=""
                error={!!errors.stakingDuration}
              >
                {SUBSCRIPTION_DURATIONS.map((duration) => (
                  <MenuItem key={duration} value={duration}>
                    {duration} Days
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>
            Duration will be used for the Maturity feature. If you're not
            planning to use it then just randomly choose a value
          </FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          required
          margin="dense"
          disabled={createContractLoading}
        >
          <InputLabel>Subscription program</InputLabel>
          <Controller
            control={control}
            name="rewardProgram"
            render={({ field }) => (
              <Select
                {...field}
                variant="outlined"
                label="Subscription program"
                defaultValue=""
                error={!!errors.rewardProgram}
              >
                {SUBSCRIPTION_CONTRACT_TYPES.map(
                  ({ id, label, description }) => (
                    <MenuItem key={id} value={id}>
                      <Box width="100%">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography fontWeight={700} lineHeight={1}>
                            {label}
                          </Typography>
                        </Stack>
                        <Typography
                          color="text.secondary"
                          variant="caption"
                          component="div"
                          mt={0.5}
                          lineHeight={1.15}
                        >
                          {description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </FormControl>
        {tokensLoading ? (
          <Stack
            sx={{ my: 2 }}
            direction="row"
            spacing={1.5}
            alignItems="center"
          >
            <CircularProgress />
            <Typography>
              In the process of getting a list of tokens. Please, wait..
            </Typography>
          </Stack>
        ) : (
          <Box mb={2}>
            <FormControl
              fullWidth
              margin="dense"
              required
              disabled={createContractLoading}
            >
              <InputLabel>Choose a reward token</InputLabel>
              <Controller
                control={control}
                name="tokenId"
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    label="Choose a reward token"
                    defaultValue=""
                  >
                    {tokens?.map((t) => (
                      <MenuItem key={t?.tokenId} value={t?.tokenId}>
                        ${t?.TokenName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        )}
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={tokensLoading}
          loading={createContractLoading}
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default CreateSubscriptionContract;
