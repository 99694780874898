import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ModalTransition from "../../../../helpers/ModalTransition";
import { MaturityLevel } from "../../../../types";

type Props = {
  step: number;
  levels: MaturityLevel[];
  isOpen: boolean;
  hide: () => void;
};

const SimulateDialog = ({ step, levels, isOpen, hide }: Props) => {
  let currentDay = 0;
  const currentReward = 0;

  return (
    <Dialog open={isOpen} onClose={hide} TransitionComponent={ModalTransition}>
      <DialogTitle>Levels simulation</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    pl: 0,
                  }}
                >
                  <Typography fontWeight={700}>Stage</Typography>
                  <Typography
                    variant="overline"
                    color="text.secondary"
                    fontSize={10}
                    component="div"
                    lineHeight={1}
                  >
                    Duration
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={700}>Daily reward</Typography>
                  <Typography
                    variant="overline"
                    color="text.secondary"
                    fontSize={10}
                    component="div"
                    lineHeight={1}
                  >
                    Token
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {levels.map(({ stakingSession, addedRewards }, index: any) => {
                const reward =
                  currentReward + (addedRewards ? addedRewards : 0);
                return (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        pl: 0,
                      }}
                    >
                      <Typography fontWeight={700}>
                        Level {index + 1}
                      </Typography>
                      <Typography
                        variant="overline"
                        color="text.secondary"
                        fontSize={10}
                        component="div"
                        lineHeight={1}
                      >
                        days {index === 0 ? currentDay : currentDay + 1}
                        {levels.length === index + 1
                          ? "+"
                          : ` - ${(currentDay +=
                              stakingSession * (step || 1))}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        gap={0.5}
                      >
                        <Chip
                          color="success"
                          size="small"
                          label="Daily base reward"
                        />
                        <strong>{reward > 0 && ` + ${reward}`}</strong>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="caption" component="div" mt={1}>
          * extra rewards will be added to daily base reward per sub-collection
          by level
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="base" color="secondary" onClick={hide} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimulateDialog;
