import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import { useParams } from "react-router-dom";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import Stepper from "@mui/material/Stepper";
import { useEffect, useState } from "react";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import Base from "./Steps/Base/Base";
import Eligibility from "./Steps/Eligibility/Eligibility";
import Divider from "@mui/material/Divider";
import Distribution from "./Steps/Distribution/Distribution";
import Fulfillment from "./Steps/Fulfillment/Fulfillment";
import RewardConfiguration from "./Steps/RewardConfiguration/RewardConfiguration";
import StepLabel from "@mui/material/StepLabel";
import { useGetRewardById } from "../../hooks/queries/rewards/state/useGetRewardById";
import Stack from "@mui/material/Stack";
import Loader from "../../components/Loader/Loader";
import { RewardInfo } from "../../types";
import Claiming from "./Steps/Claiming/Claiming";
import Summary from "./Steps/Summary/Summary";
import LoadingModal from "../../components/modals/LoadingModal";
import { useApp } from "../../services/AppService";

const steps = [
  {
    label: "Campaign Requirements",
    El: Base,
  },
  {
    label: "Eligibility",
    El: Eligibility,
  },
  {
    label: "Distribution",
    El: Distribution,
  },
  {
    label: "Fulfillment",
    El: Fulfillment,
  },
  {
    label: "Digital Reward Configuration",
    El: RewardConfiguration,
  },
  {
    label: "Cost Per Claim",
    El: Claiming,
  },
  {
    label: "Summary",
    El: Summary,
  },
];

const COMPLETED_FIELD_PROPERTIES: Array<keyof RewardInfo> = [
  "rewardSource",
  "eligibleAudience",
  "distributionType",
  "rewardConfig",
  "tokenConfig",
  "claimingPaymentConfig",
];

const CampaignConfigurator = () => {
  const { loadingModal, setLoadingModal } = useApp();
  const { campaignId } = useParams();
  const { data: rewardInfo, isLoading: rewardInfoLoading } = useGetRewardById();

  const [activeStep, setActiveStep] = useState(
    campaignId === "create-new" ? 0 : -1
  );
  const [lastCompletedStep, setLastCompletedStep] = useState<number>(-1);

  useEffect(() => {
    const id = `reward-step-${activeStep}`;
    const yOffset = window.innerWidth >= 900 ? 0 : -56;
    const element = document.getElementById(id);
    let timeout;
    if (element) {
      setTimeout(() => {
        const y =
          element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 350);
    }
    return clearTimeout(timeout);
  }, [activeStep]);

  useEffect(() => {
    if (typeof rewardInfo === "undefined") return;
    let completed = 0;
    for (const prop of COMPLETED_FIELD_PROPERTIES) {
      if (typeof rewardInfo?.[prop] === "undefined") {
        break;
      } else {
        completed++;
      }
    }
    setLastCompletedStep(completed);
    setActiveStep(completed);
  }, [lastCompletedStep, rewardInfo]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Rewards",
            Icon: RedeemRoundedIcon,
            path: "/rewards",
          },
          {
            label:
              campaignId === "create-new"
                ? "Create new campaign"
                : rewardInfo?.profile?.title,
          },
        ]}
      />
      {rewardInfoLoading && campaignId !== "create-new" ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : (
        <>
          {rewardInfo?.status === "draft" || campaignId === "create-new" ? (
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              {steps.map(({ label, El }, index) => (
                <Step
                  key={label}
                  completed={index < lastCompletedStep}
                  id={`reward-step-${index}`}
                >
                  <StepLabel>
                    <Typography
                      variant={index === activeStep ? "h5" : "h6"}
                      color={
                        index === activeStep ? "text.main" : "text.secondary"
                      }
                      fontWeight={700}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Divider sx={{ mb: 2 }} />
                    <El next={handleNext} prev={handleBack} />
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          ) : (
            <Summary />
          )}
        </>
      )}
      <LoadingModal
        data={loadingModal}
        hide={() => {
          if (!setLoadingModal) return;
          setLoadingModal(null);
        }}
      />
    </>
  );
};
export default CampaignConfigurator;
