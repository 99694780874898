import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { Fragment } from "react";
import CreateAssetAttributesItem from "./CreateAssetAttributesItem";
import Typography from "@mui/material/Typography";
import { Attribute } from "../../../types";

export type AttributeItem = { type: string; value: string };

type Props = {
  list: Attribute[];
  onAdd: () => void;
  onRemove: (index: number) => void;
  onFieldChange?: (index: number, key: keyof Attribute, value: any) => void;
  disabled: boolean;
};

const CreateAssetAttributes = ({
  list,
  onAdd,
  onRemove,
  onFieldChange,
  disabled,
}: Props) => {
  return (
    <Box mt={2}>
      <Typography fontWeight={700} variant="h6">
        Attributes
      </Typography>
      <List disablePadding>
        {list.map((i, index) => (
          <Fragment key={index}>
            <CreateAssetAttributesItem
              data={i}
              order={index}
              onRemove={() => {
                onRemove(index);
              }}
              onFieldChange={disabled ? undefined : onFieldChange}
            />
          </Fragment>
        ))}
      </List>
      <Button
        startIcon={<DataSaverOnRoundedIcon />}
        onClick={onAdd}
        variant="base"
        sx={{ mt: 1 }}
        disabled={disabled}
      >
        Add attribute
      </Button>
    </Box>
  );
};

export default CreateAssetAttributes;
