import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import QuestConfigurator from "./QuestConfigurator";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import CardGiftcardRoundedIcon from "@mui/icons-material/CardGiftcardRounded";
import { useGetQuestMissions } from "../../hooks/queries/loyalty/state/useGetQuestMissions";
import Box from "@mui/material/Box";
import { LoaderCover } from "../../components/StyledComponents";
import Stack from "@mui/material/Stack";
import Loader from "../../components/Loader/Loader";
import { useGettAllRules } from "../../hooks/queries/rules/state/useGetAllRules";
import useUpdateQuestMissions from "../../hooks/queries/loyalty/mutations/useUpdateQuestMissions";
import { FormEvent, useEffect, useRef, useState } from "react";
import { QuestMission } from "../../types";
import LoadingButton from "@mui/lab/LoadingButton";
import CardMedia from "@mui/material/CardMedia";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import CardActionArea from "@mui/material/CardActionArea";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import useUpdateQuest from "../../hooks/queries/loyalty/mutations/useUpdateQuest";
import QuestSettingsModal, {
  QuestSettings,
} from "../LoyaltyProgram/QuestSettingsModal";
import useUpdateQuestBanner from "../../hooks/queries/loyalty/mutations/useUpdateQuestBanner";
import { getBase64 } from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";

const QuestProgram = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    data: quest,
    isRefetching: questMissionsFetching,
    isLoading: questMissionsLoading,
  } = useGetQuestMissions();
  const { data: rulesList, isLoading: rulesListLoading } = useGettAllRules();
  const {
    mutateAsync: updateQuestMissions,
    isLoading: updateQuestMissionsLoading,
  } = useUpdateQuestMissions({
    onSettled: () => {},
  });
  const {
    mutateAsync: updateQuestBanner,
    isLoading: updateQuestBannerLoading,
  } = useUpdateQuestBanner({
    onSettled: () => {},
  });

  const { mutateAsync: updateQuest, isLoading: updateQuestLoading } =
    useUpdateQuest({
      onSettled: () => {
        setSettingsModalData(undefined);
      },
    });

  const [settingsModalData, setSettingsModalData] = useState<
    undefined | QuestSettings
  >();
  const [levels, setLevels] = useState<QuestMission[]>([]);

  useEffect(() => {
    if (!quest) return;
    if (quest && quest?.missions?.length > 0) {
      setLevels(quest?.missions);
    } else {
      setLevels([{ ruleTreeId: "", title: "", description: "" }]);
    }
  }, [quest]);

  const addLevel = () => {
    setLevels((state) => [
      ...state,
      { ruleTreeId: "", title: "", description: "" },
    ]);
  };

  const removeLevel = (index: number) => {
    setLevels((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const updateValue = (
    index: number,
    key: keyof QuestMission,
    ruleTreeId: string
  ) => {
    setLevels((state) => {
      const newState = [...state];
      newState[index][key] = ruleTreeId;
      return newState;
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateQuestMissions({ updateToken: quest?.updateToken, missions: levels });
  };

  const handleEditQuestModal = () => {
    setSettingsModalData(quest);
  };

  const onQuestSettingsSave = (data: QuestSettings) => {
    updateQuest({
      questId: data?._id,
      title: data?.title,
      description: data?.description,
    });
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileObj = event.target.files?.[0];
    if (!fileObj) {
      return;
    }
    const type = fileObj.type.replace(/(.*)\//g, "");
    const base64 = await getBase64(fileObj);
    event.target.value = "";
    await updateQuestBanner({ base64, type });
  };

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Loyalty Program",
            Icon: CardGiftcardRoundedIcon,
            path: "/loyalty",
          },
          {
            label: quest?.title,
          },
        ]}
      />

      {quest && (
        <Card sx={{ width: 500, maxWidth: "100%", mx: "auto", mb: 3 }}>
          <CardMedia
            sx={{
              aspectRatio: "16/9",
              textAlign: "center",
            }}
            image={quest?.banner?.assets?.[0]?.url}
          >
            <CardActionArea
              sx={{
                height: "100%",
                width: "100%",
                ...(quest?.banner?.assets?.[0]?.url && {
                  backgroundColor: "rgba(0,0,0,0.9)",
                }),
                opacity:
                  quest?.banner?.assets?.[0]?.url && !updateQuestBannerLoading
                    ? 0
                    : 1,
                transition: (theme) => theme.transitions.create("opacity"),
                "& svg": {
                  fontSize: "3em",
                },
                "&:hover": {
                  opacity: 1,
                },
              }}
              disabled={updateQuestBannerLoading}
              onClick={() => {
                inputRef?.current?.click();
              }}
            >
              {updateQuestBannerLoading ? (
                <>
                  <CircularProgress sx={{ mb: 2 }} />
                  <Typography>
                    Banner in the process of uploading to the server..
                  </Typography>
                </>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    ref={inputRef}
                    onChange={handleFileChange}
                  />
                  {quest?.banner?.assets?.[0]?.url ? (
                    <CreateRoundedIcon />
                  ) : (
                    <DataSaverOnRoundedIcon />
                  )}
                  <Typography variant="h6" fontWeight={700}>
                    {quest?.banner?.assets?.[0]?.url ? "Edit" : "Add"} quest
                    banner
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Recommended image size up to 500px. Aspect ratio 16 / 9
                  </Typography>
                </>
              )}
            </CardActionArea>
          </CardMedia>
          <CardContent sx={{ p: 1, "&:last-child": { pb: 1 } }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1}
            >
              <Box>
                <Typography variant="h6" fontWeight={700}>
                  {quest?.title}
                </Typography>
                <Typography>{quest?.description}</Typography>
              </Box>
              <Tooltip title="Edit quest profile" placement="top">
                <IconButton size="small" onClick={handleEditQuestModal}>
                  <CreateRoundedIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </CardContent>
        </Card>
      )}
      <form onSubmit={handleSubmit}>
        <Box sx={{ position: "relative" }}>
          <LoaderCover
            display={
              questMissionsFetching || questMissionsLoading || rulesListLoading
                ? "block"
                : "none"
            }
          >
            <Stack
              alignItems="center"
              sx={{
                position: "sticky",
                top: "50%",
                py: 2,
              }}
            >
              <Loader />
              <Typography
                variant="subtitle1"
                fontWeight={700}
                mt={2}
                textAlign="center"
              >
                In the process of getting the latest version of quest missions.
                Please, wait..
              </Typography>
            </Stack>
          </LoaderCover>
          <QuestConfigurator
            levels={levels}
            rulesList={rulesList}
            addLevel={addLevel}
            removeLevel={removeLevel}
            update={updateValue}
            updateQuestMissionsLoading={updateQuestMissionsLoading}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Stack alignItems="flex-end">
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={updateQuestMissionsLoading}
          >
            Save
          </LoadingButton>
        </Stack>
      </form>
      <QuestSettingsModal
        data={settingsModalData}
        loading={updateQuestLoading}
        hide={() => {
          setSettingsModalData(undefined);
        }}
        save={onQuestSettingsSave}
      />
    </>
  );
};

export default QuestProgram;
