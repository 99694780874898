import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import useSaveRewardStep from "../../../../hooks/queries/rewards/mutations/useSaveRewardStep";
import { ClaimingTransaction } from "../../../../types";
import { ClaimingProps, Inputs } from "./types";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useApp } from "../../../../services/AppService";
import { useGetRewardById } from "../../../../hooks/queries/rewards/state/useGetRewardById";
import { useEffect, useState } from "react";
import ClaimingTokens from "./ClaimingTokens";
import TextField from "@mui/material/TextField";
import { deepEqual } from "../../../../utils";

const Claiming = ({ next, prev }: ClaimingProps) => {
  const { campaignId } = useParams();
  const { selectedCommunityDocId } = useApp();
  const { data: rewardInfo } = useGetRewardById();
  const { mutateAsync: saveStep, isLoading: saveStepLoading } =
    useSaveRewardStep();
  const { handleSubmit, reset, register } = useForm<Inputs>();

  const [dataFromBd, setDataFromBd] = useState<any>();
  const [tokens, setTokens] = useState<ClaimingTransaction[]>([
    { type: "sol", amount: "" },
  ]);

  useEffect(() => {
    if (!rewardInfo?.claimingPaymentConfig) return;
    let tks: ClaimingTransaction[] = [{ type: "sol", amount: "" }];
    if (rewardInfo?.claimingPaymentConfig?.transactions?.length > 0) {
      if (
        rewardInfo?.claimingPaymentConfig?.transactions?.length === 1 &&
        rewardInfo?.claimingPaymentConfig?.transactions?.[0]?.type !== "sol"
      ) {
        tks.push(
          ...JSON.parse(
            JSON.stringify(rewardInfo?.claimingPaymentConfig?.transactions)
          )
        );
      } else {
        tks = JSON.parse(
          JSON.stringify(rewardInfo?.claimingPaymentConfig?.transactions)
        );
      }
    }
    setDataFromBd({
      destinationWalletAddress:
        rewardInfo?.claimingPaymentConfig?.destinationWalletAddress,
      transactions: JSON.parse(JSON.stringify(tks)),
    });
    setTokens(tks);
    reset({
      destinationWalletAddress:
        rewardInfo?.claimingPaymentConfig?.destinationWalletAddress,
    });
  }, [reset, rewardInfo]);

  const handleAddToken = () => {
    setTokens((state) => [
      ...state,
      { type: "splToken", tokenAddress: "", amount: "" },
    ]);
  };

  const handleRemoveToken = (index: number) => {
    setTokens((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const onTokenFieldChange = (
    index: number,
    key: keyof ClaimingTransaction,
    value: any
  ) => {
    setTokens((state) => {
      const newState = [...state];
      newState[index][key] = value;
      return newState;
    });
  };

  const onSubmit = async (data: Inputs) => {
    if (!selectedCommunityDocId || !campaignId) return;

    if (deepEqual(dataFromBd, { transactions: tokens, ...data })) {
      next();
      return;
    }

    saveStep({
      action: "update-claiming-payment",
      form: {
        campaignId,
        communityId: selectedCommunityDocId,
        transactions: tokens,
        ...data,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography>
        At this step, you can specify how much the user should pay for claiming
        the reward.
      </Typography>
      <Typography color="text.secondary">
        * If you do not want the user to pay for claiming - leave the fields
        empty and click the continue button
      </Typography>
      <TextField
        {...register("destinationWalletAddress")}
        required={tokens?.some((v) => !!v?.amount || !!v?.tokenAddress)}
        disabled={saveStepLoading}
        label="Destination wallet address"
        fullWidth
        margin="dense"
        sx={{ mb: 2 }}
      />
      <ClaimingTokens
        list={tokens}
        onAdd={handleAddToken}
        onRemove={handleRemoveToken}
        onChange={onTokenFieldChange}
        disabled={saveStepLoading}
      />
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mt: 2 }}>
        <Button
          variant="base"
          color="secondary"
          disabled={saveStepLoading}
          onClick={prev}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={saveStepLoading}
          sx={{ ml: 1 }}
        >
          Continue
        </LoadingButton>
      </Box>
    </form>
  );
};

export default Claiming;
