import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import useVerifyDiscordServer from "../../../hooks/queries/integrations/mutations/useVerifyDiscordServer";
import Typography from "@mui/material/Typography";

const IntegrationsDiscordConnect = () => {
  const [params] = useSearchParams();
  const {
    mutateAsync: verifyDiscordServer,
    isLoading: verifyDiscordServerLoading,
  } = useVerifyDiscordServer({
    onSettled: () => {},
  });

  useEffect(() => {
    const codeQ = params.get("code");
    if (codeQ) {
      verifyDiscordServer();
    }
  }, [params, verifyDiscordServer]);

  const retry = () => {
    window.location.href = `https://discord.com/oauth2/authorize?response_type=code&client_id=915708397024854026&scope=bot&permissions=8&redirect_uri=${window.location.origin}/integrations`;
  };

  return (
    <>
      <Typography variant="h6" fontWeight={700} mb={0.5}>
        Connect Discord to your Community
      </Typography>
      <Typography mb={2}>
        Meet <strong>Kevin Musk</strong>, our discord bot.
      </Typography>
      {verifyDiscordServerLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <Button onClick={retry}>Connect your discord server</Button>
      )}
    </>
  );
};

export default IntegrationsDiscordConnect;
