import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect, useState } from "react";
import SimulateDialog from "./SimulateDialog";
import { useGetContractDetails } from "../../../../hooks/queries/subscriptions/state/useGetContractDetails";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Controller, useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MaturityLevel } from "../../../../types";
import { useUpdateContractMaturity } from "../../../../hooks/queries/subscriptions/mutations/useUpdateContractMaturity";

type Inputs = {
  maturityActive: boolean;
  maturityLevels: MaturityLevel[];
};

const SubscriptionContractMaturity = () => {
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm<Inputs>({
    defaultValues: {
      maturityActive: false,
      maturityLevels: [{ ...INIT_LEVEL }],
    },
  });
  const formWatcher = watch();

  const { data: contractDetails } = useGetContractDetails();
  const { mutateAsync: updateMaturity, isLoading: updateMaturityLoading } =
    useUpdateContractMaturity();
  const [simulateModalOpen, setSimulateModalOpen] = useState(false);

  useEffect(() => {
    if (!contractDetails) return;
    reset({
      maturityActive: contractDetails?.planData?.maturityActive,
      maturityLevels: contractDetails?.planData?.maturityLevels
        ? contractDetails?.planData?.maturityLevels
        : [{ ...INIT_LEVEL }],
    });
  }, [contractDetails, reset]);

  const addLevel = () => {
    const levels = JSON.parse(JSON.stringify(getValues("maturityLevels")));
    const lastLevel = levels[levels.length - 1];
    const newLevel = {
      ...lastLevel,
      addedRewards:
        (lastLevel.addedRewards === "" ? 0 : lastLevel.addedRewards) + 1,
    };
    levels.push(newLevel);
    setValue("maturityLevels", levels);
  };

  const removeLevel = (levelIndex: number) => {
    const levels = JSON.parse(
      JSON.stringify(getValues("maturityLevels"))
    ) as MaturityLevel[];
    setValue(
      "maturityLevels",
      levels.filter((l, i) => i !== levelIndex)
    );
  };

  const handleSimulate = () => {
    setSimulateModalOpen(true);
  };

  const onSubmit = async (data: Inputs) => {
    updateMaturity(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="body1"
          color="text.secondary"
          gutterBottom
          component="div"
        >
          Gamify staking by setting up a leveling system where the longer you
          stake, the more rewards the user will get. Number of days per level
          are multiples of the staking contract's duration
        </Typography>
        <FormControl disabled={updateMaturityLoading}>
          <FormControlLabel
            control={
              <Controller
                name="maturityActive"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Activate Maturity"
            sx={{ mb: 2 }}
          />
        </FormControl>
        {formWatcher.maturityActive && (
          <Box mb={2}>
            {formWatcher?.maturityLevels?.map((level, index) => (
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="flex-start"
                spacing={1.5}
                mb={1.5}
                key={index}
              >
                <Typography fontWeight={700} whiteSpace="nowrap" mt={1}>
                  Level {index + 1}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  spacing={1}
                  flexBasis="100%"
                  width="100%"
                >
                  {contractDetails?.planData?.StakingDuration && (
                    <FormControl
                      fullWidth
                      margin="none"
                      required
                      size="small"
                      disabled={updateMaturityLoading}
                    >
                      <InputLabel>Number of days</InputLabel>
                      <Controller
                        control={control}
                        name={`maturityLevels.${index}.stakingSession`}
                        render={({ field }) => (
                          <Select
                            {...field}
                            variant="outlined"
                            label="Number of days"
                          >
                            {Array.from(Array(MAXIMUM_STEPS).keys()).map(
                              (value) => (
                                <MenuItem value={value + 1} key={value}>
                                  {(value + 1) *
                                    contractDetails?.planData?.StakingDuration}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        )}
                      />
                    </FormControl>
                  )}
                  <Controller
                    control={control}
                    name={`maturityLevels.${index}.addedRewards`}
                    rules={{
                      min: {
                        value: 0,
                        message: "Min value is 0",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onChange={(event) => {
                          field.onChange(
                            event.target.value
                              ? +event.target.value
                              : event.target.value
                          );
                        }}
                        error={!!errors.maturityLevels?.[index]?.addedRewards}
                        helperText={
                          !!errors.maturityLevels?.[index]?.addedRewards &&
                          errors.maturityLevels?.[index]?.addedRewards?.message
                        }
                        disabled={index === 0 || updateMaturityLoading}
                        margin="none"
                        fullWidth
                        color="primary"
                        type="number"
                        label="Added rewards to base amount"
                        name="AddedRewards"
                        inputProps={{ step: 1 }}
                        required
                        size="small"
                      />
                    )}
                  />
                  <IconButton
                    aria-label="delete"
                    color="error"
                    disabled={index === 0 || updateMaturityLoading}
                    onClick={() => removeLevel(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {formWatcher?.maturityLevels?.length < MAXIMUM_LEVELS && (
                <Button
                  variant="outlined"
                  color="inherit"
                  startIcon={<AddCircleOutlineIcon />}
                  disabled={updateMaturityLoading}
                  onClick={addLevel}
                >
                  Add extra level
                </Button>
              )}
              <Button
                variant="text"
                onClick={handleSimulate}
                disabled={updateMaturityLoading}
              >
                Simulate
              </Button>
            </Stack>
          </Box>
        )}
        <Box>
          <LoadingButton
            loading={updateMaturityLoading}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </Box>
      </form>
      {contractDetails?.planData?.StakingDuration && (
        <SimulateDialog
          step={contractDetails?.planData?.StakingDuration}
          levels={formWatcher.maturityLevels}
          isOpen={simulateModalOpen}
          hide={() => setSimulateModalOpen(false)}
        />
      )}
    </>
  );
};

export default SubscriptionContractMaturity;

const MAXIMUM_STEPS = 10;
const MAXIMUM_LEVELS = 12;
const INIT_LEVEL = {
  stakingSession: 1,
  addedRewards: 0,
};
