import Toolbar from "@mui/material/Toolbar";
import Logo from "../../../components/images/Logo";
import {
  StyledAppBar,
  StyledLogoIcon,
  StyledLogoName,
} from "../../../components/StyledComponents";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useApp } from "../../../services/AppService";

const Header = () => {
  const { setMobileOpen } = useApp();

  const handleDrawerToggle = () => {
    setMobileOpen((state) => !state);
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 1, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Link
          to="/profile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
          >
            <StyledLogoIcon>
              <Logo width={22} />
            </StyledLogoIcon>
            <StyledLogoName>
              <Typography component="div" fontSize={14}>
                ANYBODIES
              </Typography>
              <Typography
                variant="gradient"
                fontSize={14}
                component="div"
                mt={0.25}
                display="inline-block"
              >
                Studio
              </Typography>
            </StyledLogoName>
          </Stack>
        </Link>
        <Box sx={{ flexGrow: 1, mr: 2 }} />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
