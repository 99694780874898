import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useDebounce from "../../hooks/useDebounce";
import axios from "axios";
import { API_PATHS } from "../../constants";
import { EllipsisTypography } from "../../components/StyledComponents";
import { SearchCommunity, SearchCommunityResponse } from "../../types";
import Chip from "@mui/material/Chip";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Input = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  display: "flex",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const Loading = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  right: 10,
  top: "50%",
  marginTop: -12,
  [theme.breakpoints.down("md")]: {
    right: 40,
  },
}));

const ResultsList = styled(List)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  maxHeight: 350,
  overflowY: "auto",
  boxShadow: theme.shadows[5],
}));

const SearchInner = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: `${theme.shape.borderRadius}px`,
  backgroundColor: "rgb(71, 71, 73)",
  "&:hover": {
    backgroundColor: "rgb(93, 93, 95)",
  },
  [theme.breakpoints.down("md")]: {
    flexGrow: 1,
  },
}));

const MIN_SEARCH_LENGTH = 2;

type Props = {
  targetCommunities?: any[];
  onSelectCommunity: (community: SearchCommunity) => void;
};

const TokensDelegationSearchCommunity = ({
  onSelectCommunity,
  targetCommunities,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [showResults, setShowResults] = useState<boolean>(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const { data, isFetching } = useQuery(
    ["list", debouncedSearchTerm],
    () => searchCommunities(debouncedSearchTerm),
    {
      onSuccess: () => {
        setShowResults(debouncedSearchTerm.length >= MIN_SEARCH_LENGTH);
      },
    }
  );

  const searchCommunities = async (
    searchParam: string
  ): Promise<SearchCommunity[]> => {
    if (searchParam?.length < MIN_SEARCH_LENGTH) return [];
    const { data } = await axios.post<SearchCommunityResponse>(
      API_PATHS.studio.searchCommunity,
      {
        query: searchParam,
      }
    );
    const res = data as any;
    if (!res?.success) {
      throw new Error(`Cannot get list. Please try again`);
    }
    return res.data;
  };

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const reset = useCallback(() => {
    setShowResults(false);
    setSearchValue("");
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        reset();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, reset]);

  return (
    <Box ref={ref}>
      <SearchInner>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Input
          placeholder="Search community"
          value={searchValue}
          onChange={handleSearchChange}
          fullWidth
        />
        {isFetching && <Loading size={24} />}
        {showResults && (
          <>
            {data && (
              <ResultsList>
                {data.length > 0 ? (
                  data.map((c) => (
                    <ListItemButton
                      key={c?.accountId + c?.communityId}
                      onClick={() => {
                        onSelectCommunity(c);
                        reset();
                      }}
                      disabled={
                        !!targetCommunities?.find(
                          (t) => t.communityId === c?.communityId
                        )
                      }
                    >
                      <Avatar
                        src={c?.image}
                        alt={c?.name}
                        variant="square"
                        sx={{ width: 30, height: 30, mr: 1.5 }}
                      >
                        <InsertPhotoRoundedIcon fontSize="small" />
                      </Avatar>
                      <EllipsisTypography>{c?.name}</EllipsisTypography>
                      <Chip
                        label={c?.communityId}
                        size="small"
                        sx={{ ml: "auto" }}
                      />
                    </ListItemButton>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Communities not found.."
                      primaryTypographyProps={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      color="text.secondary"
                    />
                  </ListItem>
                )}
              </ResultsList>
            )}
          </>
        )}
      </SearchInner>
    </Box>
  );
};

export default TokensDelegationSearchCommunity;
