import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { useState } from "react";
import { SupportedCollection } from "../../../types";
import ConfirmDialog from "../../../components/ConfirmDialog";
import useRemoveContractHashlist from "../../../hooks/queries/boosters/mutations/useRemoveContractHashlist";
import Box from "@mui/material/Box";
import AttributesDialog from "./AttributesDialog";
import UploadSubscriptionContractHashlistDialog from "../../../components/UploadSubscriptionContractHashlistDialog";
import { useAddContractHashlist } from "../../../hooks/queries/boosters/mutations/useAddContractHashlist";

type Props = {
  list?: Omit<SupportedCollection, "amount">[];
  title: string | JSX.Element;
  instructions: string | JSX.Element;
  noCollectionsMessage: string;
  hashlistType: "hashlist" | "eligibleHashlist";
  isTargetCollection?: boolean;
};

type RemoveModalaData = Omit<SupportedCollection, "amount"> & {
  type: "hashlist" | "eligibleHashlist";
};

const UniversalCollection: React.FC<Props> = ({
  list,
  title,
  instructions,
  noCollectionsMessage,
  hashlistType,
  isTargetCollection = false,
}) => {
  const onSettled = () => {
    setConfirmChangeStateData(null);
  };
  const { mutateAsync: removeHashlist, isLoading: removeHashlistLoading } =
    useRemoveContractHashlist({
      type: hashlistType,
      onSettled,
    });

  const [attributesDialogOpen, setAttributesDialogOpen] = useState(false);
  const [uploadHashlistDialogOpen, setUploadHashlistDialogOpen] =
    useState(false);
  const [confirmChangeStateData, setConfirmChangeStateData] =
    useState<RemoveModalaData | null>(null);

  const handleDelete = (collection: RemoveModalaData) => {
    setConfirmChangeStateData(collection);
  };

  return (
    <>
      <Box>
        <Typography variant="body1" fontWeight={700}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          {instructions}
        </Typography>
        {list?.length === 0 && (
          <Alert severity="warning" variant="filled">
            {noCollectionsMessage}
          </Alert>
        )}
        {list && list?.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                "td, th": {
                  borderColor: (theme) => theme.palette.divider,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={700}>Name</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={700}>Total</Typography>
                    <Typography
                      variant="overline"
                      lineHeight={1}
                      color="text.secondary"
                    >
                      NFT
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map((contract) => (
                  <TableRow
                    key={contract?.collectionId}
                    hover
                    sx={{
                      "&:last-child td": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>{contract?.name}</TableCell>
                    <TableCell align="center">
                      {contract?.fullHashlist?.length}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Remove hashlist" placement="left">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => {
                            handleDelete({
                              type: hashlistType,
                              ...contract,
                            });
                          }}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setUploadHashlistDialogOpen(true);
          }}
          sx={{ mt: 1 }}
        >
          Add hashlist
        </Button>
      </Box>
      {isTargetCollection && list && list?.length > 0 && (
        <Button
          fullWidth
          onClick={() => {
            setAttributesDialogOpen(true);
          }}
          sx={{ mt: 2 }}
        >
          Target configurator
        </Button>
      )}
      <UploadSubscriptionContractHashlistDialog
        open={uploadHashlistDialogOpen}
        hide={() => {
          setUploadHashlistDialogOpen(false);
        }}
        uploadHookResult={useAddContractHashlist({
          type: hashlistType,
        })}
      />
      <ConfirmDialog
        data={confirmChangeStateData}
        title="Are you sure?"
        content={
          <>Do you want remove hashlist "{confirmChangeStateData?.name}"?</>
        }
        isLoading={removeHashlistLoading}
        hide={() => {
          setConfirmChangeStateData(null);
        }}
        confirm={() => {
          if (!confirmChangeStateData) return;
          removeHashlist(confirmChangeStateData?.collectionId);
        }}
      />
      {isTargetCollection && (
        <AttributesDialog
          open={attributesDialogOpen}
          hide={() => {
            setAttributesDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default UniversalCollection;
