import AccordionSummary from "@mui/material/AccordionSummary";
import { DrawerAccordion } from "../../../components/StyledComponents";
import Menu from "./Menu";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SyntheticEvent, useState } from "react";
import { MenuAccordionProps, MenuItemType } from "./types";

const MenuAccordion = ({ data, defaultExpanded }: MenuAccordionProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => {
    setExpanded(expanded);
  };

  const { label, children, Icon } = data;

  return (
    <DrawerAccordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {Icon && <Icon />}
        {label}
      </AccordionSummary>
      <AccordionDetails>
        <Menu list={children as MenuItemType[]} />
      </AccordionDetails>
    </DrawerAccordion>
  );
};

export default MenuAccordion;
