import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { useAuth } from "./AuthService";
import { useGetCommunities } from "../hooks/queries/community/state/useGetCommunities";
import { CommunityInfo } from "../types";
import { useQueryClient } from "@tanstack/react-query";
import { useSignOut } from "../hooks/queries/auth/useSignOut";

type FirebaseFeatures = {
  selectedCommunity: CommunityInfo | null;
  selectedCommunityDocId: string | null;
  setSelectedCommunity?: Dispatch<SetStateAction<CommunityInfo | null>>;
  communityList?: CommunityInfo[];
  setSelectedCommunityDocId?: Dispatch<SetStateAction<string | null>>;
  communityListLoading: boolean;
  clearSelectedCommunity: () => void;
  loadingModal?: null | { title: string; description: string };
  setLoadingModal?: Dispatch<
    SetStateAction<null | { title: string; description: string } | undefined>
  >;
  logout?: () => void;
  mobileOpen: boolean;
  setMobileOpen: Dispatch<SetStateAction<boolean>>;
};

const AppContext = createContext<FirebaseFeatures>({
  selectedCommunityDocId: null,
  selectedCommunity: null,
  communityListLoading: true,
} as FirebaseFeatures);

export function useApp() {
  return useContext(AppContext);
}

export const AppService = ({ children }: PropsWithChildren) => {
  const { mutateAsync: signOut } = useSignOut();
  const queryClient = useQueryClient();
  const { loadingUserState, isAuthorized, resetTokens } = useAuth();
  const { data: communityList, isLoading: communityListDataLoading } =
    useGetCommunities();
  const [communityListLoading, setCommunityListLoading] = useState(true);
  const [selectedCommunityDocId, setSelectedCommunityDocId] = useLocalStorage(
    "communityId",
    null
  );
  const [selectedCommunity, setSelectedCommunity] =
    useState<FirebaseFeatures["selectedCommunity"]>(null);
  const [loadingModal, setLoadingModal] =
    useState<FirebaseFeatures["loadingModal"]>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (loadingUserState) return;
    if (isAuthorized) {
      if (communityListDataLoading) return;
      const selectedCommunityInfo = communityList?.find(
        (communityInfo) => communityInfo?.communityId === selectedCommunityDocId
      );
      setSelectedCommunity(
        selectedCommunityInfo ? selectedCommunityInfo : null
      );
    }
    setCommunityListLoading(false);
  }, [
    communityList,
    communityListDataLoading,
    isAuthorized,
    loadingUserState,
    selectedCommunityDocId,
  ]);

  const clearSelectedCommunity = () => {
    setSelectedCommunity(null);
    setSelectedCommunityDocId(null);
  };

  const logout = async () => {
    if (!resetTokens) return;
    resetTokens();
    queryClient.clear();
    clearSelectedCommunity();
    signOut();
  };

  const value: FirebaseFeatures = {
    communityListLoading,
    selectedCommunity,
    setSelectedCommunity,
    communityList,
    selectedCommunityDocId,
    setSelectedCommunityDocId,
    clearSelectedCommunity,
    loadingModal,
    setLoadingModal,
    logout,
    mobileOpen,
    setMobileOpen,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
