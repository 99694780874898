import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useGetContracts } from "../../hooks/queries/subscriptions/state/useGetContracts";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { getRewardProgram } from "../../utils";
import Loader from "../../components/Loader/Loader";
import Empty from "../../components/Empty";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import Tooltip from "@mui/material/Tooltip";
import { LiveDot } from "../../components/StyledComponents";
import Box from "@mui/material/Box";
import LinkBehavior from "../../components/LinkBehavior";

type FilterTypes = "all" | "live" | "draft" | "archived";

const SubscriptionContracts = () => {
  const navigate = useNavigate();
  const { data: contracts, isLoading: contractsLoading } = useGetContracts();

  const [filter, setFilter] = useState<FilterTypes>("all");

  const handleChangeFilter = (
    event: React.MouseEvent<HTMLElement>,
    filter: FilterTypes
  ) => {
    if (filter === null) return;
    setFilter(filter);
  };

  const handleClick = (id: string) => {
    navigate(id);
  };

  const filteredContracts = useMemo(
    () =>
      contractsLoading
        ? []
        : contracts
            ?.filter((item) => {
              switch (filter) {
                case "live":
                  return item?.Active;
                case "draft":
                  return !item?.Active && !item?.Archived;
                case "archived":
                  return item?.Archived;
                default:
                  return !item?.Archived;
              }
            })
            .sort((a, b) => {
              if (a?.Active && !b?.Active) return -1;
              if (!a?.Active && b?.Active) return 1;
              return 0;
            }),
    [contracts, contractsLoading, filter]
  );

  return (
    <>
      <Box mb={1}>
        <Typography variant="h5" fontWeight={700}>
          Membership Contracts
        </Typography>
        <Typography color="text.secondary">
          Start you journey by creating non-custodial subscription plans. The
          NFTs staked in the plan will remain in the stakers wallet and will
          stay staked until the user decides to un-stake them.
        </Typography>
      </Box>
      {!contractsLoading && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
          mb={1.5}
        >
          <ToggleButtonGroup
            color="primary"
            value={filter}
            exclusive
            onChange={handleChangeFilter}
            size="small"
          >
            <ToggleButton value="all">All</ToggleButton>
            <ToggleButton value="live">Published</ToggleButton>
            <ToggleButton value="draft">Draft</ToggleButton>
            <ToggleButton value="archived">
              <Inventory2RoundedIcon fontSize="small" sx={{ mr: 1 }} />
              Archived
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            LinkComponent={LinkBehavior}
            href="/subscription/create-contract"
            startIcon={<DataSaverOnRoundedIcon />}
            variant="base"
            sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
          >
            Create contract
          </Button>
        </Stack>
      )}
      {contractsLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : filteredContracts?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
        >
          <Empty text="Empty" />
        </Stack>
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              "td, th": {
                borderColor: (theme) => theme.palette.divider,
              },
            }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell width={20} sx={{ pr: 0 }} />
                <TableCell>
                  <Typography fontWeight={700} py={1}>
                    Details
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={700} py={1}>
                    Token
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={700} py={1}>
                    Period
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight={700} py={1}>
                    Program
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ tr: { cursor: "pointer" } }}>
              {filteredContracts?.map((contract) => (
                <TableRow
                  key={contract?.PlanId}
                  hover
                  role="button"
                  onClick={() => handleClick(contract?.PlanId)}
                  sx={{
                    "&:last-child td": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align="center" width={20} sx={{ pr: 0 }}>
                    {contract?.Archived ? (
                      <Tooltip placement="top" title="Archived">
                        <Inventory2RoundedIcon
                          color="disabled"
                          fontSize="small"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="top"
                        title={contract?.Active ? "Active" : "Inactive"}
                      >
                        <Box width={20} height={20} textAlign="center">
                          <LiveDot
                            color={contract?.Active ? "success" : "default"}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography>{contract?.PlanTitle}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {contract?.PlanDescription}
                    </Typography>
                  </TableCell>
                  <TableCell>${contract?.TokenName}</TableCell>
                  <TableCell>{contract?.StakingDuration} Days</TableCell>
                  <TableCell>
                    {getRewardProgram(contract?.RewardProgram)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default SubscriptionContracts;
