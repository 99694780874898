import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useWallet } from "@solana/wallet-adapter-react";
import { MutationDefaultResponse, TxRequestRes } from "../../../../types";
import { Transaction } from "@solana/web3.js";
import { API_PATHS } from "../../../../constants";
import { useApp } from "../../../../services/AppService";
import { useParams } from "react-router-dom";

export function useUnsubscribeAll({ onSuccess }: { onSuccess: () => void }) {
  const { selectedCommunityDocId } = useApp();
  const { planId } = useParams();
  const { signTransaction, publicKey } = useWallet();
  const { enqueueSnackbar } = useSnackbar();

  const unsubscribeAll = async (withRewards: boolean) => {
    const { data: txRequestData } = await axios.post<TxRequestRes>(
      API_PATHS.subscription.unsubscribeAll.request,
      {
        communityId: selectedCommunityDocId,
        planId,
        walletAddress: publicKey?.toString(),
        withRewards,
      }
    );
    if (!txRequestData?.success) {
      throw new Error(
        `${txRequestData?.error?.title}\n${txRequestData?.error?.description}`
      );
    }

    const transaction = Transaction.from(
      txRequestData?.data?.rawTransaction?.data
    );
    if (!signTransaction) {
      throw new Error("Can't create transaction");
    }
    const signedTransaction = await signTransaction(transaction);
    const { data: txProccessData } = await axios.post<
      MutationDefaultResponse["data"]
    >(API_PATHS.subscription.unsubscribeAll.process, {
      rawTransaction: Object.values(
        signedTransaction.serialize({
          requireAllSignatures: false,
          verifySignatures: true,
        })
      ),
      requestId: txRequestData?.data?.requestId,
    });
    if (!txProccessData?.success) {
      throw new Error(
        `${txProccessData?.error?.title}\n${txProccessData?.error?.description}`
      );
    }
    return txProccessData;
  };

  return useMutation(
    async (withRewards: boolean) => {
      return await unsubscribeAll(withRewards);
    },
    {
      onSuccess: () => {
        onSuccess();
        enqueueSnackbar({
          variant: "success",
          message: "Fund completed successfully",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign in. Try again!",
        });
      },
    }
  );
}
