import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import COUNTRIES from "../../../../countries-list.json";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment, { Moment } from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import { Controller, useForm } from "react-hook-form";
import { RewardSources } from "../../../../types";
import useSaveRewardStep from "../../../../hooks/queries/rewards/mutations/useSaveRewardStep";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import { BaseProps, Inputs } from "./types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRewardById } from "../../../../hooks/queries/rewards/state/useGetRewardById";
import { deepEqual } from "../../../../utils";
import {
  REWARD_CATEGORIES,
  REWARD_SOURCES,
  REWARD_TYPES,
} from "../../../../constants";

const Base = ({ next }: BaseProps) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { selectedCommunityDocId } = useApp();
  const { data: rewardInfo } = useGetRewardById();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: saveStep, isLoading: saveStepLoading } =
    useSaveRewardStep();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      rewardSource: "",
      rewardType: "",
      rewardCategory: "",
      autoRedeem: false,
      eligibleCountries: [],
    },
  });
  const formWatcher = watch();

  const [claimExpirationDate, setClaimExpirationDate] = useState<Moment | null>(
    null
  );
  const [dataFromBd, setDataFromBd] = useState<any>();

  useEffect(() => {
    if (!rewardInfo) return;
    const newStructure = {
      title: rewardInfo?.profile?.title,
      description: rewardInfo?.profile?.description,
      rewardSource: rewardInfo?.rewardSource,
      rewardType: rewardInfo?.rewardType,
      autoRedeem: rewardInfo?.autoRedeem,
      eligibleCountries: JSON.parse(
        JSON.stringify(rewardInfo?.eligibleCountries)
      ),
      rewardCategory: rewardInfo?.rewardCategory,
    };
    setDataFromBd(newStructure);
    setClaimExpirationDate(moment(rewardInfo?.claimExpirationDate));
    reset(newStructure);
  }, [reset, rewardInfo]);

  const onSubmit = async (data: Inputs) => {
    if (!claimExpirationDate?.isValid()) {
      enqueueSnackbar({
        variant: "error",
        message: "Set valid campaign end date, please",
      });
      return;
    }
    if (!selectedCommunityDocId || !campaignId) return;

    if (
      typeof rewardInfo !== "undefined" &&
      deepEqual(dataFromBd, data) &&
      claimExpirationDate?.isSame(rewardInfo?.claimExpirationDate)
    ) {
      next();
      return;
    }

    const res = await saveStep({
      action:
        campaignId === "create-new" ? "create-campaign" : "update-profile",
      form: {
        ...(campaignId !== "create-new" && { campaignId }),
        communityId: selectedCommunityDocId,
        claimExpirationDate: claimExpirationDate.toDate(),
        ...data,
      },
    });
    if (campaignId === "create-new") {
      navigate(`/rewards/${res?._id}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("title", {
          required: "This field is required",
        })}
        error={!!errors.title}
        helperText={!!errors.title && errors.title.message}
        disabled={saveStepLoading}
        required
        fullWidth
        label="Name"
        margin="dense"
      />
      <TextField
        {...register("description", {
          required: "This field is required",
        })}
        error={!!errors.description}
        helperText={!!errors.description && errors.description.message}
        disabled={saveStepLoading}
        required
        fullWidth
        label="Description"
        margin="dense"
        multiline
        rows={3}
      />
      <DatePicker
        label="Campaign end date"
        minDate={moment().add(1, "days")}
        desktopModeMediaQuery="@media (min-width: 720px)"
        disabled={saveStepLoading}
        slotProps={{
          textField: {
            helperText: "MM/DD/YYYY",
            required: true,
            fullWidth: true,
          },
        }}
        value={claimExpirationDate}
        onChange={(newValue) => setClaimExpirationDate(newValue)}
      />
      <FormControl fullWidth required margin="dense" disabled={saveStepLoading}>
        <InputLabel>Reward category</InputLabel>
        <Controller
          control={control}
          name="rewardCategory"
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label="Reward category"
              defaultValue=""
              error={!!errors.rewardCategory}
            >
              {REWARD_CATEGORIES.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl
        fullWidth
        required
        margin="dense"
        disabled={saveStepLoading || typeof rewardInfo !== "undefined"}
      >
        <InputLabel>Reward source</InputLabel>
        <Controller
          control={control}
          name="rewardSource"
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label="Reward source"
              defaultValue=""
              error={!!errors.rewardSource}
            >
              {REWARD_SOURCES.map(({ id, label, description }) => (
                <MenuItem
                  key={id}
                  value={id}
                  disabled={id !== RewardSources.System}
                >
                  <Box width="100%">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={700} lineHeight={1}>
                        {label}
                      </Typography>
                      {id !== RewardSources.System && (
                        <Chip
                          label="Coming soon!"
                          color="primary"
                          size="small"
                          sx={{ fontWeight: 700 }}
                        />
                      )}
                    </Stack>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      component="div"
                      mt={0.5}
                      lineHeight={1.15}
                    >
                      {description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {formWatcher?.rewardSource && (
        <FormControl
          fullWidth
          required
          margin="dense"
          disabled={saveStepLoading || typeof rewardInfo !== "undefined"}
        >
          <InputLabel>Reward type</InputLabel>
          <Controller
            control={control}
            name="rewardType"
            render={({ field }) => (
              <Select
                {...field}
                variant="outlined"
                label="Reward type"
                defaultValue=""
              >
                {REWARD_TYPES?.[formWatcher?.rewardSource]?.map(
                  ({ id, label, description }) => (
                    <MenuItem key={id} value={id}>
                      <Box width="100%">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography fontWeight={700} lineHeight={1}>
                            {label}
                          </Typography>
                        </Stack>
                        <Typography
                          color="text.secondary"
                          variant="caption"
                          component="div"
                          mt={0.5}
                          lineHeight={1.15}
                        >
                          {description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
        </FormControl>
      )}
      <Autocomplete
        value={formWatcher.eligibleCountries}
        onChange={(e, newValue) => {
          setValue("eligibleCountries", newValue);
        }}
        multiple
        options={COUNTRIES}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Eligible countries"
            placeholder="Select country"
            helperText="Leave empty if the reward can be redeemed world wide"
          />
        )}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        disabled={saveStepLoading}
      />
      <FormControl margin="dense" disabled={saveStepLoading}>
        <FormControlLabel
          control={
            <Controller
              name="autoRedeem"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          }
          label={
            <>
              <Typography variant="subtitle1" fontWeight={700} mt={0.75}>
                One-Step Redemption: Instantly redeem Reward
              </Typography>
              <Typography color="text.secondary" variant="body2">
                Check this box to enjoy a seamless user experience with just one
                step: redeeming a reward. Upon redemption, the user will
                instantly receive the codes for your chosen reward, ensuring
                that it remains exclusive to him and cannot be traded within
                your brand's personal marketplace. If left unchecked, an
                additional 'claim' step will be introduced. Your users can
                freely trade the reward within your marketplace, without any
                concerns about buying a used reward. Once a user redeems the
                reward upon claiming, it will undergo a transformation into its
                'redeemed' version, and the code will be revealed. After
                redemption, the user will no longer be able to trade the reward.
              </Typography>
            </>
          }
          sx={{ alignItems: "flex-start" }}
        />
      </FormControl>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mb: 2 }}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={saveStepLoading}
          sx={{ mt: 1, mr: 1 }}
        >
          Continue
        </LoadingButton>
      </Box>
    </form>
  );
};

export default Base;
