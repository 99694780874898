import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent, {
  dialogContentClasses,
} from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useGetAccumulatedRewards } from "../../hooks/queries/tokens/state/useGetAccumulatedRewards";
import { Token } from "../../types";
import { useDefund } from "../../hooks/queries/tokens/mutations/useDefund";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import Chip from "@mui/material/Chip";
import { cryptoAmountToView } from "../../utils";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export interface Props {
  token: Token | null;
  hide: () => void;
}

type Inputs = {
  amount: number | string;
};

const TokenDefundModal = ({ token, hide }: Props) => {
  const { publicKey } = useWallet();
  const { mutateAsync: defund, isLoading: defundLoading } = useDefund({
    onSuccess: () => {
      hide();
    },
  });
  const theme = useTheme();
  const {
    formState: { errors },
    handleSubmit,
    resetField,
    control,
    setValue,
  } = useForm<Inputs>({
    defaultValues: { amount: undefined },
  });
  const { data: accumulatedRewards, isLoading: accumulatedRewardsLoading } =
    useGetAccumulatedRewards(token?.tokenMetadata?.address);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    resetField("amount");
  }, [resetField, token?.tokenId]);

  const onSave = (inputs: Inputs) => {
    if (!token?.tokenId || typeof inputs.amount === "string") return;
    defund({ tokenId: token?.tokenId, amount: inputs.amount });
  };

  const handleSetMaxDefund = () => {
    setValue("amount", availableToDefund, { shouldValidate: true });
  };

  const availableToDefund =
    typeof token?.balance === "number" &&
    token?.balance > 0 &&
    typeof accumulatedRewards?.sumTotalRewards === "number"
      ? token?.balance - accumulatedRewards?.sumTotalRewards
      : 0;

  return (
    <Dialog
      open={!!token}
      onClose={defundLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
      sx={{
        ".MuiPaper-root": { overflowY: "unset" },
        [`.${dialogContentClasses.root}`]: { overflowY: "unset" },
      }}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <DialogTitle component="div" sx={{ pr: 6 }}>
          Defund ${token?.tokenMetadata?.symbol} reward pool
          <IconButton
            aria-label="close"
            disabled={defundLoading}
            onClick={hide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {accumulatedRewardsLoading ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <CircularProgress size={24} />
              <Typography ml={1}>
                Loading rewards stats. Please, wait..
              </Typography>
            </Stack>
          ) : (
            <>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography color="text.secondary">Pool balance:</Typography>
                <Typography fontWeight={700}>
                  {token?.balance && token?.balance < 0
                    ? 0
                    : token?.balance.toLocaleString()}{" "}
                  ${token?.tokenMetadata?.symbol}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography color="text.secondary">Daily rewards:</Typography>
                <Typography fontWeight={700}>
                  {cryptoAmountToView(accumulatedRewards?.sumTotalDailyRewards)}{" "}
                  ${token?.tokenMetadata?.symbol}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography color="text.secondary">Total rewards:</Typography>
                <Typography fontWeight={700}>
                  {cryptoAmountToView(accumulatedRewards?.sumTotalRewards)} $
                  {token?.tokenMetadata?.symbol}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography color="text.secondary">
                  Available to defund:
                </Typography>
                <Typography fontWeight={700}>
                  {availableToDefund < 0
                    ? 0
                    : cryptoAmountToView(availableToDefund)}{" "}
                  ${token?.tokenMetadata?.symbol}
                </Typography>
              </Stack>
              {availableToDefund < 0 && (
                <Alert severity="warning" variant="filled" sx={{ mt: 2 }}>
                  <AlertTitle>Insufficient funds in the pool</AlertTitle>
                  <Typography>
                    Your pool is in debt as users are owed more than its
                    balance. Missing balance:{" "}
                    <strong>
                      {cryptoAmountToView(availableToDefund)} $
                      {token?.tokenMetadata?.symbol}
                    </strong>
                  </Typography>
                </Alert>
              )}
            </>
          )}
          {availableToDefund > 0 && (
            <Box>
              <Controller
                control={control}
                name="amount"
                rules={{
                  validate: () =>
                    availableToDefund > 0.00000001 || "Not enought funds",
                  min: {
                    value: 0.00000001,
                    message: "Minimum is 0.00000001",
                  },
                  ...(availableToDefund > 0.00000001 && {
                    max: {
                      value: availableToDefund,
                      message: `Maximum is ${cryptoAmountToView(
                        availableToDefund
                      )} ${token?.tokenMetadata?.symbol}`,
                    },
                  }),
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(event) => {
                      field.onChange(
                        event.target.value?.trim() === ""
                          ? ""
                          : +event.target.value
                      );
                    }}
                    type="number"
                    error={!!errors.amount}
                    helperText={!!errors.amount && errors.amount.message}
                    required
                    fullWidth
                    label="Number of tokens to defund"
                    inputProps={{ step: "any" }}
                    disabled={defundLoading || accumulatedRewardsLoading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          ${token?.tokenMetadata?.symbol}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Chip
                onClick={handleSetMaxDefund}
                disabled={defundLoading || accumulatedRewardsLoading}
                label="Max"
                size="small"
                sx={{ mb: 1 }}
              />
              {publicKey && (
                <Box mb={2}>
                  <Typography variant="caption" color="text.secondary">
                    Destination wallet address
                  </Typography>
                  <Typography fontWeight={500}>
                    {publicKey?.toString()}
                  </Typography>
                </Box>
              )}
              <Box textAlign="center">
                <Typography
                  variant="overline"
                  fontWeight={700}
                  color="text.secondary"
                  component="div"
                >
                  {publicKey ? "Your wallet" : "Please, connect your wallet"}
                </Typography>
                <Box display="inline-block">
                  <WalletMultiButton disabled={defundLoading} />
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        {publicKey && availableToDefund > 0 && (
          <DialogActions>
            <LoadingButton
              type="submit"
              variant="contained"
              color="error"
              disabled={accumulatedRewardsLoading}
              loading={defundLoading}
            >
              Defund
            </LoadingButton>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

export default TokenDefundModal;
