import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { useGettAllRules } from "../../../../hooks/queries/rules/state/useGetAllRules";
import CircularProgress from "@mui/material/CircularProgress";
import EligibilityHashlists from "./EligibilityHashlists";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { EligibilityProps, Hashlist, Inputs } from "./types";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import useSaveRewardStep from "../../../../hooks/queries/rewards/mutations/useSaveRewardStep";
import { useGetRewardById } from "../../../../hooks/queries/rewards/state/useGetRewardById";
import { EligableTypes } from "../../../../types";
import { useParams } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { deepEqual } from "../../../../utils";
import EligibleHashlist from "../../../../components/EligibleHashlist/EligibleHashlist";
import { CLAIM_VERIFICATION_METHODS } from "../../../../constants";

const Eligibility = ({ next, prev }: EligibilityProps) => {
  const { campaignId } = useParams();
  const { data: rulesList, isLoading: rulesListLoading } = useGettAllRules();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const { mutateAsync: saveStep, isLoading: saveStepLoading } =
    useSaveRewardStep();
  const { data: rewardInfo } = useGetRewardById();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      ruleTreeId: "",
      type: "",
    },
  });
  const formWatcher = watch();
  const [eligibleHashlists, setEligibleHashlists] = useState<Hashlist[]>([
    { minToClaim: "" },
  ]);
  const [eligibleWalletsHashlist, setEligibleWalletsHashlist] = useState<
    Hashlist[]
  >([{ minToClaim: 1 }]);
  const [dataFromBd, setDataFromBd] = useState<any>();

  useEffect(() => {
    if (!rewardInfo?.eligibleAudience) return;
    const newStructure = {
      ruleTreeId: rewardInfo?.eligibleAudience?.ruleTreeId || "",
      ruleTreeDescription: rewardInfo?.eligibleAudience?.ruleTreeDescription,
      maxClaimsPerWalletAddress:
        rewardInfo?.eligibleAudience?.maxClaimsPerWalletAddress,
      type: rewardInfo?.eligibleAudience?.type,
    };
    if (rewardInfo?.eligibleAudience?.eligibleHashlists?.length > 0) {
      if (rewardInfo?.eligibleAudience?.type === EligableTypes.Hashlist) {
        setEligibleHashlists(
          JSON.parse(
            JSON.stringify(rewardInfo?.eligibleAudience?.eligibleHashlists)
          )
        );
      } else if (
        rewardInfo?.eligibleAudience?.type === EligableTypes.WalletAddresses
      ) {
        setEligibleWalletsHashlist(
          JSON.parse(
            JSON.stringify(rewardInfo?.eligibleAudience?.eligibleHashlists)
          )
        );
      }
    }
    setDataFromBd(newStructure);
    reset(newStructure);
  }, [reset, rewardInfo]);

  const handleEligibilityAdd = () => {
    setEligibleHashlists((state) => [...state, { minToClaim: "" }]);
  };

  const handleEligibilityRemove = (index: number) => {
    setEligibleHashlists((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const clearRule = () => {
    setValue("ruleTreeId", "");
  };

  const onEligibilityFieldChange = (
    index: number,
    key: keyof Hashlist,
    value: any
  ) => {
    setEligibleHashlists((state) => {
      const newState = [...state];
      newState[index][key] = value;
      return newState;
    });
  };

  const onEligibilityWalletsFieldChange = (
    index: number,
    key: keyof Hashlist,
    value: any
  ) => {
    setEligibleWalletsHashlist((state) => {
      const newState = [...state];
      newState[0][key] = value;
      return newState;
    });
  };

  const onSubmit = async (data: Inputs) => {
    if (!selectedCommunityDocId || !campaignId) return;
    if (data.type === EligableTypes.Hashlist) {
      if (eligibleHashlists.some((v) => !v.hashlistTitle)) {
        enqueueSnackbar({
          variant: "error",
          message: "Upload haslist file, please",
        });
        return;
      }
      if (eligibleHashlists.some((v) => !v?.minToClaim || v?.minToClaim < 1)) {
        enqueueSnackbar({
          variant: "error",
          message: "Number of owned NFTs most be bigger then 1",
        });
        return;
      }
      if (
        eligibleHashlists.some(
          (v) =>
            v?.size &&
            typeof v?.minToClaim == "number" &&
            v?.size < v?.minToClaim
        )
      ) {
        enqueueSnackbar({
          variant: "error",
          message: "Number of owned NFTs can't be less then collectibles",
        });
        return;
      }
    }
    if (
      data.type === EligableTypes.WalletAddresses &&
      !eligibleWalletsHashlist?.[0]?.hashlistTitle
    ) {
      enqueueSnackbar({
        variant: "error",
        message: "Upload haslist file, please",
      });
      return;
    }

    if (
      typeof rewardInfo?.eligibleAudience !== "undefined" &&
      deepEqual(dataFromBd, {
        ...data,
      })
    ) {
      if (data?.type === EligableTypes.Unlimited) {
        next();
        return;
      }
      if (
        data?.type === EligableTypes.Hashlist &&
        deepEqual(
          rewardInfo?.eligibleAudience?.eligibleHashlists,
          eligibleHashlists
        )
      ) {
        next();
        return;
      }
      if (
        data?.type === EligableTypes.WalletAddresses &&
        deepEqual(
          rewardInfo?.eligibleAudience?.eligibleHashlists,
          eligibleWalletsHashlist
        )
      ) {
        next();
        return;
      }
    }

    saveStep({
      action: "update-eligibility",
      form: {
        campaignId,
        communityId: selectedCommunityDocId,
        eligableHashlists:
          data?.type === EligableTypes.WalletAddresses
            ? eligibleWalletsHashlist
            : eligibleHashlists,
        ...data,
        ...(data?.type === EligableTypes.Hashlist && {
          maxClaimsPerWalletAddress: 10_000,
        }),
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography fontWeight={700} variant="h6">
        Claim eligibility
      </Typography>
      <Typography color="text.secondary">
        Choose a rule to gate the claim button of the reward. Only eligible
        users that abide to the rule will be able to claim. Leave empty to let
        any user on the platform to claim the reward
      </Typography>
      {rulesListLoading ? (
        <Stack sx={{ my: 2 }} direction="row" spacing={1.5} alignItems="center">
          <Box>
            <CircularProgress />
          </Box>
          <Typography>
            In the process of getting a list of rules. Please, wait..
          </Typography>
        </Stack>
      ) : (
        <Box mb={2}>
          <FormControl fullWidth margin="dense" disabled={saveStepLoading}>
            <InputLabel>Select rule</InputLabel>
            <Controller
              control={control}
              name="ruleTreeId"
              render={({ field }) => (
                <Select
                  {...field}
                  variant="outlined"
                  label="Select rule"
                  endAdornment={
                    formWatcher?.ruleTreeId ? (
                      <Stack direction="row" mr={2}>
                        <IconButton size="small" onClick={clearRule}>
                          <CloseRoundedIcon fontSize="small" />
                        </IconButton>
                        <Tooltip title="Go to selected rule configurator">
                          <IconButton
                            size="small"
                            onClick={() => {
                              window.open(
                                `/rules/${formWatcher?.ruleTreeId}`,
                                "_blank"
                              );
                            }}
                          >
                            <OpenInNewRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    ) : null
                  }
                  defaultValue=""
                >
                  {rulesList?.map((r) => (
                    <MenuItem key={r?._id} value={r?._id}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Box>
                          <Typography fontWeight={700} lineHeight={1}>
                            {r?.name}
                          </Typography>
                          <Typography color="text.secondary" variant="caption">
                            {r?.description}
                          </Typography>
                        </Box>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          {formWatcher?.ruleTreeId && (
            <TextField
              {...register("ruleTreeDescription", {
                required: "This field is required",
              })}
              error={!!errors.ruleTreeDescription}
              helperText="Describe what the user must do to be able to claim. Remember this will be presented to the users"
              disabled={saveStepLoading}
              required
              fullWidth
              label="Rule description"
              margin="dense"
              multiline
              rows={3}
            />
          )}
        </Box>
      )}
      <Typography fontWeight={700} variant="h6">
        Claim Manager
      </Typography>
      <Typography color="text.secondary">
        Define the method to limit the number of rewards a user can claim
      </Typography>
      <FormControl fullWidth margin="dense" disabled={saveStepLoading} required>
        <InputLabel>Method</InputLabel>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label="Method"
              defaultValue=""
              error={!!errors.type}
            >
              {CLAIM_VERIFICATION_METHODS.map(({ id, label, description }) => (
                <MenuItem key={id} value={id}>
                  <Box width="100%" whiteSpace="normal">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={700} lineHeight={1}>
                        {label}
                      </Typography>
                    </Stack>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      component="div"
                      mt={0.5}
                      lineHeight={1.15}
                    >
                      {description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {formWatcher?.type === EligableTypes.Hashlist && (
        <EligibilityHashlists
          list={eligibleHashlists}
          onAdd={handleEligibilityAdd}
          onRemove={handleEligibilityRemove}
          onChange={onEligibilityFieldChange}
          disabled={saveStepLoading}
        />
      )}
      {formWatcher?.type === EligableTypes.WalletAddresses && (
        <Box mt={2}>
          <EligibleHashlist
            type="wallets"
            data={eligibleWalletsHashlist[0]}
            order={0}
            onChange={onEligibilityWalletsFieldChange}
            disabled={saveStepLoading}
          />
        </Box>
      )}
      {formWatcher?.type !== EligableTypes.Hashlist && (
        <>
          <Typography fontWeight={700} variant="h6" mt={1}>
            Checks & Balance
          </Typography>
          <Typography color="text.secondary">
            Restrict the number of claims per wallet connected
          </Typography>
          <TextField
            {...register("maxClaimsPerWalletAddress", {
              required: "This field is required",
              valueAsNumber: true,
              min: {
                value: 1,
                message: "Minimum number of claims is 1",
              },
            })}
            error={!!errors.maxClaimsPerWalletAddress}
            helperText={
              !!errors.maxClaimsPerWalletAddress &&
              errors.maxClaimsPerWalletAddress.message
            }
            type="number"
            label="Number of claims per wallet"
            margin="dense"
            required
            fullWidth
            disabled={saveStepLoading}
            sx={{ mb: 2 }}
          />
        </>
      )}
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mt: 2 }}>
        <Button
          variant="base"
          color="secondary"
          disabled={saveStepLoading}
          onClick={prev}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={rulesListLoading}
          loading={saveStepLoading}
          sx={{ ml: 1 }}
        >
          Continue
        </LoadingButton>
      </Box>
    </form>
  );
};

export default Eligibility;
