import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import { RuleListItem, RuleTarget } from "../../types";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LinkBehavior from "../../components/LinkBehavior";

type Props = { rule: RuleListItem; handleGetLink: () => void };

const RulesListItem = ({ rule, handleGetLink }: Props) => {
  return (
    <ListItem color="background.default" disablePadding>
      <ListItemButton LinkComponent={LinkBehavior} href={rule?._id}>
        <ListItemText primary={rule?.name} secondary={rule?.description} />
      </ListItemButton>
      <Stack direction="row" alignItems="center" spacing={0.5} mx={2}>
        <Chip
          label={
            <>
              <strong>{rule?.hits}</strong> hits
            </>
          }
        />
        {rule?.target === RuleTarget.WalletAddress && (
          <Tooltip title="Get rule link" placement="top">
            <IconButton size="small" color="info" onClick={handleGetLink}>
              <InsertLinkRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </ListItem>
  );
};

export default RulesListItem;
