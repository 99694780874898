import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import {
  StyledLogoIcon,
  StyledLogoName,
} from "../../../components/StyledComponents";
import Logo from "../../../components/images/Logo";
import Typography from "@mui/material/Typography";
import SignIn from "../forms/SignIn";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import { useState } from "react";
import Box from "@mui/material/Box";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import SignUp from "../forms/SignUp";
import Forgot from "../forms/Forgot";

const BOX_TITLES = {
  in: { title: "Sign In", FormEl: SignIn },
  up: { title: "Sign Up", FormEl: SignUp },
  forgot: { title: "Reset Password", FormEl: Forgot },
};

const AuthBox = () => {
  const [formType, setFormType] = useState<"in" | "up" | "forgot">("in");
  const { title, FormEl } = BOX_TITLES[formType];

  return (
    <Paper elevation={3} sx={{ overflow: "hidden", m: 2 }}>
      <Box
        sx={{
          height: 8,
          background:
            "linear-gradient(90deg, #FFE000 0%, #FF9962 50.52%, #947DFF 100%)",
        }}
      />
      <Box p={3}>
        <Stack
          flexDirection="row"
          alignItems="center"
          sx={{ whiteSpace: "nowrap", mb: 3 }}
        >
          <StyledLogoIcon>
            <Logo width={22} />
          </StyledLogoIcon>
          <StyledLogoName>
            <Typography component="div" fontSize={14}>
              ANYBODIES
            </Typography>
            <Typography
              variant="gradient"
              fontSize={14}
              component="div"
              mt={0.25}
              display="inline-block"
            >
              Studio
            </Typography>
          </StyledLogoName>
        </Stack>
        <Typography variant="h5" fontWeight={700}>
          {title}
        </Typography>
        <FormEl setType={setFormType} />
        <Divider textAlign="center" sx={{ my: 1.5 }}>
          OR
        </Divider>
        <Button
          variant="base"
          color="secondary"
          fullWidth
          startIcon={
            formType === "in" ? (
              <PersonAddRoundedIcon />
            ) : (
              <KeyboardBackspaceRoundedIcon />
            )
          }
          onClick={() => {
            setFormType((state) => (state === "in" ? "up" : "in"));
          }}
        >
          {formType === "in" ? "Create account" : "Back to Sign In"}
        </Button>
      </Box>
    </Paper>
  );
};

export default AuthBox;
