import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import {
  MutationDefaultResponse,
  ProcessPhygitalPayload,
  PublishPhygitalPayload,
  TxRequestResponse,
} from "../../../../types";
import { API_PATHS } from "../../../../constants";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";
const publishPhygitalRequest = async (data: PublishPhygitalPayload) => {
  return await axios.post<TxRequestResponse>(
    API_PATHS.phygital.publish.request,
    { data }
  );
};

const publishPhygitalProcess = async (data: ProcessPhygitalPayload) => {
  return await axios.post<MutationDefaultResponse>(
    API_PATHS.phygital.publish.process,
    { data }
  );
};
export default function usePublishPhygital({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { selectedCommunityDocId, setLoadingModal } = useApp();
  const { publicKey, signTransaction } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    async (claimableId: string) => {
      if (!selectedCommunityDocId || !claimableId || !publicKey) return;

      const { data: publishTxRes } = await publishPhygitalRequest({
        communityId: selectedCommunityDocId,
        claimableId,
        walletAddress: publicKey?.toString(),
      });

      if (!isSuccessResponse(publishTxRes?.data)) {
        throw new Error(
          `${publishTxRes?.data?.error?.title}\n${publishTxRes?.data?.error?.description}`
        );
      }

      if (!signTransaction) {
        throw new Error("Can't create transaction");
      }
      console.log(publishTxRes);

      const transaction = Transaction.from(
        (publishTxRes as any).data.rawTransaction?.data
      );

      const signedTransaction = await signTransaction(transaction);
      const { data: finalRes } = await publishPhygitalProcess({
        communityId: selectedCommunityDocId,
        claimableId,
        rawTransaction: Object.values(signedTransaction.serialize()),
        requestId: (publishTxRes as any)?.data?.requestId,
      });
      if (!isSuccessResponse(finalRes?.data)) {
        throw new Error(
          `${finalRes?.data?.error?.title}\n${finalRes?.data?.error?.description}`
        );
      }
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.phygital.byId, claimableId],
        exact: true,
        refetchType: "active",
      });
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.phygital.list, selectedCommunityDocId],
        exact: true,
        refetchType: "active",
      });
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.phygital.publishedList, selectedCommunityDocId],
        exact: true,
        refetchType: "active",
      });
      return finalRes;
    },
    {
      onMutate: () => {
        if (!setLoadingModal) return;
        setLoadingModal({
          title: "Phygital publishing in process",
          description: "May take a while, trust the process",
        });
      },
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Phygital now published!",
        });
        onSuccess();
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        if (!setLoadingModal) return;
        setLoadingModal(null);
      },
      onSettled: () => {
        if (!setLoadingModal) return;
        setLoadingModal(null);
      },
    }
  );
}
