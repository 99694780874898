import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useGettAllRules } from "../../hooks/queries/rules/state/useGetAllRules";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LinkBehavior from "../../components/LinkBehavior";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { TokenCampaignDetails } from "../../types";

const TokensDelegationConfig = ({
  campaign,
}: {
  campaign: TokenCampaignDetails;
}) => {
  const { data: rulesList } = useGettAllRules();
  return (
    <>
      <Box
        textAlign="left"
        sx={{
          overflowX: "auto",
          "& table": {
            tableLayout: "fixed",
            maxWidth: "100%",
            tr: {
              td: {
                verticalAlign: "top",
                borderSpacing: 0,
                py: 0.5,
                "&:not(:first-of-type)": { pl: 2 },
                "&:last-of-type": {
                  fontWeight: "bold",
                },
              },
            },
          },
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>Title:</td>
              <td>{campaign?.title}</td>
            </tr>
            <tr>
              <td>Description:</td>
              <td>{campaign?.description}</td>
            </tr>
            <tr>
              <td>Token:</td>
              <td>
                <Stack
                  direction="row"
                  alignItems="center"
                  width="100%"
                  spacing={0.5}
                >
                  <Avatar
                    alt={campaign?.tokenMetadata?.symbol}
                    src={campaign?.tokenMetadata?.uri}
                    variant="rounded"
                    sx={{ width: 20, height: 20 }}
                  >
                    <InsertPhotoRoundedIcon />
                  </Avatar>
                  <Typography fontWeight={700}>
                    ${campaign?.tokenMetadata?.symbol}
                  </Typography>
                </Stack>
              </td>
            </tr>
            {campaign?.created && (
              <tr>
                <td>Created:</td>
                <td>
                  {new Date(campaign?.created)?.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </td>
              </tr>
            )}
            <tr>
              <td>Campaign duration:</td>
              <td>
                {campaign?.phases?.reduce((acc, phase) => {
                  const { duration } = phase;
                  if (!duration) return acc;
                  return acc + duration;
                }, 0)}{" "}
                days
              </td>
            </tr>
            <tr>
              <td>Total cost for campaign per 1 community:</td>
              <td>
                {campaign?.phases?.reduce((acc, phase) => {
                  const { dailyReward, duration, maxSubscriptions } = phase;
                  if (!dailyReward || !duration || !maxSubscriptions)
                    return acc;
                  const total = dailyReward * duration * maxSubscriptions;
                  return acc + total;
                }, 0)}{" "}
                tokens
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Typography variant="h6" fontWeight="bold" mt={2} mb={1}>
        Phases
      </Typography>
      <Stack alignItems="center" justifyContent="flex-start" spacing={0.75}>
        {campaign?.phases?.map((phase, index) => {
          const phaseRule = rulesList?.find((rule) => rule._id === phase.rule);
          const phaseEntryRule = rulesList?.find(
            (rule) => rule._id === phase.entryRule
          );
          return (
            <Fragment key={index}>
              <Paper sx={{ p: 1, width: "100%" }} key={index}>
                <Typography fontWeight={700} mb={0.5}>
                  Phase {index + 1}
                </Typography>
                {index !== 0 && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      mb={1}
                    >
                      <KeyRoundedIcon fontSize="small" color="secondary" />
                      <Typography color="text.secondary">
                        Checking the fulfillment of the conditions for starting
                        a phase
                      </Typography>
                    </Stack>
                    <Typography component="div">
                      Rule:{" "}
                      <Stack
                        display="inline-flex"
                        direction="row"
                        alignItems="center"
                        spacing={0.5}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {phaseEntryRule?.name || "—"}
                        </span>
                        {phaseEntryRule && (
                          <Tooltip title="Go to rule configurator">
                            <IconButton
                              color="primary"
                              LinkComponent={LinkBehavior}
                              size="small"
                              href={`/rules/${phase?.entryRule}` || "#"}
                              target="_blank"
                            >
                              <OpenInNewRoundedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                    </Typography>
                    {phase?.entryRuleDescription && (
                      <Typography component="div">
                        Rule description:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {phase?.entryRuleDescription}
                        </span>
                      </Typography>
                    )}
                    <Divider sx={{ my: 1 }} />
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={0.5}
                      mb={1}
                    >
                      <TuneRoundedIcon fontSize="small" color="info" />
                      <Typography color="text.secondary">Config</Typography>
                    </Stack>
                  </>
                )}
                <Typography component="div">
                  Daily reward:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {phase?.dailyReward}
                  </span>
                </Typography>
                <Typography component="div">
                  Duration:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {` ${phase?.duration} day${phase?.duration > 1 ? "s" : ""}`}
                  </span>
                </Typography>
                <Typography component="div">
                  Rule:{" "}
                  <Stack
                    display="inline-flex"
                    direction="row"
                    alignItems="center"
                    spacing={0.5}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {phaseRule?.name || "—"}
                    </span>
                    {phaseRule && (
                      <Tooltip title="Go to rule configurator">
                        <IconButton
                          color="primary"
                          LinkComponent={LinkBehavior}
                          size="small"
                          href={`/rules/${phase.rule}` || "#"}
                          target="_blank"
                        >
                          <OpenInNewRoundedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Typography>
                {phase?.ruleDescription && (
                  <Typography component="div">
                    Rule description:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {phase?.ruleDescription}
                    </span>
                  </Typography>
                )}
                <Typography component="div">
                  Maximum subscriptions:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {phase?.maxSubscriptions}
                  </span>
                </Typography>
                <Typography component="div">
                  Maximum subscriptions <strong>per wallet</strong>:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {phase?.maxSubscriptionsPerWallet || "Infinity"}
                  </span>
                </Typography>
              </Paper>
              {index !== campaign?.phases?.length - 1 && (
                <Box
                  sx={{
                    height: 16,
                    backgroundColor: "divider",
                    width: "1px",
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </Stack>
    </>
  );
};

export default TokensDelegationConfig;
