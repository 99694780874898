import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { LoginResponse } from "../../../types";
import axios from "axios";
import { useAuth } from "../../../services/AuthService";
import { API_PATHS } from "../../../constants";

async function signIn(email: string, password: string) {
  return await axios.post<LoginResponse>(API_PATHS.auth.login, {
    email,
    password,
  });
}

export function useSignIn() {
  const { enqueueSnackbar } = useSnackbar();
  const { updateTokens } = useAuth();

  return useMutation(
    async ({ email, password }: { email: string; password: string }) => {
      const { data } = await signIn(email, password);
      if (!data?.success) {
        throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
      }
      return data;
    },
    {
      onSuccess: (res) => {
        if (!updateTokens) return;
        updateTokens(
          res?.data?.idToken,
          res?.data?.refreshToken,
          res?.data?.expiresIn
        );
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign in. Try again!",
        });
      },
    }
  );
}
