import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../../helpers/ModalTransition";
import LoadingButton from "@mui/lab/LoadingButton";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import { useUnsubscribeAll } from "../../../hooks/queries/subscriptions/mutations/useUnsubscribeAll";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export interface Props {
  open: boolean;
  hide: () => void;
}

const SubscriptionContractUnsubscribeDialog = ({ open, hide }: Props) => {
  const { publicKey } = useWallet();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { mutateAsync: unsubscribeAll, isLoading: unsubscribeAllLoading } =
    useUnsubscribeAll({
      onSuccess: () => {
        hide();
      },
    });
  const [withRewards, setWithRewards] = useState(true);

  const handleUnsubscribe = async () => {
    if (!publicKey) return;
    await unsubscribeAll(withRewards);
  };

  return (
    <Dialog
      open={open}
      onClose={unsubscribeAllLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <DialogTitle>Unsubscribe all</DialogTitle>
      <DialogContent>
        <Alert severity="warning" variant="filled" sx={{ mb: 2 }}>
          <AlertTitle>Be careful!</AlertTitle>
          <Typography>
            This action will <strong>unsubscribe</strong> all tokens from this
            contract. This action cannot be undone.
          </Typography>
        </Alert>
        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={withRewards}
              onChange={() => setWithRewards((state) => !state)}
            />
          }
          label="Unsubscribe all tokens with rewards"
          disabled={unsubscribeAllLoading}
        />
      </DialogContent>
      <DialogActions>
        {publicKey ? (
          <LoadingButton
            variant="contained"
            color="error"
            loading={unsubscribeAllLoading}
            onClick={handleUnsubscribe}
          >
            Unsubscribe all
          </LoadingButton>
        ) : (
          <WalletMultiButton />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionContractUnsubscribeDialog;
