import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../../../services/AppService";
import { CommunitiesByCampaignResponse } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { useParams } from "react-router-dom";

const getCommunitiesByCompaign = async (payload: {
  communityId: string;
  campaignId: string;
}) => {
  const { data } = await axios.post<CommunitiesByCampaignResponse>(
    API_PATHS.tokenCampaigns.communitiesByCampaign,
    payload
  );
  return data;
};

export const useGetCommunitiesByCompaign = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const { campaignId } = useParams();

  return useQuery(
    [API_PATHS.tokenCampaigns.communitiesByCampaign, campaignId],
    async () => {
      if (!selectedCommunityDocId || !campaignId) return;
      const res = await getCommunitiesByCompaign({
        communityId: selectedCommunityDocId,
        campaignId,
      });
      if (!res?.success) {
        throw new Error(`${res?.error?.title}\n${res?.error?.description}`);
      }
      return res?.data;
    },
    {
      enabled: !!selectedCommunityDocId && !!campaignId,
      staleTime: 30 * 60 * 1000,
      retry: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};
