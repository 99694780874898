import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";

type Props = {
  isAllowed: boolean;
  redirectPath: string;
};

const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/auth",
  children,
}: PropsWithChildren<Props>) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
