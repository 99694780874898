import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { UpdateQuestBannerPayload, UploadedImage } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

type Props = {
  onSettled: () => void;
};

const updateQuestBanner = async (data: UpdateQuestBannerPayload) => {
  return await axios.post<any>(API_PATHS.loyalty.update.banner, { data });
};

export default function useUpdateQuestBanner({ onSettled }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { questId } = useParams();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UploadedImage) => {
      if (!questId) return;
      const { data } = await updateQuestBanner({ ...payload, questId });

      if (isSuccessResponse(data?.data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.loyalty.byId, questId],
          exact: true,
          refetchType: "active",
        });
        return data.data;
      }
      throw new Error(
        `${data.data?.error?.title}\n${data.data?.error?.description}`
      );
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar({
          variant: "success",
          message: "Quest banner successfully updated",
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
}
