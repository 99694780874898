import { useState } from "react";
import RulesTree from "./RulesTree/RulesTree";
import RulesTreeAddModal from "./RulesTree/RulesTreeAddModal";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useGetRuleTree } from "../../hooks/queries/rules/state/useGetRuleTree";
import {
  ConditionOperator,
  Rule as Verification,
  RuleType,
  CommunityRuleType,
  RuleTarget,
} from "../../types";
import useAddNewRule from "../../hooks/queries/rules/mutations/useAddNewRule";
import RulesTreeItemLabel from "./RulesTree/RulesTreeItemLabel";
import Paper from "@mui/material/Paper";
import useRemoveRule from "../../hooks/queries/rules/mutations/useRemoveRule";
import useUpdateCondition from "../../hooks/queries/rules/mutations/useUpdateCondition";
import Box from "@mui/material/Box";
import Loader from "../../components/Loader/Loader";
import { LoaderCover } from "../../components/StyledComponents";
import AltRouteRoundedIcon from "@mui/icons-material/AltRouteRounded";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import Stack from "@mui/material/Stack";
import TestRule from "../../components/rules/TestRule";
import TextFieldWithCopy from "../../components/TextFieldWithCopy";
import { generateRuleLink } from "../../utils";
import RuleSettingsModal, { RuleSettings } from "../Rules/RuleSettingsModal";
import useUpdateRuleTree from "../../hooks/queries/rules/mutations/useUpdateRuleTree";
import Button from "@mui/material/Button";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

const Rule = () => {
  const {
    data: ruleTree,
    isRefetching: ruleTreeFetching,
    isLoading: ruleTreeLoading,
  } = useGetRuleTree();
  const { mutateAsync: addNewRule, isLoading: addNewRuleLoading } =
    useAddNewRule({
      onSettled: () => {
        setAddRuleModal(null);
      },
    });
  const { mutateAsync: removeRule, isLoading: removeRuleLoading } =
    useRemoveRule({
      onSettled: () => {
        setConfirmRemoveData(null);
      },
    });
  const { mutateAsync: updateCondition, isLoading: updateConditionLoading } =
    useUpdateCondition({
      onSettled: () => {},
    });

  const [settingsModalData, setSettingsModalData] = useState<
    undefined | RuleSettings
  >();

  const { mutateAsync: updateRuleTree, isLoading: updateRuleTreeLoading } =
    useUpdateRuleTree({
      onSettled: () => {
        setSettingsModalData(undefined);
      },
    });

  const [addRuleModal, setAddRuleModal] = useState<any>(null);
  const [confirmRemoveData, setConfirmRemoveData] = useState<null | {
    keyMap: number[];
    rule: Verification;
  }>(null);
  const link = generateRuleLink(ruleTree?._id || "", ruleTree?.target);

  function removeEmptyConditions(rule: any, parent = null) {
    if (typeof rule !== "object") {
      return;
    }

    if (parent) {
      rule.parent = parent;
    }

    if ("children" in rule && Array.isArray(rule.children)) {
      for (const child of rule.children) {
        removeEmptyConditions(child, rule);
      }
      rule.children = rule.children.filter((child: any) => {
        if ("condition" in child && child.children.length === 0) {
          return false;
        }
        delete child.parent;
        return true;
      });
    }
    return rule;
  }

  const onConditionMutate = async (
    conditionId: string,
    newCondition: ConditionOperator
  ) => {
    if (!ruleTree?.updateToken) return;
    await updateCondition({
      conditionId,
      newCondition,
      updateToken: ruleTree?.updateToken,
    });
  };

  const onRuleRemove = (keyMap: number[], rule: Verification) => {
    setConfirmRemoveData({ keyMap, rule });
  };

  const ruleRemove = async (keyMap: number[]) => {
    const mutatedTree = JSON.parse(JSON.stringify(ruleTree?.tree));
    let buffer: any = mutatedTree?.children;

    for (let i = 0; i < keyMap.length; i++) {
      const key = keyMap[i];
      if (i + 1 === keyMap?.length) {
        buffer.splice(key, 1);
      } else {
        buffer = buffer?.[key].children;
      }
    }
    removeEmptyConditions(mutatedTree);
    await removeRule({ mutatedTree, updateToken: ruleTree?.updateToken });
  };

  const onRuleShowAddModal = (
    parentRuleId: string,
    canAddConditionGroup: boolean,
    type: RuleType | CommunityRuleType
  ) => {
    let ruleConfig: any;
    let newProps = {};
    switch (type) {
      case "holder":
        newProps = { hashlistId: "" };
        break;
      case "subscriber":
        newProps = { planId: "" };
        break;
      case "discord-role":
        newProps = { roleId: "" };
        break;
      case "phygital":
        newProps = { phygitalCollectionId: "" };
        break;
    }
    ruleConfig = { number: null, ...newProps };
    setAddRuleModal({
      parentRuleId,
      rule: { id: "new", ruleType: type, ruleConfig },
      canAddConditionGroup,
    });
  };

  const onRuleAdd = async (newRule: any) => {
    await addNewRule({ ...newRule, updateToken: ruleTree?.updateToken });
  };

  const handleEditRuleModal = () => {
    setSettingsModalData(ruleTree);
  };

  const onRuleSettingsSave = (data: RuleSettings) => {
    updateRuleTree({
      treeId: data?._id,
      name: data?.name,
      description: data?.description,
    });
  };

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Rules",
            Icon: AltRouteRoundedIcon,
            path: "/rules",
          },
          {
            label: ruleTree?.name,
          },
        ]}
      />
      {ruleTree?.name && (
        <>
          <Typography variant="h5" fontWeight={700}>
            {ruleTree?.name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {ruleTree?.description}
          </Typography>
          <Button
            variant="base"
            color="secondary"
            startIcon={<CreateRoundedIcon />}
            sx={{ mb: 2 }}
            onClick={handleEditRuleModal}
          >
            Edit rule information
          </Button>
          <Box mb={2}>
            <TextFieldWithCopy label="Link" value={link} />
          </Box>
          {ruleTree?.target === RuleTarget.WalletAddress && (
            <TestRule treeId={ruleTree?._id} />
          )}
          <Divider sx={{ mt: 1, mb: 2 }} />
        </>
      )}
      <Box sx={{ position: "relative" }}>
        <LoaderCover
          display={
            ruleTreeFetching || ruleTreeLoading || updateConditionLoading
              ? "block"
              : "none"
          }
        >
          <Stack
            alignItems="center"
            sx={{
              position: "sticky",
              top: "50%",
              py: 2,
            }}
          >
            <Loader />
            <Typography
              variant="subtitle1"
              fontWeight={700}
              mt={2}
              textAlign="center"
            >
              In the process of getting the latest version of rules tree.
              Please, wait..
            </Typography>
          </Stack>
        </LoaderCover>
        <RulesTree
          data={ruleTree?.tree}
          target={ruleTree?.target}
          onConditionChange={onConditionMutate}
          onRuleRemove={onRuleRemove}
          onAddClick={onRuleShowAddModal}
        />
      </Box>
      <RulesTreeAddModal
        data={addRuleModal}
        loading={addNewRuleLoading}
        hide={() => {
          setAddRuleModal(null);
        }}
        save={onRuleAdd}
      />
      <RuleSettingsModal
        data={settingsModalData}
        loading={updateRuleTreeLoading}
        hide={() => {
          setSettingsModalData(undefined);
        }}
        save={onRuleSettingsSave}
      />
      <ConfirmDialog
        data={confirmRemoveData}
        title="Are you sure?"
        content={
          <>
            The condition to be removed:
            {confirmRemoveData?.rule && (
              <Paper
                sx={{
                  p: 1,
                  my: 1,
                  mx: -1,
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
              >
                <RulesTreeItemLabel rule={confirmRemoveData?.rule} />
              </Paper>
            )}
            Action cannot be undone!
          </>
        }
        isLoading={removeRuleLoading}
        hide={() => {
          setConfirmRemoveData(null);
        }}
        confirm={(data) => {
          ruleRemove(data?.keyMap);
        }}
      />
    </>
  );
};
export default Rule;
