import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Asset } from "../types";

type Props = { data?: Asset };

const SummaryAsset = ({ data }: Props) => {
  return (
    <Card
      sx={{
        maxWidth: "100%",
        width: 300,
      }}
    >
      {data?.digitalAssetConfig?.assets?.[0]?.url && (
        <CardMedia
          sx={{ height: 300 }}
          image={data?.digitalAssetConfig?.assets?.[0]?.url}
          title="image"
        />
      )}
      <CardContent>
        <Typography fontWeight={700}>{data?.name}</Typography>
        <Typography color="text.secondary">{data?.description}</Typography>
        {!!data?.sellerFeeBasisPoints && (
          <Chip
            label={`${data?.sellerFeeBasisPoints / 100}% royalty`}
            color="primary"
            size="small"
            sx={{ fontWeight: 700, mt: 1 }}
          />
        )}
        {data?.attributes && data?.attributes?.length > 0 && (
          <>
            <Divider sx={{ mt: 1, mb: 1 }}>
              <Typography
                variant="overline"
                lineHeight={1}
                fontWeight={600}
                color="text.secondary"
              >
                Attributes
              </Typography>
            </Divider>
            <Stack
              direction="row"
              flexWrap="wrap"
              gap={1}
              justifyContent="space-around"
            >
              {data?.attributes?.map((attr, index) =>
                attr?.trait_type && attr?.value ? (
                  <Box
                    key={attr?.trait_type + index}
                    borderRadius={1}
                    borderColor="divider"
                    py={0.5}
                    px={1}
                    textAlign="center"
                    sx={{ borderWidth: 1, borderStyle: "solid" }}
                  >
                    <Typography variant="subtitle2" fontWeight={500}>
                      {attr?.trait_type}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {attr?.value}
                    </Typography>
                  </Box>
                ) : null
              )}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default SummaryAsset;
