import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CreateCommunityPayload } from "../../../../types";
import { API_PATHS } from "../../../../constants";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";

type Props = {
  onSettled: () => void;
};

const createCommunity = async (data: CreateCommunityPayload) => {
  return await axios.post<any>(API_PATHS.community.create, data);
};

export default function useCreateNewCommunity({ onSettled }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (payload: CreateCommunityPayload) => {
      const { data } = await createCommunity(payload);

      if (isSuccessResponse(data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.community.list],
          exact: true,
          refetchType: "active",
        });
        return data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: `Your community was created!`,
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        navigate("/select-community");
        onSettled();
      },
    }
  );
}
