import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalTransition from "../../../../helpers/ModalTransition";
import { SupportedCollection } from "../../../../types";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import useChangeDailyReward from "../../../../hooks/queries/subscriptions/mutations/useChangeDailyReward";

type Props = {
  data?: SupportedCollection;
  hide: () => void;
};

type Inputs = {
  amount: number | "";
};

const SetAmountDialog = ({ data, hide }: Props) => {
  const {
    formState: { errors },
    handleSubmit,
    reset,
    register,
  } = useForm<Inputs>({
    defaultValues: {
      amount: "",
    },
  });
  const {
    mutateAsync: changeDailyReward,
    isLoading: changeDailyRewardLoading,
  } = useChangeDailyReward();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    reset({ amount: data?.amount });
  }, [data?.amount, reset]);

  const onSubmit = async ({ amount }: Inputs) => {
    if (!data || amount === "") return;
    const response = await changeDailyReward({
      collectionId: data?.collectionId,
      amount,
    });
    if (response?.success) {
      hide();
    }
  };

  return (
    <Dialog
      open={!!data}
      onClose={changeDailyRewardLoading ? undefined : hide}
      TransitionComponent={ModalTransition}
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Change amount</DialogTitle>
        <DialogContent>
          <TextField
            {...register("amount", {
              required: "This field is required",
              valueAsNumber: true,
              min: {
                value: 0,
                message: "Minimum is 0",
              },
            })}
            error={!!errors.amount}
            helperText={!!errors.amount && errors.amount.message}
            type="number"
            label="Daily reward"
            margin="dense"
            required
            fullWidth
            inputProps={{ step: "any" }}
            disabled={changeDailyRewardLoading}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="base"
            color="secondary"
            onClick={hide}
            disabled={changeDailyRewardLoading}
          >
            Close
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={changeDailyRewardLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetAmountDialog;
