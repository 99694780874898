import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSignIn } from "../../../hooks/queries/auth/useSignIn";

type Inputs = {
  email: string;
  password: string;
};

const SignIn = ({
  setType,
}: {
  setType: Dispatch<SetStateAction<"in" | "up" | "forgot">>;
}) => {
  const { mutateAsync: signIn, isLoading: signInLoading } = useSignIn();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>();

  const onSubmit = (data: Inputs) => {
    signIn(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("email", {
          required: "This field is required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          },
        })}
        error={!!errors.email}
        helperText={!!errors.email && errors.email.message}
        disabled={signInLoading}
        required
        fullWidth
        label="Email address"
        autoComplete="email"
      />
      <TextField
        {...register("password", {
          required: "This field is required",
          minLength: {
            value: 8,
            message: "Min length is 8",
          },
        })}
        error={!!errors.password}
        helperText={!!errors.password && errors.password.message}
        disabled={signInLoading}
        required
        fullWidth
        label="Password"
        type="password"
        autoComplete="current-password"
      />
      <Box textAlign="right">
        <Button
          variant="text"
          color="inherit"
          size="small"
          disabled={signInLoading}
          onClick={() => {
            setType("forgot");
          }}
        >
          Forgot password?
        </Button>
      </Box>
      <LoadingButton
        loading={signInLoading}
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        type="submit"
      >
        Sign In
      </LoadingButton>
    </form>
  );
};

export default SignIn;
