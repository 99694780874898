import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { RewardsByIdResponse } from "../../../../types";
import { API_PATHS } from "../../../../constants";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";

const getReward = async (campaignId?: string) => {
  const { data } = await axios.post<RewardsByIdResponse>(
    API_PATHS.rewards.byId,
    {
      data: { campaignId },
    }
  );
  return data;
};

export const useGetRewardById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { campaignId } = useParams();

  return useQuery(
    [API_PATHS.rewards.byId, campaignId],
    async () => {
      const { data } = await getReward(campaignId);
      if (isSuccessResponse(data)) {
        return data?.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      enabled: !!campaignId && campaignId !== "create-new",
      staleTime: 5 * 60 * 1000,
      retry: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        navigate("/rewards");
      },
    }
  );
};
