import { Fragment } from "react";
import "./RulesTree.scss";
import RulesTreeAdd from "./RulesTreeAdd";
import RulesTreeItem from "./RulesTreeItem";
import { RulesTreeProps } from "./types";
import {
  CommunityRuleType,
  Condition,
  ConditionOperator,
  RuleType,
} from "../../../types";

const LEVEL_LIMIT = 3;

const RulesTree = ({
  data,
  target,
  onConditionChange,
  onRuleRemove,
  onAddClick,
}: RulesTreeProps) => {
  if (typeof data == "undefined") {
    return null;
  }

  const renderItem = (
    condition: Condition,
    key: number,
    level: number,
    keyMap: number[]
  ) => {
    return (
      <RulesTreeItem
        key={level}
        level={level}
        data={condition}
        onConditionChange={(newOperator: ConditionOperator) => {
          onConditionChange(condition?.ruleId, newOperator);
        }}
      >
        {condition?.children?.map((ruleOrCondition, index) => (
          <Fragment key={key + index + 1}>
            {"condition" in ruleOrCondition ? (
              renderItem(ruleOrCondition, key + index + 2, level + 1, [
                ...keyMap,
                index,
              ])
            ) : (
              <RulesTreeItem
                data={ruleOrCondition}
                level={level}
                onRuleRemove={() => {
                  onRuleRemove([...keyMap, index], ruleOrCondition);
                }}
              />
            )}
          </Fragment>
        ))}
        <RulesTreeAdd
          target={target}
          onClick={(type: RuleType | CommunityRuleType) => {
            onAddClick(
              condition?.ruleId,
              keyMap?.length + 1 < LEVEL_LIMIT,
              type
            );
          }}
        />
      </RulesTreeItem>
    );
  };

  return <ul className="condition-tree">{renderItem(data, 0, 1, [])}</ul>;
};

export default RulesTree;
