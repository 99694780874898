import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { RulesConfigurationType } from "../../types";
import { UseMutationResult } from "@tanstack/react-query";
import { useGettAllRules } from "../../hooks/queries/rules/state/useGetAllRules";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

type Props = {
  rulesDetails?: RulesConfigurationType;
  updateDetailsHook: () => UseMutationResult<
    any,
    any,
    RulesConfigurationType,
    unknown
  >;
  isTokenCampaign: boolean;
};

const RulesConfiguration = ({
  rulesDetails,
  updateDetailsHook,
  isTokenCampaign,
}: Props) => {
  const { mutateAsync: update, isLoading: updateLoading } = updateDetailsHook();
  const { data: rulesList, isLoading: rulesListLoading } = useGettAllRules();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<RulesConfigurationType>({
    defaultValues: {
      allowMultiSubscribe: false,
      maxSubscriptionsActive: false,
      maxSubscriptions: 0,
      maxSubscriptionsPerWalletActive: false,
      maxSubscriptionsPerWallet: 0,
      canSubscribeRuleIdActive: false,
      canSubscribeRuleId: "",
      canSubscribeRuleDescription: "",
    },
  });
  const formWatcher = watch();
  const isMaxSubscriptionsActive = watch("maxSubscriptionsActive");
  const isMaxSubscriptionsPerWalletActive = watch(
    "maxSubscriptionsPerWalletActive"
  );
  const isCanSubscribeRuleIdActive = watch("canSubscribeRuleIdActive");

  useEffect(() => {
    reset({
      allowMultiSubscribe: rulesDetails?.allowMultiSubscribe || false,
      maxSubscriptionsActive: rulesDetails?.maxSubscriptionsActive || false,
      maxSubscriptions: rulesDetails?.maxSubscriptions || 0,
      maxSubscriptionsPerWalletActive:
        rulesDetails?.maxSubscriptionsPerWalletActive || false,
      maxSubscriptionsPerWallet: rulesDetails?.maxSubscriptionsPerWallet || 0,
      canSubscribeRuleIdActive: rulesDetails?.canSubscribeRuleIdActive || false,
      canSubscribeRuleId: rulesDetails?.canSubscribeRuleId || "",
      canSubscribeRuleDescription:
        rulesDetails?.canSubscribeRuleDescription || "",
    });
  }, [rulesDetails, reset]);

  const onSubmit = async (data: RulesConfigurationType) => {
    update(data);
  };

  const disabled = updateLoading || isTokenCampaign;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography color="text.secondary" gutterBottom>
        Make your contract more secure and exclusive by adding gates and
        restrictions. Use the Rule System to limit the number of subscribers and
        set specific entry requirements.
      </Typography>
      <List disablePadding>
        <Divider component="li" />
        <ListItem
          sx={{
            gap: 1,
            flexDirection: "column",
            alignItems: "initial",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <ListItemText
              primary="Maximum subscriptions"
              secondary="You can specify the maximum number of subscriptions for this contract"
              sx={{ my: 0 }}
            />
            <Controller
              name="maxSubscriptionsActive"
              control={control}
              render={({ field }) => (
                <Switch {...field} checked={field.value} disabled={disabled} />
              )}
            />
          </Stack>
          {isMaxSubscriptionsActive && (
            <TextField
              {...register("maxSubscriptions", {
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: "Minimum is 0",
                },
              })}
              inputProps={{ step: 1 }}
              error={!!errors.maxSubscriptions}
              helperText={
                !!errors.maxSubscriptions && errors.maxSubscriptions.message
              }
              type="number"
              required={isMaxSubscriptionsActive}
              fullWidth
              label="Maximum subscriptions allowed in this contract"
              disabled={disabled}
            />
          )}
        </ListItem>
        <Divider component="li" />
        <ListItem
          sx={{
            gap: 1,
            flexDirection: "column",
            alignItems: "initial",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <ListItemText
              primary={
                <>
                  Maximum subscriptions <strong>per wallet</strong>
                </>
              }
              secondary={
                <>
                  You can specify the maximum number of subscriptions for this
                  contract <strong>per wallet</strong>
                </>
              }
              sx={{ my: 0 }}
            />
            <Controller
              name="maxSubscriptionsPerWalletActive"
              control={control}
              render={({ field }) => (
                <Switch {...field} checked={field.value} disabled={disabled} />
              )}
            />
          </Stack>
          {isMaxSubscriptionsPerWalletActive && (
            <TextField
              {...register("maxSubscriptionsPerWallet", {
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: "Minimum is 0",
                },
              })}
              inputProps={{ step: 1 }}
              error={!!errors.maxSubscriptionsPerWallet}
              helperText={
                !!errors.maxSubscriptionsPerWallet &&
                errors.maxSubscriptionsPerWallet.message
              }
              type="number"
              required={isMaxSubscriptionsPerWalletActive}
              fullWidth
              label={
                <>
                  Maximum subscriptions allowed <strong>per wallet</strong> in
                  this contract
                </>
              }
              disabled={disabled}
            />
          )}
        </ListItem>
        {!isTokenCampaign && (
          <>
            <Divider component="li" />
            <ListItem
              sx={{
                gap: 1,
                flexDirection: "column",
                alignItems: "initial",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ListItemText
                  primary="Enable multiple subscriptions"
                  secondary="Members subscribed to this contract can also subscribe to other contracts."
                  sx={{ my: 0 }}
                />
                <Controller
                  name="allowMultiSubscribe"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      disabled={disabled}
                    />
                  )}
                />
              </Stack>
            </ListItem>
            <Divider component="li" />
            <ListItem
              sx={{
                gap: 1,
                flexDirection: "column",
                alignItems: "initial",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ListItemText
                  primary="Rule"
                  secondary="You can create a rule (Rule Service) and use it to gate this contract"
                  sx={{ my: 0 }}
                />
                <Controller
                  name="canSubscribeRuleIdActive"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      disabled={disabled}
                    />
                  )}
                />
              </Stack>
              {isCanSubscribeRuleIdActive && (
                <>
                  {rulesListLoading ? (
                    <Stack
                      sx={{ my: 2 }}
                      direction="row"
                      spacing={1.5}
                      alignItems="center"
                    >
                      <Box>
                        <CircularProgress />
                      </Box>
                      <Typography>
                        In the process of getting a list of rules. Please,
                        wait..
                      </Typography>
                    </Stack>
                  ) : (
                    <>
                      <FormControl fullWidth margin="dense" disabled={disabled}>
                        <InputLabel>Select rule</InputLabel>
                        <Controller
                          control={control}
                          name="canSubscribeRuleId"
                          render={({ field }) => (
                            <Select
                              {...field}
                              variant="outlined"
                              disabled={disabled}
                              label="Select rule"
                              defaultValue=""
                              endAdornment={
                                field.value ? (
                                  <Stack direction="row" mr={2}>
                                    <Tooltip title="Go to selected rule configurator">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          window.open(
                                            `/rules/${field.value}`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        <OpenInNewRoundedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                ) : null
                              }
                            >
                              {rulesList?.map((r) => (
                                <MenuItem key={r?._id} value={r?._id}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                  >
                                    <Box>
                                      <Typography
                                        fontWeight={700}
                                        lineHeight={1}
                                      >
                                        {r?.name}
                                      </Typography>
                                      <Typography
                                        color="text.secondary"
                                        variant="caption"
                                      >
                                        {r?.description}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                      {formWatcher?.canSubscribeRuleId && (
                        <TextField
                          {...register("canSubscribeRuleDescription", {
                            required: "This field is required",
                          })}
                          error={!!errors.canSubscribeRuleDescription}
                          helperText="Describe what the user must do to be able to subscribe. Remember this will be presented to the users"
                          disabled={disabled}
                          required
                          fullWidth
                          label="Rule description"
                          multiline
                          rows={3}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </ListItem>
          </>
        )}
        <Divider component="li" />
      </List>
      {!isTokenCampaign && (
        <LoadingButton
          variant="contained"
          type="submit"
          loading={updateLoading}
          sx={{ mt: 2 }}
        >
          Save
        </LoadingButton>
      )}
    </form>
  );
};

export default RulesConfiguration;
