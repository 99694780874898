import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent, {
  dialogContentClasses,
} from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContentText from "@mui/material/DialogContentText";
import { useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useFund } from "../../hooks/queries/tokens/mutations/useFund";
import { Token } from "../../types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";

export interface Props {
  token: Token | null;
  hide: () => void;
}

type Inputs = {
  amount: number;
};

const TokenFundModal = ({ token, hide }: Props) => {
  const { publicKey } = useWallet();
  const { mutateAsync: fund, isLoading: fundLoading } = useFund({
    onSuccess: () => {
      hide();
    },
  });
  const theme = useTheme();
  const {
    register,
    formState: { errors },
    handleSubmit,
    resetField,
  } = useForm<Inputs>({ defaultValues: { amount: 1 } });
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    resetField("amount");
  }, [token, resetField]);

  const onSave = (inputs: Inputs) => {
    if (!token?.tokenId) return;
    fund({ tokenId: token?.tokenId, amount: inputs.amount });
  };

  return (
    <Dialog
      open={!!token}
      onClose={fundLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
      sx={{
        ".MuiPaper-root": { overflowY: "unset" },
        [`.${dialogContentClasses.root}`]: { overflowY: "unset" },
      }}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <DialogTitle component="div" sx={{ pr: 6 }}>
          Fund ${token?.tokenMetadata?.symbol} reward pool
          <IconButton
            aria-label="close"
            disabled={fundLoading}
            onClick={hide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure you enter the correct info
          </DialogContentText>
          <TextField
            {...register("amount", {
              required: "This field is required",
              valueAsNumber: true,
            })}
            type="number"
            error={!!errors.amount}
            helperText={!!errors.amount && errors.amount.message}
            required
            fullWidth
            label="Number of tokens to fund"
            inputProps={{ step: "any" }}
            disabled={fundLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  ${token?.tokenMetadata?.symbol}
                </InputAdornment>
              ),
            }}
          />
          <Box textAlign="center">
            <Typography
              variant="overline"
              fontWeight={700}
              color="text.secondary"
              component="div"
            >
              {publicKey ? "Your wallet" : "Please, connect your wallet"}
            </Typography>
            <Box display="inline-block">
              <WalletMultiButton disabled={fundLoading} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {publicKey ? (
            <LoadingButton
              type="submit"
              variant="contained"
              loading={fundLoading}
            >
              Fund
            </LoadingButton>
          ) : (
            <WalletMultiButton />
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TokenFundModal;
