import Typography from "@mui/material/Typography";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import ToysIcon from "@mui/icons-material/Toys";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Controller, useForm } from "react-hook-form";
import { Hashlist, Inputs } from "./types";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import EligibilityHashlists from "./EligibilityHashlists";
import FormControl from "@mui/material/FormControl";
import { ClaimingTransaction, EligableTypes } from "../../types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import { useGettAllRules } from "../../hooks/queries/rules/state/useGetAllRules";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { useGetAllAssets } from "../../hooks/queries/assets/state/useGetAllAssets";
import LoadingButton from "@mui/lab/LoadingButton";
import ClaimingTokens from "./ClaimingTokens";
import Divider from "@mui/material/Divider";
import useCreatePhygital from "../../hooks/queries/phygital/mutations/useCreatePhygital";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import EligibleHashlist from "../../components/EligibleHashlist/EligibleHashlist";
import { CLAIM_VERIFICATION_METHODS } from "../../constants";
import AssetSelect from "../../components/AssetSelect";
import FormHelperText from "@mui/material/FormHelperText";

const CreatePhygital = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: createPhygital, isLoading: createPhygitalLoading } =
    useCreatePhygital();
  const { data: rulesList, isLoading: rulesListLoading } = useGettAllRules();
  const { data: assetsList, isLoading: assetsListLoading } = useGetAllAssets();
  const [claimExpirationDate, setClaimExpirationDate] = useState<Moment | null>(
    null
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      assetMetadataId: "",
      ruleTreeId: "",
      type: "",
    },
  });
  const formWatcher = watch();
  const [eligableHashlists, setEligableHashlists] = useState<Hashlist[]>([
    { minToClaim: "" },
  ]);
  const [eligibleWalletsHashlist, setEligibleWalletsHashlist] = useState<
    Hashlist[]
  >([{ minToClaim: 1 }]);
  const [tokens, setTokens] = useState<ClaimingTransaction[]>([
    { type: "sol", amount: "" },
  ]);

  const handleEligibilityAdd = () => {
    setEligableHashlists((state) => [...state, { minToClaim: "" }]);
  };

  const handleEligibilityRemove = (index: number) => {
    setEligableHashlists((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const clearRule = () => {
    setValue("ruleTreeId", "");
  };

  const onEligibilityFieldChange = (
    index: number,
    key: keyof Hashlist,
    value: any
  ) => {
    setEligableHashlists((state) => {
      const newState = [...state];
      newState[index][key] = value;
      return newState;
    });
  };

  const handleAddToken = () => {
    setTokens((state) => [
      ...state,
      { type: "splToken", tokenAddress: "", amount: "" },
    ]);
  };

  const handleRemoveToken = (index: number) => {
    setTokens((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const onEligibilityWalletsFieldChange = (
    index: number,
    key: keyof Hashlist,
    value: any
  ) => {
    setEligibleWalletsHashlist((state) => {
      const newState = [...state];
      newState[0][key] = value;
      return newState;
    });
  };

  const onTokenFieldChange = (
    index: number,
    key: keyof ClaimingTransaction,
    value: any
  ) => {
    setTokens((state) => {
      const newState = [...state];
      newState[index][key] = value;
      return newState;
    });
  };

  const onSubmit = async (data: Inputs) => {
    const res = await createPhygital({
      transactions: tokens,
      eligableHashlists:
        data?.type === EligableTypes.WalletAddresses
          ? eligibleWalletsHashlist
          : eligableHashlists,
      claimExpirationDate: claimExpirationDate?.isValid()
        ? claimExpirationDate.toDate()
        : null,
      ...data,
    });
    if (res?.success) {
      enqueueSnackbar({
        variant: "success",
        message: `New phygital created`,
      });
      navigate(`/phygital/details/${res?.data?._id}`);
    }
  };

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Phygital",
            Icon: ToysIcon,
            path: "/phygital",
          },
          {
            label: "Create",
          },
        ]}
      />
      <Typography variant="h5" fontWeight={700} mb={1}>
        Create to phygital campaign
      </Typography>
      <Alert variant="filled" color="warning" severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Attention!</AlertTitle>
        <Typography>
          Be careful! After submitting the form, you will not be able to change
          or delete this phygital
        </Typography>
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register("title", {
            required: "This field is required",
          })}
          error={!!errors.title}
          helperText={!!errors.title && errors.title.message}
          disabled={createPhygitalLoading}
          required
          fullWidth
          label="Name"
          margin="dense"
        />
        <TextField
          {...register("description", {
            required: "This field is required",
          })}
          error={!!errors.description}
          helperText={!!errors.description && errors.description.message}
          disabled={createPhygitalLoading}
          required
          fullWidth
          label="Description"
          margin="dense"
          multiline
          rows={3}
          sx={{ mb: 1 }}
        />
        <DatePicker
          label="Claim experation date"
          minDate={moment().add(1, "days")}
          desktopModeMediaQuery="@media (min-width: 720px)"
          slotProps={{
            textField: {
              helperText: "MM/DD/YYYY",
              fullWidth: true,
            },
          }}
          value={claimExpirationDate}
          onChange={(newValue) => setClaimExpirationDate(newValue)}
          disabled={createPhygitalLoading}
        />
        <Divider sx={{ mb: 1 }}>
          <Typography fontWeight={700} variant="subtitle1">
            Claim eligibility
          </Typography>
        </Divider>
        <Typography variant="subtitle2" color="text.secondary">
          Choose a rule to gate the claim button of the reward. Only eligible
          users that abide to the rule will be able to claim.
        </Typography>
        {rulesListLoading ? (
          <Stack
            sx={{ my: 2 }}
            direction="row"
            spacing={1.5}
            alignItems="center"
          >
            <Box>
              <CircularProgress />
            </Box>
            <Typography>
              In the process of getting a list of rules. Please, wait..
            </Typography>
          </Stack>
        ) : (
          <Box>
            <FormControl
              fullWidth
              margin="dense"
              disabled={createPhygitalLoading}
            >
              <InputLabel>Select rule</InputLabel>
              <Controller
                control={control}
                name="ruleTreeId"
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    label="Select rule"
                    endAdornment={
                      formWatcher?.ruleTreeId ? (
                        <Stack direction="row" mr={2}>
                          <IconButton size="small" onClick={clearRule}>
                            <CloseRoundedIcon fontSize="small" />
                          </IconButton>
                          <Tooltip title="Go to selected rule configurator">
                            <IconButton
                              size="small"
                              onClick={() => {
                                window.open(
                                  `/rules/${formWatcher?.ruleTreeId}`,
                                  "_blank"
                                );
                              }}
                            >
                              <OpenInNewRoundedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      ) : null
                    }
                    defaultValue=""
                  >
                    {rulesList?.map((r) => (
                      <MenuItem key={r?._id} value={r?._id}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box>
                            <Typography fontWeight={700} lineHeight={1}>
                              {r?.name}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              variant="caption"
                            >
                              {r?.description}
                            </Typography>
                          </Box>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {formWatcher?.ruleTreeId && (
              <TextField
                {...register("ruleTreeDescription", {
                  required: "This field is required",
                })}
                error={!!errors.ruleTreeDescription}
                helperText="Describe what the user must do to be able to claim. Remember this will be presented to the users"
                disabled={createPhygitalLoading}
                required
                fullWidth
                label="Rule description"
                margin="dense"
                multiline
                rows={3}
              />
            )}
          </Box>
        )}
        <TextField
          {...register("maxClaimsPerWalletAddress", {
            required: "This field is required",
            valueAsNumber: true,
            min: {
              value: 1,
              message: "Minimum number of claims is 1",
            },
          })}
          error={!!errors.maxClaimsPerWalletAddress}
          helperText={
            errors.maxClaimsPerWalletAddress
              ? errors.maxClaimsPerWalletAddress.message
              : "Restrict the number of claims per wallet connected"
          }
          type="number"
          label="Number of claims per wallet"
          margin="dense"
          required
          fullWidth
          disabled={createPhygitalLoading}
        />
        <FormControl
          fullWidth
          margin="dense"
          disabled={createPhygitalLoading}
          required
        >
          <InputLabel>Method</InputLabel>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select
                {...field}
                variant="outlined"
                label="Method"
                defaultValue=""
                error={!!errors.type}
              >
                {CLAIM_VERIFICATION_METHODS.map(
                  ({ id, label, description }) => (
                    <MenuItem key={id} value={id}>
                      <Box width="100%" whiteSpace="normal">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography fontWeight={700} lineHeight={1}>
                            {label}
                          </Typography>
                        </Stack>
                        <Typography
                          color="text.secondary"
                          variant="caption"
                          component="div"
                          mt={0.5}
                          lineHeight={1.15}
                        >
                          {description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  )
                )}
              </Select>
            )}
          />
          <FormHelperText>
            Define the method to limit the number of rewards a user can claim
          </FormHelperText>
        </FormControl>
        {formWatcher?.type === EligableTypes.Hashlist && (
          <EligibilityHashlists
            list={eligableHashlists}
            onAdd={handleEligibilityAdd}
            onRemove={handleEligibilityRemove}
            onChange={onEligibilityFieldChange}
            disabled={createPhygitalLoading}
          />
        )}
        {formWatcher?.type === EligableTypes.WalletAddresses && (
          <Box mt={2}>
            <EligibleHashlist
              type="wallets"
              data={eligibleWalletsHashlist[0]}
              order={0}
              onChange={onEligibilityWalletsFieldChange}
              disabled={createPhygitalLoading}
            />
          </Box>
        )}
        <Divider>
          <Typography fontWeight={700} variant="subtitle1">
            Asset
          </Typography>
        </Divider>
        <AssetSelect
          list={assetsList}
          loading={assetsListLoading}
          control={control}
          name="assetMetadataId"
          disabled={createPhygitalLoading}
        />
        <Divider>
          <Typography fontWeight={700} variant="subtitle1">
            Cost per Claim
          </Typography>
        </Divider>
        <Typography variant="subtitle2">
          You can specify how much the user should pay for claiming the
          phygital.
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          * If you do not want the user to pay for claiming - leave the fields
          empty
        </Typography>
        <TextField
          {...register("destinationWalletAddress")}
          required={tokens?.some((v) => !!v?.amount || !!v?.tokenAddress)}
          disabled={createPhygitalLoading}
          label="Destination wallet address"
          fullWidth
          margin="dense"
          sx={{ mb: 2 }}
        />
        <Divider>
          <Typography fontWeight={700} variant="subtitle1">
            Payment tokens
          </Typography>
        </Divider>
        <ClaimingTokens
          list={tokens}
          onAdd={handleAddToken}
          onRemove={handleRemoveToken}
          onChange={onTokenFieldChange}
          disabled={createPhygitalLoading}
        />
        <Divider sx={{ my: 1 }}>
          <Typography fontWeight={700} variant="subtitle1">
            QR/NFC Configurator
          </Typography>
        </Divider>
        <Typography variant="subtitle2">
          Configure the amount on unique claim pages and the number of claimable
          NFTs per page.
          <br />
          1. QR codes - if you wish to set up 1 claim page with multiple NFTs
          that can be claimed, set the number of unique links to 1 and number of
          claimables per link to as many as you wish. This example can work for
          event tickets.
          <br />
          2. NFC chips - All taps can lead to the same claim page (set number of
          unique links to 1) or each chip can have his own unique page (set
          number of unique links to be equal to number of chips). To control the
          number of taps, we suggest having 1 page per NFC chip and 1 claimable
          NFT per page.
        </Typography>
        <TextField
          {...register("totalClaimablePages", {
            required: "This field is required",
            valueAsNumber: true,
            min: {
              value: 1,
              message: "Minimum number of claims is 1",
            },
          })}
          error={!!errors.totalClaimablePages}
          helperText={
            errors.totalClaimablePages
              ? errors.totalClaimablePages.message
              : "Number of unique claim page links that can be used to generate unique QR codes / NFC Chips"
          }
          type="number"
          label="Number of links"
          margin="dense"
          required
          fullWidth
          disabled={createPhygitalLoading}
          sx={{ mb: 2 }}
        />
        <TextField
          {...register("totalClaimablesPerPage", {
            required: "This field is required",
            valueAsNumber: true,
            min: {
              value: 1,
              message: "Minimum number of claims is 1",
            },
          })}
          error={!!errors.totalClaimablesPerPage}
          helperText={
            errors.totalClaimablesPerPage
              ? errors.totalClaimablesPerPage.message
              : "Number of collectibles that can be claimed per claim page"
          }
          type="number"
          label="Claimables per link"
          margin="dense"
          required
          fullWidth
          disabled={createPhygitalLoading}
        />
        <Divider sx={{ my: 2 }} />
        <LoadingButton
          loading={createPhygitalLoading}
          fullWidth
          variant="contained"
          type="submit"
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default CreatePhygital;
