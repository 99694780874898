import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../../helpers/ModalTransition";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { ConditionOperator, RuleType } from "../../../types";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGetDiscordRoles } from "../../../hooks/queries/integrations/state/useGetDiscordRoles";
import CircularProgress from "@mui/material/CircularProgress";
import { parseDataFromJSON } from "../../../utils";
import { useGetAllPublishedPhygital } from "../../../hooks/queries/phygital/state/useGetAllPublishedPhygital";
import Empty from "../../../components/Empty";
import Paper from "@mui/material/Paper";
import { useGetContracts } from "../../../hooks/queries/subscriptions/state/useGetContracts";
import { useGetAllTokens } from "../../../hooks/queries/tokens/state/useGetAllTokens";

const RULE_LABELS = {
  holder: {
    title: "holder",
    description:
      "This condition will check if a user holds in his wallet a number of digital assets from an uploaded hash list",
    selectLabel: "token",
  },
  "token-holder": {
    title: "fungible token holder",
    description:
      "This condition will check if a user holds in his wallet a minimum amount of a spedific fungible token",
    selectLabel: "",
  },
  subscriber: {
    title: "subscriber",
    description:
      "This condition will check if a user has a number of membership NFTs subscribed to a specific plan",
    selectLabel: "subscription plan",
  },
  phygital: {
    title: "phygital",
    description: "",
    selectLabel: "phygital collection",
  },
  "discord-role": {
    title: "Discord role",
    description:
      "This condition will check if a user has a specific Discord role in your connected server",
    selectLabel: "Discord role",
  },
  "minimum-unique-subscribers": {
    title: "minimum unique subscribers",
    description:
      "This condition will check if the contract is subscribed by the specified number of unique wallets",
    selectLabel: "subscription plan",
  },
};
export interface RulesTreeAddModalProps {
  data?: {
    parentRuleId: string;
    rule: any;
    canAddConditionGroup: boolean;
  };
  loading: boolean;
  hide: () => void;
  save: (newRule: any) => void;
}

type Inputs = {
  minimumHolding: number;
  minimum: number;
  selectedId: string;
  condition: ConditionOperator;
};

const RulesTreeAddModal = ({
  data,
  loading,
  hide,
  save,
}: RulesTreeAddModalProps) => {
  const theme = useTheme();
  const { data: contracts, isLoading: contractsLoading } = useGetContracts();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    values: {
      minimumHolding: data?.rule?.ruleConfig?.number,
      selectedId:
        data?.rule?.ruleType === "phygital"
          ? data?.rule?.ruleConfig?.phygitalCollectionId
          : data?.rule?.ruleConfig?.planId,
      minimum: data?.rule?.ruleConfig?.minimum,
      condition: "OR",
    },
  });
  const { data: discordRoles, isLoading: discordRolesLoading } =
    useGetDiscordRoles();
  const { data: phygitalList } = useGetAllPublishedPhygital();
  const { data: tokesnList, isLoading: tokesListLoading } = useGetAllTokens();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [withConditionGroup, setWithConditionGroup] = useState(false);
  const [hashlist, setHashlist] = useState<any>(null);

  useEffect(() => {
    if (!data?.rule) return;
    return () => {
      setWithConditionGroup(false);
      setHashlist(null);
    };
  }, [data?.rule]);

  const onFileSelected = async (e: any) => {
    setHashlist(null);
    const jsonData = await parseDataFromJSON(e);
    setHashlist({
      hashlistTitle: jsonData.fileName,
      hashlist: jsonData.arr,
    });
  };

  const onSave = (inputs: Inputs) => {
    if (!data || (data?.rule?.ruleType === "holder" && !hashlist)) return;

    const newRule: any = {
      ruleId: data?.parentRuleId,
      rulePayload: {
        ruleType: data?.rule?.ruleType,
      },
    };

    switch (data?.rule?.ruleType) {
      case "phygital":
        newRule.rulePayload.phygitalId = inputs.selectedId;
        break;
      case "token-holder":
        newRule.rulePayload.tokenId = inputs.selectedId;
        break;
      case "subscriber":
        newRule.rulePayload.subscriptionId = inputs.selectedId;
        newRule.rulePayload.subscriptionName = contracts?.find(
          (p) => p?.PlanId === inputs.selectedId
        )?.PlanTitle;
        break;
      case "holder":
        newRule.rulePayload.hashlist = hashlist?.hashlist;
        newRule.rulePayload.hashlistTitle = hashlist?.hashlistTitle;
        break;
      case "discord-role":
        newRule.rulePayload.roleId = inputs.selectedId;
        break;
      case "minimum-unique-subscribers":
        newRule.rulePayload.minimum = inputs.minimum;
        break;
    }
    if (data?.rule?.ruleType !== "discord-role") {
      newRule.rulePayload.minimumHolding = inputs.minimumHolding;
    }
    if (withConditionGroup) {
      newRule.condition = inputs.condition;
    }
    save(newRule);
  };

  const selectList = () => {
    switch (data?.rule?.ruleType) {
      case "subscriber":
        return contracts?.map((p) => (
          <MenuItem value={p?.PlanId} key={p?.PlanId}>
            {p?.PlanTitle}
          </MenuItem>
        ));
      case "phygital":
        return phygitalList?.length === 0 ? (
          <Box m={2}>
            <Empty text="You don't have any phygital collections" />
          </Box>
        ) : (
          phygitalList?.map((p) => (
            <MenuItem value={p?._id} key={p?._id}>
              <Stack
                direction="row"
                alignItems="flex-start"
                width="100%"
                spacing={1}
              >
                <Paper
                  sx={{
                    width: 40,
                    flexShrink: 0,
                    borderRadius: "3px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={p?.tokenMetadata?.digitalAssetConfig?.assets?.[0]?.url}
                    alt="asset"
                  />
                </Paper>
                <Box lineHeight={1} whiteSpace="normal">
                  <Typography fontWeight={700}>{p?.profile?.title}</Typography>
                  <Typography color="text.secondary" variant="caption">
                    {p?.profile?.description}
                  </Typography>
                </Box>
              </Stack>
            </MenuItem>
          ))
        );
      case "token-holder":
        return tokesnList?.length === 0 ? (
          <Box m={2}>
            <Empty text="You don't have any tokens" />
          </Box>
        ) : (
          tokesnList?.map((t) => (
            <MenuItem value={t?.tokenId} key={t?.tokenId}>
              <Stack
                direction="row"
                alignItems="flex-start"
                width="100%"
                spacing={1}
              >
                <Paper
                  sx={{
                    width: 40,
                    flexShrink: 0,
                    borderRadius: "3px",
                    overflow: "hidden",
                  }}
                >
                  <img src={t?.tokenMetadata?.uri} alt="asset" />
                </Paper>
                <Box lineHeight={1} whiteSpace="normal">
                  <Typography fontWeight={700}>
                    {t?.tokenMetadata?.name}
                  </Typography>
                  <Typography color="text.secondary" variant="caption">
                    {t?.tokenMetadata?.address}
                  </Typography>
                </Box>
              </Stack>
            </MenuItem>
          ))
        );
      case "discord-role":
        return discordRoles?.map((r) => (
          <MenuItem value={r?.id} key={r?.id}>
            {r?.name}
          </MenuItem>
        ));
      default:
        return <></>;
    }
  };

  const select = () => {
    if (
      (data?.rule?.ruleType === "discord-role" && discordRolesLoading) ||
      (data?.rule?.ruleType === "token-holder" && tokesListLoading) ||
      (data?.rule?.ruleType === "subscriber" && contractsLoading)
    ) {
      return (
        <Stack sx={{ mt: 2 }} direction="row" spacing={1.5} alignItems="center">
          <Box>
            <CircularProgress />
          </Box>
          <Typography>
            In the process of getting a list of{" "}
            {data?.rule?.ruleType === "discord-role" && "roles."}
            {data?.rule?.ruleType === "subscriber" && "contracts."}
            {data?.rule?.ruleType === "token-holder" && "tokens."} Please,
            wait..
          </Typography>
        </Stack>
      );
    }
    const label = `Select ${
      RULE_LABELS?.[data?.rule?.ruleType as RuleType]?.selectLabel
    }`;
    return (
      <FormControl fullWidth margin="dense" required>
        <InputLabel>{label}</InputLabel>
        <Select
          {...register("selectedId", {
            required: "This field is required",
          })}
          label={label}
          disabled={loading}
          defaultValue=""
        >
          {selectList()}
        </Select>
      </FormControl>
    );
  };

  return (
    <Dialog
      open={!!data}
      onClose={loading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <DialogTitle>
          {data?.rule?.id === "new" ? "Add" : "Edit"}{" "}
          {RULE_LABELS?.[data?.rule?.ruleType as RuleType]?.title} condition
        </DialogTitle>
        <DialogContent>
          {RULE_LABELS?.[data?.rule?.ruleType as RuleType]?.description && (
            <Typography mb={2}>
              {RULE_LABELS?.[data?.rule?.ruleType as RuleType]?.description}
            </Typography>
          )}
          {data?.rule?.id === "new" && data?.canAddConditionGroup && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={withConditionGroup}
                    onChange={(e) => setWithConditionGroup(e.target.checked)}
                    disabled={loading}
                  />
                }
                label="Create condition with new group"
              />
              {withConditionGroup && (
                <Stack direction="row" alignItems="center" spacing={1} mb={3}>
                  <Typography fontWeight={700}>Group with operator</Typography>
                  <Box
                    sx={{
                      width: 80,
                    }}
                  >
                    <FormControl
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{ backgroundColor: "background.default" }}
                    >
                      <Select
                        {...register("condition", {
                          required: "This field is required",
                        })}
                        defaultValue="OR"
                        sx={{
                          "& .MuiSelect-select": {
                            p: "5.5px 8px",
                          },
                        }}
                        disabled={loading}
                      >
                        <MenuItem value="OR">Or</MenuItem>
                        <MenuItem value="AND">And</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              )}
            </>
          )}
          {data?.rule && (
            <>
              {data?.rule?.ruleType === "holder" ? (
                <Alert
                  variant="outlined"
                  severity={
                    hashlist?.hashlist?.length > 0 ? "success" : "warning"
                  }
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => uploadInputRef.current?.click()}
                    >
                      {hashlist?.hashlist?.length > 0 ? "Change" : "Upload"}
                      <input
                        ref={uploadInputRef}
                        type="file"
                        accept=".json"
                        style={{ display: "none" }}
                        onChange={onFileSelected}
                        disabled={loading}
                      />
                    </Button>
                  }
                  sx={{ my: 1 }}
                >
                  <AlertTitle>
                    {hashlist?.hashlist?.length > 0
                      ? hashlist?.hashlistTitle
                      : "Upload hashlist"}
                  </AlertTitle>
                  <Typography>
                    {hashlist?.hashlist?.length > 0 ? (
                      <>
                        <strong>{hashlist?.hashlist?.length}</strong> NFTS
                        DETECTED
                      </>
                    ) : (
                      "Please upload a hashlist json file"
                    )}
                  </Typography>
                </Alert>
              ) : data?.rule?.ruleType ===
                "minimum-unique-subscribers" ? null : (
                select()
              )}
              {data?.rule?.ruleType !== "discord-role" &&
                data?.rule?.ruleType !== "minimum-unique-subscribers" && (
                  <TextField
                    {...register("minimumHolding", {
                      valueAsNumber: true,
                      required: "This field is required",
                      min: 1,
                    })}
                    type="number"
                    error={!!errors.minimumHolding}
                    helperText={
                      !!errors.minimumHolding && errors.minimumHolding.message
                    }
                    disabled={loading}
                    required
                    fullWidth
                    label={
                      data?.rule?.ruleType === "token-holder"
                        ? "Minimum number of tokens the user must hold in his wallet"
                        : "Minimum number of digital assets the user must own in his wallet"
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              {data?.rule?.ruleType === "minimum-unique-subscribers" && (
                <TextField
                  {...register("minimum", {
                    valueAsNumber: true,
                    required: "This field is required",
                    min: 1,
                  })}
                  type="number"
                  error={!!errors.minimum}
                  helperText={!!errors.minimum && errors.minimum.message}
                  disabled={loading}
                  required
                  fullWidth
                  label="Minimum number of unique wallets"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="base"
            onClick={hide}
            disabled={loading}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" loading={loading} variant="contained">
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RulesTreeAddModal;
