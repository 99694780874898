import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import { CreateAssetPayload } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const createAsset = async (data: CreateAssetPayload) => {
  return await axios.post<any>(API_PATHS.assets.create, { data });
};

export default function useCreateAsset() {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: Omit<CreateAssetPayload, "communityId">) => {
      if (!selectedCommunityDocId) return;
      const { data } = await createAsset({
        communityId: selectedCommunityDocId,
        ...payload,
      });

      if (isSuccessResponse(data?.data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.assets.list, selectedCommunityDocId],
          exact: true,
          refetchType: "active",
        });
        return data?.data;
      }

      throw new Error(
        `${data?.data?.error?.title}\n${data?.data?.error?.description}`
      );
    },
    {
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
}
