import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import {
  CommunityByCampaign,
  AddCommunityInCampaignResponse,
  SearchCommunity,
} from "../../../../types";
import { isSuccessResponse } from "../../../../utils";
import { useParams } from "react-router-dom";

const addCommunity = async (payload: {
  communityId: string;
  campaignId: string;
  targetCommunity: SearchCommunity;
}) => {
  return await axios.post<AddCommunityInCampaignResponse>(
    API_PATHS.tokenCampaigns.addCommunityToCampaign,
    payload
  );
};

const useAddCommunityToCampaign = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();
  const { campaignId } = useParams();

  return useMutation(
    async (payload: SearchCommunity) => {
      if (!selectedCommunityDocId || !campaignId) return;
      const { data } = await addCommunity({
        communityId: selectedCommunityDocId,
        campaignId,
        targetCommunity: payload,
      });

      if (isSuccessResponse(data)) {
        return data.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar({
          variant: "success",
          message: `${data?.name} added to campaign successfully!`,
        });
        queryClient.setQueryData(
          [API_PATHS.tokenCampaigns.communitiesByCampaign, campaignId],
          (currentState?: CommunityByCampaign[]) => {
            if (
              typeof currentState === "undefined" ||
              typeof data === "undefined"
            ) {
              return currentState;
            }
            return [...currentState, data];
          }
        );
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useAddCommunityToCampaign;
