import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForgotPassword } from "../../../hooks/queries/auth/useForgotPassword";

type Inputs = {
  email: string;
};

const Forgot = () => {
  const { mutateAsync: requestForgot, isLoading: requestForgotLoading } =
    useForgotPassword();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    await requestForgot(data.email);
    setValue("email", "");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("email", {
          required: "This field is required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          },
        })}
        error={!!errors.email}
        helperText={!!errors.email && errors.email.message}
        disabled={requestForgotLoading}
        required
        fullWidth
        label="Email address"
        autoComplete="email"
      />
      <LoadingButton
        loading={requestForgotLoading}
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        type="submit"
      >
        Send reset password email
      </LoadingButton>
    </form>
  );
};

export default Forgot;
