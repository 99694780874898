import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "./Loader/Loader";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ModalTransition from "../helpers/ModalTransition";

type Props = {
  data: any;
  title: string;
  content: string | JSX.Element;
  isLoading: boolean;
  hide: () => void;
  confirm: (data: any) => void;
};

const ConfirmDialog = ({
  data,
  title,
  content,
  isLoading,
  hide,
  confirm,
}: Props) => {
  return (
    <Dialog
      open={!!data}
      onClose={isLoading ? undefined : hide}
      fullWidth
      maxWidth="xs"
      keepMounted
      TransitionComponent={ModalTransition}
    >
      {isLoading ? (
        <DialogContent>
          <Stack alignItems="center" spacing={2}>
            <Loader />
            <Typography>May take a while, trust the process..</Typography>
          </Stack>
        </DialogContent>
      ) : (
        <>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            {typeof content === "string" ? (
              <DialogContentText>{content}</DialogContentText>
            ) : (
              content
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="base" onClick={hide} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={() => confirm(data)}
              disabled={isLoading}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
