import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import LinkBehavior from "../../components/LinkBehavior";
import Loader from "../../components/Loader/Loader";
import Empty from "../../components/Empty";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Fragment } from "react";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useGetAllTokenCampaigns } from "../../hooks/queries/token-campaign/state/useGetAllTokenCampaigns";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const TokensDelegationList = () => {
  const { data: campaigns, isLoading: campaignsLoading } =
    useGetAllTokenCampaigns();
  return (
    <>
      <Typography variant="h4" fontWeight={700} mb={1}>
        Token booster
      </Typography>
      <Typography color="text.secondary">
        Launch a token using our platform.This feature allows you to craft a
        contract designed for widespread activation across the Anybodies
        communities. Each initiative is structured with specific milestones and
        a cap on tasks, aiming to boost your token's success through our
        specialized airdrop tool. This approach not only enhances your existing
        holders but also fosters long-term engagement with your token across
        multiple & select communities.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <Typography variant="h5" fontWeight={700} mb={1}>
          Campaigns
        </Typography>
        <Button
          LinkComponent={LinkBehavior}
          href="/token-booster-create"
          startIcon={<DataSaverOnRoundedIcon />}
          variant="base"
        >
          Create campaign
        </Button>
      </Stack>
      {campaignsLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3} height="100%">
          <Loader />
        </Stack>
      ) : campaigns?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
          height="100%"
        >
          <Empty text="You don't have any campaigns" />
        </Stack>
      ) : (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <List disablePadding dense>
            {campaigns?.map((campaign, index) => (
              <Fragment key={campaign?._id}>
                {index !== 0 && <Divider component="li" />}
                <ListItem color="background.default" disablePadding>
                  <ListItemButton
                    LinkComponent={LinkBehavior}
                    href={campaign?._id}
                  >
                    <Tooltip
                      title={campaign?.tokenMetadata?.symbol}
                      placement="top"
                    >
                      <Avatar
                        alt={campaign?.tokenMetadata?.symbol}
                        src={campaign?.tokenMetadata?.uri}
                        variant="rounded"
                        sx={{ width: 32, height: 32, mr: 1 }}
                      >
                        <InsertPhotoRoundedIcon />
                      </Avatar>
                    </Tooltip>
                    <ListItemText
                      primary={campaign?.name}
                      secondary={`${campaign?.communitiesCount} communit${
                        campaign?.communitiesCount > 1 ? "ies" : "y"
                      }`}
                    />
                  </ListItemButton>
                </ListItem>
              </Fragment>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default TokensDelegationList;
