import Box from "@mui/material/Box";
import Footer from "../../layouts/base/Footer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useApp } from "../../services/AppService";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import { useState } from "react";
import CreateNewCommunityModal from "./CreateNewCommunityModal";
import Empty from "../../components/Empty";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import Avatar from "@mui/material/Avatar";

const SelectCommunity = () => {
  const {
    communityList,
    communityListLoading,
    setSelectedCommunity,
    setSelectedCommunityDocId,
  } = useApp();

  const [showCreateCommunity, setShowCreateCommunity] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: 480, maxWidth: "100%", m: "auto", p: 1.5 }}>
        <Paper elevation={3} sx={{ overflow: "hidden" }}>
          <Box
            sx={{
              height: 8,
              background:
                "linear-gradient(90deg, #FFE000 0%, #FF9962 50.52%, #947DFF 100%)",
            }}
          />
          <Box pt={2}>
            <Typography variant="h5" fontWeight={700} px={2}>
              Select community
            </Typography>
            {typeof communityList === "undefined" || communityListLoading ? (
              <List>
                {[1, 2, 3].map((i) => (
                  <ListItem key={i}>
                    <ListItemAvatar>
                      <Skeleton width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText primary={<Skeleton />} />
                    <Skeleton
                      width={42}
                      height={24}
                      sx={{ borderRadius: 16, ml: 1 }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : communityList?.length > 0 ? (
              <List>
                {communityList?.map((communityInfo) => {
                  return (
                    <ListItem disablePadding key={communityInfo?.VaultAddress}>
                      <ListItemButton
                        onClick={() => {
                          if (
                            !setSelectedCommunity ||
                            !setSelectedCommunityDocId
                          )
                            return;
                          setSelectedCommunityDocId(communityInfo?.communityId);
                          setSelectedCommunity(communityInfo);
                        }}
                      >
                        {communityInfo?.ImageURL && (
                          <ListItemAvatar>
                            <Avatar
                              alt={communityInfo?.VaultName}
                              src={communityInfo?.ImageURL}
                              variant="rounded"
                            />
                          </ListItemAvatar>
                        )}
                        <ListItemText primary={communityInfo?.VaultName} />
                        {communityInfo?.IsLive && (
                          <Chip
                            label="Live"
                            color="primary"
                            size="small"
                            sx={{ fontWeight: 700, ml: 1 }}
                          />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Box mt={5} px={1.5}>
                <Empty text="You haven't created any community yet. Create now!">
                  <SouthRoundedIcon
                    fontSize="large"
                    color="primary"
                    sx={{ my: 2 }}
                  />
                </Empty>
              </Box>
            )}
          </Box>
        </Paper>
        <Stack alignItems="center" mt={4}>
          <Button
            variant="base"
            color="secondary"
            startIcon={<DataSaverOnRoundedIcon />}
            onClick={() => setShowCreateCommunity(true)}
          >
            Create a new community
          </Button>
        </Stack>
      </Box>
      <Footer />
      <CreateNewCommunityModal
        isOpen={showCreateCommunity}
        hide={() => {
          setShowCreateCommunity(false);
        }}
      />
    </Box>
  );
};

export default SelectCommunity;
