import React, { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {
  SubscriptionContractAttributeCountMap,
  SubscriptionContractAttributeMap,
} from "../../../types";

type AttributesSetupProps = {
  name: string;
  attributeMap: SubscriptionContractAttributeMap;
  attributeCountMap: SubscriptionContractAttributeCountMap;
  handleAmountChanged: (key: string, amount: number) => void;
  handleSensitivityChanged: (key: string, sensitivity: number) => void;
  handleCustomChanged: (key: string, trait: string, status: boolean) => void;
  handleValueChanged: (key: string, trait: string, value: number) => void;
};

const AttributesSetup: React.FC<AttributesSetupProps> = ({
  name,
  attributeMap,
  attributeCountMap,
  handleAmountChanged,
  handleSensitivityChanged,
  handleCustomChanged,
  handleValueChanged,
}) => {
  const traits = Object.keys(attributeMap[name])
    .sort((a, b) => a.localeCompare(b))
    .map((trait, index) => (
      <Fragment key={trait}>
        <Divider sx={{ my: 1 }}>
          {index === 0 && (
            <Typography
              variant="overline"
              lineHeight={1}
              color="text.secondary"
            >
              Values
            </Typography>
          )}
        </Divider>
        <Box>
          <Typography variant="body2" mb={0.5}>
            {trait} ({attributeMap[name]?.[trait]?.count} |{" "}
            {parseFloat(
              (
                (attributeMap?.[name]?.[trait]?.count /
                  attributeCountMap?.[name]?.count) *
                100
              ).toFixed(1)
            )}
            %)
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Checkbox
              checked={attributeMap[name][trait].custom}
              onChange={(e) =>
                handleCustomChanged(name, trait, e.target.checked)
              }
            />
            {attributeMap[name][trait].custom ? (
              <TextField
                fullWidth
                disabled={attributeMap[name][trait].custom === false}
                onChange={(e) =>
                  handleValueChanged(name, trait, +e.target.value)
                }
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                size="small"
                sx={{
                  ...(attributeMap[name][trait].value === 0 && {
                    background: "rgba(255, 0, 0, 0.11)",
                  }),
                }}
                value={attributeMap[name][trait].value}
              />
            ) : (
              <Typography color="text.secondary">
                {attributeMap[name][trait].value}
              </Typography>
            )}
          </Stack>
        </Box>
      </Fragment>
    ));

  return (
    <Box>
      <Box
        sx={{
          minWidth: 200,
          width: "100%",
          border: "1px solid",
          borderColor: "divider",
          p: 1,
          borderRadius: "3px",
        }}
      >
        <Typography fontWeight={700} textAlign="center">
          {name}
        </Typography>
        <TextField
          margin="dense"
          required
          fullWidth
          variant="filled"
          label="Max amount"
          helperText="Input the highest daily reward that you would like to give a trait in this list"
          onChange={(e) => handleAmountChanged(name, +e.target.value)}
          type="number"
          value={attributeCountMap[name]?.amount || 0}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          size="small"
        />
        <TextField
          margin="dense"
          required
          fullWidth
          variant="filled"
          label="Distribution"
          helperText="How evenly do you want to award rare traits?"
          type="number"
          value={attributeCountMap[name]?.sensitivity || 10}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          size="small"
          onChange={(e) => {
            handleSensitivityChanged(name, +e.target.value);
          }}
        />
        {traits}
      </Box>
    </Box>
  );
};

export default AttributesSetup;
