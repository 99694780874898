import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import useCreateBooster from "../../hooks/queries/boosters/mutations/useCreateBooster";

export interface Props {
  open: boolean;
  hide: () => void;
}

type Inputs = {
  planTitle: string;
  planDescription: string;
};

const CreateNew = ({ open, hide }: Props) => {
  const theme = useTheme();
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { mutateAsync: create, isLoading: createLoading } = useCreateBooster({
    onSettled: hide,
  });

  useEffect(() => {
    reset({ planTitle: "", planDescription: "" });
  }, [open, reset]);

  const onSave = (data: Inputs) => {
    create(data);
  };

  return (
    <Dialog
      open={open}
      onClose={createLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <DialogTitle>Create multiplier contract</DialogTitle>
        <DialogContent>
          <>
            <TextField
              {...register("planTitle", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3",
                },
              })}
              error={!!errors.planTitle}
              helperText={!!errors.planTitle && errors.planTitle.message}
              required
              fullWidth
              label="Name"
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              disabled={createLoading}
            />
            <TextField
              {...register("planDescription", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3",
                },
              })}
              error={!!errors.planDescription}
              helperText={
                errors.planDescription
                  ? errors.planDescription.message
                  : "Make sure to give as much information about the plan in this field"
              }
              required
              fullWidth
              label="Description"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={3}
              margin="dense"
              disabled={createLoading}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="base"
            onClick={hide}
            disabled={createLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={createLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNew;
