import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppService } from "./AppService";
import { WalletService } from "./WalletService";
import { AuthService } from "./AuthService";

const queryClient = new QueryClient();

export const AppState = ({ children }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthService>
        <WalletService>
          <AppService>{children}</AppService>
        </WalletService>
      </AuthService>
    </QueryClientProvider>
  );
};
