import Typography from "@mui/material/Typography";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import RocketLaunchRoundedIcon from "@mui/icons-material/RocketLaunchRounded";
import { useState } from "react";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useGetTokenCampaign } from "../../hooks/queries/token-campaign/state/useGetTokenCampaign";
import Stack from "@mui/material/Stack";
import Loader from "../../components/Loader/Loader";
import TokensDelegationConfig from "./TokensDelegationConfig";
import Box from "@mui/material/Box";
import { TokenCampaignDetails } from "../../types";
import TokensDelegationTargetCommunities from "./TokensDelegationTargetCommunities";

const TokensDelegationCreate = () => {
  const { data: campaign, isLoading: campaignLoading } = useGetTokenCampaign();

  const [activeTab, setActiveTab] = useState(TABS[0]);

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Token booster",
            Icon: RocketLaunchRoundedIcon,
            path: "/token-booster",
          },
          {
            label: campaign?.name,
          },
        ]}
      />
      {campaignLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3} height="100%">
          <Loader />
        </Stack>
      ) : (
        <>
          <Typography variant="h5" fontWeight={700}>
            {campaign?.name}
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <Tabs
            value={activeTab}
            variant="scrollable"
            onChange={(e, newValue) => {
              setActiveTab(newValue);
            }}
          >
            {TABS.map((label) => (
              <Tab label={label} value={label} key={label} />
            ))}
          </Tabs>
          <Divider sx={{ mb: 1 }} />
          <Box sx={{ display: activeTab === TABS[0] ? "block" : "none" }}>
            <TokensDelegationConfig
              campaign={campaign as TokenCampaignDetails}
            />
          </Box>
          <Box sx={{ display: activeTab === TABS[1] ? "block" : "none" }}>
            <TokensDelegationTargetCommunities />
          </Box>
        </>
      )}
    </>
  );
};

export default TokensDelegationCreate;

const TABS = ["Config", "Target communities"];
