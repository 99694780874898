import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { API_PATHS } from "../../../constants";
import { useAuth } from "../../../services/AuthService";
import { GetSnapshotPayload } from "../../../types";
import { useApp } from "../../../services/AppService";

const getSnapshot = async (data: GetSnapshotPayload) => {
  return await axios.post<unknown>(API_PATHS.snapshot, { data });
};

const useGetSnapshot = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useAuth();
  const { selectedCommunityDocId } = useApp();

  return useMutation(
    async (payload: Omit<GetSnapshotPayload["payload"], "vaultId">) => {
      const idToken = await getToken();
      const { data } = await getSnapshot({
        idToken,
        payload: {
          vaultId: selectedCommunityDocId,
          ...payload,
        },
      });
      return data;
    },
    {
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useGetSnapshot;
