import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
export const FIREBASE_APP = initializeApp({
  apiKey: "AIzaSyABZVLQeN4r91nlyRHYww3Nr5yxblLRrfY",
  authDomain: "diamondvaults.io",
  projectId: "nft-anybodies",
  storageBucket: "nft-anybodies.appspot.com",
  messagingSenderId: "773321643955",
  appId: "1:773321643955:web:4d92166eafd8ab3958ddc6",
  measurementId: "G-QFKFEHZ0WL",
});

export function useForgotPassword() {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (email: string) => {
      await sendPasswordResetEmail(getAuth(FIREBASE_APP), email);

      return { success: true };
    },
    {
      onSuccess: (res) => {
        enqueueSnackbar({
          variant: "success",
          message: "A reset password email was sent.",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign in. Try again!",
        });
      },
    }
  );
}
