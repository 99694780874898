export interface SuccessResponse<T> {
  success: true;
  data: T;
}

export interface ErrorResponse {
  success: false;
  error: {
    title: string;
    description: string;
  };
}

export type ServerResponse<T> = { data: SuccessResponse<T> | ErrorResponse };

export type RulesListResponse = ServerResponse<RuleListItem[]>;

export enum RuleTarget {
  WalletAddress = "walletAddress",
  Community = "community",
}

export type RuleListItem = {
  hits: number;
  name: string;
  description: string;
  _id: string;
  target: RuleTarget;
};

export type RuleTreeResponse = ServerResponse<RuleTree>;

export type RuleTree = {
  accountId: string;
  communityId: string;
  createdDate: Date;
  description: string;
  hits: number;
  name: string;
  tree: Condition;
  updateDate: Date;
  updateToken: string;
  target: RuleTarget;
  _id: string;
};

export type ConditionOperator = "AND" | "OR";

export type Condition = {
  condition: ConditionOperator;
  ruleId: string;
  ruleType: string;
  _id: string;
  children: Array<Condition | Rule>;
};

export interface RuleBase {
  ruleId: string;
}

export type RuleType =
  | "holder"
  | "subscriber"
  | "phygital"
  | "discord-role"
  | "token-holder";

export type CommunityRuleType = "minimum-unique-subscribers";

export interface HolderRule extends RuleBase {
  ruleType: "holder";
  hashlistId: string;
  minimumHolding: number;
  hashlistTitle: string;
  hashlistSize: number;
  hashlistManagerId: string;
}

export interface SubscriberRule extends RuleBase {
  ruleType: "subscriber";
  subscriptionId: string;
  minimumHolding: number;
}

export interface PhygitalRule extends RuleBase {
  ruleType: "phygital";
  phygitalId: string;
  minimumHolding: number;
  phygitalProfile: {
    title: string;
    description: string;
    bannerImage: string;
  };
}

export interface TokenHolderRule extends RuleBase {
  ruleType: "token-holder";
  tokenId: string;
  tokenProfile: {
    address: string;
    name: string;
    symbol: string;
    uri: string;
  };
  minimumHolding: number;
}

export interface MinimumUniqueSubscribersCommunityRule extends RuleBase {
  ruleType: "minimum-unique-subscribers";
  minimum: number;
}

export interface DiscordRule extends RuleBase {
  ruleType: "discord-role";
  roleId: string;
  roleName: string;
  serverId: string;
  serverName: string;
}

export type Rule =
  | HolderRule
  | SubscriberRule
  | PhygitalRule
  | DiscordRule
  | TokenHolderRule
  | MinimumUniqueSubscribersCommunityRule;

export type FullHashlistByManagerIdResponse = ServerResponse<string[]>;

export type DiscordRolesResponse = ServerResponse<DiscordRole[]>;

export type DiscordRole = {
  color: number;
  createdTimestamp: number;
  guild: string;
  hoist: boolean;
  icon: any;
  id: string;
  managed: boolean;
  mentionable: boolean;
  name: string;
  permissions: string;
  rawPosition: number;
  tags: any;
  unicodeEmoji: any;
};

export type QuestsListResponse = ServerResponse<QuestItem[]>;

export type QuestItem = {
  accountId: string;
  banner: FirebaseImage;
  communityId: string;
  created: Date;
  description: string;
  hits: number;
  missions: QuestMission[];
  status: "draft" | "live";
  title: string;
  updateToken: string;
  updated: Date;
  _id: string;
};

export type QuestMission = {
  description: string;
  ruleTreeId: string;
  title: string;
};

export type QuestMissionsResponse = ServerResponse<QuestItem>;

export type TestRuleTreeResponse = ServerResponse<boolean>;

export type FirebaseImage = {
  assets: {
    path: string;
    url: string;
    _id: string;
  }[];
  created: Date;
  manifest: string;
  _id: string;
};
export interface CommunityProfile {
  vaultId: string;
  create: Date;
  projectId: string;
  bookBurnerSize: number;
  bookBurner: string;
  projectName: string;
  projectDescription?: string;
  linkTwitter: string;
  linkDiscord: string;
  showDiscord: boolean;
  showSecondaryMarket: boolean;
  linkSecondaryMarket: string;
  linkWebsite: string;
  profileImage: Image;
  verifiedDate: Date;
  verified: boolean;
  showWebsite: boolean;
  coverImage: Image;
  showTwitter: boolean;
}

export type Image = {
  path: string;
  url: string;
};

export type FirebaseDate = {
  _seconds: number;
  _nanoseconds: number;
};

export type Attribute = {
  trait_type: string;
  value: string;
};

export type UploadedImage = {
  type: string;
  base64: string;
};

export type RoyaltyCreator = {
  address: string;
  share: string | number;
};

export type AssetsListResponse = ServerResponse<Asset[]>;

export type Asset = {
  _id: string;
  accountId: string;
  communityId: string;
  assetTitle: string;
  blockchain: string;
  digitalAssetConfig: {
    created: Date;
    manifest: string;
    assets: [
      {
        url: string;
        path: string;
        _id: string;
      }
    ];
    _id: string;
  };
  attributes: Attribute[];
  creators: RoyaltyCreator[];
  name: string;
  description: string;
  symbol: string;
  assetCollection: {
    name: string;
    family: string;
  };
  sellerFeeBasisPoints: 23;
  createdDate: Date;
  lastUpdated: Date;
};

export type Country = {
  code: string;
  name: string;
};

export enum RewardSources {
  System = "system",
  Shopify = "shopify",
}

export enum SubscriptionContractType {
  Collection = "collection",
  Attribute = "attribute-collection",
}

export enum RewardTypesSystem {
  Discount = "discount",
  GiftCard = "gift-card",
}

export enum RewardFulfillmentTypes {
  Upload = "upload",
  Scratch = "scratch",
}

export enum RewardTypesShopify {
  Product = "shopify-product",
  GiftCard = "shopify-gift-card",
}

export type RewardTypes = RewardTypesSystem | RewardTypesShopify;

export type RewardSaveStepPayload =
  | RewardSaveStepPayloadCreate
  | RewardSaveStepPayloadUpdate
  | RewardSaveStepPayloadEligibility
  | RewardSaveStepPayloadDistribution
  | RewardSaveStepPayloadFulfillment
  | RewardSaveStepPayloadMetadata
  | RewardSaveStepPayloadClaiming;

export type RewardSaveStepPayloadBase = {
  form: {
    campaignId?: string;
    communityId: string;
    autoRedeem: boolean;
    title: string;
    description: string;
    rewardSource: RewardSources | "";
    rewardType: RewardTypes | "";
    claimExpirationDate: Date;
    eligibleCountries?: Country[];
    rewardCategory: string;
  };
};

export interface RewardSaveStepPayloadCreate extends RewardSaveStepPayloadBase {
  action: "create-campaign";
}

export interface RewardSaveStepPayloadUpdate extends RewardSaveStepPayloadBase {
  action: "update-profile";
}

export type RewardSaveStepPayloadEligibility = {
  action: "update-eligibility";
  form: {
    campaignId: string;
    communityId: string;
    ruleTreeId?: string;
    ruleTreeDescription?: string;
    maxClaimsPerWalletAddress: number;
    type: EligableTypes | "";
    eligableHashlists?: any;
  };
};

export type RewardSaveStepPayloadDistribution = {
  action: "update-distribution";
  form: {
    campaignId: string;
    communityId: string;
    distributionType: DistributionTypes | "";
  };
};

export type RewardSaveStepPayloadFulfillment = {
  action: "update-fulfillment";
  form: {
    campaignId: string;
    communityId: string;
    expirationDate: Date | null;
    fulfillmentType: string;
    size?: number | string;
    hashlistManagerId?: string;
    hashlistTitle?: string;
    codesArray?: string[][];
  };
};

export type RewardSaveStepPayloadMetadata = {
  action: "update-token-metadata";
  form: {
    campaignId: string;
    communityId: string;
    assetMetadataId: string;
    redeemedAssetMetadataId?: string;
    redemptionInstructions: string[];
    redemptionUri: string;
  };
};

export type RewardSaveStepPayloadClaiming = {
  action: "update-claiming-payment";
  form: {
    campaignId: string;
    communityId: string;
    destinationWalletAddress: string;
    transactions: ClaimingTransaction[];
  };
};

export type ClaimingTransaction = {
  type: "sol" | "splToken";
  tokenAddress?: string;
  amount: number | string;
};

export type RewardInfo = {
  accountId: string;
  claimExpirationDate: Date;
  claimedCount: number;
  communityId: string;
  created: Date;
  eligibleCountries: Country[];
  profile: {
    bannerImage: FirebaseImage;
    description: string;
    title: string;
  };
  rewardCategory: string;
  rewardSource: RewardSources;
  rewardType: RewardTypes;
  status: "draft" | "published" | "live" | "ended";
  autoRedeem: boolean;
  totalAvailableReward: number;
  updated: Date;
  _id: string;
  claimLimitType?: string;
  eligibleAudience?: {
    eligibleHashlists: DbHashlist[];
    maxClaimsPerWalletAddress: number;
    type: EligableTypes;
    ruleTreeId?: string;
    ruleTreeDescription?: string;
  };
  distributionType?: DistributionTypes;
  redeemExpirationDate?: Date;
  rewardConfig?: {
    fulfillmentType: "upload" | "scratch";
    hashlistManagerId: string;
    scratchHashlistManagerId: string;
    hashlistTitle: string;
    size: number;
    headers: string[];
  };
  tokenConfig?: {
    assetMetadataId: string;
    redeemedAssetMetadataId?: string;
  };
  redemptionInstructions?: {
    steps: string[];
    redemptionUri: string;
  };
  claimingPaymentConfig?: {
    destinationWalletAddress: string;
    transactions: ClaimingTransaction[];
  };
};

export type DbHashlist = {
  hashlistTitle?: string;
  hashlistManagerId?: string;
  size?: number;
  minToClaim?: number | "";
};

export type AllRewardsResponse = ServerResponse<RewardInfo[]>;

export type RewardsByIdResponse = ServerResponse<RewardInfo>;

export enum EligableTypes {
  Unlimited = "unlimited",
  Hashlist = "hashlist",
  WalletAddresses = "walletAddresses",
}

export enum DistributionTypes {
  Giveaway = "giveaway",
  Raffle = "raffle",
}

export type TxRequestResponse = ServerResponse<{
  success: true;
  rawTransaction: any;
  requestId: string;
}>;

export type MutationDefaultResponse = ServerResponse<unknown>;

export type PhygitalListResponse = ServerResponse<Phygital[]>;

export type Phygital = {
  _id: string;
  profile: Profile;
  publishedDate: Date;
  claimPercent: number;
  distributionType: string;
  tokenMetadata: TokenMetadata;
};

export type Profile = {
  title: string;
  description: string;
  bannerImage: string;
};

export type TokenMetadata = {
  _id: string;
  accountId: string;
  communityId: string;
  assetTitle: string;
  blockchain: string;
  digitalAssetConfig: DigitalAssetConfig;
  attributes: Attribute[];
  creators: Creator[];
  name: string;
  description: string;
  symbol: string;
  assetCollection: AssetCollection;
  sellerFeeBasisPoints: number;
  createdDate: Date;
  lastUpdated: Date;
  __v: number;
};

export type AssetCollection = {
  name: string;
  family: string;
};

export type Creator = {
  address: string;
  share: number;
};

export type DigitalAssetConfig = {
  created: Date;
  manifest: string;
  assets: { path: string; url: string; _id: string }[];
  _id: string;
  __v: number;
};

export type PhygitalByIdResponse = ServerResponse<
  RewardInfo & {
    totalClaimablePages: number;
    totalClaimablesPerPage: number;
  }
>;

export type RewardSource = {
  id: string;
  label: string;
  description: string;
};

export type RewardType = {
  [key: RewardSource["id"]]: {
    id: string;
    label: string;
    description: string;
  }[];
};

export type LoginResponse = ServerResponse<{
  displayName: string;
  email: string;
  expiresIn: number;
  idToken: string;
  kind: string;
  localId: string;
  refreshToken: string;
  registered: boolean;
}>["data"];

export type RefreshTokenResponse = ServerResponse<{
  idToken: string;
  expiresIn: number;
  refreshToken: string;
}>["data"];

export type AllCommunitiesResponse = ServerResponse<CommunityInfo[]>["data"];

export type CommunityInfo = {
  ActivationTxid: string;
  Created: Date;
  DiscordData: { ServerName: string; name: string };
  ServerName: string;
  ImageURL: string;
  ImageWidth: number;
  IsLive: boolean;
  ProjectId: string;
  SecondaryLink: string;
  ShowMultiplier: boolean;
  VaultAddress: string;
  VaultName: string;
  VaultRoute: string;
  VaultRouteSearch: string;
  VaultSize: number;
  communityId: string;
  HolderVerificationRoleId?: string;
};

export type AllSubscriptionContractsResponse = ServerResponse<
  SubscriptionContractListItem[]
>["data"];

export type SubscriptionContractListItem = {
  Active: boolean;
  Archived: boolean;
  Created: Date;
  PlanDescription: string;
  PlanId: string;
  PlanTitle: string;
  RewardProgram: string;
  StakingDuration: number;
  StakingProgram: string;
  TokenName: string;
};

export type AllTokensResponse = ServerResponse<Token[]>["data"];

export type Token = {
  Created: Date;
  DecimalPerToken: number;
  TokenName: string;
  TokenNameUpper: string;
  TokenNameLower: string;
  tokenId: string;
  tokenMetadata: {
    symbol: string;
    address: string;
    decimals: number;
    name: string;
    uri: string;
  };
  balance: number;
  isSol: boolean;
};

export type CommunityProfileResponse = ServerResponse<CommunityProfile>["data"];

export type UpdateProfilePayload = {
  projectName: string;
  projectDescription: string;
  showDiscord: boolean;
  showSecondaryMarket: boolean;
  showTwitter: boolean;
  showWebsite: boolean;
  linkDiscord: string;
  linkSecondaryMarket: string;
  linkTwitter: string;
  linkWebsite: string;
};

export type UpdateProfileAssetPayload = { fileType: string; base64: string };

export type TxRequestRes = ServerResponse<{
  rawTransaction: {
    type: string;
    data: Buffer;
  };
  requestId: string;
}>["data"];

export type CreateContractPayload = {
  communityId: string;
  stakingDuration: number | string;
  rewardProgram: SubscriptionContractType | string;
  planTitle: string;
  planDescription: string;
  tokenId: string;
};

export type CreateContractResponse = ServerResponse<{
  contractId: string;
}>["data"];

export type SubscriptionDetailsResponse =
  ServerResponse<SubscriptionContract>["data"];

export type SubscriptionContract = {
  planData: PlanData;
  supportedCollections: SupportedCollection[];
};

export type PlanData = {
  StakingDuration: number;
  SupportsAutoStaking: boolean;
  ProjectId: string;
  VaultId: string;
  Created: Date;
  RewardProgram: SubscriptionContractType;
  StakingProgram: string;
  TokenData: TokenData;
  TokenId: string;
  Hidden: boolean;
  HolderVerificationRoleId?: string;
  Setup: boolean;
  Active: boolean;
  Archived: boolean;
  maturityActive: boolean;
  maturityLevels: MaturityLevel[];
  PlanTitle: string;
  PlanDescription: string;
  benefitsPreview: BenefitsPreview;
  feeAddon?: FeeAddon;
  rulesDetails?: RulesConfigurationType;
  AttributeMap?: SubscriptionContractAttributeMap;
  AttributeCountMap?: SubscriptionContractAttributeCountMap;
  bonkCampaign?: boolean;
  tokenCampaign?: boolean;
};

export type SubscriptionContractAttributeCountMap = {
  [attribute_name: string]: {
    amount: number;
    count: number;
    sensitivity: number;
  };
};

export type SubscriptionContractAttributeMap = {
  [trait_type: string]: {
    [type: string]: {
      custom: boolean;
      count: number;
      value: number;
    };
  };
};
export type SubscriptionBoosterAttributeCountMap = {
  [attribute_name: string]: {
    amount: number;
  };
};

export type SubscriptionBoosterAttributeMap = {
  [trait_type: string]: {
    [type: string]: {
      count: number;
      supported: boolean;
    };
  };
};

export type FeeAddon = {
  active: boolean;
  walletAddress: string;
  feeSol: number;
};

export type RulesConfigurationType = {
  allowMultiSubscribe: boolean;
  maxSubscriptionsActive: boolean;
  maxSubscriptions: number;
  maxSubscriptionsPerWalletActive: boolean;
  maxSubscriptionsPerWallet: number;
  canSubscribeRuleIdActive: boolean;
  canSubscribeRuleId: string;
  canSubscribeRuleDescription: string;
};

export type TokenData = {
  TokenNameUpper: string;
  TokenNameLower: string;
  ProjectId: string;
  TokenName: string;
  isSol: boolean;
  TokenAddress: string;
  DecimalPerToken: number;
  Created: Date;
};

export type BenefitsPreview = {
  redeemables: boolean;
  discounts: boolean;
  events: boolean;
};

export type MaturityLevel = {
  addedRewards: number;
  stakingSession: number;
};

export type SupportedCollection = {
  collectionId: string;
  fullHashlist: string[];
  amount: number;
  name: string;
};

export type UpdateSubscriptionContractInfoPayload = {
  communityId: string;
  planId: string;
  planTitle: string;
  planDescription: string;
  benefitsPreview: {
    discounts: boolean;
    redeemables: boolean;
    events: boolean;
  };
};

export type UpdateSubscriptionContractDiscordRolePayload = {
  communityId: string;
  planId: string;
  roleId: string;
};

export type UpdateSubscriptionContractFeeAddonPayload = {
  communityId: string;
  planId: string;
  feeAddon: FeeAddon;
};

export type UpdateSubscriptionContractRulesPayload = {
  communityId: string;
  planId: string;
  rules: RulesConfigurationType;
};

export type UpdateSubscriptionContractMaturityPayload = {
  communityId: string;
  planId: string;
  maturityActive: boolean;
  maturityLevels?: MaturityLevel[];
};

export type AddContractHashlistPayload = {
  communityId: string;
  planId: string;
  name: string;
  amount?: number;
  hashlist: string[];
};

export type ChangeDailyRewardPayload = {
  communityId: string;
  planId: string;
  collectionId: string;
  amount: number;
};
export interface Manifest {
  image: string;
  symbol: string;
  seller_fee_basis_points: number;
  animation_url: string;
  external_url: string;
  name: string;
  description: string;
  mintAddress?: string;
  attributes: ManifestAttribute[];
  collection: ManifestCollection;
  properties: ManifestProperties;
}

export interface ManifestAttribute {
  value: string;
  trait_type: string;
}

export type ManifestCollection = {
  name: string;
  family: string;
};

export type ManifestProperties = {
  creators: {
    address: string;
    share: number;
  }[];
  files: {
    type: "image/jpeg" | "image/jpg" | "video/mp4";
    uri: string;
  }[];
  category: "image" | "video";
};

export type TokensMetadataResponse = ServerResponse<{
  metadataTokens: Manifest[];
  success: true;
}>["data"];

export type CreateQuestPayload = {
  communityId: string;
  title: string;
  description: string;
};

export type UpdateQuestProfilePayload = {
  questId: string;
  title: string;
  description: string;
};

export interface UpdateQuestBannerPayload extends UploadedImage {
  questId: string;
}

export type UpdateQuestMissionsPayload = {
  questId: string;
  updateToken?: string;
  missions: QuestMission[];
};

export type UpdateQuestStatusPayload = {
  questId: string;
  status: QuestItem["status"];
};

export type CreateNewRuleTreePayload = {
  communityId: string;
  name: string;
  description: string;
  target: RuleTarget;
};

export type UpdateRuleTreePayload = {
  treeId: string;
  name: string;
  description: string;
};

export type CreateAssetPayload = {
  communityId: string;
  assetTitle: string;
  filePayload: UploadedImage;
  assetMetadata: {
    attributes: Attribute[];
    creators: RoyaltyCreator[];
    name: string;
    description: string;
    sellerFeeBasisPoints: number;
  };
};

export type CreatePhygitalPayload = {
  communityId: string;
  title: string;
  description: string;
  maxClaimsPerWalletAddress: number;
  type: string;
  assetMetadataId: string;
  transactions?: any[];
  totalClaimablePages: number;
  totalClaimablesPerPage: number;
  eligableHashlists?: any;
  claimExpirationDate: Date | null;
};

export type VerifyDiscordServerPayload = {
  communityId: null | string;
  code: string | null;
  url: string;
};

export type UpdateRulOperatorPayload = {
  treeId: string;
  conditionId: string;
  newCondition: ConditionOperator;
  updateToken: string;
};

export type PublishRewardPayload = {
  communityId: string;
  campaignId: string;
  walletAddress: string;
};

export type ProcessRewardPayload = {
  communityId: string;
  campaignId: string;
  rawTransaction: number[];
  requestId: string;
};

export type PublishPhygitalPayload = {
  communityId: string;
  claimableId: string;
  walletAddress: string;
};

export type ProcessPhygitalPayload = {
  communityId: string;
  claimableId: string;
  rawTransaction: number[];
  requestId: string;
};
export type CreateCommunityPayload = {
  communityName: string;
  communityRoute: string;
  secondaryLink: string;
};

export type SignUpPayload = {
  email: string;
  password: string;
  projectName: string;
  discord?: string;
  twitter?: string;
  affiliateId?: string;
};

export type BoosterListItem = {
  Active: boolean;
  Archived?: boolean;
  Created: Date;
  PlanDescription: string;
  PlanTitle: string;
  Setup: boolean;
  StakingDuration: number;
  StakingProgram: string;
  id: string;
};

export type CreateBoosterPayload = {
  communityId: string;
  planTitle: string;
  planDescription: string;
};

export type BoosterDetails = {
  Active: boolean;
  Archived: boolean;
  Created: FirebaseDate;
  FilterOption: "all" | "attribute";
  LimitPlan: BoosterLimit;
  MaxUsable: number;
  MultiplierAction: BoosterMultiplierTypes;
  MultiplierValue: number;
  PlanDescription: string;
  PlanTitle: string;
  Setup: boolean;
  StakingDuration: number;
  StakingProgram: string;
  boostableCollections: Omit<SupportedCollection, "amount">[];
  boostersCollections: Omit<SupportedCollection, "amount">[];
  id: string;
  feeAddon?: FeeAddon;
  HolderVerificationRoleId?: string;
  AttributeMap?: SubscriptionBoosterAttributeMap;
  AttributeCountMap?: SubscriptionContractAttributeCountMap;
};

export enum BoosterMultiplierTypes {
  Addition = "addition",
  Multiplication = "multiplication",
  Fixed = "fixed",
}

export enum BoosterLimit {
  All = "all",
  Limit = "limit",
}

export type UpdateBoosterInfoPayload = {
  communityId: string;
  planId: string;
  planTitle: string;
  planDescription: string;
};

export type UpdateBoosterConfigurationPayload = {
  communityId: string;
  planId: string;
  limitPlan: BoosterLimit;
  maxUsable?: number;
  multiplierAction: BoosterMultiplierTypes;
  multiplierValue: number;
};

export enum SubscriptionsReportTypes {
  Stakers = "STAKERS",
  StakersNftNumber = "STAKERS_NFT_NUMBER",
  StakersNftArray = "STAKERS_NFT_ARRAY",
}

export type GetSnapshotPayload = {
  idToken: string | null;
  payload: {
    reportId: SubscriptionsReportTypes;
    vaultId: string | null;
    planId: string;
  };
};

export type AccumulatedRewardsResponse = ServerResponse<{
  sumTotalDailyRewards: number;
  sumTotalMinuteRewards: number;
  sumTotalRewards: number;
}>["data"];

export type SearchCommunity = {
  name: string;
  accountId: string;
  communityId: string;
  isLive: boolean;
  vaultRoute: string;
  image?: string;
};

export type SearchCommunityResponse = ServerResponse<{
  success: true;
  data: SearchCommunity[];
}>;

export interface TokenCampaignListItem {
  _id: string;
  communitiesCount: number;
  description: string;
  name: string;
  title: string;
  tokenId: string;
  tokenMetadata: {
    address: string;
    decimals: number;
    id: string;
    name: string;
    symbol: string;
    uri: string;
  };
}

export interface TokenCampaignDetails extends TokenCampaignListItem {
  phases: TokenCampaignPhase[];
  created: Date;
}

export type TokenCampaignDetailsResponse =
  ServerResponse<TokenCampaignDetails>["data"];

export type TokenCampaignsResponse = ServerResponse<
  TokenCampaignListItem[]
>["data"];

export type TokenCampaignPhase = {
  dailyReward: number;
  duration: number;
  maxSubscriptions: number;
  rule?: string;
  ruleDescription?: string;
  maxSubscriptionsPerWallet?: number;
  entryRule?: string;
  entryRuleDescription?: string;
};

export type CreateNewTokenCampaignPayload = {
  communityId: string;
  name: string;
  title: string;
  description: string;
  tokenId: string;
  phases: TokenCampaignPhase[];
};

export type CreateNewTokenCampaignResponse = ServerResponse<
  CreateNewTokenCampaignPayload & {
    accountId: string;
    communitiesCount: number;
    communityId: string;
    created: Date;
    _id: string;
  }
>["data"];

export type CommunityByCampaign = {
  accountId: string;
  budget: number;
  campaignId: string;
  communityId: string;
  communityRoute: string;
  contractId: string;
  created: Date;
  currentPhase: number;
  currentPhaseEndDate: Date;
  image: string;
  name: string;
  status: "live" | "pending" | "ended";
  tokenId: string;
  ended?: Date;
  returnBackToPool?: number;
  returnBackToPoolTxid?: string;
};

export type AddCommunityInCampaignResponse =
  ServerResponse<CommunityByCampaign>["data"];

export type CommunitiesByCampaignResponse = ServerResponse<
  CommunityByCampaign[]
>["data"];
