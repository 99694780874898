import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { MainTheme } from "./config/mui";
import { AppState } from "./services";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { notistackComponents, notistackIcons } from "./config/notistack";
import axios from "axios";
import { BASE_API_URL } from "./constants";

axios.defaults.baseURL = BASE_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={MainTheme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={3}
        preventDuplicate
        iconVariant={notistackIcons}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        Components={notistackComponents}
      >
        <AppState>
          <App />
        </AppState>
      </SnackbarProvider>
    </LocalizationProvider>
  </ThemeProvider>
);
