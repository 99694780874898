import { Components } from "@mui/material/styles";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    gradient: true;
  }
  interface ButtonPropsVariantOverrides {
    base: true;
  }
}

export const MuiButton: Components["MuiButton"] = {
  defaultProps: {
    disableElevation: true,
    variant: "contained",
  },
  styleOverrides: {
    root: {
      textTransform: "none",
      fontWeight: 700,
    },
  },
  variants: [
    {
      props: {
        variant: "gradient",
      },
      style: {
        background:
          "linear-gradient(90deg, #FFE000 0%, #FF9962 50.52%, #947DFF 100%)",
        color: "#000",
      },
    },
    {
      props: {
        variant: "gradient",
        disabled: true,
      },
      style: {
        background:
          "linear-gradient(90deg, rgba(255, 224, 0, 0.3) 0%, rgba(255, 153, 98, 0.3) 50.52%, rgba(148, 125, 255, 0.3) 100%)",
      },
    },
    {
      props: { variant: "base" },
      style: {
        background: "#121214",
        color: "#FFF",
      },
    },
    {
      props: { variant: "base", color: "secondary" },
      style: {
        background: "#2C2E36",
        color: "#FFF",
      },
    },
    {
      props: { variant: "outlined", color: "secondary" },
      style: {
        color: "#FFF",
        backgroundColor: "#121214",
        borderColor: "rgba(255,255,255,0.3)",
      },
    },
  ],
};

export const MuiTooltip: Components["MuiTooltip"] = {
  defaultProps: {
    arrow: true,
  },
};

export const MuiDrawer: Components["MuiDrawer"] = {
  styleOverrides: {
    paper: {
      border: 0,
      justifyContent: "center",
    },
  },
};

export const MuiTab: Components["MuiTab"] = {
  styleOverrides: {
    root: {
      textTransform: "none",
    },
  },
};

export const MuiAccordion: Components["MuiAccordion"] = {
  defaultProps: {
    disableGutters: true,
    elevation: 0,
  },
};

export const MuiDialog: Components["MuiDialog"] = {
  styleOverrides: {
    root: {
      "& .MuiPaper-root": {
        backgroundColor: "default",
        backgroundImage: "none",
        borderRadius: "10px",
      },
    },
  },
};

export const MuiTextField: Components["MuiTextField"] = {
  defaultProps: {
    margin: "dense",
  },
};

export const MuiSkeleton: Components["MuiSkeleton"] = {
  defaultProps: {
    animation: "wave",
    variant: "rounded",
  },
};
