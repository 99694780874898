import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
