import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../../../services/AppService";
import { QuestsListResponse } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const getAllQuests = async (communityId: string | null) => {
  const { data } = await axios.post<QuestsListResponse>(
    API_PATHS.loyalty.list,
    {
      data: { communityId },
    }
  );
  if (isSuccessResponse(data?.data)) {
    return data.data?.data;
  }
  throw new Error(
    `${data?.data?.error?.title}\n${data?.data?.error?.description}`
  );
};

export const useGetAllQuests = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  return useQuery(
    [API_PATHS.loyalty.list, selectedCommunityDocId],
    () => getAllQuests(selectedCommunityDocId),
    {
      staleTime: 30 * 60 * 1000,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};
