import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";
import { TestRuleTreeResponse } from "../../../../types";

const testRule = async (url: string) => {
  return await axios.post<TestRuleTreeResponse>(url);
};

const useTestRule = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (url: string) => {
      const { data } = await testRule(url);

      if (isSuccessResponse(data?.data)) {
        return data?.data?.data;
      }

      throw new Error(
        `${data.data?.error?.title}\n${data.data?.error?.description}`
      );
    },
    {
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useTestRule;
