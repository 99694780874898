import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { UseMutationResult } from "@tanstack/react-query";
import ModalTransition from "../helpers/ModalTransition";
import { parseDataFromJSON } from "../utils";
import Link from "@mui/material/Link";

type Props = {
  open: boolean;
  hide: () => void;
  uploadHookResult: UseMutationResult<any, any, any, unknown>;
};

const UploadSubscriptionContractHashlistDialog = ({
  open,
  hide,
  uploadHookResult,
}: Props) => {
  const { mutateAsync, isLoading } = uploadHookResult;
  const { enqueueSnackbar } = useSnackbar();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [hashlist, setHashlist] = useState<string[]>([]);

  useEffect(() => {
    setName("");
    setHashlist([]);
  }, [open]);

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const jsonData = await parseDataFromJSON(e);
    setHashlist(jsonData.arr);
    setName(jsonData.fileName);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (hashlist.length === 0 || !name) {
      enqueueSnackbar({
        variant: "error",
        message: "Upload haslist file, please",
      });
      return;
    }
    const payload = {
      hashlist,
      name,
    };
    try {
      await mutateAsync(payload);
    } catch (error) {
    } finally {
      hide();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={isLoading ? undefined : hide}
      TransitionComponent={ModalTransition}
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>Upload hashlist</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" mb={2}>
            You can get your existing collection hashlist by creator wallet
            address or candy machine id{" "}
            <Link
              component="a"
              href="https://magiceden.io/mintlist-tool"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </Link>
            .
          </Typography>
          <Alert
            variant="outlined"
            severity={hashlist?.length > 0 ? "success" : "warning"}
            action={
              <Button
                color="inherit"
                size="small"
                disabled={isLoading}
                onClick={() => uploadInputRef.current?.click()}
              >
                {hashlist?.length > 0 ? "Change" : "Upload"}
                <input
                  ref={uploadInputRef}
                  type="file"
                  accept=".json"
                  style={{ display: "none" }}
                  onChange={onFileSelected}
                  disabled={isLoading}
                />
              </Button>
            }
            sx={{ my: 1 }}
          >
            <AlertTitle>
              {hashlist?.length > 0 ? name : "Upload hashlist"}
            </AlertTitle>
            <Typography>
              {hashlist?.length > 0 ? (
                <>
                  <strong>{hashlist?.length}</strong> NFT
                  {hashlist?.length > 1 ? "s" : ""} DETECTED
                </>
              ) : (
                "Please upload a hashlist json file"
              )}
            </Typography>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            variant="base"
            color="secondary"
            onClick={hide}
            disabled={isLoading}
          >
            Close
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UploadSubscriptionContractHashlistDialog;
