import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

type Props = {
  variant?: "gradient";
};

const CustomWalletButton = ({ variant }: Props) => {
  return (
    <WalletMultiButton
      className={`${variant === "gradient" ? "wallet-multi-gradient" : ""}`}
    />
  );
};

export default CustomWalletButton;
