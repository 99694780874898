import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import { UpdateQuestStatusPayload } from "../../../../types";
import { API_PATHS } from "../../../../constants";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";

type Props = {
  onSettled: () => void;
};

const updateQuestStatus = async (data: UpdateQuestStatusPayload) => {
  return await axios.post<any>(API_PATHS.loyalty.update.status, { data });
};

export default function useUpdateQuestStatus({ onSettled }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: UpdateQuestStatusPayload) => {
      const { data } = await updateQuestStatus(payload);
      if (isSuccessResponse(data?.data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.loyalty.list, selectedCommunityDocId],
          exact: true,
          refetchType: "active",
        });
        return data.data;
      }

      throw new Error(
        `${data.data?.error?.title}\n${data.data?.error?.description}`
      );
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar({
          variant: "success",
          message: `"${data?.data?.title}" successfully in status ${data?.data?.status}`,
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
}
