import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { API_PATHS } from "../../../../constants";
import { useParams } from "react-router-dom";
import { isSuccessResponse } from "../../../../utils";

const setContractStatus = async (data: {
  communityId: string;
  planId: string;
  active: boolean;
}) => {
  return await axios.post<any>(API_PATHS.booster.update.status, data);
};

type Props = {
  onSettled: () => void;
};

const useSetContractStatus = ({ onSettled }: Props) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const { planId } = useParams();

  return useMutation(
    async (status: boolean) => {
      if (!selectedCommunityDocId || !planId) return;
      const { data } = await setContractStatus({
        communityId: selectedCommunityDocId,
        planId,
        active: status,
      });

      if (isSuccessResponse(data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.booster.byId, selectedCommunityDocId, planId],
          exact: true,
          refetchType: "active",
        });
        return data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },

    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [API_PATHS.booster.list, selectedCommunityDocId],
          exact: true,
          refetchType: "active",
        });
        enqueueSnackbar({
          variant: "success",
          message: "Contract status successfully updated!",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Something went wrong. Try again!",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
};
export default useSetContractStatus;
