import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { SubscriptionContractAttributeMap } from "../../../types";
import Box from "@mui/material/Box";

type FilterInputProps = {
  filter: string[];
  setFilter: (filter: string[]) => void;
  attributeMap: SubscriptionContractAttributeMap;
  disabled: boolean;
};

const FilterInput: React.FC<FilterInputProps> = ({
  filter,
  setFilter,
  attributeMap,
  disabled,
}) => {
  return (
    <Box mb={1}>
      <Autocomplete
        value={filter}
        onChange={(e, newValue) => {
          setFilter(newValue);
        }}
        multiple
        options={Object.keys(attributeMap).sort((a, b) => a.localeCompare(b))}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="View filter"
            placeholder="Select trait type"
            helperText="The filter is designed to quickly find the desired trait type. When saving, all data is sent, the filter only affects the display traits of the list"
          />
        )}
        disabled={disabled}
      />
    </Box>
  );
};

export default FilterInput;
