import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { API_PATHS } from "../../../constants";

async function signOut() {
  return await axios.post<any>(API_PATHS.auth.signout);
}

export function useSignOut() {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async () => {
      const { data } = await signOut();
      delete axios.defaults.headers.common.Authorization;
      return data;
    },
    {
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign out. Try again!",
        });
      },
    }
  );
}
