import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FulfillmentUploadCSV from "./FulfillmentUploadCSV";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
import { useApp } from "../../../../services/AppService";
import { useGetRewardById } from "../../../../hooks/queries/rewards/state/useGetRewardById";
import useSaveRewardStep from "../../../../hooks/queries/rewards/mutations/useSaveRewardStep";
import { FulfillmentProps, Inputs } from "./types";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { deepEqual } from "../../../../utils";
import {
  FULFILLMENT_TYPES,
  PRICE_PER_REWARD_CREATION,
  PRICE_PER_REWARD_NFT,
  REWARD_CODES_TEMPLATE_LINK,
} from "../../../../constants";
import Link from "@mui/material/Link";
import { EligableTypes, RewardFulfillmentTypes } from "../../../../types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";

const Fulfillment = ({ next, prev }: FulfillmentProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { campaignId } = useParams();
  const { selectedCommunityDocId } = useApp();
  const { data: rewardInfo } = useGetRewardById();
  const { mutateAsync: saveStep, isLoading: saveStepLoading } =
    useSaveRewardStep();
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    register,
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      fulfillmentType: "",
      size: "",
    },
  });
  const formWatcher = watch();
  const [expirationDate, setExpirationDate] = useState<Moment | null>(null);
  const [csv, setCsv] = useState<
    | {
        codesArray?: string[][];
        hashlistTitle: string;
        size: number;
        hashlistManagerId?: string;
      }
    | undefined
  >();
  const [potentionNumber, setPotentialNumber] = useState<number | null>(null);

  useEffect(() => {
    if (rewardInfo?.rewardConfig) {
      if (
        rewardInfo?.rewardConfig?.fulfillmentType ===
        RewardFulfillmentTypes.Upload
      ) {
        setCsv(rewardInfo?.rewardConfig);
      }
      reset({
        fulfillmentType: rewardInfo?.rewardConfig?.fulfillmentType,
        size:
          rewardInfo?.rewardConfig?.fulfillmentType ===
          RewardFulfillmentTypes.Scratch
            ? rewardInfo?.rewardConfig?.size
            : "",
      });
      setExpirationDate(
        rewardInfo?.redeemExpirationDate
          ? moment(rewardInfo?.redeemExpirationDate)
          : null
      );
    }
    if (rewardInfo?.eligibleAudience?.type === EligableTypes.Unlimited) {
      return;
    }
    if (rewardInfo?.eligibleAudience?.type === EligableTypes.Hashlist) {
      const pNumArr: number[] = [];
      rewardInfo?.eligibleAudience?.eligibleHashlists.forEach((hash: any) => {
        pNumArr.push(Math.floor(hash?.size / hash?.minToClaim));
      });
      setPotentialNumber(Math.min(...pNumArr));
      return;
    }
    if (
      rewardInfo?.eligibleAudience?.type === EligableTypes.WalletAddresses &&
      rewardInfo?.eligibleAudience?.eligibleHashlists?.[0]?.size
    ) {
      setPotentialNumber(
        rewardInfo?.eligibleAudience?.eligibleHashlists?.[0]?.size
      );
    }
  }, [reset, rewardInfo]);

  const handleSetCSV = (
    newData:
      | {
          codesArray: string[][];
          hashlistTitle: string;
          size: number;
        }
      | undefined
  ) => {
    setCsv(newData);
  };

  const onSubmit = async (data: Inputs) => {
    if (!selectedCommunityDocId || !campaignId) return;
    if (
      data?.fulfillmentType === RewardFulfillmentTypes.Upload &&
      typeof csv === "undefined"
    ) {
      enqueueSnackbar({
        variant: "error",
        message: "Upload a CSV file, please",
      });
      return;
    }

    if (
      deepEqual(
        data?.fulfillmentType === RewardFulfillmentTypes.Upload
          ? csv
          : {
              ...data,
              hashlistTitle: rewardInfo?.rewardConfig?.hashlistTitle,
              hashlistManagerId: rewardInfo?.rewardConfig?.hashlistManagerId,
              headers: rewardInfo?.rewardConfig?.headers,
              scratchHashlistManagerId:
                rewardInfo?.rewardConfig?.scratchHashlistManagerId,
            },
        rewardInfo?.rewardConfig
      ) &&
      (!rewardInfo?.redeemExpirationDate ||
        expirationDate?.isSame(rewardInfo?.redeemExpirationDate))
    ) {
      next();
      return;
    }

    saveStep({
      action: "update-fulfillment",
      form: {
        campaignId,
        communityId: selectedCommunityDocId,
        expirationDate: expirationDate?.isValid()
          ? expirationDate.toDate()
          : null,
        ...data,
        ...(data?.fulfillmentType === RewardFulfillmentTypes.Upload
          ? csv
          : {
              hashlistTitle: "Scratch hashlist",
            }),
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {potentionNumber && (
        <>
          <Typography fontWeight={700} variant="h6">
            Potential Number of reward to distribute
          </Typography>
          <Typography color="text.secondary" mb={2}>
            According to the list uploaded in the last step, the potential
            number of rewards that can be claimed is -{" "}
            <strong>{potentionNumber}</strong>
          </Typography>
        </>
      )}
      <Typography fontWeight={700} variant="h6">
        Choose the fulfillment method for your campaign
      </Typography>
      <Typography color="text.secondary" mb={2}>
        1. The <strong>"Scratch"</strong> method - Manually mark the reward NFTs
        as "used" at an event / point of sale. An official representative
        scratches the NFT to its "used" state by scanning a QR code or by simply
        clicking on a link on the redeem page.
        <br />
        2. The <strong>"Upload CSV"</strong> method - Upload a CSV file with
        coupon codes of which the user will be able to redeem at an IRL PoS or
        an e-commerce store. Once the user redeems the coupon, the NFT will
        mutate to its redeemed state and the codes will reveal. This is a great
        method to reward your users with gift cards or coupon codes.
      </Typography>
      <FormControl
        fullWidth
        required
        margin="dense"
        disabled={saveStepLoading}
        sx={{ mb: 2 }}
      >
        <InputLabel>Method</InputLabel>
        <Controller
          control={control}
          name="fulfillmentType"
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label="Method"
              defaultValue=""
              error={!!errors.fulfillmentType}
            >
              {FULFILLMENT_TYPES.map(({ id, label }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {formWatcher.fulfillmentType === RewardFulfillmentTypes.Upload && (
        <>
          <Typography fontWeight={700} variant="h6">
            Upload a CSV file with the coupon codes to distribute
          </Typography>
          <Typography color="text.secondary" mb={2}>
            The reward type you are going to give your holders is{" "}
            <strong>
              {rewardInfo?.rewardType === "discount" ? "Discount" : "Gift"}
            </strong>
            .
            <br />
            Upload a CSV file with coupon codes to give to your holders. Make
            sure to upload a CSV file in the following format -{" "}
            <Link component="a" href={REWARD_CODES_TEMPLATE_LINK}>
              CSV template
            </Link>
            .
            <br />
            The reward given to the user will act as a global discount that the
            user will apply
          </Typography>
          <FulfillmentUploadCSV
            data={csv}
            onChange={handleSetCSV}
            disabled={saveStepLoading}
          />
        </>
      )}
      {formWatcher.fulfillmentType === RewardFulfillmentTypes.Scratch && (
        <FormGroup sx={{ mb: 2 }}>
          <TextField
            {...register("size", {
              required: "This field is required",
              valueAsNumber: true,
              min: {
                value: 1,
                message: "Minimum number is 1",
              },
            })}
            error={!!errors.size}
            helperText={!!errors.size && errors.size.message}
            type="number"
            label="Number of redeemables"
            margin="dense"
            required
            fullWidth
            disabled={saveStepLoading}
          />
          <FormHelperText>
            Define the total number or rewards to distribute. Users will show
            the QR code on the redeeming page to an official at the event /
            point of sale upon redeeming
          </FormHelperText>
        </FormGroup>
      )}
      <Typography fontWeight={700} variant="h6">
        Codes experation date
      </Typography>
      <DatePicker
        minDate={moment(rewardInfo?.claimExpirationDate).add(1, "days")}
        desktopModeMediaQuery="@media (min-width: 720px)"
        slotProps={{
          textField: {
            helperText: "MM/DD/YYYY",
            fullWidth: true,
            hiddenLabel: true,
          },
        }}
        value={expirationDate}
        onChange={(newValue) => setExpirationDate(newValue)}
        disabled={saveStepLoading}
      />
      {csv?.size && (
        <>
          <Typography fontWeight={700} variant="h6" mt={2}>
            Estimated Campaign Costs
          </Typography>
          <Typography color="text.secondary">
            According to the number of reward to distribute, here are the
            estimated costs to launch this campaign:
          </Typography>
          <Typography fontWeight={700} variant="h6" color="lightgreen">
            ~
            {parseFloat(
              (
                PRICE_PER_REWARD_CREATION +
                PRICE_PER_REWARD_NFT * csv?.size
              ).toFixed(3)
            )}{" "}
            $SOL
          </Typography>
        </>
      )}
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mt: 2 }}>
        <Button
          variant="base"
          color="secondary"
          disabled={saveStepLoading}
          onClick={prev}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={saveStepLoading}
          sx={{ ml: 1 }}
        >
          Continue
        </LoadingButton>
      </Box>
    </form>
  );
};

export default Fulfillment;
