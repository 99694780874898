import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useRef } from "react";
import { downloadCSVFile, parseDataFromCSV } from "../../../../utils";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useGetHashlist } from "../../../../hooks/queries/hashlist/useGetHashlist";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";

type Props = {
  data:
    | {
        codesArray?: string[][];
        hashlistTitle: string;
        size: number;
        hashlistManagerId?: string;
        headers?: string[];
      }
    | undefined;
  onChange?: (
    newData:
      | {
          codesArray: string[][];
          hashlistTitle: string;
          size: number;
        }
      | undefined
  ) => void;
  disabled: boolean;
};

const FulfillmentUploadCSV = ({ data, onChange, disabled }: Props) => {
  const { refetch, isFetching } = useGetHashlist(
    data?.hashlistManagerId ? data?.hashlistManagerId : ""
  );
  const handleDownloadCSV = async (name: string) => {
    refetch().then(({ data: hashlist }) => {
      if (!hashlist?.length || !data?.headers) return;
      const csvData: string[][] = [data?.headers];
      hashlist?.forEach((h) => {
        csvData.push(JSON.parse(h));
      });
      downloadCSVFile(csvData, name);
    });
  };
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    onChange(undefined);
    const jsonData = await parseDataFromCSV(e);
    onChange({
      codesArray: jsonData?.arr,
      hashlistTitle: jsonData?.fileName,
      size: jsonData?.arr?.length - 1,
    });
  };

  return (
    <Alert
      variant="outlined"
      severity={data ? "success" : "warning"}
      action={
        <>
          {data?.hashlistManagerId && (
            <Tooltip title="Download file">
              <IconButton
                size="small"
                disabled={isFetching}
                onClick={() => {
                  handleDownloadCSV(data?.hashlistTitle || "");
                }}
                sx={{ mr: 1 }}
              >
                {isFetching ? (
                  <CircularProgress
                    sx={{ width: "18px !important", height: "18px !important" }}
                  />
                ) : (
                  <DownloadingRoundedIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          )}
          {!!onChange && (
            <Button
              color="inherit"
              size="small"
              onClick={() => uploadInputRef.current?.click()}
              disabled={disabled}
            >
              {data ? "Change" : "Upload"}
              <input
                ref={uploadInputRef}
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                onChange={onFileSelected}
              />
            </Button>
          )}
        </>
      }
      sx={{ width: "100%", mb: 2 }}
    >
      <AlertTitle>{data ? data?.hashlistTitle : "Upload CSV"}</AlertTitle>
      <Typography>
        {data ? (
          <>
            <strong>{data?.size}</strong> codes{" "}
            {data?.hashlistManagerId ? "" : "DETECTED"}
          </>
        ) : (
          "Please upload a CSV file"
        )}
      </Typography>
    </Alert>
  );
};

export default FulfillmentUploadCSV;
