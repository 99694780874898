import Stack from "@mui/material/Stack";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import { useGetContractDetails } from "../../hooks/queries/subscriptions/state/useGetContractDetails";
import Loader from "../../components/Loader/Loader";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { SUBSCRIPTION_CONTRACT_TYPES_LABEL } from "../../constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { useApp } from "../../services/AppService";
import SubscriptionContractInfo from "./tabs/SubscriptionContractInfo";
import Divider from "@mui/material/Divider";
import SubscriptionContractMaturity from "./tabs/SubscriptionContractMaturity/SubscriptionContractMaturity";
import Alert from "@mui/material/Alert";
import Collection from "./tabs/Collection/Collection";
import Tooltip from "@mui/material/Tooltip";
import FeeAddon from "../../components/SubscriptionContract/FeeAddon";
import { useUpdateContractFeeAddons } from "../../hooks/queries/subscriptions/mutations/useUpdateContractFeeAddons";
import Integrations from "../../components/SubscriptionContract/Integrations";
import { useUpdateContractDiscordRole } from "../../hooks/queries/subscriptions/mutations/useUpdateContractDiscordRole";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import Settings from "./tabs/Settings";
import { LiveDot } from "../../components/StyledComponents";
import RulesConfiguration from "../../components/SubscriptionContract/Rules";
import { useUpdateContractRules } from "../../hooks/queries/subscriptions/mutations/useUpdateContractRules";

const SubscriptionContract = () => {
  const { data: contractDetails, isLoading: contractDetailsLoading } =
    useGetContractDetails();
  const { selectedCommunity } = useApp();

  const [activeTab, setActiveTab] = useState("info");

  const isTokenCampaign =
    contractDetails?.planData?.tokenCampaign ||
    contractDetails?.planData?.bonkCampaign ||
    false;

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Contracts",
            path: "/subscription/contracts",
          },
          {
            label: contractDetails?.planData?.PlanTitle,
          },
        ]}
      />
      {contractDetailsLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : (
        <>
          <Box>
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
              {contractDetails?.planData?.Archived ? (
                <Tooltip placement="top" title="Archived">
                  <Inventory2RoundedIcon color="disabled" fontSize="small" />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    contractDetails?.planData?.Active ? "Active" : "Inactive"
                  }
                >
                  <LiveDot
                    color={
                      contractDetails?.planData?.Active ? "success" : "default"
                    }
                  />
                </Tooltip>
              )}
              <Typography variant="h6" fontWeight={700}>
                {contractDetails?.planData?.PlanTitle}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Chip
                label={`$${contractDetails?.planData?.TokenData?.TokenName}`}
                size="small"
              />
              {contractDetails?.planData?.RewardProgram && (
                <Chip
                  label={
                    SUBSCRIPTION_CONTRACT_TYPES_LABEL[
                      contractDetails?.planData?.RewardProgram
                    ]
                  }
                  size="small"
                />
              )}
            </Stack>
          </Box>
          {!contractDetails?.planData?.Setup && !isTokenCampaign && (
            <Alert severity="error" variant="filled" sx={{ mt: 1 }}>
              Please setup the daily rewards before publishing the contract
            </Alert>
          )}
          <Divider sx={{ mt: 2 }} />
          <Tabs
            value={activeTab}
            variant="scrollable"
            onChange={(e, newValue) => {
              setActiveTab(newValue);
            }}
          >
            {TABS.map((label) =>
              isTokenCampaign && label === "Maturity" ? null : (
                <Tab label={label} value={label.toLowerCase()} key={label} />
              )
            )}
          </Tabs>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ display: activeTab === "info" ? "block" : "none" }}>
            <SubscriptionContractInfo />
          </Box>
          <Box
            sx={{ display: activeTab === "integrations" ? "block" : "none" }}
          >
            {selectedCommunity?.DiscordData ? (
              <Integrations
                roleId={contractDetails?.planData?.HolderVerificationRoleId}
                updateRoleHook={useUpdateContractDiscordRole}
              />
            ) : (
              <Alert severity="info" variant="filled">
                You haven't connected your Discord server in Social
                Integrations. If you'd like to give specific roles for staking
                please do so before proceeding
              </Alert>
            )}
          </Box>
          <Box sx={{ display: activeTab === "collections" ? "block" : "none" }}>
            <Collection />
          </Box>
          <Box sx={{ display: activeTab === "maturity" ? "block" : "none" }}>
            <SubscriptionContractMaturity />
          </Box>
          <Box sx={{ display: activeTab === "fee addon" ? "block" : "none" }}>
            <FeeAddon
              addonDetails={contractDetails?.planData?.feeAddon}
              updateDetailsHook={useUpdateContractFeeAddons}
            />
          </Box>
          <Box sx={{ display: activeTab === "rules" ? "block" : "none" }}>
            <RulesConfiguration
              rulesDetails={contractDetails?.planData?.rulesDetails}
              updateDetailsHook={useUpdateContractRules}
              isTokenCampaign={isTokenCampaign}
            />
          </Box>
          <Box sx={{ display: activeTab === "settings" ? "block" : "none" }}>
            <Settings />
          </Box>
        </>
      )}
    </>
  );
};

export default SubscriptionContract;

const TABS = [
  "Info",
  "Integrations",
  "Collections",
  "Rules",
  "Maturity",
  "Fee addon",
  "Settings",
];
