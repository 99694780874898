import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Empty from "./Empty";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import LinkBehavior from "./LinkBehavior";

const AssetSelect = ({ list, loading, control, name, disabled }: any) => {
  return (
    <>
      {loading ? (
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Box>
            <CircularProgress />
          </Box>
          <Typography>
            In the process of getting a list of assets. Please, wait..
          </Typography>
        </Stack>
      ) : (
        <FormControl fullWidth margin="dense" required disabled={disabled}>
          <InputLabel>Select asset</InputLabel>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Select
                {...field}
                variant="outlined"
                label="Select asset"
                defaultValue=""
              >
                {list?.length === 0 && (
                  <Box my={2}>
                    <Empty text="No assets available">
                      <Button
                        LinkComponent={LinkBehavior}
                        href="/assets/create"
                        variant="gradient"
                        sx={{ mt: 1.5 }}
                      >
                        Create new
                      </Button>
                    </Empty>
                  </Box>
                )}
                {list?.map((asset: any) => (
                  <MenuItem key={asset?._id} value={asset?._id}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      width="100%"
                      spacing={1}
                    >
                      <Paper
                        sx={{
                          width: 40,
                          flexShrink: 0,
                          borderRadius: "3px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={asset?.digitalAssetConfig?.assets?.[0]?.url}
                          alt="asset"
                        />
                      </Paper>
                      <Box lineHeight={1} whiteSpace="normal">
                        <Typography fontWeight={700}>{asset?.name}</Typography>
                        <Typography color="text.secondary" variant="caption">
                          {asset?.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormHelperText>
            The asset that will be claimed by the user
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};

export default AssetSelect;
