import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import ConfirmDialog from "../../../components/ConfirmDialog";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import Stack from "@mui/material/Stack";
import useGetBoosterDetails from "../../../hooks/queries/boosters/state/useGetBoosterDetails";
import useSetContractStatus from "../../../hooks/queries/boosters/mutations/useSetContractStatus";
import useArchiveContract from "../../../hooks/queries/boosters/mutations/useArchiveContract";

const Settings = () => {
  const { data: contractDetails } = useGetBoosterDetails();
  const { mutateAsync: setStatus, isLoading: setStatusLoading } =
    useSetContractStatus({
      onSettled: () => {
        setConfirmationData(null);
      },
    });
  const { mutateAsync: archive, isLoading: archiveLoading } =
    useArchiveContract({
      onSettled: () => {
        setConfirmationData(null);
      },
    });
  const [confirmationData, setConfirmationData] = useState<null | {
    type: "archive" | "status";
    status: boolean;
  }>(null);

  const handleStatusChange = () => {
    setConfirmationData({
      type: "status",
      status: !contractDetails?.Active,
    });
  };

  const handleArchive = () => {
    setConfirmationData({
      type: "archive",
      status: true,
    });
  };

  return (
    <>
      <List disablePadding>
        <ListItem sx={{ gap: 1, alignItems: "flex-start" }}>
          <ListItemText
            primary="Status"
            secondary="Choose between active or inactive to control visibility for users."
            sx={{ my: 0 }}
          />
          <Switch
            color="success"
            checked={contractDetails?.Active}
            onChange={handleStatusChange}
            disabled={contractDetails?.Archived}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem sx={{ gap: 1, alignItems: "flex-start" }}>
          <ListItemText
            primary="Archive"
            secondary="Archive this contract to make it inactive, while preserving existing subscriptions"
            sx={{ my: 0 }}
          />
          {contractDetails?.Archived ? (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Inventory2RoundedIcon color="disabled" fontSize="inherit" />
              <Typography color="text.secondary" fontWeight={700}>
                Archived
              </Typography>
            </Stack>
          ) : (
            <Button
              variant="base"
              color="secondary"
              size="small"
              startIcon={<ArchiveRoundedIcon />}
              onClick={handleArchive}
              sx={{ flexShrink: 0 }}
            >
              Archive
            </Button>
          )}
        </ListItem>
      </List>
      <ConfirmDialog
        data={confirmationData}
        title="Are you sure?"
        content={
          confirmationData?.type === "archive" ? (
            <Alert severity="error" variant="filled">
              <AlertTitle>Be careful!</AlertTitle>
              <Typography>
                This action will archive the contract and set its status to{" "}
                <strong>inactive</strong>. This action cannot be undone.
              </Typography>
            </Alert>
          ) : (
            <>
              Do you want set contract status to{" "}
              <strong>
                {confirmationData?.status ? "active" : "inactive"}
              </strong>
              ?
            </>
          )
        }
        isLoading={setStatusLoading || archiveLoading}
        hide={() => {
          setConfirmationData(null);
        }}
        confirm={(data) => {
          if (!confirmationData) return;
          if (confirmationData?.type === "status") {
            setStatus(data.status);
          }

          if (confirmationData.type === "archive") {
            archive();
          }
        }}
      />
    </>
  );
};

export default Settings;
