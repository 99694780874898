import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SNAPSHOT_TYPES } from "../../constants";

const Preview = ({ report }: { report?: (typeof SNAPSHOT_TYPES)[0] }) => {
  return (
    <>
      <Typography variant="h6" fontWeight={700}>
        {report?.label}
      </Typography>
      <Typography color="text.secondary" mb={1}>
        {report?.description}
      </Typography>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: 1,
          color: "orange",
          p: 2,
          fontFamily: "monospace",
          fontSize: 12,
          whiteSpace: "break-spaces",
        }}
      >
        {report?.preview}
      </Box>
    </>
  );
};

export default Preview;
