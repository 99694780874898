import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Divider from "@mui/material/Divider";
import { useGetAllPhygital } from "../../hooks/queries/phygital/state/useGetAllPhygital";
import Loader from "../../components/Loader/Loader";
import Empty from "../../components/Empty";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Fragment } from "react";
import PhygitalItem from "./PhygitalItem";
import LinkBehavior from "../../components/LinkBehavior";

const Phygital = () => {
  const { data: phygitaList, isLoading: phygitaListLoading } =
    useGetAllPhygital();

  return (
    <>
      <Typography variant="h4" fontWeight={700} mb={1}>
        Phygital
      </Typography>
      <Typography color="text.secondary">
        The Phygital service lets you airdrop NFTs via QR codes and NFC chips.
        This service can be applied in amazing ways such as access to events,
        proof of authenticity, merchandise, digital to physical parring or just
        a "thank you" memorabilia for being a loyal customer!
        <br />
        The collection minted with every scan or tap can be automatically used
        in the rules service to gate the claim button on your reward campaigns!
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <Typography variant="h5" fontWeight={700}>
          List
        </Typography>
        <Button
          LinkComponent={LinkBehavior}
          href="create"
          startIcon={<DataSaverOnRoundedIcon />}
          variant="base"
        >
          Create phygital
        </Button>
      </Stack>
      {phygitaListLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : phygitaList?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
        >
          <Empty text={`You don't have any phygital`} />
        </Stack>
      ) : (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            borderRadius: "5px",
            overflow: "hidden",
            mt: 2,
          }}
        >
          <List disablePadding dense>
            {phygitaList?.map((phygital, index) => (
              <Fragment key={phygital?._id}>
                {index !== 0 && <Divider component="li" />}
                <PhygitalItem phygital={phygital} />
              </Fragment>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default Phygital;
