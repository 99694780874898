import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useApp } from "../../../services/AppService";
import { useGetDiscordRoles } from "../../../hooks/queries/integrations/state/useGetDiscordRoles";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import useUpdateHolderVerificationRole from "../../../hooks/queries/integrations/mutations/useUpdateHolderVerificationRole";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export const IntegrationsDiscordHolderVerification: React.FC<any> = () => {
  const { selectedCommunity } = useApp();
  const { data: discordRoles, isLoading: discordRolesLoading } =
    useGetDiscordRoles();

  const [holderRoleId, setHolderRoleId] = useState("");

  const { mutateAsync: updateRole, isLoading: updateRoleLoading } =
    useUpdateHolderVerificationRole({
      onSettled: () => {},
    });

  useEffect(() => {
    setHolderRoleId(selectedCommunity?.HolderVerificationRoleId || "");
  }, [selectedCommunity]);

  const onUpdate = () => {
    updateRole(holderRoleId);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setHolderRoleId(event.target.value);
  };

  if (discordRolesLoading) {
    return (
      <Stack sx={{ mt: 2 }} direction="row" spacing={1.5} alignItems="center">
        <Box>
          <CircularProgress />
        </Box>
        <Typography>
          In the process of getting a list of roles. Please, wait..
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Typography mb={1} color="text.secondary">
        Please select the role you would like to give users that have a staked
        token in the vault
      </Typography>
      <FormControl fullWidth margin="dense">
        <InputLabel shrink>Selected role</InputLabel>
        <Select
          variant="outlined"
          value={holderRoleId}
          label="Selected role"
          displayEmpty
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="">
            <em style={{ color: "red" }}>Not active</em>
          </MenuItem>
          {discordRoles?.map((role) => (
            <MenuItem key={role?.id} value={role?.id}>
              {role?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {holderRoleId !== (selectedCommunity?.HolderVerificationRoleId || "") && (
        <LoadingButton
          onClick={onUpdate}
          loading={updateRoleLoading}
          variant="contained"
          sx={{ mt: 1 }}
        >
          Update
        </LoadingButton>
      )}
    </>
  );
};
