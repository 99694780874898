import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import { useEffect, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import useTestRule from "../../hooks/queries/rules/mutations/useTestRule";

type Props = { treeId?: string };

const TestRule = ({ treeId }: Props) => {
  const [walletAddress, setWalletAddress] = useState("");
  const { mutateAsync: testRule, isLoading: testRuleLoading } = useTestRule();
  const [testResult, setTestResult] = useState<boolean | undefined>();

  useEffect(() => {
    setWalletAddress("");
    cleanTestResult();
  }, [treeId]);

  const handleTest = async () => {
    cleanTestResult();
    const result = await testRule(
      `RULES_executeValidation?ruleId=${treeId}&walletAddress=${walletAddress}`
    );
    setTestResult(result);
  };

  const cleanTestResult = () => {
    setTestResult(undefined);
  };

  return (
    <>
      <Typography variant="subtitle1" fontWeight={700}>
        You can test rule using the form below
      </Typography>
      <FormControl fullWidth variant="filled" margin="dense">
        <InputLabel htmlFor="wallet">Wallet Address</InputLabel>
        <FilledInput
          type="text"
          fullWidth
          id="wallet"
          disabled={testRuleLoading}
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <LoadingButton
                color="info"
                variant="contained"
                loading={testRuleLoading}
                onClick={handleTest}
                disabled={walletAddress?.length < 5}
              >
                Test
              </LoadingButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {typeof testResult !== "undefined" && (
        <Alert
          iconMapping={{
            error: <HighlightOffRoundedIcon fontSize="inherit" />,
          }}
          variant="filled"
          severity={testResult ? "success" : "error"}
          color={testResult ? "success" : "error"}
          onClose={cleanTestResult}
          sx={{ mt: 1 }}
        >
          <Typography>
            Wallet address {testResult ? "passes" : "does not pass"} this rule
          </Typography>
        </Alert>
      )}
    </>
  );
};
export default TestRule;
