import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import { RuleListItem } from "../../types";
import Typography from "@mui/material/Typography";
import TestRule from "../../components/rules/TestRule";
import TextFieldWithCopy from "../../components/TextFieldWithCopy";
import { generateRuleLink } from "../../utils";
import Box from "@mui/material/Box";

export interface ModalLevelsProps {
  data: RuleListItem | null;
  hide: () => void;
}

const RuleLinkModal = ({ data, hide }: ModalLevelsProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const link = generateRuleLink(data?._id || "");

  return (
    <Dialog
      open={!!data}
      onClose={hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <DialogTitle>
        {data?.name}
        <Typography>{data?.description}</Typography>
      </DialogTitle>
      <DialogContent>
        <>
          <Box mb={2}>
            <TextFieldWithCopy label="Link" value={link} />
          </Box>
          <TestRule treeId={data?._id} />
        </>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="base" onClick={hide}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RuleLinkModal;
