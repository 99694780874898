import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CommunityProfile, UpdateProfilePayload } from "../../types";
import { PROFILE_LINKS_FIELDS } from "../../constants";
import { Controller, useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { deepEqual, validateLinkRegExp } from "../../utils";
import CardActions from "@mui/material/CardActions";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUpdateProfile } from "../../hooks/queries/profile/mutations/useUpdateProfile";

const ProfileSetup = ({ data }: { data?: CommunityProfile }) => {
  const { mutateAsync: updateProfile, isLoading: updateProfileLoading } =
    useUpdateProfile();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    reset,
  } = useForm<UpdateProfilePayload>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      showDiscord: false,
      showSecondaryMarket: false,
      showTwitter: false,
      showWebsite: false,
      linkDiscord: "",
      linkSecondaryMarket: "",
      linkTwitter: "",
      linkWebsite: "",
    },
  });
  const formWatcher = watch();
  const [changed, setChanged] = useState(false);
  const [dataFromBd, setDataFromBd] = useState<any>();

  useEffect(() => {
    if (!data) return;
    const {
      projectName,
      projectDescription,
      showDiscord,
      showSecondaryMarket,
      showTwitter,
      showWebsite,
      linkDiscord,
      linkSecondaryMarket,
      linkTwitter,
      linkWebsite,
    } = data;
    const newStructure = {
      projectName,
      projectDescription,
      showDiscord,
      showSecondaryMarket,
      showTwitter,
      showWebsite,
      linkDiscord,
      linkSecondaryMarket,
      linkTwitter,
      linkWebsite,
    };
    setDataFromBd(newStructure);
    reset(newStructure);
  }, [data, reset]);

  useEffect(() => {
    setChanged(deepEqual(dataFromBd, formWatcher));
  }, [dataFromBd, formWatcher]);

  const onSubmit = async (data: UpdateProfilePayload) => {
    updateProfile(data);
  };

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" fontWeight={700}>
            Community profile settings
          </Typography>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            {...register("projectName", {
              required: "This field is required",
            })}
            required
            error={!!errors.projectName}
            helperText={!!errors.projectName && errors.projectName.message}
            label="Name"
            margin="dense"
            variant="outlined"
            fullWidth
            disabled={updateProfileLoading}
          />
          <TextField
            {...register("projectDescription", {
              required: "This field is required",
            })}
            required
            error={!!errors.projectDescription}
            helperText={
              !!errors.projectDescription && errors.projectDescription.message
            }
            label="Description"
            margin="dense"
            multiline
            inputProps={{ maxLength: 300 }}
            variant="outlined"
            fullWidth
            disabled={updateProfileLoading}
          />
          <Divider sx={{ my: 2, textTransform: "uppercase", fontWeight: 700 }}>
            External links
          </Divider>
          <Grid container columnSpacing={3} rowSpacing={1.5}>
            {PROFILE_LINKS_FIELDS?.map(
              ({ switchKey, linkKey, label, hint }) => (
                <Grid item xs={12} sm={6} key={switchKey}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Tooltip title={label} placement="top">
                      <Box>
                        <Controller
                          name={switchKey as keyof UpdateProfilePayload}
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              disabled={
                                !formWatcher[
                                  linkKey as keyof UpdateProfilePayload
                                ] ||
                                !!errors?.[
                                  linkKey as keyof UpdateProfilePayload
                                ] ||
                                updateProfileLoading
                              }
                              checked={props.value as boolean}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      </Box>
                    </Tooltip>
                    <TextField
                      {...register(linkKey as keyof UpdateProfilePayload, {
                        pattern: {
                          value: validateLinkRegExp,
                          message: "Invalid link",
                        },
                      })}
                      error={!!errors?.[linkKey as keyof UpdateProfilePayload]}
                      helperText={
                        !!errors?.[linkKey as keyof UpdateProfilePayload] &&
                        errors?.[linkKey as keyof UpdateProfilePayload]?.message
                      }
                      disabled={updateProfileLoading}
                      fullWidth
                      label={hint}
                      margin="dense"
                    />
                  </Stack>
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            loading={updateProfileLoading}
            disabled={changed}
          >
            Save
          </LoadingButton>
        </CardActions>
      </form>
    </Card>
  );
};

export default ProfileSetup;
