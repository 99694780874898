import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useGettAllRules } from "../../hooks/queries/rules/state/useGetAllRules";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";
import Divider from "@mui/material/Divider";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useMemo } from "react";
import {
  CreateNewTokenCampaignPayload,
  RuleListItem,
  RuleTarget,
  TokenCampaignPhase,
} from "../../types";
import FormHelperText from "@mui/material/FormHelperText";

type Props = {
  disabled: boolean;
  control: Control<Omit<CreateNewTokenCampaignPayload, "communityId">>;
  phases: TokenCampaignPhase[];
  index: number;
  errors: FieldErrors<Omit<CreateNewTokenCampaignPayload, "communityId">>;
  register: UseFormRegister<Omit<CreateNewTokenCampaignPayload, "communityId">>;
  removePhase: (index: number) => void;
};

const TokensDelegationPhase = ({
  disabled,
  control,
  errors,
  register,
  phases,
  index,
  removePhase,
}: Props) => {
  const { data: rulesList, isLoading: rulesListLoading } = useGettAllRules();

  const [rules, communityRules] = useMemo(() => {
    const a: RuleListItem[] = [];
    const b: RuleListItem[] = [];
    rulesList?.forEach((rule) => {
      if (rule.target === RuleTarget.WalletAddress) {
        a.push(rule);
      } else {
        b.push(rule);
      }
    });
    return [a, b];
  }, [rulesList]);

  return (
    <Paper sx={{ p: 1, width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography fontWeight={700} mb={0.5}>
          Phase {index + 1}
        </Typography>
        <IconButton
          size="small"
          color="error"
          disabled={index === 0 || disabled}
          onClick={() => removePhase(index)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Stack>
      {index !== 0 && (
        <>
          <>
            {rulesListLoading ? (
              <Stack
                sx={{ my: 2 }}
                direction="row"
                spacing={1.5}
                alignItems="center"
              >
                <Box>
                  <CircularProgress />
                </Box>
                <Typography>
                  In the process of getting a list of rules. Please, wait..
                </Typography>
              </Stack>
            ) : (
              <>
                <Stack direction="row" alignItems="center" spacing={0.5} mb={1}>
                  <KeyRoundedIcon fontSize="small" color="secondary" />
                  <Typography color="text.secondary">Entry rule</Typography>
                </Stack>
                <Typography color="text.secondary" variant="body2">
                  Choose the community rule that must be met before this phase
                  starts. The evaluation will occur at the end of the previous
                  phase, and if the rule is not passed, this phase will not
                  commence
                </Typography>
                <FormControl
                  size="small"
                  fullWidth
                  margin="dense"
                  disabled={disabled}
                >
                  <InputLabel>Select rule</InputLabel>
                  <Controller
                    control={control}
                    name={`phases.${index}.entryRule`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant="outlined"
                        disabled={disabled}
                        label="Select rule"
                        defaultValue=""
                        endAdornment={
                          field.value ? (
                            <Stack direction="row" mr={2}>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  field.onChange("");
                                }}
                              >
                                <CloseRoundedIcon fontSize="small" />
                              </IconButton>
                              <Tooltip title="Go to selected rule configurator">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    window.open(
                                      `/rules/${field.value}`,
                                      "_blank"
                                    );
                                  }}
                                >
                                  <OpenInNewRoundedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          ) : null
                        }
                      >
                        {communityRules?.map((r) => (
                          <MenuItem key={r?._id} value={r?._id}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box>
                                <Typography fontWeight={700} lineHeight={1}>
                                  {r?.name}
                                </Typography>
                                <Typography
                                  color="text.secondary"
                                  variant="caption"
                                >
                                  {r?.description}
                                </Typography>
                              </Box>
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                {phases[index]?.entryRule && (
                  <TextField
                    {...register(`phases.${index}.entryRuleDescription`, {
                      required: "This field is required",
                    })}
                    error={!!errors?.phases?.[index]?.entryRuleDescription}
                    helperText="Describe what the user must do to be able to subscribe. Remember this will be presented to the users"
                    size="small"
                    disabled={disabled}
                    required
                    fullWidth
                    label="Rule description"
                    multiline
                    rows={3}
                  />
                )}
              </>
            )}
          </>
          <Divider sx={{ my: 1 }} />
          <Stack direction="row" alignItems="center" spacing={0.5} mb={1}>
            <TuneRoundedIcon fontSize="small" color="info" />
            <Typography color="text.secondary">Config</Typography>
          </Stack>
        </>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register(`phases.${index}.dailyReward`, {
              required: "This field is required",
              valueAsNumber: true,
              min: {
                value: 0,
                message: "Minimum is 0",
              },
            })}
            error={!!errors?.phases?.[index]?.dailyReward}
            helperText={
              !!errors?.phases?.[index]?.dailyReward &&
              errors?.phases?.[index]?.dailyReward?.message
            }
            size="small"
            type="number"
            label="Daily reward"
            required
            fullWidth
            inputProps={{ step: "any" }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register(`phases.${index}.duration`, {
              required: "This field is required",
              valueAsNumber: true,
              validate: (value) =>
                Number.isInteger(value) || "Only whole numbers are allowed",
              min: {
                value: 0,
                message: "Minimum is 0",
              },
            })}
            error={!!errors?.phases?.[index]?.duration}
            helperText={
              !!errors?.phases?.[index]?.duration &&
              errors?.phases?.[index]?.duration?.message
            }
            size="small"
            type="number"
            label="Duration"
            required
            fullWidth
            inputProps={{ step: 1 }}
            disabled={disabled}
            InputProps={{
              endAdornment: "days",
            }}
          />
        </Grid>
      </Grid>
      <>
        {rulesListLoading ? (
          <Stack
            sx={{ my: 2 }}
            direction="row"
            spacing={1.5}
            alignItems="center"
          >
            <Box>
              <CircularProgress />
            </Box>
            <Typography>
              In the process of getting a list of rules. Please, wait..
            </Typography>
          </Stack>
        ) : (
          <>
            <FormControl
              size="small"
              fullWidth
              margin="dense"
              disabled={disabled}
            >
              <InputLabel>Subscription rule</InputLabel>
              <Controller
                control={control}
                name={`phases.${index}.rule`}
                render={({ field, fieldState, formState }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    disabled={disabled}
                    label="Subscription rule"
                    defaultValue=""
                    endAdornment={
                      field.value ? (
                        <Stack direction="row" mr={2}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              field.onChange("");
                            }}
                          >
                            <CloseRoundedIcon fontSize="small" />
                          </IconButton>
                          <Tooltip title="Go to selected rule configurator">
                            <IconButton
                              size="small"
                              onClick={() => {
                                window.open(`/rules/${field.value}`, "_blank");
                              }}
                            >
                              <OpenInNewRoundedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      ) : null
                    }
                  >
                    {rules?.map((r) => (
                      <MenuItem key={r?._id} value={r?._id}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box>
                            <Typography fontWeight={700} lineHeight={1}>
                              {r?.name}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              variant="caption"
                            >
                              {r?.description}
                            </Typography>
                          </Box>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>
                {
                  "By setting a specific “Subscription Rule”, you are creating a gated contract that requires a wallet to meet this rule before it can subscribe"
                }
              </FormHelperText>
            </FormControl>
            {phases[index]?.rule && (
              <TextField
                {...register(`phases.${index}.ruleDescription`, {
                  required: "This field is required",
                })}
                error={!!errors?.phases?.[index]?.ruleDescription}
                helperText="Describe what the user must do to be able to subscribe. Remember this will be presented to the users"
                size="small"
                disabled={disabled}
                required
                fullWidth
                label="Rule description"
                multiline
                rows={3}
              />
            )}
          </>
        )}
      </>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register(`phases.${index}.maxSubscriptions`, {
              required: "This field is required",
              valueAsNumber: true,
              min: {
                value: 0,
                message: "Minimum is 0",
              },
              validate: (value) => {
                if (!Number.isInteger(value)) {
                  return "Only whole numbers are allowed";
                }
                if (index > 0 && value < phases[index - 1].maxSubscriptions) {
                  return "Must be greater than previous phase";
                }
                return true;
              },
            })}
            error={!!errors?.phases?.[index]?.maxSubscriptions}
            helperText={
              !!errors?.phases?.[index]?.maxSubscriptions &&
              errors?.phases?.[index]?.maxSubscriptions?.message
            }
            size="small"
            inputProps={{ step: 1 }}
            type="number"
            fullWidth
            label="Maximum subscriptions allowed"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register(`phases.${index}.maxSubscriptionsPerWallet`, {
              valueAsNumber: true,
              min: {
                value: 0,
                message: "Minimum is 0",
              },
            })}
            error={!!errors?.phases?.[index]?.maxSubscriptionsPerWallet}
            helperText={
              !!errors?.phases?.[index]?.maxSubscriptionsPerWallet &&
              errors?.phases?.[index]?.maxSubscriptionsPerWallet?.message
            }
            size="small"
            inputProps={{ step: 1 }}
            type="number"
            fullWidth
            label={
              <>
                Maximum subscriptions allowed <strong>per wallet</strong>
              </>
            }
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TokensDelegationPhase;
