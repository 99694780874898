import Loader from "../components/Loader/Loader";
import Stack from "@mui/material/Stack";

const LoadingRoutes = () => {
  return (
    <Stack justifyContent="center" alignItems="center" height="100vh">
      <Loader />
    </Stack>
  );
};

export default LoadingRoutes;
