import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import { useApp } from "../../../../services/AppService";
import { ServerResponse, VerifyDiscordServerPayload } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

type Props = {
  onSettled: () => void;
};

const verifyDiscordServer = async (data: VerifyDiscordServerPayload) => {
  return await axios.post<ServerResponse<{ updated: true }>["data"]>(
    API_PATHS.integrations.discord.verify,
    data
  );
};

const useVerifyDiscordServer = ({ onSettled }: Props) => {
  const { selectedCommunityDocId } = useApp();
  const { enqueueSnackbar } = useSnackbar();
  const [params] = useSearchParams();
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const code = params.get("code");
      const { data } = await verifyDiscordServer({
        communityId: selectedCommunityDocId,
        code,
        url: `${window.location.origin}/integrations`,
      });
      if (isSuccessResponse(data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.community.list],
          exact: true,
          refetchType: "active",
        });
        return data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: `Kevin Musk has been added to your server!`,
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
};

export default useVerifyDiscordServer;
