import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { CreatePhygitalPayload } from "../../../../types";
import { isSuccessResponse } from "../../../../utils";

const createPhygital = async (data: CreatePhygitalPayload) => {
  return await axios.post<any>(API_PATHS.phygital.create, { data });
};

const useCreatePhygital = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: Omit<CreatePhygitalPayload, "communityId">) => {
      if (!selectedCommunityDocId) return;

      const { data } = await createPhygital({
        communityId: selectedCommunityDocId,
        ...payload,
      });

      if (isSuccessResponse(data?.data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.phygital.list, selectedCommunityDocId],
          exact: true,
          refetchType: "active",
        });
        return data.data;
      }

      throw new Error(
        `${data.data?.error?.title}\n${data.data?.error?.description}`
      );
    },
    {
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useCreatePhygital;
