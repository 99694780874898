import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import RuleSettingsModal, { RuleSettings } from "./RuleSettingsModal";
import { Fragment, useMemo, useState } from "react";
import { useApp } from "../../services/AppService";
import { useGettAllRules } from "../../hooks/queries/rules/state/useGetAllRules";
import useAddNewRuleTree from "../../hooks/queries/rules/mutations/useAddNewRuleTree";
import Loader from "../../components/Loader/Loader";
import Box from "@mui/material/Box";
import Empty from "../../components/Empty";
import RuleLinkModal from "./RuleLinkModal";
import { RuleListItem, RuleTarget } from "../../types";
import RulesListItem from "./RulesListItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Rules = () => {
  const { selectedCommunityDocId } = useApp();
  const { data: rulesList, isLoading: rulesListLoading } = useGettAllRules();
  const [linkModalRule, setLinkModalRule] = useState<null | RuleListItem>(null);
  const [target, setTarget] = useState(RuleTarget.WalletAddress);
  const { mutateAsync: addNewRuleTree, isLoading } = useAddNewRuleTree({
    onSettled: () => {
      setSettingsModalData(undefined);
    },
  });

  const [settingsModalData, setSettingsModalData] = useState<
    undefined | RuleSettings
  >();

  const handleAddRuleModal = () => {
    if (!selectedCommunityDocId) return;
    setSettingsModalData({
      _id: "new",
      name: "",
      description: "",
      target,
    });
  };

  const onRuleSettingsSave = (data: RuleSettings) => {
    addNewRuleTree({
      name: data.name,
      description: data.description,
      target: data.target,
    });
  };

  const filteredList = useMemo(() => {
    if (!rulesList) return [];
    if (!target) return rulesList;
    return rulesList.filter((rule) => rule.target === target);
  }, [rulesList, target]);

  return (
    <>
      <Typography variant="h4" fontWeight={700} mb={1}>
        Rules service
      </Typography>
      <Typography color="text.secondary" mb={1}>
        Revolutionize your user engagement strategy with the cutting-edge
        verification tools from Rule Service.
      </Typography>
      <Typography color="text.secondary" fontWeight={600}>
        By harnessing its power, your brand gains the ability to:
      </Typography>
      <Box color="text.secondary">
        <ol>
          <li>
            Verify specific role assignments within your Discord community
          </li>
          <li>Determine if users hold a specific NFTs</li>
          <li>Check who has joined your membership program</li>
        </ol>
      </Box>
      <Typography color="text.secondary" mt={1}>
        Rule Service offers unmatched flexibility, allowing you to devise both
        straightforward and intricate rules aligning with your unique business
        strategy.
      </Typography>
      {rulesListLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : (
        <>
          <Tabs
            value={target}
            variant="scrollable"
            onChange={(e, newValue) => {
              setTarget(newValue);
            }}
          >
            {(Object.keys(RuleTarget) as Array<keyof typeof RuleTarget>).map(
              (key) => (
                <Tab
                  label={TARGET_TITLES[RuleTarget[key]]}
                  value={RuleTarget[key]}
                  key={key}
                />
              )
            )}
          </Tabs>
          <Divider sx={{ mb: 2 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            mb={1}
          >
            <Typography variant="h5" fontWeight={700}>
              {TARGET_TITLES[target]} rules
            </Typography>
            <Button
              startIcon={<DataSaverOnRoundedIcon />}
              variant="base"
              onClick={handleAddRuleModal}
            >
              Add rule
            </Button>
          </Stack>
          {filteredList?.length === 0 ? (
            <Stack
              justifyContent="center"
              sx={{
                p: { xs: 2, md: 4 },
              }}
            >
              <Empty text="You don't have any rules" />
            </Stack>
          ) : (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                borderRadius: "5px",
                overflow: "hidden",
              }}
            >
              <List disablePadding dense>
                {filteredList?.map((r, index) => (
                  <Fragment key={r?._id}>
                    {index !== 0 && <Divider component="li" />}
                    <RulesListItem
                      rule={r}
                      handleGetLink={() => {
                        setLinkModalRule(r);
                      }}
                    />
                  </Fragment>
                ))}
              </List>
            </Box>
          )}
        </>
      )}
      <RuleSettingsModal
        data={settingsModalData}
        loading={isLoading}
        hide={() => {
          setSettingsModalData(undefined);
        }}
        save={onRuleSettingsSave}
      />
      <RuleLinkModal
        data={linkModalRule}
        hide={() => {
          setLinkModalRule(null);
        }}
      />
    </>
  );
};

export default Rules;

const TARGET_TITLES = {
  [RuleTarget.WalletAddress]: "Wallet",
  [RuleTarget.Community]: "Community",
};
