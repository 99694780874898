import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import {
  CommunityByCampaign,
  AddCommunityInCampaignResponse,
} from "../../../../types";
import { isSuccessResponse } from "../../../../utils";
import { useParams } from "react-router-dom";

const removeCommunity = async (payload: {
  communityId: string;
  campaignId: string;
  contractId: string;
}) => {
  return await axios.post<AddCommunityInCampaignResponse>(
    API_PATHS.tokenCampaigns.removeCommunity,
    payload
  );
};

const useRemoveCommunity = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();
  const { campaignId } = useParams();

  return useMutation(
    async (contractId: string) => {
      if (!campaignId || !selectedCommunityDocId) return;
      const { data } = await removeCommunity({
        contractId,
        communityId: selectedCommunityDocId,
        campaignId,
      });

      if (isSuccessResponse(data)) {
        queryClient.setQueryData(
          [API_PATHS.tokenCampaigns.communitiesByCampaign, campaignId],
          (currentState?: CommunityByCampaign[]) => {
            if (typeof currentState === "undefined") {
              return currentState;
            }
            return currentState?.filter(
              (community) => community?.contractId !== contractId
            );
          }
        );
        return data.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: `Community was removed and defunded successfully`,
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useRemoveCommunity;
