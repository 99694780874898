import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

type TokensMappingDialogProps = {
  busyMapping: boolean;
  loaded: number;
  total: number;
};

const TokensMappingDialog: React.FC<TokensMappingDialogProps> = ({
  busyMapping,
  loaded,
  total,
}) => {
  return (
    <Dialog open={busyMapping}>
      <DialogTitle>Mapping NFTs Attributes</DialogTitle>
      <DialogContent>
        <Alert variant="filled" severity="warning">
          Please DO NOT close this page until the mapping is completed
        </Alert>
        <Typography textAlign="center" mt={2} variant="h5">
          <strong>{loaded.toLocaleString()}</strong> / {total.toLocaleString()}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default TokensMappingDialog;
