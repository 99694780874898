import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { BoosterListItem } from "../../types";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { LiveDot } from "../../components/StyledComponents";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import LinkBehavior from "../../components/LinkBehavior";

type Props = {
  data: BoosterListItem;
};

const SubscriptionMultiplierContractListItem = ({ data }: Props) => {
  return (
    <ListItem color="background.default" disablePadding>
      <ListItemButton
        LinkComponent={LinkBehavior}
        href={data?.id}
        sx={{ px: 1 }}
      >
        <Box mr={1}>
          {data?.Archived ? (
            <Tooltip placement="top" title="Archived">
              <Inventory2RoundedIcon color="disabled" fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip
              placement="top"
              title={data?.Active ? "Active" : "Inactive"}
            >
              <Stack
                width={20}
                height={20}
                justifyContent="center"
                alignItems="center"
              >
                <LiveDot color={data?.Active ? "success" : "default"} />
              </Stack>
            </Tooltip>
          )}
        </Box>
        <ListItemText
          primary={data?.PlanTitle}
          secondary={data?.PlanDescription}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SubscriptionMultiplierContractListItem;
