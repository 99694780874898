import TextField from "@mui/material/TextField";
import RewardConfigurationHowRedeemSteps from "./RewardConfigurationHowRedeemSteps";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Inputs, RewardConfigurationProps } from "./types";
import { useGetAllAssets } from "../../../../hooks/queries/assets/state/useGetAllAssets";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import RewardsConfigurationAssetSelect from "../../../../components/AssetSelect";
import { useParams } from "react-router-dom";
import { useApp } from "../../../../services/AppService";
import useSaveRewardStep from "../../../../hooks/queries/rewards/mutations/useSaveRewardStep";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGetRewardById } from "../../../../hooks/queries/rewards/state/useGetRewardById";
import { deepEqual, validateLinkRegExp } from "../../../../utils";
import AssetSelect from "../../../../components/AssetSelect";
import Typography from "@mui/material/Typography";

const RewardConfiguration = ({ next, prev }: RewardConfigurationProps) => {
  const { campaignId } = useParams();
  const { selectedCommunityDocId } = useApp();
  const { data: assetsList, isLoading: assetsListLoading } = useGetAllAssets();
  const { data: rewardInfo } = useGetRewardById();
  const { mutateAsync: saveStep, isLoading: saveStepLoading } =
    useSaveRewardStep();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      assetMetadataId: "",
      redeemedAssetMetadataId: "",
    },
  });
  const formWatcher = watch();

  const [wantRedeemedAsset, setWantRedeemedAsset] = useState(false);
  const [redemptionInstructions, setRedemptionInstructions] = useState([""]);
  const [dataFromBd, setDataFromBd] = useState<any>();

  useEffect(() => {
    if (!rewardInfo?.tokenConfig || !rewardInfo?.redemptionInstructions) return;
    setWantRedeemedAsset(!!rewardInfo?.tokenConfig?.redeemedAssetMetadataId);
    setRedemptionInstructions(rewardInfo?.redemptionInstructions?.steps);
    const newStructure = {
      assetMetadataId: rewardInfo?.tokenConfig?.assetMetadataId,
      redeemedAssetMetadataId:
        rewardInfo?.tokenConfig?.redeemedAssetMetadataId || "",
      redemptionUri: rewardInfo?.redemptionInstructions?.redemptionUri || "",
    };
    setDataFromBd(newStructure);
    reset(newStructure);
  }, [reset, rewardInfo]);

  const handleAddPoint = () => {
    setRedemptionInstructions((state) => [...state, ""]);
  };

  const handleRemovePoint = (index: number) => {
    setRedemptionInstructions((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const handleChangePoint = (index: number, value: any) => {
    setRedemptionInstructions((state) => {
      const newState = [...state];
      newState[index] = value;
      return newState;
    });
  };

  const changeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setWantRedeemedAsset(e.target.checked);
    if (!e.target.checked) {
      setValue("redeemedAssetMetadataId", "");
    }
  };

  const onSubmit = async (data: Inputs) => {
    if (!selectedCommunityDocId || !campaignId) return;

    if (
      deepEqual(dataFromBd, data) &&
      deepEqual(
        rewardInfo?.redemptionInstructions?.steps,
        redemptionInstructions
      )
    ) {
      next();
      return;
    }

    await saveStep({
      action: "update-token-metadata",
      form: {
        campaignId,
        communityId: selectedCommunityDocId,
        redemptionInstructions,
        ...data,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {rewardInfo?.autoRedeem && (
        <Typography color="text.secondary" mb={2}>
          This campaign will have a redeem only phase. The users will be able to
          redeem the rewards directly from the redeem page if they are eligible.
        </Typography>
      )}
      <RewardsConfigurationAssetSelect
        list={assetsList?.filter(
          (asset) => asset?._id !== formWatcher?.redeemedAssetMetadataId
        )}
        loading={assetsListLoading}
        control={control}
        name="assetMetadataId"
        disabled={saveStepLoading}
      />
      {!rewardInfo?.autoRedeem && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                onChange={changeCheckbox}
                checked={wantRedeemedAsset}
                color="primary"
                disabled={saveStepLoading}
              />
            }
            label="I want to change asset after reward is used"
          />
          {wantRedeemedAsset && (
            <AssetSelect
              list={assetsList?.filter(
                (asset) => asset?._id !== formWatcher?.assetMetadataId
              )}
              loading={assetsListLoading}
              control={control}
              name="redeemedAssetMetadataId"
              disabled={saveStepLoading}
            />
          )}
        </>
      )}
      <RewardConfigurationHowRedeemSteps
        list={redemptionInstructions}
        onAdd={handleAddPoint}
        onRemove={handleRemovePoint}
        onChange={handleChangePoint}
        disabled={saveStepLoading}
      />
      <TextField
        {...register("redemptionUri", {
          pattern: {
            value: validateLinkRegExp,
            message: "Invalid link",
          },
        })}
        error={!!errors.redemptionUri}
        helperText={!!errors.redemptionUri && errors.redemptionUri.message}
        disabled={saveStepLoading}
        label="Website link where the user can redeem his reward"
        fullWidth
        margin="dense"
        sx={{ mt: 2 }}
      />
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mt: 2 }}>
        <Button
          variant="base"
          color="secondary"
          disabled={saveStepLoading}
          onClick={prev}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={assetsListLoading}
          loading={saveStepLoading}
          sx={{ ml: 1 }}
        >
          Continue
        </LoadingButton>
      </Box>
    </form>
  );
};

export default RewardConfiguration;
