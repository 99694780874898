import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { ServerResponse } from "../../../../types";
import { API_PATHS } from "../../../../constants";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";

const getPhygitalLinks = async (data: { claimableId: string }) => {
  return await axios.post<ServerResponse<string[]>>(API_PATHS.phygital.links, {
    data,
  });
};

export default function useGetPhygitalLinks({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (claimableId: string) => {
      const { data } = await getPhygitalLinks({
        claimableId,
      });

      if (isSuccessResponse(data?.data)) {
        return data?.data?.data;
      }

      throw new Error(
        `${data.data?.error?.title}\n${data.data?.error?.description}`
      );
    },
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
}
