import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { RoyaltyCreator } from "../../../types";
import Divider from "@mui/material/Divider";

type Props = {
  data: RoyaltyCreator;
  order: number;
  onRemove?: () => void;
  onFieldChange?: (
    index: number,
    key: keyof RoyaltyCreator,
    value: any
  ) => void;
  disabled: boolean;
};

const CreateAssetRoyaltySplitItem = ({
  data,
  order,
  onRemove,
  onFieldChange,
}: Props) => {
  return (
    <>
      {order !== 0 && <Divider component="li" sx={{ my: 1 }} />}
      <ListItem
        disableGutters
        secondaryAction={
          onRemove ? (
            <IconButton
              edge="end"
              aria-label="delete"
              color="error"
              onClick={onRemove}
            >
              <DeleteIcon />
            </IconButton>
          ) : undefined
        }
        sx={{ py: 0.25 }}
      >
        <ListItemText>
          <Stack
            direction={{ sm: "row" }}
            alignItems="center"
            columnGap={1.5}
            rowGap={1}
          >
            <TextField
              label="Share"
              type="number"
              value={data?.share}
              disabled={!onFieldChange}
              onChange={(e) => {
                if (!onFieldChange) return;
                onFieldChange(
                  order,
                  "share",
                  e.currentTarget.value ? parseInt(e.currentTarget.value) : ""
                );
              }}
              margin="none"
              required
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Typography fontWeight={700}>%</Typography>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Wallet address"
              value={data?.address}
              disabled={!onFieldChange}
              onChange={(e) => {
                if (!onFieldChange) return;
                onFieldChange(order, "address", e.currentTarget.value);
              }}
              margin="none"
              required
              fullWidth
            />
          </Stack>
        </ListItemText>
      </ListItem>
    </>
  );
};

export default CreateAssetRoyaltySplitItem;
