import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";

export type QuestSettings = {
  _id: string;
  title: string;
  description: string;
  hits?: number;
};

export interface ModalLevelsProps {
  data?: QuestSettings;
  loading: boolean;
  hide: () => void;
  save: (data: QuestSettings) => void;
}

type Inputs = {
  title: string;
  description: string;
};

const QuestSettingsModal = ({
  data,
  loading,
  hide,
  save,
}: ModalLevelsProps) => {
  const theme = useTheme();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    values: {
      title: data?.title || "",
      description: data?.description || "",
    },
  });
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    reset({ title: "", description: "" });
  }, [data, reset]);

  const onSave = (inputs: Inputs) => {
    if (!data) return;
    save({ ...data, title: inputs.title, description: inputs.description });
  };

  return (
    <Dialog
      open={!!data}
      onClose={loading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <DialogTitle>
          {data?._id === "new" ? "Add new" : "Edit"} quest
        </DialogTitle>
        <DialogContent>
          <>
            <TextField
              {...register("title", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3",
                },
              })}
              error={!!errors.title}
              helperText={!!errors.title && errors.title.message}
              required
              fullWidth
              label="Name"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              disabled={loading}
            />
            <TextField
              {...register("description", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3",
                },
              })}
              error={!!errors.description}
              helperText={!!errors.description && errors.description.message}
              required
              fullWidth
              label="Description"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              multiline
              rows={3}
              disabled={loading}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="base"
            onClick={hide}
            disabled={loading}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default QuestSettingsModal;
