import Avatar from "@mui/material/Avatar";
import CardActionArea from "@mui/material/CardActionArea";
import Tooltip from "@mui/material/Tooltip";
import { ChangeEvent, useRef } from "react";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  img?: string;
  loading: boolean;
  onSelected: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ProfileLogo = ({ img, loading, onSelected }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Tooltip title={`${img ? "Edit" : "Add"} community logo`} placement="top">
      <CardActionArea
        sx={{
          width: "auto",
          borderRadius: "5px",
        }}
        disabled={loading}
        onClick={() => {
          inputRef?.current?.click();
        }}
      >
        <input
          type="file"
          accept="image/*"
          hidden
          ref={inputRef}
          onChange={onSelected}
        />
        <Avatar
          src={loading ? undefined : img}
          alt="Logo"
          sx={{ width: 80, height: 80 }}
          variant="rounded"
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <DataSaverOnRoundedIcon fontSize="large" />
          )}
        </Avatar>
      </CardActionArea>
    </Tooltip>
  );
};

export default ProfileLogo;
