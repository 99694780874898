import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Discord from "../../../components/images/Discord";
import { CommunityProfile } from "../../../types";

const SOCIALS = [
  { label: "Twitter", Icon: TwitterIcon },
  { label: "Discord", Icon: Discord },
  { label: "Website", Icon: LanguageIcon },
];

type Props = {
  loading: boolean;
  profile?: CommunityProfile;
};

const ProfileLinks = ({ loading, profile }: Props) => {
  if (loading) {
    return (
      <Stack direction="row" spacing={2} mb={2} mt={1}>
        {SOCIALS.map((i, index) => (
          <Skeleton key={index} width="100%" />
        ))}
      </Stack>
    );
  }

  if (
    !profile?.showDiscord &&
    !profile?.showTwitter &&
    !profile?.showWebsite &&
    !profile?.showSecondaryMarket
  )
    return null;

  return (
    <Stack direction="row" alignItems="center" spacing={2} mb={2}>
      {!!profile &&
        SOCIALS.map(({ label, Icon }) => {
          const showProp = `show${label}`;
          if (profile?.[showProp as keyof CommunityProfile]) {
            const iconProps =
              showProp === "showDiscord" ? { fill: "#FFF", width: "1em" } : {};
            const linkProp = `link${label}`;
            const link = profile?.[
              linkProp as keyof CommunityProfile
            ] as string;
            return (
              <Button
                key={label}
                variant="text"
                startIcon={<Icon {...iconProps} />}
                sx={{ color: "#fff" }}
                onClick={() => {
                  window.open(link, "_blank");
                }}
              >
                {label}
              </Button>
            );
          }
          return null;
        })}
    </Stack>
  );
};

export default ProfileLinks;
