import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  BoosterLimit,
  BoosterMultiplierTypes,
  UpdateBoosterConfigurationPayload,
} from "../../../types";
import useGetBoosterDetails from "../../../hooks/queries/boosters/state/useGetBoosterDetails";
import FormHelperText from "@mui/material/FormHelperText";
import { BOOSTER_LIMITS, BOOSTER_MULTIPLIER_TYPES } from "../../../constants";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Chip from "@mui/material/Chip";
import useUpdateBoosterConfiguration from "../../../hooks/queries/boosters/mutations/useUpdateBoosterConfiguration";

type Inputs = {
  limitPlan: "" | BoosterLimit;
  maxUsable?: number;
  multiplierAction: "" | BoosterMultiplierTypes;
  multiplierValue: number;
};

const Configuration = () => {
  const { data: contractDetails } = useGetBoosterDetails();
  const { mutateAsync: updateConfig, isLoading: updateConfigLoading } =
    useUpdateBoosterConfiguration();
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
    register,
  } = useForm<Inputs>({
    defaultValues: {
      limitPlan: "",
      multiplierAction: "",
      maxUsable: 1,
    },
  });

  const isLimited = watch("limitPlan") === BoosterLimit.Limit;
  const multiplierType = watch("multiplierAction");

  useEffect(() => {
    if (!contractDetails) return;
    reset({
      limitPlan: contractDetails?.LimitPlan,
      maxUsable: contractDetails?.MaxUsable,
      multiplierAction: contractDetails?.MultiplierAction,
      multiplierValue: contractDetails?.MultiplierValue,
    });
  }, [contractDetails, reset]);

  const onSubmit = async (data: Inputs) => {
    updateConfig(
      data as Omit<UpdateBoosterConfigurationPayload, "communityId" | "planId">
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        fullWidth
        required
        margin="dense"
        disabled={updateConfigLoading}
      >
        <InputLabel>Limit</InputLabel>
        <Controller
          control={control}
          name="limitPlan"
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label="Limit"
              defaultValue=""
              error={!!errors.limitPlan}
            >
              {BOOSTER_LIMITS.map(({ id, label, description }) => (
                <MenuItem key={id} value={id}>
                  <Box width="100%">
                    <Typography fontWeight={700} lineHeight={1}>
                      {label}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      component="div"
                      mt={0.5}
                      lineHeight={1.15}
                    >
                      {description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>
          How many staked OG NFTs can a single multiplier be activated on?
        </FormHelperText>
      </FormControl>
      {isLimited && (
        <TextField
          {...register("maxUsable", {
            valueAsNumber: true,
            min: {
              value: 1,
              message: "Minimum is 1",
            },
          })}
          error={!!errors.maxUsable}
          helperText={!!errors.maxUsable && errors.maxUsable.message}
          type="number"
          required={isLimited}
          fullWidth
          label="Max NFTs"
          margin="dense"
          disabled={updateConfigLoading}
        />
      )}
      <Divider sx={{ my: 2 }} />
      <FormControl
        fullWidth
        required
        margin="dense"
        disabled={updateConfigLoading}
      >
        <InputLabel>Multiplication type</InputLabel>
        <Controller
          control={control}
          name="multiplierAction"
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label="Multiplication type"
              defaultValue=""
              error={!!errors.multiplierAction}
            >
              {BOOSTER_MULTIPLIER_TYPES.map(({ id, label, description }) => (
                <MenuItem key={id} value={id}>
                  <Box width="100%">
                    <Typography fontWeight={700} lineHeight={1}>
                      {label}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      component="div"
                      mt={0.5}
                      lineHeight={1.15}
                    >
                      {description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>How will the multiplier be applied?</FormHelperText>
      </FormControl>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{ mt: 2, mr: 0.5, whiteSpace: "nowrap" }}
        >
          {multiplierType === BoosterMultiplierTypes.Fixed ? (
            "Set daily reward to:"
          ) : (
            <>
              <Chip label="Daily reward" size="small" />{" "}
              {multiplierType === BoosterMultiplierTypes.Addition ? (
                <AddRoundedIcon />
              ) : (
                <CloseRoundedIcon />
              )}
            </>
          )}
        </Stack>
        <TextField
          {...register("multiplierValue", {
            required: true,
            valueAsNumber: true,
            min: {
              value: 1,
              message: "Minimum is 1",
            },
          })}
          error={!!errors.multiplierValue}
          helperText={
            !!errors.multiplierValue && errors.multiplierValue.message
          }
          type="number"
          required
          fullWidth
          hiddenLabel
          size="small"
          margin="dense"
          disabled={updateConfigLoading}
        />
      </Box>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={updateConfigLoading}
        sx={{ mt: 2 }}
      >
        Save
      </LoadingButton>
    </form>
  );
};

export default Configuration;
