import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinkBehavior from "../../components/LinkBehavior";

type Props = { phygital: any };

const PhygitalItem = ({ phygital }: Props) => {
  return (
    <ListItem color="background.default" disablePadding>
      <ListItemButton
        LinkComponent={LinkBehavior}
        href={`details/${phygital?._id}`}
        sx={{ display: "block" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1.5}
        >
          <ListItemText
            primary={phygital?.profile?.title}
            secondary={phygital?.profile?.description}
          />
          <Stack alignItems="center" spacing={0.5} width={70} flexShrink={0}>
            <Typography
              color={phygital?.status === "draft" ? "default" : "secondary"}
              variant="overline"
              lineHeight={1}
              fontWeight={900}
            >
              {phygital?.status}
            </Typography>
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default PhygitalItem;
