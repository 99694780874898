import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { RefreshTokenResponse } from "../types";
import { BASE_API_URL } from "../constants";

type AuthServiceType = {
  loadingUserState: boolean;
  setLoadingUserState: Dispatch<SetStateAction<boolean>>;
  isAuthorized: boolean;
  updateTokens: (
    idToken: string,
    refreshToken: string,
    tokenExpires: number
  ) => void;
  resetTokens: () => void;
  getToken: () => Promise<string | null>;
};

const AuthContext = createContext<AuthServiceType>({} as AuthServiceType);

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthService = ({ children }: PropsWithChildren) => {
  const [loadingUserState, setLoadingUserState] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const refreshToken = useCallback(async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      resetTokens();
      return;
    }
    const response = await fetch(`${BASE_API_URL}/auth/refresh-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refreshToken }),
    });
    const data = (await response.json()) as RefreshTokenResponse;
    if (data?.success && data?.data?.idToken && data?.data?.refreshToken) {
      if (!updateTokens) return;
      updateTokens(
        data.data.idToken,
        data.data.refreshToken,
        data.data.expiresIn
      );
    } else {
      resetTokens();
    }
  }, []);

  const getToken = useCallback(async () => {
    const idToken = localStorage.getItem("idToken");
    const tokenExpires = localStorage.getItem("tokenExpires");
    const tokenExpiresDate = new Date(
      tokenExpires ? parseInt(tokenExpires) : Date.now()
    );

    // Check if token is expired
    if (Date.now() >= tokenExpiresDate.getTime()) {
      await refreshToken();
      return localStorage.getItem("idToken");
    }
    return idToken;
  }, [refreshToken]);

  const loadToken = useCallback(() => {
    getToken()
      .then((token) => {
        setIsAuthorized(!!token);
      })
      .catch(() => {
        setIsAuthorized(false);
      })
      .finally(() => {
        setLoadingUserState(false);
      });
  }, [getToken]);

  useEffect(() => {
    loadToken();
  }, [loadToken]);

  axios.interceptors.request.use(
    async (config) => {
      // Edit response config
      const token = await getToken();
      if (!token) return config;
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const updateTokens: AuthServiceType["updateTokens"] = (
    idToken,
    refreshToken,
    tokenExpires
  ) => {
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("tokenExpires", `${Date.now() + tokenExpires}`);
    setIsAuthorized(true);
  };

  const resetTokens = () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenExpires");
    setIsAuthorized(false);
  };

  const value: AuthServiceType = {
    loadingUserState,
    setLoadingUserState,
    isAuthorized,
    updateTokens,
    resetTokens,
    getToken,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
