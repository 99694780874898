import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useGetContractDetails } from "../../../hooks/queries/subscriptions/state/useGetContractDetails";
import { SUBSCRIPTION_CONTRACT_BENEFITS } from "../../../constants";
import Checkbox from "@mui/material/Checkbox";
import { UpdateSubscriptionContractInfoPayload } from "../../../types";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { useUpdateContractProfile } from "../../../hooks/queries/subscriptions/mutations/useUpdateContractProfile";

type Inputs = Omit<
  UpdateSubscriptionContractInfoPayload,
  "communityId" & "planId"
>;

const SubscriptionContractInfo = () => {
  const { data: contractDetails } = useGetContractDetails();
  const { mutateAsync: updateProfile, isLoading: updateProfileLoading } =
    useUpdateContractProfile();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      benefitsPreview: {
        redeemables: false,
        discounts: false,
        events: false,
      },
    },
  });

  useEffect(() => {
    if (!contractDetails) return;
    const newStructure = {
      planTitle: contractDetails?.planData?.PlanTitle,
      planDescription: contractDetails?.planData?.PlanDescription,
      benefitsPreview: contractDetails?.planData?.benefitsPreview,
    };
    reset(newStructure);
  }, [contractDetails, reset]);

  const onSubmit = async (data: Inputs) => {
    updateProfile(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("planTitle", {
          required: "This field is required",
          minLength: {
            value: 3,
            message: "Minimum length is 3",
          },
          maxLength: {
            value: 50,
            message: "Max length is 50",
          },
        })}
        error={!!errors.planTitle}
        helperText={!!errors.planTitle && errors.planTitle.message}
        required
        fullWidth
        label="Title"
        margin="dense"
        disabled={updateProfileLoading}
      />
      <TextField
        {...register("planDescription", {
          required: "This field is required",
          minLength: {
            value: 3,
            message: "Minimum length is 3",
          },
          maxLength: {
            value: 150,
            message: "Max length is 150",
          },
        })}
        error={!!errors.planDescription}
        helperText={
          errors.planDescription
            ? errors.planDescription.message
            : "Make sure to give as much information about the plan in this field. For example, How many tokens per day an NFT will earn if staked"
        }
        required
        fullWidth
        label="Description"
        multiline
        rows={3}
        margin="dense"
        disabled={updateProfileLoading}
      />
      <Typography fontWeight={700} variant="h6" mt={1}>
        Upcoming Subscription Benefits
      </Typography>
      <Typography color="text.secondary">
        Showcase the future benefits for subscibers. This is only an indicator
        for your stakers what extra benefits there are for staking other than
        the reward tokens. To launch reward campaigns, please go to "Rewards" on
        the sidebar menu
      </Typography>
      <Box mb={2}>
        {SUBSCRIPTION_CONTRACT_BENEFITS.map((i) => (
          <FormControl key={i} disabled={updateProfileLoading}>
            <FormControlLabel
              control={
                <Controller
                  name={`benefitsPreview.${i.toLowerCase()}` as keyof Inputs}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value as unknown as boolean}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={i}
            />
          </FormControl>
        ))}
      </Box>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={updateProfileLoading}
      >
        Save
      </LoadingButton>
    </form>
  );
};

export default SubscriptionContractInfo;
