import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Fragment } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { ElligibilityHashlistsProps } from "./types";
import EligibleHashlist from "../../../../components/EligibleHashlist/EligibleHashlist";

const ElligibilityHashlists = ({
  list,
  onAdd,
  onRemove,
  onChange,
  disabled,
}: ElligibilityHashlistsProps) => {
  return (
    <Box>
      <Typography fontWeight={700} variant="h6" mt={2}>
        Upload hashlists
      </Typography>
      <Typography color="text.secondary">
        Limit the number of rewards a user can claim based on the uploaded file.
        <br />
        Examples:
      </Typography>
      <Typography color="text.secondary" component="div">
        <ol>
          <li>
            a user must hold 5 digital collectibles from the list in order to
            claim. After the user claims, those collectibles are 'black listed',
            preventing the user to claim again;
          </li>
          <li>
            a user can claim 1 reward per wallet listed in the uploaded file.
          </li>
        </ol>
      </Typography>
      <List>
        {list.map((i, index) => (
          <Fragment key={index}>
            {index > 0 && <ListItem divider disablePadding />}
            <ListItem
              disableGutters
              secondaryAction={
                list?.length > 1 ? (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="error"
                    disabled={disabled}
                    onClick={() => onRemove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null
              }
            >
              <ListItemText>
                <EligibleHashlist
                  type="assets"
                  data={i}
                  order={index}
                  onChange={onChange}
                  key={index}
                  disabled={disabled}
                />
              </ListItemText>
            </ListItem>
          </Fragment>
        ))}
      </List>
      <Button
        startIcon={<DataSaverOnRoundedIcon />}
        onClick={onAdd}
        variant="base"
      >
        Add hashlist
      </Button>
    </Box>
  );
};

export default ElligibilityHashlists;
