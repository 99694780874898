import Divider from "@mui/material/Divider";
import ProfilePreview from "./ProfilePreview/ProfilePreview";
import Typography from "@mui/material/Typography";
import ProfileSetup from "./ProfileSetup";
import { useGetProfile } from "../../hooks/queries/profile/state/useGetProfile";
import Stack from "@mui/material/Stack";
import Loader from "../../components/Loader/Loader";
import { useApp } from "../../services/AppService";
import Box from "@mui/material/Box";
import TextFieldWithCopy from "../../components/TextFieldWithCopy";

export const Profile = () => {
  const {
    data: profile,
    isLoading: profileLoading,
    isFetching: profileFetching,
  } = useGetProfile();
  const { selectedCommunity, selectedCommunityDocId } = useApp();

  return (
    <>
      <Box mb={1.5}>
        <Typography variant="h5" fontWeight={700}>
          Profile
        </Typography>
        <Typography color="text.secondary" mb={0.5}>
          Manage your profile
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} gap={1}>
          <TextFieldWithCopy
            label="Account id"
            value={selectedCommunity?.ProjectId || ""}
          />
          <TextFieldWithCopy
            label="Community id"
            value={selectedCommunityDocId || ""}
          />
        </Stack>
      </Box>

      {profileLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : (
        <>
          <Divider sx={{ my: 2 }} />
          <ProfilePreview data={profile} isLoading={profileFetching} />
          <ProfileSetup data={profile} />
        </>
      )}
    </>
  );
};
