import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import { CreateQuestPayload } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";

type Props = {
  onSettled: () => void;
};

const createQuest = async (data: CreateQuestPayload) => {
  return await axios.post<any>(API_PATHS.loyalty.create, { data });
};

export default function useCreateQuest({ onSettled }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: Omit<CreateQuestPayload, "communityId">) => {
      if (!selectedCommunityDocId) return;

      const { data } = await createQuest({
        communityId: selectedCommunityDocId,
        ...payload,
      });

      if (!data?.data?.success) {
        throw new Error(
          `${data?.data?.error?.title}\n${data?.data?.error?.description}`
        );
      }

      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.loyalty.list, selectedCommunityDocId],
        exact: true,
        refetchType: "active",
      });
      return data;
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar({
          variant: "success",
          message: `New quest "${data?.data?.data?.title}" created`,
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
}
