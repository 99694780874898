import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QuestMissionsResponse } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const getQuestMissions = async (questId?: string) => {
  const { data } = await axios.post<QuestMissionsResponse>(
    API_PATHS.loyalty.byId,
    {
      data: { questId },
    }
  );
  if (isSuccessResponse(data?.data)) {
    return data.data?.data;
  }
  throw new Error(
    `Quest id ${questId} not found. Please check the link. It's most likely incorrect`
  );
};

export const useGetQuestMissions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { questId } = useParams();

  return useQuery(
    [API_PATHS.loyalty.byId, questId],
    () => getQuestMissions(questId),
    {
      enabled: !!questId,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        navigate("/loyalty");
      },
    }
  );
};
