import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import TextField from "@mui/material/TextField";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";
import { QuestMission, RuleListItem } from "../../types";

const LIMIT = 20;

type Props = {
  levels: QuestMission[];
  rulesList?: RuleListItem[];
  addLevel: () => void;
  update: (index: number, key: keyof QuestMission, ruleTreeId: string) => void;
  removeLevel: (index: number) => void;
  updateQuestMissionsLoading: boolean;
};

const QuestConfigurator = ({
  levels,
  rulesList,
  addLevel,
  update,
  removeLevel,
  updateQuestMissionsLoading,
}: Props) => {
  const getLimit = () => {
    if (rulesList && rulesList?.length < LIMIT) {
      return rulesList.length;
    }
    return LIMIT;
  };

  return (
    <Box>
      <Timeline
        sx={{
          m: 0,
          p: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {levels?.map((l, i) => (
          <TimelineItem
            key={i}
            sx={{
              "&:hover .MuiTimelineDot-root": {
                backgroundColor: (theme) => theme.palette.primary.main,
              },
            }}
          >
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  width: 32,
                  height: 32,
                  justifyContent: "center",
                  fontWeight: "bold",
                  color: "black",
                  my: 1,
                  transition: (theme) =>
                    theme.transitions.create("background-color"),
                }}
              >
                {i + 1}
              </TimelineDot>
              {levels?.length >= getLimit() &&
              i + 1 === levels?.length ? null : (
                <TimelineConnector />
              )}
            </TimelineSeparator>
            <TimelineContent
              sx={{
                mt: 0,
                mb: i + 1 === levels?.length ? 0 : 1.5,
                ml: 2,
                py: 1,
                px: 1.5,
                bgcolor: "background.default",
                backgroundImage:
                  "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                borderRadius: "5px",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box width="100%">
                  {rulesList && rulesList?.length > 0 && (
                    <FormControl fullWidth margin="dense" required size="small">
                      <InputLabel>Select rule</InputLabel>
                      <Select
                        label="Select rule"
                        defaultValue=""
                        value={l?.ruleTreeId}
                        endAdornment={
                          l?.ruleTreeId ? (
                            <Tooltip title="Go to selected rule configurator">
                              <IconButton
                                size="small"
                                sx={{ mr: 2 }}
                                onClick={() => {
                                  window.open(
                                    `/rules/${l?.ruleTreeId}`,
                                    "_blank"
                                  );
                                }}
                              >
                                <OpenInNewRoundedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          ) : null
                        }
                        disabled={updateQuestMissionsLoading}
                        onChange={(e) => {
                          update(i, "ruleTreeId", e.target.value);
                        }}
                      >
                        {rulesList?.map((r) => {
                          if (
                            l?.ruleTreeId !== r?._id &&
                            levels.filter((l) => l?.ruleTreeId === r?._id)
                              ?.length > 0
                          ) {
                            return null;
                          }
                          return (
                            <MenuItem key={r?._id} value={r?._id}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                              >
                                <Box>
                                  <Typography fontWeight={700} lineHeight={1}>
                                    {r?.name}
                                  </Typography>
                                  <Typography
                                    color="text.secondary"
                                    variant="caption"
                                  >
                                    {r?.description}
                                  </Typography>
                                </Box>
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    required
                    fullWidth
                    value={l?.title}
                    label="Title"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={updateQuestMissionsLoading}
                    onChange={(e) => {
                      update(i, "title", e.target.value);
                    }}
                  />
                  <TextField
                    required
                    fullWidth
                    value={l?.description}
                    label="Description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    rows={3}
                    disabled={updateQuestMissionsLoading}
                    onChange={(e) => {
                      update(i, "description", e.target.value);
                    }}
                  />
                </Box>
                {levels?.length < 2 ? null : (
                  <Tooltip placement="top" title="Remove level">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="error"
                      onClick={() => removeLevel(i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      {levels?.length < getLimit() && (
        <Button
          startIcon={<DataSaverOnRoundedIcon />}
          onClick={addLevel}
          variant="base"
          sx={{ mt: 1 }}
        >
          Add level
        </Button>
      )}
    </Box>
  );
};

export default QuestConfigurator;
