import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import ModalTransition from "../helpers/ModalTransition";
import useLaunchCommunity from "../hooks/queries/community/mutations/useLaunchCommunity";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useApp } from "../services/AppService";
import Button from "@mui/material/Button";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";

export interface Props {
  open: boolean;
  hide: () => void;
}

const LaunchCommunityDialog = ({ open, hide }: Props) => {
  const { publicKey } = useWallet();
  const { selectedCommunity } = useApp();
  const { mutateAsync: launch, isLoading: launchLoading } =
    useLaunchCommunity();

  const handleLaunch = () => {
    launch();
  };

  const handleGoToCommunity = () => {
    window.open(
      `https://anybodies.com/c/${selectedCommunity?.VaultRoute}`,
      "_blank"
    );
  };

  return (
    <Dialog
      open={open}
      onClose={launchLoading ? undefined : hide}
      keepMounted
      maxWidth="xs"
      fullWidth
      TransitionComponent={ModalTransition}
      sx={{ "& .MuiPaper-root": { overflowY: "unset" } }}
    >
      <DialogTitle fontWeight={900}>
        Launch community
        <IconButton
          aria-label="close"
          disabled={launchLoading}
          onClick={hide}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowY: "unset" }}>
        {selectedCommunity?.IsLive ? (
          <>
            <Typography variant="h6" fontWeight={900} mb={2}>
              Now your community is{" "}
              <Typography
                component="span"
                variant="gradient"
                fontSize="inherit"
                fontWeight={900}
              >
                live
              </Typography>
            </Typography>
            <Button
              endIcon={<LaunchRoundedIcon />}
              size="large"
              variant="gradient"
              fullWidth
              onClick={handleGoToCommunity}
            >
              Open my community page
            </Button>
          </>
        ) : (
          <>
            <Typography mb={3}>
              To launch your community, you’ll need to fund the wallet with{" "}
              <strong>1 $SOL</strong>
            </Typography>
            <Box textAlign="center">
              <Typography
                variant="overline"
                fontWeight={700}
                color="text.secondary"
                component="div"
              >
                {publicKey ? "Your wallet" : "Please, connect your wallet"}
              </Typography>
              <Box display="inline-block">
                <WalletMultiButton disabled={launchLoading} />
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      {!selectedCommunity?.IsLive && (
        <DialogActions>
          <LoadingButton
            type="submit"
            variant="gradient"
            disabled={!publicKey}
            loading={launchLoading}
            onClick={handleLaunch}
            fullWidth
          >
            Launch
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default LaunchCommunityDialog;
