import {
  BoosterLimit,
  BoosterMultiplierTypes,
  EligableTypes,
  RewardFulfillmentTypes,
  RewardSource,
  RewardSources,
  RewardType,
  RewardTypesSystem,
  SubscriptionContractType,
  SubscriptionsReportTypes,
} from "./types";

export const PRICE_PER_REWARD_CREATION = 0.0273 * 2;
export const PRICE_PER_PHYGITAL_CREATION = 0.0273 * 2;
export const PRICE_PER_REWARD_NFT = 0.04616 * 2;
export const PRICE_PER_PHYGITAL_NFT = 0.04616 * 2;

export const REWARD_CODES_TEMPLATE_LINK =
  "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/landing%2FTemplate_Upload.zip?alt=media&token=11010695-5f38-42d4-9293-b3d9b800449b";

export const CLAIM_VERIFICATION_METHODS = [
  {
    id: EligableTypes.Unlimited,
    label: "Unlimited",
    description: "The user will be able to claim as many times as he wishes",
  },
  {
    id: EligableTypes.Hashlist,
    label: "Collectibles list",
    description:
      "Only users that comply with the rule chosen under “Claim Eligibility” and hold in the connect wallet digital collectibles from the uploaded list will be able to claim the reward",
  },
  {
    id: EligableTypes.WalletAddresses,
    label: "Predefined wallet list",
    description:
      "Only users that comply with the rule chosen under “Claim Eligibility” and connected a wallet from the uploaded list will be able to claim the reward",
  },
];

export const REWARD_SOURCES: RewardSource[] = [
  {
    id: RewardSources.System,
    label: "Manual",
    description:
      "Manually upload your own CSV file with reward codes. These codes will be distributed to eligible users!",
  },
  {
    id: RewardSources.Shopify,
    label: "Shopify",
    description:
      "Connect to your Shopify account and automatically generate compatible codes to your e-commerce website according to the campaign setup.",
  },
];

export const REWARD_TYPES: RewardType = {
  system: [
    {
      id: RewardTypesSystem.Discount,
      label: "Discount",
      description: "i.e 5%, 10% discount etc.",
    },
    {
      id: RewardTypesSystem.GiftCard,
      label: "Gift card",
      description: "i.e 5$, 10$ gift card",
    },
  ],
};

export const REWARD_CATEGORIES = [
  "Accessories",
  "Food",
  "Health & Wellness",
  "Electronics",
  "Cars & Insurance",
  "Fashion",
  "Sporting goods",
  "Home",
  "Streaming",
  "Airline",
  "Books",
  "Toys",
];

export const FULFILLMENT_TYPES = [
  {
    id: RewardFulfillmentTypes.Upload,
    label: "Upload",
  },
  {
    id: RewardFulfillmentTypes.Scratch,
    label: "Scratch",
  },
];

export const BASE_API_URL =
  "https://us-central1-nft-anybodies.cloudfunctions.net";

export const PROFILE_LINKS_FIELDS = [
  {
    switchKey: "showTwitter",
    linkKey: "linkTwitter",
    label: "Show link to twitter",
    hint: "Twitter",
  },
  {
    switchKey: "showDiscord",
    linkKey: "linkDiscord",
    label: "Show link to discord",
    hint: "Discord",
  },
  {
    switchKey: "showWebsite",
    linkKey: "linkWebsite",
    label: "Show link to website",
    hint: "Website",
  },
  {
    switchKey: "showSecondaryMarket",
    linkKey: "linkSecondaryMarket",
    label: "Show link to secondary market",
    hint: "Secondary market",
  },
];

export const SUBSCRIPTION_DURATIONS = [7, 15, 30, 60];

export const SUBSCRIPTION_CONTRACT_TYPES: RewardSource[] = [
  {
    id: SubscriptionContractType.Attribute,
    label: "Attribute Based",
    description:
      "This is best used for projects that want to have custom rewards based on rarity or trait group. You are able to assign a custom reward value for each attribute and trait.",
  },
  {
    id: SubscriptionContractType.Collection,
    label: "Collection Based",
    description:
      "This is best used for projects with multiple collections. You are able to assign a custom reward value to each collection in a single vault.",
  },
];

export const SUBSCRIPTION_CONTRACT_TYPES_LABEL = {
  [SubscriptionContractType.Attribute]: "Atrribute based",
  [SubscriptionContractType.Collection]: "Collections based",
};

export const SUBSCRIPTION_CONTRACT_BENEFITS = [
  "Discounts",
  "Redeemables",
  "Events",
];

export const BOOSTER_LIMITS = [
  {
    id: BoosterLimit.All,
    label: "Unlimited",
    description:
      "A single booster NFT can be used to boost the daily reward of UNLIMITED staked NFTS",
  },
  {
    id: BoosterLimit.Limit,
    label: "Limit to number of staked NFTs",
    description:
      "Each booster NFT can boost the daily reward of a specific number of Staked NFTs",
  },
];

export const BOOSTER_MULTIPLIER_TYPES = [
  {
    id: BoosterMultiplierTypes.Addition,
    label: "Addition (+)",
    description: "Add a fixed additional amount the original daily reward",
  },
  {
    id: BoosterMultiplierTypes.Multiplication,
    label: "Multiplication (X)",
    description:
      "Multiply the original daily reward by a specific number. Example: originaly 10 daily, after adding a 1.5X multiplier = 15 daily",
  },
  {
    id: BoosterMultiplierTypes.Fixed,
    label: "Fixed",
    description:
      "Boost the daily reward of each NFT to a fixed amount per day. Example: originaly X daily, after setting a fixed multiplier to 10 = 10 tokens per day",
  },
];

export const BOOSTER_TARGET_TYPES = [
  {
    id: "all",
    label: "All OGs in the Hashlists",
    description: "All staked NFTs in the OG hashlist can use this multiplier",
  },
  {
    id: "attribute",
    label: "OG Attribute filter",
    description: "Only OG NFTs with Specific traits can use this multiplier",
  },
];

export const SNAPSHOT_TYPES = [
  {
    id: SubscriptionsReportTypes.Stakers,
    label: "List of stakers wallets",
    description: "Returns a list of the unique staking wallets",
    preview: `[
      "WALLET_ADDRESS",
      "9Vms5K57cvoJmtwXLqJous7Uv9v6B3phGPUe41X3HrY",
      ...
  ]`,
  },
  {
    id: SubscriptionsReportTypes.StakersNftNumber,
    label: "List of stakers wallets with number staked",
    description:
      "Returns a map of the unique staking wallets and the number of NFTs staked with each once",
    preview: `{
      "WALLET_ADDRESS": {NUMBER_OF_NFTS_STAKED},
      "9Vms5K57cvoJmtwXLqJous7Uv9v6B3phGPUe41X3HrY": 5,
      ...
  }`,
  },
  {
    id: SubscriptionsReportTypes.StakersNftArray,
    label: "List of stakers wallets with list of NFTs",
    description:
      "Returns a map of the unique staking wallets and the list of NFTs staked with each once",
    preview: `{
      "WALLET_ADDRESS": [
          "NFT_TOKEN_ADDRESS",
          "NFT_TOKEN_ADDRESS",
      ],
      "9Vms5K57cvoJmtwXLqJous7Uv9v6B3phGPUe41X3HrY": [
          "31yxxdhs69gBPmDwXsen1AZYEUYu588WdSZDL2DzeWAg",
          "9ruqPwKRv2eCzd7tuzZ15eMmW8CPDtK2K3G8zw48jNog",
      ],
      ...
  }`,
  },
];

export const API_PATHS = {
  auth: {
    login: "auth/login",
    signout: "auth/signout",
    forgot: "auth/forgot-password",
    signUp: "auth/signup",
  },
  profile: {
    info: "profile/v1/api/info",
    update: "profile/v1/api/update",
    updateAssets: {
      logo: "profile/v1/api/logo/update",
      cover: "profile/v1/api/cover/update",
    },
  },
  tokens: {
    list: "rewardTokens/v1/api/list",
    add: "rewardTokens/v1/api/add",
    fund: {
      request: "rewardTokens/v1/api/fund/request",
      process: "rewardTokens/v1/api/fund/process",
    },
    defund: {
      request: "rewardTokens/v1/api/defund/request",
      process: "rewardTokens/v1/api/defund/process",
    },
    accumulatedRewards: "rewardTokens/v1/api/accumulated",
  },
  community: {
    list: "community/v1/api/list",
    create: "community/v1/api/create",
    launch: {
      request: "community/v1/api/activate/request",
      process: "community/v1/api/activate/process",
    },
  },
  subscription: {
    list: "subscription/v1/api/list",
    byId: "subscription/v1/api/details",
    create: "subscription/v1/api/create",
    update: {
      profile: "subscription/v1/api/profile/update",
      feeAddon: "subscription/v1/api/fee-addon/update",
      rules: "subscription/v1/api/rules/update",
      hashlist: {
        add: "subscription/v1/api/hashlist/add",
        remove: "subscription/v1/api/hashlist/remove",
        setAmount: "subscription/v1/api/hashlist/set-amount",
      },
      setAttributes: "subscription/v1/api/attributes/set",
      role: "subscription/v1/api/role/update",
      maturity: "subscription/v1/api/maturity/set",
      status: "subscription/v1/api/status/update",
      archive: "subscription/v1/api/archive",
    },
    unsubscribeAll: {
      request: "subscription/v1/api/bulk-unstake/request",
      process: "subscription/v1/api/bulk-unstake/process",
    },
  },
  booster: {
    list: "booster/v1/api/list",
    byId: "booster/v1/api/details",
    create: "booster/v1/api/create",
    update: {
      profile: "booster/v1/api/profile/update",
      configuration: "booster/v1/api/config/set",
      feeAddon: "booster/v1/api/fee-addon/update",
      role: "booster/v1/api/role/update",
      hashlist: {
        add: "booster/v1/api/hashlist/add",
        remove: "booster/v1/api/hashlist/remove",
      },
      eligibleHashlist: {
        add: "booster/v1/api/eligible/add",
        remove: "booster/v1/api/eligible/remove",
      },
      setAttributes: "booster/v1/api/eligible/filter/set",
      status: "booster/v1/api/status/update",
      archive: "booster/v1/api/archive",
    },
  },
  loyalty: {
    list: "QUESTS_getAllQuests",
    create: "QUESTS_createQuest",
    byId: "QUESTS_getQuestById",
    update: {
      profile: "QUESTS_updateQuestProfile",
      banner: "QUESTS_updateQuestBanner",
      missions: "QUESTS_updateQuestMissions",
      status: "QUESTS_updateQuestStatus",
    },
  },
  phygital: {
    list: "CLAIMABLE_getAllClaimables",
    publishedList: "CLAIMABLE_getPublishedClaimables",
    create: "CLAIMABLE_submitNewClaimable",
    byId: "CLAIMABLE_getClaimable",
    publish: {
      request: "CLAIMABLE_requestPublishClaimable",
      process: "CLAIMABLE_processPublishClaimable",
    },
    links: "CLAIMABLE_getClaimablesPageLinks",
  },
  rules: {
    list: "RULES_getAllTreesByAccountIdAndCommunityId",
    byId: "RULES_getTreeById",
    create: "RULES_createNewRuleTree",
    tree: {
      add: "RULES_addRule",
      updateOperator: "RULES_updateOperator",
      remove: "RULES_traverseAndCompare",
      updateProfile: "RULES_updateRuleTree",
    },
  },
  assets: {
    list: "ASSET_MD_getAssestMetadata",
    create: "ASSET_MD_submitNewAssetMetadata",
  },
  rewards: {
    list: "REWARDS_getAllCampaigns",
    byId: "REWARDS_getCampaign",
    publish: {
      request: "REWARDS_requestPublishCampaign",
      process: "REWARDS_processPublishCampaign",
    },
    saveStep: "REWARDS_dispatchCampaignAction",
  },
  solana: {
    getTokensMetadata: "SOLANA_getTokensMetadata",
  },
  integrations: {
    discord: {
      roles: "ROLES_getCommunityRoles",
      verify: "community/v1/api/discord/verify",
      holderRoleUpdate: "community/v1/api/discord/set-role",
    },
  },
  hashlist: {
    byId: "HASHLIST_getFullHashlistByManagerId",
  },
  snapshot: "V5Client_getVaultReport",
  studio: {
    searchCommunity: "studio/v1/api/community/search",
  },
  tokenCampaigns: {
    list: "tokenCampaign/v1/api/list",
    create: "tokenCampaign/v1/api/create",
    byId: "tokenCampaign/v1/api/campaign/details",
    communitiesByCampaign: "tokenCampaign/v1/api/campaign/community/list",
    addCommunityToCampaign: "tokenCampaign/v1/api/campaign/community/add",
    removeCommunity: "tokenCampaign/v1/api/campaign/community/cancel",
  },
};
