import { useApp } from "../../services/AppService";
import IntegrationsDiscordInfo from "./Discord/IntegrationsDiscordInfo";
import IntegrationsDiscordConnect from "./Discord/IntegrationsDiscordConnect";

export const Integrations = () => {
  const { selectedCommunity } = useApp();

  if (selectedCommunity?.DiscordData) {
    return (
      <IntegrationsDiscordInfo
        serverName={selectedCommunity?.DiscordData?.name}
      />
    );
  }

  return <IntegrationsDiscordConnect />;
};
