import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { useApp } from "../../../../services/AppService";
import { TokenCampaignsResponse } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";

const getAllTokenCampaigns = async (communityId: string | null) => {
  const { data } = await axios.post<TokenCampaignsResponse>(
    API_PATHS.tokenCampaigns.list,
    {
      communityId,
    }
  );
  return data;
};

export const useGetAllTokenCampaigns = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  return useQuery(
    [API_PATHS.tokenCampaigns.list, selectedCommunityDocId],
    async () => {
      const res = await getAllTokenCampaigns(selectedCommunityDocId);
      if (!res?.success) {
        throw new Error(`${res?.error?.title}\n${res?.error?.description}`);
      }
      return res?.data;
    },
    {
      enabled: !!selectedCommunityDocId,
      staleTime: 30 * 60 * 1000,
      retry: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};
