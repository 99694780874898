import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import ModalTransition from "../../../helpers/ModalTransition";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import AttributesSetup from "./AttributesSetup";
import FilterInput from "./FilterInput";
import TokensMappingDialog from "./TokensMappingDialog";
import { useGetContractDetails } from "../../../hooks/queries/subscriptions/state/useGetContractDetails";
import { useGetTokensMetadata } from "../../../hooks/queries/solana/useGetTokensMetadata";
import { useSetContractAttributes } from "../../../hooks/queries/subscriptions/mutations/useSetContractAttributes";
import {
  SubscriptionContractAttributeCountMap,
  SubscriptionContractAttributeMap,
} from "../../../types";

type Props = { open: boolean; hide: () => void };

const SubscriptionCollectionAttributesDialog: React.FC<Props> = ({
  open,
  hide,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const { data: contractDetails } = useGetContractDetails();
  const { mutateAsync: getTokensMetadata } = useGetTokensMetadata();
  const {
    mutateAsync: setContractAttributes,
    isLoading: setContractAttributesLoading,
  } = useSetContractAttributes();

  const [filter, setFilter] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [totalLoaded, setTotalLoaded] = useState(0);
  const [supportedTokens, setsupportedTokens] = useState<string[]>([]);
  const [busyMapping, setBusyMapping] = useState(false);
  const [attributeMap, setAttributeMap] =
    useState<SubscriptionContractAttributeMap>({});
  const [attributeCountMap, setAttributeCountMap] =
    useState<SubscriptionContractAttributeCountMap>({});

  const handleSensitivityChanged = useCallback(
    (key: string, sensitivity: number) => {
      setHasChanges(true);
      setAttributeCountMap((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          sensitivity,
        },
      }));
    },
    []
  );

  const handleAmountChanged = useCallback((key: string, amount: number) => {
    setHasChanges(true);
    setAttributeCountMap((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        amount,
      },
    }));
  }, []);

  const handleCustomChanged = useCallback(
    (key: string, trait: string, status: boolean) => {
      setHasChanges(true);
      setAttributeMap((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [trait]: {
            ...prevState[key][trait],
            custom: status,
          },
        },
      }));
    },
    []
  );

  const handleValueChanged = useCallback(
    (key: string, trait: string, value: number) => {
      setHasChanges(true);
      setAttributeMap((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [trait]: {
            ...prevState[key][trait],
            value,
          },
        },
      }));
    },
    []
  );

  const getNewMap = useCallback(
    (
      map: SubscriptionContractAttributeMap,
      countMap: SubscriptionContractAttributeCountMap
    ) => {
      const newMap = { ...map };
      for (const key in countMap) {
        const { amount, sensitivity, count } = countMap[key];
        for (const trait in map[key]) {
          if (newMap[key][trait].custom === false) {
            const val = +(
              (1 - (newMap[key][trait].count / count / sensitivity) * 100) *
              amount
            ).toFixed(0);
            newMap[key][trait].value = val < 0 ? 0 : val;
          }
        }
      }
      return newMap;
    },
    []
  );

  useEffect(() => {
    if (!contractDetails) {
      return;
    }
    setHasChanges(false);
    const tokesAddresses: string[] = [];
    contractDetails?.supportedCollections?.forEach((item) => {
      tokesAddresses.push(...item?.fullHashlist);
    });
    const supportedTokens = tokesAddresses.filter(
      (v, i, a) => a.indexOf(v) === i
    );
    setsupportedTokens(supportedTokens);
    if (
      contractDetails?.planData?.Setup &&
      contractDetails?.planData?.AttributeMap &&
      contractDetails?.planData?.AttributeCountMap
    ) {
      setAttributeMap(contractDetails?.planData?.AttributeMap);
      setAttributeCountMap(contractDetails?.planData?.AttributeCountMap);
    } else {
      setAttributeMap({});
      setAttributeCountMap({});
    }
  }, [contractDetails]);

  useEffect(() => {
    if (attributeCountMap) {
      setAttributeMap((state) => getNewMap(state, attributeCountMap));
    }
  }, [attributeCountMap, getNewMap]);

  const refreshMap = async () => {
    if (supportedTokens.length <= 0) {
      return;
    }
    setBusyMapping(true);
    getTokensAttributeMap();
  };

  const getTokensAttributeMap = useCallback(async () => {
    setTotalLoaded(0);
    const arrays: string[][] = [];
    const size = 10;
    const tempAttributeMap: SubscriptionContractAttributeMap = {};
    const tempAttributeCountMap: SubscriptionContractAttributeCountMap = {};
    const supportedTokensCopy = JSON.parse(JSON.stringify(supportedTokens));

    while (supportedTokensCopy.length > 0)
      arrays.push(supportedTokensCopy.splice(0, size));

    await Promise.all(
      arrays.map(async (tokenArray) => {
        const tokenData = await getTokensMetadata(tokenArray);
        if (tokenData?.length <= 0) {
          return;
        }
        tokenData.forEach((data) => {
          if (data?.attributes) {
            for (const { trait_type, value } of data.attributes) {
              if (trait_type === "Sequence") continue;
              if (!tempAttributeCountMap[trait_type]) {
                tempAttributeCountMap[trait_type] = {
                  amount: attributeCountMap?.[trait_type]
                    ? attributeCountMap?.[trait_type]?.amount
                    : 100,
                  sensitivity: attributeCountMap?.[trait_type]
                    ? attributeCountMap?.[trait_type]?.sensitivity
                    : 10,
                  count: 0,
                };
              }
              tempAttributeCountMap[trait_type].count++;

              if (!tempAttributeMap?.[trait_type])
                tempAttributeMap[trait_type] = {};
              const traitValue = value.length > 0 ? value : "_None_";
              if (!tempAttributeMap[trait_type]?.[traitValue]) {
                const existingTraitValueConfig = attributeMap?.[trait_type]?.[
                  traitValue
                ]
                  ? JSON.parse(
                      JSON.stringify(attributeMap?.[trait_type]?.[traitValue])
                    )
                  : undefined;
                tempAttributeMap[trait_type][traitValue] =
                  existingTraitValueConfig || {
                    count: 0,
                    value: 0,
                    custom: false,
                  };
                if (existingTraitValueConfig) {
                  tempAttributeMap[trait_type][traitValue].count = 0;
                }
              }
              tempAttributeMap[trait_type][traitValue].count++;
            }
          }
        });
        setTotalLoaded((state) => (state += tokenData?.length));
      })
    );

    const newMap = getNewMap(tempAttributeMap, tempAttributeCountMap);
    await setContractAttributes({
      attributeMap: newMap,
      attributeCountMap: tempAttributeCountMap,
    });
    setAttributeMap(newMap);
    setAttributeCountMap(tempAttributeCountMap);
    setBusyMapping(false);
  }, [
    supportedTokens,
    setContractAttributes,
    getNewMap,
    getTokensMetadata,
    attributeCountMap,
    attributeMap,
  ]);

  const saveChanges = async () => {
    if (attributeMap && attributeCountMap) {
      await setContractAttributes({
        attributeMap,
        attributeCountMap,
      });
    }
  };

  const filteredAttributeKeys = useMemo(() => {
    return Object.keys(attributeMap)
      .sort((a, b) => a.localeCompare(b))
      .filter((key) => filter.includes(key) || filter.length === 0);
  }, [attributeMap, filter]);

  return (
    <Dialog
      open={open}
      onClose={hide}
      TransitionComponent={ModalTransition}
      maxWidth="xl"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>Attributes setup</DialogTitle>
      <DialogContent>
        <Alert severity="info" variant="filled" sx={{ mb: 1 }}>
          Both "Max amount" and "Distribution" have no effect on the actual
          daily-rewards. They are there to just help quickly populate the
          daily-reward fields of the traits of a specific attribute. You don't
          need to use them if you want to manually fill in all the values.
        </Alert>
        {supportedTokens?.length > 0 &&
          Object.keys(attributeMap).length === 0 && (
            <Box textAlign="center">
              <Typography mb={1} fontWeight={700}>
                Need to get information about all attributes from your hashlists
              </Typography>
              <Button
                color="secondary"
                onClick={refreshMap}
                disabled={setContractAttributesLoading}
              >
                Click here to map attributes
              </Button>
            </Box>
          )}
        <TokensMappingDialog
          busyMapping={busyMapping}
          loaded={totalLoaded}
          total={supportedTokens.length}
        />
        {Object.keys(attributeMap).length > 0 && (
          <FilterInput
            filter={filter}
            setFilter={setFilter}
            attributeMap={attributeMap}
            disabled={setContractAttributesLoading}
          />
        )}
        <Box
          sx={{
            position: "relative",
            overflowX: "auto",
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            spacing={1}
            sx={{
              "&::before,&::after": {
                content: '""',
                margin: "auto",
              },
            }}
          >
            {filteredAttributeKeys.map((key) => (
              <AttributesSetup
                name={key}
                attributeMap={attributeMap}
                attributeCountMap={attributeCountMap}
                handleAmountChanged={handleAmountChanged}
                handleSensitivityChanged={handleSensitivityChanged}
                handleCustomChanged={handleCustomChanged}
                handleValueChanged={handleValueChanged}
                key={key}
              />
            ))}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box>
          {Object.keys(attributeMap).length > 0 && (
            <Button
              color="secondary"
              onClick={refreshMap}
              disabled={setContractAttributesLoading}
            >
              Refresh attributes
            </Button>
          )}
        </Box>
        <Stack spacing={1} direction="row">
          <Button
            variant="base"
            color="secondary"
            onClick={hide}
            disabled={setContractAttributesLoading}
          >
            Close
          </Button>
          <LoadingButton
            variant="contained"
            loading={setContractAttributesLoading}
            disabled={!hasChanges}
            onClick={saveChanges}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionCollectionAttributesDialog;
