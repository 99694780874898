import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useGetDiscordRoles } from "../../hooks/queries/integrations/state/useGetDiscordRoles";
import { UseMutationResult } from "@tanstack/react-query";

type Inputs = {
  roleId: string;
};

type Props = {
  roleId?: string;
  updateRoleHook: () => UseMutationResult<any, any, string, unknown>;
};

const Integrations = ({ roleId, updateRoleHook }: Props) => {
  const { data: discordRoles, isLoading: discordRolesLoading } =
    useGetDiscordRoles();
  const { mutateAsync: updateRole, isLoading: updateRoleLoading } =
    updateRoleHook();
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      roleId: "",
    },
  });

  useEffect(() => {
    reset({
      roleId: roleId || "",
    });
  }, [roleId, reset]);

  const onSubmit = async (data: Inputs) => {
    updateRole(data.roleId);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {discordRolesLoading ? (
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Box>
            <CircularProgress />
          </Box>
          <Typography>
            In the process of getting a list of roles. Please, wait..
          </Typography>
        </Stack>
      ) : (
        discordRoles &&
        discordRoles?.length > 0 && (
          <>
            <Typography variant="h6" fontWeight={700}>
              Discord subscriber verification
            </Typography>
            <Typography mb={1}>
              Choose a role that will be given to all the subscribers in this
              plan
            </Typography>
            <FormControl fullWidth margin="dense" disabled={updateRoleLoading}>
              <InputLabel shrink>Role</InputLabel>
              <Controller
                control={control}
                name="roleId"
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    label="Role"
                    defaultValue=""
                    error={!!errors.roleId}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em style={{ color: "red" }}>Not active</em>
                    </MenuItem>
                    {discordRoles?.map((r) => (
                      <MenuItem value={r?.id} key={r?.id}>
                        {r?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </>
        )
      )}
      <LoadingButton
        variant="contained"
        type="submit"
        disabled={discordRolesLoading}
        loading={updateRoleLoading}
        sx={{ mt: 2 }}
      >
        Save
      </LoadingButton>
    </form>
  );
};

export default Integrations;
