import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { RuleTreeResponse } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const getRuleTree = async (treeId?: string) => {
  const { data } = await axios.post<RuleTreeResponse>(API_PATHS.rules.byId, {
    data: { treeId },
  });

  return data;
};

export const useGetRuleTree = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { treeId } = useParams();

  return useQuery(
    [API_PATHS.rules.byId, treeId],
    async () => {
      const { data } = await getRuleTree(treeId);
      if (isSuccessResponse(data)) {
        return data?.data;
      }

      throw new Error(
        `Rule id ${treeId} not found. Please check the link. It's most likely incorrect`
      );
    },
    {
      enabled: !!treeId,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        navigate("/rules");
      },
    }
  );
};
