import TextField from "@mui/material/TextField";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Button from "@mui/material/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import { RewardConfigurationHowRedeemStepsProps } from "./types";

const RewardConfigurationHowRedeemSteps = ({
  list,
  onRemove,
  onAdd,
  onChange,
  disabled,
}: RewardConfigurationHowRedeemStepsProps) => {
  return (
    <>
      <Typography fontWeight={700} variant="h6" mt={2}>
        Redeem Instructions
      </Typography>
      <Typography color="text.secondary" mb={1}>
        Add information in bullet points regarding how to redeem the reward
      </Typography>
      <Timeline
        sx={{
          m: 0,
          p: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {list?.map((step, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineConnector
                sx={{
                  ...(index === 0 && { backgroundColor: "transparent" }),
                }}
              />
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pr: 0 }}>
              <Stack direction="row" alignItems="center" spacing={1.5}>
                <TextField
                  label="Bullet point"
                  required
                  value={step}
                  fullWidth
                  margin="none"
                  onChange={(e) => {
                    onChange(index, e.target.value);
                  }}
                  disabled={disabled}
                />
                {list?.length > 1 && (
                  <IconButton
                    color="error"
                    onClick={() => {
                      onRemove(index);
                    }}
                    disabled={disabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <Button
        startIcon={<DataSaverOnRoundedIcon />}
        onClick={onAdd}
        variant="base"
        disabled={disabled}
        sx={{ mt: 1 }}
      >
        Add bullet point
      </Button>
    </>
  );
};

export default RewardConfigurationHowRedeemSteps;
