import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { API_PATHS } from "../../../../constants";
import { UpdateProfileAssetPayload } from "../../../../types";

export function useUpdateProfileAsset() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  const updateProfileLogo = async (
    type: "cover" | "logo",
    image: UpdateProfileAssetPayload
  ) => {
    return await axios.post<any>(API_PATHS.profile.updateAssets[type], {
      ...image,
      communityId: selectedCommunityDocId,
    });
  };

  return useMutation(
    async ({
      type,
      image,
    }: {
      type: "cover" | "logo";
      image: UpdateProfileAssetPayload;
    }) => {
      const { data } = await updateProfileLogo(type, image);
      if (!data?.success) {
        throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
      }
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.profile.info, selectedCommunityDocId],
        exact: true,
        refetchType: "active",
      });
      return data;
    },

    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Profile logo successfully updated!",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign in. Try again!",
        });
      },
    }
  );
}
