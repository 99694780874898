import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import { Fragment } from "react";
import CreateAssetRoyaltySplitItem from "./CreateAssetRoyaltySplitItem";
import { RoyaltyCreator } from "../../../types";

type Props = {
  list: RoyaltyCreator[];
  onAdd: () => void;
  onRemove: (index: number) => void;
  onFieldChange: (index: number, key: keyof RoyaltyCreator, value: any) => void;
  disabled: boolean;
};

const CreateAssetRoyaltySplit = ({
  list,
  onAdd,
  onRemove,
  onFieldChange,
  disabled,
}: Props) => {
  return (
    <Box mt={2}>
      <Typography fontWeight={700} variant="h6">
        Royalty split
      </Typography>
      <List disablePadding>
        {list.map((i, index) => (
          <Fragment key={index}>
            <CreateAssetRoyaltySplitItem
              data={i}
              order={index}
              onRemove={
                list?.length > 1
                  ? () => {
                      onRemove(index);
                    }
                  : undefined
              }
              onFieldChange={disabled ? undefined : onFieldChange}
              disabled={disabled}
            />
          </Fragment>
        ))}
      </List>
      <Button
        startIcon={<DataSaverOnRoundedIcon />}
        onClick={onAdd}
        variant="base"
        sx={{ mt: 1 }}
        disabled={disabled}
      >
        Add split
      </Button>
    </Box>
  );
};

export default CreateAssetRoyaltySplit;
