import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { BoosterListItem, ServerResponse } from "../../../../types";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const getBoosters = async (communityId: string | null) => {
  const { data } = await axios.post<
    ServerResponse<{ boosterPlans: BoosterListItem[] }>["data"]
  >(API_PATHS.booster.list, {
    communityId,
  });
  return data;
};

const useGetBoosters = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  return useQuery(
    [API_PATHS.booster.list, selectedCommunityDocId],
    async () => {
      const data = await getBoosters(selectedCommunityDocId);
      if (isSuccessResponse(data)) {
        return data?.data?.boosterPlans;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      staleTime: 30 * 60 * 1000,
      retry: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useGetBoosters;
