import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Fragment, useState } from "react";
import { useApp } from "../../services/AppService";
import Loader from "../../components/Loader/Loader";
import Box from "@mui/material/Box";
import Empty from "../../components/Empty";
import QuestSettingsModal, { QuestSettings } from "./QuestSettingsModal";
import { useGetAllQuests } from "../../hooks/queries/loyalty/state/useGetAllQuests";
import useCreateQuest from "../../hooks/queries/loyalty/mutations/useCreateQuest";
import ConfirmDialog from "../../components/ConfirmDialog";
import { QuestItem } from "../../types";
import Paper from "@mui/material/Paper";
import useUpdateQuestStatus from "../../hooks/queries/loyalty/mutations/useUpdateQuestStatus";
import LoyaltyListItem from "./LoyaltyListItem";

const LoyaltyPrograms = () => {
  const { selectedCommunityDocId } = useApp();
  const { data: questsList, isLoading: questsListLoading } = useGetAllQuests();
  const { mutateAsync: createQuest, isLoading } = useCreateQuest({
    onSettled: () => {
      setSettingsModalData(undefined);
    },
  });
  const {
    mutateAsync: updateQuestStatus,
    isLoading: isUpdateQuestStatusLoading,
  } = useUpdateQuestStatus({
    onSettled: () => {
      setConfirmChangeQuestStateData(null);
    },
  });

  const [settingsModalData, setSettingsModalData] = useState<
    undefined | QuestSettings
  >();
  const [confirmChangeQuestStateData, setConfirmChangeQuestStateData] =
    useState<QuestItem | null>(null);

  const handleAddQuestModal = () => {
    if (!selectedCommunityDocId) return;
    setSettingsModalData({
      _id: "new",
      title: "",
      description: "",
    });
  };

  const onQuestSettingsSave = (data: QuestSettings) => {
    createQuest({ title: data.title, description: data.description });
  };

  return (
    <>
      <Typography variant="h4" fontWeight={700} mb={1}>
        Loyalty program
      </Typography>
      <Typography color="text.secondary">
        Introducing the Loyalty Program, a dynamic tool for boosting user
        engagement!
        <br />
        Watch your community thrive as you empower your most loyal users,
        tracking their devotion through customizable quests and levels.
        <br />
        Utilizing the Rule Service, you can tailor quests to meet your unique
        business objectives, effectively fostering user engagement.
        <br />
        Rewarding loyalty has never been so satisfying.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <Typography variant="h5" fontWeight={700}>
          Quests
        </Typography>
        <Button
          startIcon={<DataSaverOnRoundedIcon />}
          variant="base"
          onClick={handleAddQuestModal}
        >
          Create new quest
        </Button>
      </Stack>
      {questsListLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : questsList?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
        >
          <Empty text="You don't have any quests" />
        </Stack>
      ) : (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <List disablePadding dense>
            {questsList?.map((quest, index) => (
              <Fragment key={quest?._id}>
                {index !== 0 && <Divider component="li" />}
                <LoyaltyListItem
                  quest={quest}
                  handleStatusChange={() => {
                    setConfirmChangeQuestStateData({
                      ...quest,
                      status: quest?.status === "live" ? "draft" : "live",
                    });
                  }}
                />
              </Fragment>
            ))}
          </List>
        </Box>
      )}
      <QuestSettingsModal
        data={settingsModalData}
        loading={isLoading}
        hide={() => {
          setSettingsModalData(undefined);
        }}
        save={onQuestSettingsSave}
      />
      <ConfirmDialog
        data={confirmChangeQuestStateData}
        title="Are you sure?"
        content={
          <>
            Do you want{" "}
            {confirmChangeQuestStateData?.status === "draft"
              ? "deactivate"
              : "activate"}{" "}
            quest?
            {confirmChangeQuestStateData?._id && (
              <Paper
                sx={{
                  p: 1,
                  my: 1,
                  mx: -1,
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
              >
                <Typography variant="subtitle2">
                  {confirmChangeQuestStateData?.title}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {confirmChangeQuestStateData?.description}
                </Typography>
              </Paper>
            )}
          </>
        }
        isLoading={isUpdateQuestStatusLoading}
        hide={() => {
          setConfirmChangeQuestStateData(null);
        }}
        confirm={(data) => {
          if (!confirmChangeQuestStateData) return;
          updateQuestStatus({
            questId: confirmChangeQuestStateData?._id,
            status: confirmChangeQuestStateData?.status,
          });
        }}
      />
    </>
  );
};

export default LoyaltyPrograms;
