import Card from "@mui/material/Card";
import { Asset } from "../../types";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

type Props = { asset: Asset };

const AssetsItem = ({ asset }: Props) => {
  return (
    <Card>
      <CardMedia
        sx={{
          aspectRatio: "1/1",
          overflow: "hidden",
          position: "relative",
        }}
        title={asset?.name}
        image={asset?.digitalAssetConfig?.assets?.[0]?.url}
      />
      <CardContent>
        <Typography fontWeight={700} color="text.secondary">
          {asset?.assetTitle}
        </Typography>
        <Typography fontWeight={700}>{asset?.name}</Typography>
        <Typography color="text.secondary">{asset?.description}</Typography>
        {!!asset?.sellerFeeBasisPoints && (
          <>
            <Chip
              label={`${asset?.sellerFeeBasisPoints / 100}% royalty`}
              color="primary"
              size="small"
              sx={{ fontWeight: 700, my: 1 }}
            />
            {asset?.creators?.map((creator, index) => (
              <Typography
                key={index}
                color="text.secondary"
                variant="subtitle2"
                sx={{ wordBreak: "break-all" }}
              >
                <strong>{creator?.share}%</strong> - {creator?.address}
              </Typography>
            ))}
          </>
        )}
        {asset?.attributes?.length > 0 && (
          <>
            <Divider sx={{ mt: 1, mb: 1 }}>
              <Typography
                variant="overline"
                lineHeight={1}
                fontWeight={600}
                color="text.secondary"
              >
                Attributes
              </Typography>
            </Divider>
            <Stack
              direction="row"
              flexWrap="wrap"
              gap={1}
              justifyContent="space-around"
            >
              {asset?.attributes?.map((attr, index) =>
                attr?.trait_type && attr?.value ? (
                  <Box
                    key={attr?.trait_type + index}
                    borderRadius={1}
                    borderColor="divider"
                    py={0.5}
                    px={1}
                    textAlign="center"
                    sx={{ borderWidth: 1, borderStyle: "solid" }}
                  >
                    <Typography variant="subtitle2" fontWeight={500}>
                      {attr?.trait_type}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {attr?.value}
                    </Typography>
                  </Box>
                ) : null
              )}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default AssetsItem;
