import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";
import { EligableTypes } from "../../types";
import { Hashlist } from "./types";
import { useGetHashlist } from "../../hooks/queries/hashlist/useGetHashlist";
import { downloadJSONFile } from "../../utils";

type Props = {
  type?: EligableTypes;
  data: Hashlist;
};

const EligibleHashlistSummary = ({ type, data }: Props) => {
  const { refetch, isFetching } = useGetHashlist(
    data?.hashlistManagerId ? data?.hashlistManagerId : ""
  );
  const handleFetchPosts = async (name: string) => {
    refetch().then(({ data }) => {
      if (!data?.length) return;
      downloadJSONFile(data, name);
    });
  };

  return (
    <Stack direction={{ sm: "row" }} alignItems="center" gap={1.5}>
      <Alert
        variant="outlined"
        severity="success"
        action={
          <Tooltip title="Download hashlist">
            <IconButton
              size="small"
              disabled={isFetching}
              onClick={() => {
                handleFetchPosts(data?.hashlistTitle || "");
              }}
            >
              {isFetching ? (
                <CircularProgress
                  sx={{ width: "18px !important", height: "18px !important" }}
                />
              ) : (
                <DownloadingRoundedIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        }
        sx={{ width: "100%" }}
      >
        <AlertTitle>{data?.hashlistTitle}</AlertTitle>
        <Typography>
          <strong>{data?.size}</strong>{" "}
          {`${type === EligableTypes.Hashlist ? "NFT" : "wallet"}${
            data?.size && data?.size > 1 ? "s" : ""
          }`}
        </Typography>
        {type === EligableTypes.Hashlist && (
          <Typography>
            Number of owned NFTs: <strong>{data?.minToClaim}</strong>
          </Typography>
        )}
      </Alert>
    </Stack>
  );
};

export default EligibleHashlistSummary;
