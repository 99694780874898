import { useState } from "react";
import { useGetContracts } from "../../hooks/queries/subscriptions/state/useGetContracts";
import Typography from "@mui/material/Typography";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Preview from "./Preview";
import InputLabel from "@mui/material/InputLabel";
import { SNAPSHOT_TYPES } from "../../constants";
import Stack from "@mui/material/Stack";
import { SubscriptionsReportTypes } from "../../types";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import useGetSnapshot from "../../hooks/queries/snapshot/useGetSnapshot";
import { downloadJSONFile } from "../../utils";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

const Snapshots = () => {
  const { mutateAsync: getSnapshot, isLoading: getSnapshotLoading } =
    useGetSnapshot();
  const { data: contracts, isLoading: contractsLoading } = useGetContracts();

  const [selectedReportType, setSelectedReportType] =
    useState<SubscriptionsReportTypes>(SubscriptionsReportTypes.Stakers);
  const [contract, setContract] = useState("");

  const handleContractChange = (event: SelectChangeEvent) => {
    setContract(event.target.value);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setSelectedReportType(event.target.value as SubscriptionsReportTypes);
  };

  const downloadSnapshot = async () => {
    const report = await getSnapshot({
      reportId: selectedReportType,
      planId: contract,
    });
    if (report) {
      downloadJSONFile(
        report,
        `Anybodies_Snapshot_${new Date().toLocaleDateString()}.json`
      );
    }
  };

  return (
    <div>
      <Typography variant="h5" fontWeight={700}>
        Snapshots & APIs
      </Typography>
      <Typography color="text.secondary" mb={1}>
        Get live and updated data from your vault.
      </Typography>
      <FormControl
        fullWidth
        margin="dense"
        disabled={getSnapshotLoading}
        required
      >
        <InputLabel>Snapshot type</InputLabel>
        <Select
          variant="outlined"
          label="Snapshot type"
          value={selectedReportType}
          onChange={handleTypeChange}
        >
          {SNAPSHOT_TYPES.map(({ id, label, description }) => (
            <MenuItem key={id} value={id}>
              <Box width="100%" whiteSpace="normal">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography fontWeight={700} lineHeight={1}>
                    {label}
                  </Typography>
                </Stack>
                <Typography
                  color="text.secondary"
                  variant="caption"
                  component="div"
                  mt={0.5}
                  lineHeight={1.15}
                >
                  {description}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {contractsLoading ? (
        <Stack direction="row" spacing={1.5} alignItems="center" mt={1}>
          <Box>
            <CircularProgress />
          </Box>
          <Typography>
            In the process of getting a list of contracts. Please, wait..
          </Typography>
        </Stack>
      ) : (
        <FormControl fullWidth margin="dense" disabled={getSnapshotLoading}>
          <InputLabel shrink>Contract</InputLabel>
          <Select
            value={contract}
            label="Contract"
            onChange={handleContractChange}
            defaultValue=""
            displayEmpty
          >
            <MenuItem value="">
              <em style={{ color: "green" }}>All contracts</em>
            </MenuItem>
            {contracts?.map((c) => (
              <MenuItem key={c?.PlanId} value={c?.PlanId}>
                {c?.PlanTitle}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Filter by subscription plan (optional)
          </FormHelperText>
        </FormControl>
      )}
      <Divider>
        <Typography variant="overline" fontWeight={700}>
          Preview
        </Typography>
      </Divider>
      <Preview
        report={SNAPSHOT_TYPES.find((type) => type.id === selectedReportType)}
      />
      <Box textAlign="center" mt={2}>
        <LoadingButton
          variant="contained"
          startIcon={<DownloadRoundedIcon />}
          onClick={downloadSnapshot}
          disabled={contractsLoading}
          loading={getSnapshotLoading}
        >
          Download
        </LoadingButton>
      </Box>
    </div>
  );
};

export default Snapshots;
