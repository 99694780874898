import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { FullHashlistByManagerIdResponse } from "../../../types";
import axios from "axios";
import { API_PATHS } from "../../../constants";
import { isSuccessResponse } from "../../../utils";

const getHashlist = async (managerId: string) => {
  const { data } = await axios.post<FullHashlistByManagerIdResponse>(
    API_PATHS.hashlist.byId,
    {
      data: { managerId },
    }
  );
  return data;
};

export const useGetHashlist = (managerId: string) => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(
    [API_PATHS.hashlist.byId, managerId],
    async () => {
      const { data } = await getHashlist(managerId);
      if (isSuccessResponse(data)) {
        return data?.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};
