import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Attribute } from "../../../types";
import Divider from "@mui/material/Divider";

type Props = {
  data: Attribute;
  order: number;
  onRemove?: () => void;
  onFieldChange?: (index: number, key: keyof Attribute, value: any) => void;
};

const CreateAssetAttributesItem = ({
  data,
  order,
  onRemove,
  onFieldChange,
}: Props) => {
  return (
    <>
      {order !== 0 && <Divider component="li" sx={{ my: 1 }} />}
      <ListItem
        disableGutters
        secondaryAction={
          onRemove ? (
            <IconButton
              edge="end"
              aria-label="delete"
              color="error"
              onClick={onRemove}
            >
              <DeleteIcon />
            </IconButton>
          ) : null
        }
        sx={{ py: 0.25 }}
      >
        <ListItemText>
          <Stack
            direction={{ sm: "row" }}
            alignItems="center"
            columnGap={1.5}
            rowGap={1}
          >
            <TextField
              label="Type"
              value={data?.trait_type}
              disabled={!onFieldChange}
              onChange={
                onFieldChange
                  ? (e) => {
                      onFieldChange(order, "trait_type", e.currentTarget.value);
                    }
                  : undefined
              }
              margin="none"
              required
              fullWidth
            />
            <TextField
              label="Value"
              value={data?.value}
              disabled={!onFieldChange}
              onChange={
                onFieldChange
                  ? (e) => {
                      onFieldChange(order, "value", e.currentTarget.value);
                    }
                  : undefined
              }
              margin="none"
              required
              fullWidth
            />
          </Stack>
        </ListItemText>
      </ListItem>
    </>
  );
};

export default CreateAssetAttributesItem;
