import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Attribute, UploadedImage } from "../../../types";
import { ChangeEvent, useRef } from "react";
import { getBase64 } from "../../../utils";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

type Props = {
  formData: any;
  attributes: Attribute[];
  image?: UploadedImage;
  setImage: (data?: UploadedImage) => void;
  disabled: boolean;
};

const CreateAssetPreview = ({
  formData,
  attributes,
  image,
  setImage,
  disabled,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const image = e?.target?.files?.[0];
    if (!image) {
      setImage(undefined);
      return;
    }
    const type = image.type.replace(/(.*)\//g, "");
    const base64 = await getBase64(image);
    e.target.value = "";
    setImage({ type, base64 });
  };

  return (
    <Box position="sticky" sx={{ top: 8, pt: 1, textAlign: "center" }}>
      <Card
        sx={{
          display: "inline-block",
          margin: "auto",
          textAlign: "left",
          maxWidth: "100%",
          width: 300,
        }}
      >
        <CardMedia
          sx={{
            maxWidth: "100%",
            aspectRatio: "1/1",
            overflow: "hidden",
            position: "relative",
          }}
          title="image"
        >
          {image && (
            <img
              src={`data:image/${image?.type};base64,${image?.base64}`}
              alt="Asset"
            />
          )}
          <CardActionArea
            sx={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.7)",
              opacity: image ? 0 : 1,
              transition: (theme) => theme.transitions.create("opacity"),
              p: 2,
              textAlign: "center",
              "& svg": {
                fontSize: "3em",
              },
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={() => {
              inputRef?.current?.click();
            }}
            disabled={disabled}
          >
            <>
              <input
                type="file"
                accept="image/png, image/jpeg"
                hidden
                ref={inputRef}
                onChange={onSelected}
              />
              {image ? (
                <CreateRoundedIcon />
              ) : (
                <DataSaverOnRoundedIcon fontSize="large" />
              )}
              <Typography variant="h6" fontWeight={700}>
                {image ? "Edit" : "Set"} image
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Recommended image size up to 1500px.
                <br />
                Aspect ratio 1 / 1
              </Typography>
            </>
          </CardActionArea>
        </CardMedia>
        <CardContent>
          <Typography fontWeight={700}>{formData?.name}</Typography>
          <Typography color="text.secondary">
            {formData?.description}
          </Typography>
          {!!formData?.sellerFeeBasisPoints && (
            <Chip
              label={`${formData?.sellerFeeBasisPoints}% royalty`}
              color="primary"
              size="small"
              sx={{ fontWeight: 700, mt: 1 }}
            />
          )}
          {attributes?.length > 0 && (
            <>
              <Divider sx={{ mt: 1, mb: 1 }}>
                <Typography
                  variant="overline"
                  lineHeight={1}
                  fontWeight={600}
                  color="text.secondary"
                >
                  Attributes
                </Typography>
              </Divider>
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={1}
                justifyContent="space-around"
              >
                {attributes?.map((attr, index) =>
                  attr?.trait_type && attr?.value ? (
                    <Box
                      key={attr?.trait_type + index}
                      borderRadius={1}
                      borderColor="divider"
                      py={0.5}
                      px={1}
                      textAlign="center"
                      sx={{ borderWidth: 1, borderStyle: "solid" }}
                    >
                      <Typography variant="subtitle2" fontWeight={500}>
                        {attr?.trait_type}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {attr?.value}
                      </Typography>
                    </Box>
                  ) : null
                )}
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateAssetPreview;
