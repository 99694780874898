import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useRef } from "react";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";

type Props = {
  fileName: string;
  img?: string;
  height?: any;
  loading: boolean;
  onSelected: (e: ChangeEvent<HTMLInputElement>) => void;
};

const UploadPhoto = ({ fileName, img, height, loading, onSelected }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Box
      sx={{
        height: img ? height || "auto" : "auto",
        backgroundColor: (theme) => theme.palette.background.paper,
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CardActionArea
        sx={{
          height: "100%",
          width: "100%",
          ...(img && {
            backgroundColor: "rgba(0,0,0,0.9)",
          }),
          opacity: img && !loading ? 0 : 1,
          transition: (theme) => theme.transitions.create("opacity"),
          p: 2,
          textAlign: "center",
          "& svg": {
            fontSize: "3em",
          },
          "&:hover": {
            opacity: 1,
          },
        }}
        disabled={loading}
        onClick={() => {
          inputRef?.current?.click();
        }}
      >
        {loading ? (
          <>
            <CircularProgress sx={{ mb: 2 }} />
            <Typography>
              File in the process of uploading to the server..
            </Typography>
          </>
        ) : (
          <>
            <input
              type="file"
              accept="image/*"
              hidden
              ref={inputRef}
              onChange={onSelected}
            />
            {img ? (
              <CreateRoundedIcon />
            ) : (
              <DataSaverOnRoundedIcon fontSize="large" />
            )}
            <Typography variant="h6" fontWeight={700}>
              {img ? "Edit" : "Add"} {fileName}
            </Typography>
          </>
        )}
      </CardActionArea>
    </Box>
  );
};

export default UploadPhoto;
