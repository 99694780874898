import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import useSaveRewardStep from "../../../../hooks/queries/rewards/mutations/useSaveRewardStep";
import { DistributionTypes } from "../../../../types";
import { DistributionProps, Inputs } from "./types";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useApp } from "../../../../services/AppService";
import { useGetRewardById } from "../../../../hooks/queries/rewards/state/useGetRewardById";
import { useEffect } from "react";

const Distribution = ({ next, prev }: DistributionProps) => {
  const { campaignId } = useParams();
  const { selectedCommunityDocId } = useApp();
  const { data: rewardInfo } = useGetRewardById();
  const { mutateAsync: saveStep, isLoading: saveStepLoading } =
    useSaveRewardStep();
  const { handleSubmit, control, reset } = useForm<Inputs>({
    defaultValues: {
      distributionType: "",
    },
  });

  useEffect(() => {
    if (!rewardInfo?.distributionType) return;
    const newStructure = {
      distributionType: rewardInfo?.distributionType,
    };
    reset(newStructure);
  }, [reset, rewardInfo]);

  const onSubmit = async (data: Inputs) => {
    if (!selectedCommunityDocId || !campaignId) return;

    if (rewardInfo?.distributionType === data?.distributionType) {
      next();
      return;
    }

    saveStep({
      action: "update-distribution",
      form: {
        campaignId,
        communityId: selectedCommunityDocId,
        ...data,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography fontWeight={700} variant="h6">
        Distribution Method
      </Typography>
      <Typography color="text.secondary">
        Define the distribution method you wish to use for your users
      </Typography>
      <FormControl
        fullWidth
        margin="dense"
        disabled={saveStepLoading}
        required
        sx={{ mb: 2 }}
      >
        <InputLabel>Method</InputLabel>
        <Controller
          control={control}
          name="distributionType"
          render={({ field }) => (
            <Select
              {...field}
              variant="outlined"
              label="Method"
              defaultValue=""
            >
              {DISTRIBUTION_METHODS.map(({ id, label, description }) => (
                <MenuItem
                  key={id}
                  value={id}
                  disabled={id === DistributionTypes.Raffle}
                >
                  <Box width="100%">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={700} lineHeight={1}>
                        {label}
                      </Typography>
                      {id === DistributionTypes.Raffle && (
                        <Chip
                          label="Coming soon!"
                          color="primary"
                          size="small"
                          sx={{ fontWeight: 700 }}
                        />
                      )}
                    </Stack>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      component="div"
                      mt={0.5}
                      lineHeight={1.15}
                    >
                      {description}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mt: 2 }}>
        <Button
          variant="base"
          color="secondary"
          disabled={saveStepLoading}
          onClick={prev}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={saveStepLoading}
          sx={{ ml: 1 }}
        >
          Continue
        </LoadingButton>
      </Box>
    </form>
  );
};

export default Distribution;

const DISTRIBUTION_METHODS = [
  {
    id: DistributionTypes.Giveaway,
    label: "Giveaway - FCFS",
    description:
      "Distribution based on first come first serve users that comply the eligibility step",
  },
  {
    id: DistributionTypes.Raffle,
    label: "Raffle",
    description:
      "Distribution based on raffle. Users that comply with the eligibility step will participate in the raffle",
  },
];
