import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { API_PATHS } from "../../../../constants";
import { UpdateSubscriptionContractDiscordRolePayload } from "../../../../types";
import { useParams } from "react-router-dom";

const updateDiscordRole = async (
  data: UpdateSubscriptionContractDiscordRolePayload
) => {
  return await axios.post<any>(API_PATHS.booster.update.role, data);
};

const useUpdateBoosterDiscordRole = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const { planId } = useParams();

  return useMutation(
    async (roleId: UpdateSubscriptionContractDiscordRolePayload["roleId"]) => {
      if (!selectedCommunityDocId || !planId) return;
      const { data } = await updateDiscordRole({
        roleId,
        communityId: selectedCommunityDocId,
        planId,
      });
      if (!data?.success) {
        throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
      }
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.booster.byId, selectedCommunityDocId, planId],
        exact: true,
        refetchType: "active",
      });
      return data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Contract discord staker role successfully updated!",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Something went wrong. Try again!",
        });
      },
    }
  );
};

export default useUpdateBoosterDiscordRole;
