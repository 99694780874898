import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import {
  MutationDefaultResponse,
  ProcessRewardPayload,
  PublishRewardPayload,
} from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const publishRewardRequest = async (data: PublishRewardPayload) => {
  return await axios.post<any>(API_PATHS.rewards.publish.request, { data });
};

const publishRewardProcess = async (data: ProcessRewardPayload) => {
  return await axios.post<MutationDefaultResponse>(
    API_PATHS.rewards.publish.process,
    { data }
  );
};

export default function usePublishReward({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { selectedCommunityDocId, setLoadingModal } = useApp();
  const { publicKey, signTransaction } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(
    async (campaignId: string) => {
      if (!selectedCommunityDocId || !campaignId || !publicKey) return;
      const { data: publishTxRes } = await publishRewardRequest({
        communityId: selectedCommunityDocId,
        campaignId,
        walletAddress: publicKey?.toString(),
      });

      if (!publishTxRes?.data?.success) {
        throw new Error(
          `${publishTxRes?.data?.error?.title}\n${publishTxRes?.data?.error?.description}`
        );
      }

      if (!signTransaction) {
        throw new Error("Can't create transaction");
      }

      const transaction = Transaction.from(
        publishTxRes?.data?.rawTransaction?.data
      );

      const signedTransaction = await signTransaction(transaction);
      const { data: finalRes } = await publishRewardProcess({
        communityId: selectedCommunityDocId,
        campaignId,
        rawTransaction: Object.values(signedTransaction.serialize()),
        requestId: publishTxRes?.data?.requestId,
      });
      if (!isSuccessResponse(finalRes?.data)) {
        throw new Error(
          `${finalRes?.data?.error?.title}\n${finalRes?.data?.error?.description}`
        );
      }
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.rewards.byId, campaignId],
        exact: true,
        refetchType: "active",
      });
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.rewards.list, selectedCommunityDocId],
        exact: true,
        refetchType: "active",
      });
      return finalRes;
    },
    {
      onMutate: () => {
        if (!setLoadingModal) return;
        setLoadingModal({
          title: "Campaign publishing in process",
          description: "May take a while, trust the process",
        });
      },
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Campaign now live!",
        });
        onSuccess();
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        if (!setLoadingModal) return;
        setLoadingModal(null);
      },
      onSettled: () => {
        if (!setLoadingModal) return;
        setLoadingModal(null);
      },
    }
  );
}
