import List from "@mui/material/List";
import { useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import MenuAccordion from "./MenuAccordion";
import { MenuProps } from "./types";

const Menu = ({ list }: MenuProps) => {
  const location = useLocation();

  return (
    <List sx={{ py: 0 }}>
      {list.map((menuItem) => {
        if (menuItem?.children) {
          return (
            <MenuAccordion
              key={menuItem.label}
              data={menuItem}
              defaultExpanded={menuItem.children.some(
                (i) => location.pathname === i.path
              )}
            />
          );
        }
        return (
          <MenuItem
            key={menuItem.label}
            selected={location.pathname === menuItem.path}
            path={menuItem.path}
            Icon={menuItem.Icon}
            label={menuItem.label}
          />
        );
      })}
    </List>
  );
};

export default Menu;
