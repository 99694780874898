import { useForm } from "react-hook-form";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ModalTransition from "../../helpers/ModalTransition";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import useCreateNewCommunity from "../../hooks/queries/community/mutations/useCreateNewCommunity";
import { useEffect } from "react";
import { CreateCommunityPayload } from "../../types";

type Props = {
  isOpen: boolean;
  hide: () => void;
};

const SECONDARY_LINK_START = "https://magiceden.io/marketplace/";

const CreateNewCommunityModal = ({ isOpen, hide }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<CreateCommunityPayload>();
  const {
    mutateAsync: createNewCommunity,
    isLoading: createNewCommunityLoading,
  } = useCreateNewCommunity({
    onSettled: () => {
      hide();
    },
  });

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  const onSubmit = (data: CreateCommunityPayload) => {
    data.secondaryLink = `${SECONDARY_LINK_START}${data.secondaryLink}`;
    createNewCommunity(data);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={createNewCommunityLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={700}
            mb={1}
            component="div"
          >
            Create your community profile
          </Typography>
          <Typography>Please make sure you enter the correct info</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            {...register("communityName", {
              required: "Vault Name is required",
            })}
            error={!!errors.communityName}
            helperText={!!errors.communityName && errors.communityName.message}
            disabled={createNewCommunityLoading}
            required
            fullWidth
            label="Community Name"
            autoFocus
            margin="dense"
          />
          <TextField
            {...register("communityRoute", {
              required: "Community Path is required",
              pattern: {
                message:
                  "Community Path can only include letters and numbers (White spaces are not allowed)",
                value: /^[a-zA-Z0-9_.-]*$/g,
              },
            })}
            error={!!errors.communityRoute}
            helperText={
              !!errors.communityRoute && errors.communityRoute.message
            }
            disabled={createNewCommunityLoading}
            required
            fullWidth
            label="Community Path"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  https://anybodies.com/c/
                </InputAdornment>
              ),
            }}
            margin="dense"
          />

          <TextField
            {...register("secondaryLink", {
              required: "Secondary Link is required",
              pattern: {
                message:
                  "Vault Path can only include letters and numbers (White spaces are not allowed)",
                value: /^[a-zA-Z0-9_.-]*$/g,
              },
            })}
            error={!!errors.secondaryLink}
            helperText={!!errors.secondaryLink && errors.secondaryLink.message}
            disabled={createNewCommunityLoading}
            required
            fullWidth
            label="Magic Eden Link"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {SECONDARY_LINK_START}
                </InputAdornment>
              ),
            }}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            onClick={hide}
            disabled={createNewCommunityLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={createNewCommunityLoading}
          >
            Create Community
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateNewCommunityModal;
