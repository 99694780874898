import { ChangeEvent, useCallback, useRef } from "react";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";
import { Hashlist } from "./types";
import { downloadJSONFile, parseDataFromJSON } from "../../utils";
import { useGetHashlist } from "../../hooks/queries/hashlist/useGetHashlist";

type Props = {
  type: "wallets" | "assets";
  data: Hashlist;
  order: number;
  onChange: (index: number, key: keyof Hashlist, value: any) => void;
  disabled: boolean;
};

const EligibleHashlist = ({ type, data, order, onChange, disabled }: Props) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { refetch, isFetching } = useGetHashlist(
    data?.hashlistManagerId ? data?.hashlistManagerId : ""
  );
  const handleFetchPosts = useCallback(
    async (name: string) => {
      refetch().then(({ data }) => {
        if (!data?.length) return;
        downloadJSONFile(data, name);
      });
    },
    [refetch]
  );

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const jsonData = await parseDataFromJSON(e);
    onChange(order, "hashlist", jsonData?.arr);
    onChange(order, "size", jsonData?.arr?.length);
    onChange(order, "hashlistTitle", jsonData?.fileName);
  };

  return (
    <Stack direction={{ sm: "row" }} alignItems="center" gap={1.5}>
      <Alert
        variant="outlined"
        severity={data?.hashlistTitle ? "success" : "warning"}
        action={
          data?.hashlistTitle && !data?.hashlist ? (
            <Tooltip title="Download hashlist">
              <IconButton
                size="small"
                disabled={isFetching}
                onClick={() => {
                  handleFetchPosts(data?.hashlistTitle || "");
                }}
              >
                {isFetching ? (
                  <CircularProgress
                    sx={{ width: "18px !important", height: "18px !important" }}
                  />
                ) : (
                  <DownloadingRoundedIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              color="inherit"
              size="small"
              disabled={disabled}
              onClick={() => uploadInputRef.current?.click()}
            >
              {data?.hashlistTitle ? "Change" : "Upload"}
              <input
                ref={uploadInputRef}
                type="file"
                accept=".json"
                style={{ display: "none" }}
                onChange={onFileSelected}
              />
            </Button>
          )
        }
        sx={{ width: "100%" }}
      >
        <AlertTitle>
          {data?.hashlistTitle ? data?.hashlistTitle : "Upload hashlist"}
        </AlertTitle>
        <Typography>
          {data?.hashlistTitle ? (
            <>
              <strong>{data?.size}</strong>{" "}
              {`${type === "assets" ? "NFT" : "wallet"}${
                data?.size && data?.size > 1 ? "s" : ""
              }`}{" "}
              {data?.hashlistManagerId ? "" : "DETECTED"}
            </>
          ) : (
            "Please upload a hashlist json file"
          )}
        </Typography>
      </Alert>
      {type === "assets" && (
        <TextField
          type="number"
          label="Number of owned NFTs"
          value={data?.minToClaim}
          helperText="The number of collectibles the user must own"
          onChange={(e) =>
            onChange(
              order,
              "minToClaim",
              e.currentTarget.value ? parseInt(e.currentTarget.value) : ""
            )
          }
          margin="dense"
          required
          fullWidth
          disabled={disabled}
        />
      )}
    </Stack>
  );
};

export default EligibleHashlist;
