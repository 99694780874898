import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { AllTokensResponse } from "../../../../types";
import { API_PATHS } from "../../../../constants";

export const useGetAllTokens = () => {
  const { selectedCommunityDocId } = useApp();
  const { enqueueSnackbar } = useSnackbar();

  const useGetAllTokens = async () => {
    const { data } = await axios.post<AllTokensResponse>(
      API_PATHS.tokens.list,
      {
        communityId: selectedCommunityDocId,
      }
    );
    if (!data?.success) {
      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    }
    return data.data;
  };

  return useQuery(
    [API_PATHS.tokens.list, selectedCommunityDocId],
    useGetAllTokens,
    {
      staleTime: 30 * 60 * 1000,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};
