import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import Box from "@mui/material/Box";
import { EllipsisTypography } from "../../../components/StyledComponents";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LoopRoundedIcon from "@mui/icons-material/LoopRounded";
import Typography from "@mui/material/Typography";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";
import Divider from "@mui/material/Divider";

const MenuSelectedCommunity = ({ selectedCommunity, handleSwitch }: any) => {
  return (
    <>
      <Box textAlign="center">
        <Divider>
          <Stack justifyContent="center">
            <HandshakeRoundedIcon />
          </Stack>
        </Divider>
      </Box>
      <Stack direction="row" alignItems="center" mt={1.5} mb={0.5} mx={1}>
        <Avatar
          alt={selectedCommunity?.VaultName}
          src={selectedCommunity?.ImageURL}
          variant="rounded"
          sx={{ height: 30, width: 30, borderRadius: "3px" }}
        >
          <InsertPhotoRoundedIcon />
        </Avatar>
        <Box minWidth={0} ml={1} mr={1}>
          <EllipsisTypography
            lineHeight={1}
            fontWeight={700}
            letterSpacing="0.02em"
          >
            {selectedCommunity?.VaultName}
          </EllipsisTypography>
          <Typography
            color={selectedCommunity?.IsLive ? "primary" : "error"}
            fontSize={14}
            mt={0.25}
            lineHeight={1}
            letterSpacing="0.02em"
            fontWeight={600}
          >
            {selectedCommunity?.IsLive ? "Live" : "Not Live"}
          </Typography>
        </Box>
        <Tooltip title="Switch community">
          <IconButton size="small" sx={{ ml: "auto" }} onClick={handleSwitch}>
            <LoopRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
};

export default MenuSelectedCommunity;
