import { RewardInfo } from "../../types";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinkBehavior from "../../components/LinkBehavior";

type Props = { reward: RewardInfo };

const RewardsItem = ({ reward }: Props) => {
  return (
    <ListItem color="background.default" disablePadding>
      <ListItemButton
        LinkComponent={LinkBehavior}
        href={reward?._id}
        sx={{ display: "block" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1.5}
        >
          <ListItemText
            primary={reward?.profile?.title}
            secondary={reward?.profile?.description}
          />
          <Stack alignItems="center" spacing={0.5} width={48} flexShrink={0}>
            <Typography
              color={
                reward?.status === "draft"
                  ? "default"
                  : reward?.status === "live"
                  ? "secondary"
                  : "error"
              }
              variant="overline"
              lineHeight={1}
              fontWeight={900}
            >
              {reward?.status}
            </Typography>
            {reward?.rewardConfig && (
              <Typography
                fontWeight={700}
                lineHeight={1}
                sx={{ whiteSpace: "nowrap", fontSize: 10 }}
                variant={
                  reward?.claimedCount === reward?.rewardConfig?.size
                    ? "gradient"
                    : "body1"
                }
              >
                {reward?.claimedCount === reward?.rewardConfig?.size
                  ? "Sold out"
                  : `${reward?.claimedCount} / ${reward?.totalAvailableReward}`}
              </Typography>
            )}
          </Stack>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default RewardsItem;
