import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

type Props = { label: string; value: string };

const TextFieldWithCopy = ({ label, value }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    return "clipboard" in navigator
      ? await navigator.clipboard.writeText(text)
      : document.execCommand("copy", true, text);
  }
  const handleCopyClick = () => {
    copyTextToClipboard(value)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel>{label}</InputLabel>
      <FilledInput
        type="text"
        fullWidth
        readOnly
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip
              title={isCopied ? "Copied!" : "Copy to clypboard"}
              placement="top"
            >
              <IconButton size="small" onClick={handleCopyClick}>
                <ContentCopyRoundedIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
export default TextFieldWithCopy;
