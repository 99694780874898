import Stack from "@mui/material/Stack";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import Loader from "../../components/Loader/Loader";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { useApp } from "../../services/AppService";
import Divider from "@mui/material/Divider";
import ConfirmDialog from "../../components/ConfirmDialog";
import useGetBoosterDetails from "../../hooks/queries/boosters/state/useGetBoosterDetails";
import Info from "./tabs/Info";
import Configuration from "./tabs/Configuration";
import FeeAddon from "../../components/SubscriptionContract/FeeAddon";
import useUpdateBoosterFeeAddon from "../../hooks/queries/boosters/mutations/useUpdateBoosterFeeAddon";
import Integrations from "../../components/SubscriptionContract/Integrations";
import useUpdateBoosterDiscordRole from "../../hooks/queries/boosters/mutations/useUpdateBoosterDiscordRole";
import UniversalCollection from "./tabs/UniversalCollection";
import Tooltip from "@mui/material/Tooltip";
import useSetContractStatus from "../../hooks/queries/boosters/mutations/useSetContractStatus";
import useArchiveContract from "../../hooks/queries/boosters/mutations/useArchiveContract";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Settings from "./tabs/Settings";
import { LiveDot } from "../../components/StyledComponents";

const SubscriptionMultiplierContract = () => {
  const { data: contractDetails, isLoading: contractDetailsLoading } =
    useGetBoosterDetails();
  const { mutateAsync: setStatus, isLoading: setStatusLoading } =
    useSetContractStatus({
      onSettled: () => {
        setConfirmationData(null);
      },
    });
  const { mutateAsync: archive, isLoading: archiveLoading } =
    useArchiveContract({
      onSettled: () => {
        setConfirmationData(null);
      },
    });
  const { selectedCommunity } = useApp();

  const [confirmationData, setConfirmationData] = useState<null | {
    type: "archive" | "status";
    status: boolean;
  }>(null);

  const [activeTab, setActiveTab] = useState("info");

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Multiplier Contracts",
            path: "/subscription/multiplier-contracts",
          },
          {
            label: contractDetails?.PlanTitle,
          },
        ]}
      />
      {contractDetailsLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : (
        <>
          <Box>
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
              {contractDetails?.Archived ? (
                <Tooltip placement="top" title="Archived">
                  <Inventory2RoundedIcon color="disabled" fontSize="small" />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="top"
                  title={contractDetails?.Active ? "Active" : "Inactive"}
                >
                  <LiveDot
                    color={contractDetails?.Active ? "success" : "default"}
                  />
                </Tooltip>
              )}
              <Typography variant="h6" fontWeight={700}>
                {contractDetails?.PlanTitle}
              </Typography>
            </Stack>
            <Divider sx={{ mt: 2 }} />
          </Box>
          <Tabs
            value={activeTab}
            variant="scrollable"
            onChange={(e, newValue) => {
              setActiveTab(newValue);
            }}
          >
            {TABS.map((label) => (
              <Tab label={label} value={label.toLowerCase()} key={label} />
            ))}
          </Tabs>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ display: activeTab === "info" ? "block" : "none" }}>
            <Info />
          </Box>

          <Box
            sx={{ display: activeTab === "configuration" ? "block" : "none" }}
          >
            <Configuration />
          </Box>
          <Box
            sx={{ display: activeTab === "integrations" ? "block" : "none" }}
          >
            {selectedCommunity?.DiscordData ? (
              <Integrations
                roleId={contractDetails?.HolderVerificationRoleId}
                updateRoleHook={useUpdateBoosterDiscordRole}
              />
            ) : (
              <Alert severity="info" variant="filled">
                You haven't connected your Discord server in Social
                Integrations. If you'd like to give specific roles for staking
                please do so before proceeding
              </Alert>
            )}
          </Box>
          <Box sx={{ display: activeTab === "collections" ? "block" : "none" }}>
            <UniversalCollection
              hashlistType="hashlist"
              list={contractDetails?.boostersCollections}
              title="Multiplier Hashlist"
              instructions="All the NFTs that are in these hashlists will be considered Multiplier
          NFTs."
              noCollectionsMessage="No sub-collections have been uploaded yet. Currently, this contract
          does not support any Multiplier NFTs."
            />
          </Box>
          <Box
            sx={{
              display:
                activeTab === "compatible collections" ? "block" : "none",
            }}
          >
            <UniversalCollection
              hashlistType="eligibleHashlist"
              list={contractDetails?.boostableCollections}
              title="Supported OGs"
              instructions={
                <>
                  You can control what NFTs are eligible to be boosted.
                  <br />
                  Please upload the Hashlist of the NFTs your want this
                  multiplier to have an effect on.
                  <br />
                  Only the NFTs in these hashlists will be able to use a staked
                  multiplier.
                </>
              }
              noCollectionsMessage="No sub-collections have been uploaded yes. Currenly, this contract
              does not support any NFT."
              isTargetCollection
            />
          </Box>
          <Box sx={{ display: activeTab === "fee addon" ? "block" : "none" }}>
            <FeeAddon
              addonDetails={contractDetails?.feeAddon}
              updateDetailsHook={useUpdateBoosterFeeAddon}
            />
          </Box>
          <Box sx={{ display: activeTab === "settings" ? "block" : "none" }}>
            <Settings />
          </Box>
        </>
      )}
      <ConfirmDialog
        data={confirmationData}
        title="Are you sure?"
        content={
          confirmationData?.type === "archive" ? (
            <Alert severity="error" variant="filled">
              <AlertTitle>Be careful!</AlertTitle>
              <Typography>
                This action will archive the contract and set its status to{" "}
                <strong>inactive</strong>. This action cannot be undone.
              </Typography>
            </Alert>
          ) : (
            <>
              Do you want set contract status to{" "}
              <strong>
                {confirmationData?.status ? "active" : "inactive"}
              </strong>
              ?
            </>
          )
        }
        isLoading={setStatusLoading || archiveLoading}
        hide={() => {
          setConfirmationData(null);
        }}
        confirm={(data) => {
          if (!confirmationData) return;
          if (confirmationData?.type === "status") {
            setStatus(data.status);
          }

          if (confirmationData.type === "archive") {
            archive();
          }
        }}
      />
    </>
  );
};

export default SubscriptionMultiplierContract;

const TABS = [
  "Info",
  "Configuration",
  "Collections",
  "Compatible collections",
  "Integrations",
  "Fee addon",
  "Settings",
];
