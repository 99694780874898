import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {
  SubscriptionBoosterAttributeMap,
  SubscriptionBoosterAttributeCountMap,
} from "../../../../types";
import FormControlLabel from "@mui/material/FormControlLabel";

type AttributesSetupProps = {
  name: string;
  attributeMap: SubscriptionBoosterAttributeMap;
  attributeCountMap: SubscriptionBoosterAttributeCountMap;
  handleCustomChanged: (key: string, trait: string, status: boolean) => void;
};

const AttributesSetup: React.FC<AttributesSetupProps> = ({
  name,
  attributeMap,
  attributeCountMap,
  handleCustomChanged,
}) => {
  const traits = Object.keys(attributeMap[name])
    .sort((a, b) => a.localeCompare(b))
    .map((trait, index) => (
      <Fragment key={trait}>
        <Divider sx={{ my: 1 }}>
          {index === 0 && (
            <Typography
              variant="overline"
              lineHeight={1}
              color="text.secondary"
            >
              Values
            </Typography>
          )}
        </Divider>
        <Box>
          <FormControlLabel
            label={
              <>
                {trait} ({attributeMap[name]?.[trait]?.count} |{" "}
                {parseFloat(
                  (
                    (attributeMap?.[name]?.[trait]?.count /
                      attributeCountMap?.[name]?.amount) *
                    100
                  ).toFixed(1)
                )}
                %)
              </>
            }
            control={
              <Checkbox
                checked={attributeMap[name][trait].supported}
                onChange={(e) =>
                  handleCustomChanged(name, trait, e.target.checked)
                }
              />
            }
          />
        </Box>
      </Fragment>
    ));

  return (
    <Box>
      <Box
        sx={{
          minWidth: 200,
          width: "100%",
          border: "1px solid",
          borderColor: "divider",
          p: 1,
          borderRadius: "3px",
        }}
      >
        <Typography fontWeight={700} textAlign="center">
          {name}
        </Typography>
        {traits}
      </Box>
    </Box>
  );
};

export default AttributesSetup;
