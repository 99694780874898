import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import {
  RuleListItem,
  RuleTree,
  UpdateRuleTreePayload,
} from "../../../../types";
import { API_PATHS } from "../../../../constants";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";
import { useApp } from "../../../../services/AppService";

type Props = {
  onSettled: () => void;
};

const updateRuleTree = async (data: UpdateRuleTreePayload) => {
  return await axios.post<any>(API_PATHS.rules.tree.updateProfile, { data });
};

const useUpdateRuleTree = ({ onSettled }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { selectedCommunityDocId } = useApp();

  return useMutation(
    async (payload: UpdateRuleTreePayload) => {
      const { data } = await updateRuleTree(payload);

      if (isSuccessResponse(data?.data)) {
        queryClient.setQueryData(
          [API_PATHS.rules.byId, payload?.treeId],
          (currentState: RuleTree | undefined) => {
            if (typeof currentState === "undefined") {
              return currentState;
            }
            return {
              ...currentState,
              name: payload?.name,
              description: payload?.description,
            };
          }
        );
        queryClient.setQueryData(
          [API_PATHS.rules.list, selectedCommunityDocId],
          (currentState: RuleListItem[] | undefined) => {
            if (typeof currentState === "undefined") {
              return currentState;
            }
            return currentState.map((item) => {
              if (item._id === payload?.treeId) {
                return {
                  ...item,
                  name: payload?.name,
                  description: payload?.description,
                };
              }
              return item;
            });
          }
        );
        return data.data;
      }

      throw new Error(
        `${data.data?.error?.title}\n${data.data?.error?.description}`
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Rule successfully updated",
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
};

export default useUpdateRuleTree;
