import Typography from "@mui/material/Typography";
import { Rule } from "../../../types";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DownloadingRoundedIcon from "@mui/icons-material/DownloadingRounded";
import { useGetHashlist } from "../../../hooks/queries/hashlist/useGetHashlist";
import { downloadJSONFile } from "../../../utils";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { useGetContracts } from "../../../hooks/queries/subscriptions/state/useGetContracts";

type Props = { rule: Rule };

const RulesTreeItemLabel = ({ rule }: Props) => {
  const { data: contracts } = useGetContracts();
  const { refetch, isFetching } = useGetHashlist(
    rule?.ruleType === "holder" ? rule?.hashlistManagerId : ""
  );
  const handleFetchPosts = async (name: string) => {
    refetch().then(({ data }) => {
      if (!data?.length) return;
      downloadJSONFile(data, name);
    });
  };

  switch (rule?.ruleType) {
    case "holder":
      return (
        <Typography component="div">
          Check if user has a{" "}
          <Typography
            fontWeight="bold"
            component="span"
            variant="h6"
            color="primary"
          >
            {rule?.minimumHolding}
          </Typography>{" "}
          NFT
          {rule?.minimumHolding > 1 ? "s" : ""} in hashlist{" "}
          <Stack
            direction="row"
            display="inline-flex"
            alignItems="center"
            spacing={0.25}
          >
            <Chip
              label={
                <>
                  {rule?.hashlistTitle}{" "}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    (<strong>{rule?.hashlistSize}</strong> NFT
                    {rule?.hashlistSize > 1 ? "s" : ""})
                  </Typography>
                </>
              }
              size="small"
              variant="outlined"
              sx={{
                fontWeight: 700,
                height: "auto",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                },
              }}
            />{" "}
            <Tooltip title="Download hashlist">
              <IconButton
                size="small"
                disabled={isFetching}
                onClick={() => {
                  handleFetchPosts(rule?.hashlistTitle);
                }}
              >
                {isFetching ? (
                  <CircularProgress
                    sx={{ width: "18px !important", height: "18px !important" }}
                  />
                ) : (
                  <DownloadingRoundedIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </Typography>
      );
    case "subscriber":
      return (
        <Typography component="div">
          Check if user subscribed{" "}
          <Typography
            fontWeight="bold"
            component="span"
            variant="h6"
            color="primary"
          >
            {rule?.minimumHolding}
          </Typography>{" "}
          NFT
          {rule?.minimumHolding > 1 ? "s" : ""} for a plan{" "}
          <Chip
            label={
              contracts?.find((p) => p?.PlanId === rule?.subscriptionId)
                ?.PlanTitle
            }
            size="small"
            variant="outlined"
            sx={{ fontWeight: 700 }}
          />
        </Typography>
      );
    case "phygital":
      return (
        <Box>
          <Typography component="div">
            Check if user has a{" "}
            <Typography
              fontWeight="bold"
              component="span"
              variant="h6"
              color="primary"
            >
              {rule?.minimumHolding}
            </Typography>{" "}
            NFT
            {rule?.minimumHolding > 1 ? "s" : ""} from phygital product:
          </Typography>
          <Stack
            direction="row"
            alignItems="flex-start"
            width="100%"
            spacing={1}
            mt={0.5}
          >
            <Paper
              sx={{
                width: 40,
                flexShrink: 0,
                borderRadius: "3px",
                overflow: "hidden",
              }}
            >
              <img src={rule?.phygitalProfile?.bannerImage} alt="Asset" />
            </Paper>
            <Box lineHeight={1} whiteSpace="normal">
              <Typography fontWeight={700}>
                {rule?.phygitalProfile?.title}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {rule?.phygitalProfile?.description}
              </Typography>
            </Box>
          </Stack>
        </Box>
      );
    case "token-holder":
      return (
        <Box>
          <Typography component="div">
            Check if user has{" "}
            <Typography
              fontWeight="bold"
              component="span"
              variant="h6"
              color="primary"
            >
              {rule?.minimumHolding}
            </Typography>{" "}
            token
            {rule?.minimumHolding > 1 ? "s" : ""} in their wallet:
          </Typography>
          <Stack
            direction="row"
            alignItems="flex-start"
            width="100%"
            spacing={1}
            mt={0.5}
          >
            <Paper
              sx={{
                width: 40,
                flexShrink: 0,
                borderRadius: "3px",
                overflow: "hidden",
              }}
            >
              <img src={rule?.tokenProfile?.uri} alt="Asset" />
            </Paper>
            <Box lineHeight={1} whiteSpace="normal">
              <Typography fontWeight={700}>
                {rule?.tokenProfile?.name}
              </Typography>
              <Typography color="text.secondary" variant="caption">
                {rule?.tokenProfile?.address}
              </Typography>
            </Box>
          </Stack>
        </Box>
      );
    case "discord-role":
      return (
        <Typography component="div">
          Check if user has a role{" "}
          <Chip
            label={rule?.roleName}
            size="small"
            variant="outlined"
            sx={{ fontWeight: 700 }}
          />
        </Typography>
      );
    case "minimum-unique-subscribers":
      return (
        <Typography component="div">
          Check if the contract is subscribed minimum{" "}
          <Typography
            fontWeight="bold"
            component="span"
            variant="h6"
            color="primary"
          >
            {rule?.minimum}
          </Typography>{" "}
          unique wallet
          {rule?.minimum > 1 ? "s" : ""}
        </Typography>
      );
  }
};

export default RulesTreeItemLabel;
