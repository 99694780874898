import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { API_PATHS } from "../../../../constants";
import {
  ServerResponse,
  SubscriptionBoosterAttributeMap,
  SubscriptionBoosterAttributeCountMap,
} from "../../../../types";
import { useParams } from "react-router-dom";
import { isSuccessResponse } from "../../../../utils";

const setContractAttributes = async (data: {
  communityId: string;
  planId: string;
  filterOption: "all" | "attribute";
  attributeMap?: SubscriptionBoosterAttributeMap;
  attributeCountMap?: SubscriptionBoosterAttributeCountMap;
}) => {
  return await axios.post<ServerResponse<any>["data"]>(
    API_PATHS.booster.update.setAttributes,
    data
  );
};

export function useSetContractAttributes() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const { planId } = useParams();

  return useMutation(
    async (values: {
      filterOption: "all" | "attribute";
      attributeMap?: SubscriptionBoosterAttributeMap;
      attributeCountMap?: SubscriptionBoosterAttributeCountMap;
    }) => {
      if (!selectedCommunityDocId || !planId) return;
      const { data } = await setContractAttributes({
        communityId: selectedCommunityDocId,
        planId,
        ...values,
      });

      if (isSuccessResponse(data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.booster.byId, selectedCommunityDocId, planId],
          exact: true,
          refetchType: "active",
        });
        return data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },

    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Boostables configurator successfully updated!",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Something went wrong. Try again!",
        });
      },
    }
  );
}
