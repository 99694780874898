import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { FeeAddon } from "../../types";
import { UseMutationResult } from "@tanstack/react-query";

type Props = {
  addonDetails?: FeeAddon;
  updateDetailsHook: () => UseMutationResult<any, any, FeeAddon, unknown>;
};

const FeeAddonConfiguration = ({ addonDetails, updateDetailsHook }: Props) => {
  const { mutateAsync: updateFeeAddon, isLoading: updateFeeAddonLoading } =
    updateDetailsHook();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm<FeeAddon>({
    defaultValues: {
      active: false,
    },
  });

  const isActive = watch("active");

  useEffect(() => {
    reset(
      addonDetails
        ? addonDetails
        : { active: false, walletAddress: "", feeSol: undefined }
    );
  }, [addonDetails, reset]);

  const onSubmit = async (data: FeeAddon) => {
    updateFeeAddon(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography color="text.secondary" gutterBottom>
        Add an on-top additional fee for users subscribing to this plan.
      </Typography>
      <Box>
        <FormControl disabled={updateFeeAddonLoading}>
          <FormControlLabel
            control={
              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value as unknown as boolean}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Activate fee addon"
          />
        </FormControl>
      </Box>
      {isActive && (
        <>
          <TextField
            {...register("walletAddress")}
            error={!!errors.walletAddress}
            helperText={!!errors.walletAddress && errors.walletAddress.message}
            required={isActive}
            fullWidth
            label="Wallet address for fees"
            margin="dense"
            disabled={!isActive || updateFeeAddonLoading}
          />
          <TextField
            {...register("feeSol", {
              valueAsNumber: true,
              min: {
                value: 0.00000001,
                message: "Minimum is 0.00000001",
              },
            })}
            inputProps={{ step: "any" }}
            error={!!errors.feeSol}
            helperText={!!errors.feeSol && errors.feeSol.message}
            type="number"
            required={isActive}
            fullWidth
            label="$SOL to be charger per NFT subscribed"
            margin="dense"
            disabled={!isActive || updateFeeAddonLoading}
          />
        </>
      )}
      <LoadingButton
        variant="contained"
        type="submit"
        loading={updateFeeAddonLoading}
        sx={{ mt: 2 }}
      >
        Save
      </LoadingButton>
    </form>
  );
};

export default FeeAddonConfiguration;
