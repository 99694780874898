import { ClaimingTokensProps } from "./types";
import List from "@mui/material/List";
import { Fragment } from "react";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const LIMIT = 2;

const ClaimingTokens = ({
  list,
  onRemove,
  onAdd,
  onChange,
  disabled,
}: ClaimingTokensProps) => {
  return (
    <>
      <Typography variant="h6" fontWeight={700}>
        Payment tokens
      </Typography>
      <List disablePadding>
        {list.map((i, index) => (
          <Fragment key={index}>
            {index !== 0 && <Divider component="li" sx={{ my: 1 }} />}
            <ListItem
              disableGutters
              secondaryAction={
                i?.type === "sol" ? null : (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    color="error"
                    onClick={() => {
                      onRemove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )
              }
              sx={{ py: 0.25 }}
            >
              <ListItemText>
                <Stack
                  direction={{
                    xs: i?.type === "sol" ? "row" : "column",
                    sm: "row",
                  }}
                  alignItems="center"
                  columnGap={1.5}
                  rowGap={1}
                >
                  <TextField
                    type="number"
                    label="Amount"
                    value={i?.amount}
                    disabled={disabled}
                    onChange={(e) => {
                      onChange(
                        index,
                        "amount",
                        e.currentTarget.value
                          ? parseFloat(e.currentTarget.value)
                          : ""
                      );
                    }}
                    margin="none"
                    required={i?.type === "splToken"}
                    fullWidth
                  />
                  {i?.type === "sol" ? (
                    <Typography variant="subtitle1" fontWeight={500}>
                      $SOL
                    </Typography>
                  ) : (
                    <TextField
                      label="Token address"
                      value={i?.tokenAddress}
                      disabled={disabled}
                      onChange={(e) => {
                        onChange(index, "tokenAddress", e.currentTarget.value);
                      }}
                      margin="none"
                      required={i?.type === "splToken"}
                      fullWidth
                    />
                  )}
                </Stack>
              </ListItemText>
            </ListItem>
          </Fragment>
        ))}
      </List>
      {list?.length < LIMIT && (
        <Button
          startIcon={<DataSaverOnRoundedIcon />}
          onClick={onAdd}
          variant="base"
          sx={{ mt: 1 }}
          disabled={disabled}
        >
          Add custom token
        </Button>
      )}
    </>
  );
};

export default ClaimingTokens;
