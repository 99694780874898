import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Header from "../layouts/base/Header/Header";
import MenuDrawer from "../layouts/base/MenuDrawer/MenuDrawer";
import {
  DrawerHeader,
  StyledMain,
  drawerWidth,
} from "../components/StyledComponents";
import Footer from "../layouts/base/Footer";

const Layout = () => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header />
      <MenuDrawer />
      <StyledMain
        component="main"
        sx={{
          width: {
            xs: "100%",
            md: `calc(100% - ${drawerWidth}px)`,
          },
        }}
      >
        <DrawerHeader sx={{ display: { md: "none" } }} />
        <Box py={1.5} px={{ xs: 1.5, md: 3, height: "100%" }}>
          <Outlet />
        </Box>
        <Footer />
      </StyledMain>
    </Box>
  );
};

export default Layout;
