import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import Chip from "@mui/material/Chip";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import { capitalize } from "@mui/material/utils";
import CardActionArea from "@mui/material/CardActionArea";
import Paper from "@mui/material/Paper";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useState } from "react";
import TokensDelegationAddCommunityModal from "./TokensDelegationAddCommunityModal";
import { useGetCommunitiesByCompaign } from "../../hooks/queries/token-campaign/state/useGetCommunitiesByCompaign";
import Loader from "../../components/Loader/Loader";
import Tooltip from "@mui/material/Tooltip";
import useRemoveCommunity from "../../hooks/queries/token-campaign/mutations/useRemoveCommunity";
import ConfirmDialog from "../../components/ConfirmDialog";
import { CommunityByCampaign } from "../../types";

const TokensDelegationTargetCommunities = () => {
  const { data: communities, isLoading: communitiesLoading } =
    useGetCommunitiesByCompaign();
  const { mutateAsync: removeCommunity, isLoading: removeCommunityLoading } =
    useRemoveCommunity();
  const [communityForRemove, setCommunityForRemove] =
    useState<CommunityByCampaign>();
  const [addCommunityModalOpen, setAddCommunityModalOpen] = useState(false);

  const handleRemove = async (community: CommunityByCampaign) => {
    try {
      await removeCommunity(community?.contractId);
    } catch (error) {
    } finally {
      setCommunityForRemove(undefined);
    }
  };

  if (communitiesLoading) {
    <Stack justifyContent="center" alignItems="center" p={3} height="100%">
      <Loader />
    </Stack>;
  }

  return (
    <Grid container spacing={1} justifyContent="center">
      {communities?.map((community) => (
        <Grid item xs={12} sm={6} key={community?.communityId}>
          <Card sx={{ height: "100%" }}>
            <CardHeader
              avatar={
                <Avatar
                  src={community?.image}
                  alt={community?.name}
                  variant="rounded"
                  sx={{ width: 48, height: 48 }}
                >
                  <InsertPhotoRoundedIcon />
                </Avatar>
              }
              action={
                <>
                  <IconButton
                    size="small"
                    color="primary"
                    LinkComponent="a"
                    href={`https://anybodies.com/c/${community?.communityRoute}`}
                    target="_blank"
                  >
                    <OpenInNewRoundedIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                  {community?.status === "pending" && (
                    <Tooltip title="Remove and defund" placement="top">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => setCommunityForRemove(community)}
                      >
                        <CloseRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              }
              title={
                <Typography fontWeight={700}>{community?.name}</Typography>
              }
              subheader={
                <Chip
                  size="small"
                  label={capitalize(community?.status)}
                  color={
                    community?.status === "live"
                      ? "success"
                      : community?.status === "pending"
                      ? "warning"
                      : "default"
                  }
                  sx={{ fontWeight: 700 }}
                />
              }
            />
            <CardContent sx={{ pt: 0 }}>
              <Typography variant="body2">
                ID: <strong>{community?.communityId}</strong>
                {community?.status === "live" && (
                  <>
                    <br />
                    Current phase:{" "}
                    <strong>{community?.currentPhase + 1}</strong>
                    <br />
                    Current phase end date:{" "}
                    <strong>
                      {new Date(
                        community?.currentPhaseEndDate
                      )?.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </strong>
                  </>
                )}
                {community?.status === "ended" && community?.ended && (
                  <>
                    <br />
                    End date:{" "}
                    <strong>
                      {new Date(community?.ended)?.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </strong>
                    <br />
                    On phase: <strong>{community?.currentPhase + 1}</strong>
                    <br />
                    Balance returned to pool:{" "}
                    <strong>{community?.returnBackToPool}</strong>
                  </>
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <Paper sx={{ height: "100%" }}>
          <CardActionArea
            sx={{ height: "100%" }}
            onClick={() => setAddCommunityModalOpen(true)}
          >
            <Stack alignItems="center" spacing={1} p={2}>
              <DataSaverOnRoundedIcon sx={{ fontSize: 48 }} />
              <Typography fontWeight={900}>Add community</Typography>
            </Stack>
          </CardActionArea>
        </Paper>
      </Grid>
      <TokensDelegationAddCommunityModal
        open={addCommunityModalOpen}
        hide={() => {
          setAddCommunityModalOpen(false);
        }}
      />
      <ConfirmDialog
        data={communityForRemove}
        title="Are you sure?"
        content={
          <>
            Do you want remove <strong>{communityForRemove?.name}</strong>?
          </>
        }
        isLoading={removeCommunityLoading}
        hide={() => {
          setCommunityForRemove(undefined);
        }}
        confirm={(data) => {
          if (!communityForRemove) return;
          handleRemove(communityForRemove);
        }}
      />
    </Grid>
  );
};

export default TokensDelegationTargetCommunities;
