import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useApp } from "../../../../services/AppService";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { CreateNewRuleTreePayload } from "../../../../types";
import { isSuccessResponse } from "../../../../utils";

type Props = {
  onSettled: () => void;
};

const createNewRuleTree = async (data: CreateNewRuleTreePayload) => {
  return await axios.post<any>(API_PATHS.rules.create, { data });
};

const useAddNewRuleTree = ({ onSettled }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: Omit<CreateNewRuleTreePayload, "communityId">) => {
      if (!selectedCommunityDocId) return;
      const { data } = await createNewRuleTree({
        communityId: selectedCommunityDocId,
        ...payload,
      });

      if (isSuccessResponse(data?.data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.rules.list, selectedCommunityDocId],
          exact: true,
          refetchType: "active",
        });
        return data.data;
      }

      throw new Error(
        `${data.data?.error?.title}\n${data.data?.error?.description}`
      );
    },
    {
      onSuccess: (data) => {
        enqueueSnackbar({
          variant: "success",
          message: `New rule "${data?.data?.name}" created`,
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
};

export default useAddNewRuleTree;
