import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { API_PATHS } from "../../../constants";
import { Manifest, TokensMetadataResponse } from "../../../types";

const getTokensMetadata = async (data: string[]) => {
  return await axios.post<TokensMetadataResponse | any>(
    API_PATHS.solana.getTokensMetadata,
    {
      data,
    }
  );
};

export function useGetTokensMetadata() {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (arr: string[]) => {
      const { data } = await getTokensMetadata(arr);
      if (!data?.data?.success) {
        throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
      }
      return data?.data?.metadataTokens as Manifest[];
    },

    {
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Try again!",
        });
      },
    }
  );
}
