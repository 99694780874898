import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { BoosterDetails, ServerResponse } from "../../../../types";
import { API_PATHS } from "../../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { isSuccessResponse } from "../../../../utils";

const getDetails = async (payload: { planId: string; communityId: string }) => {
  const { data } = await axios.post<ServerResponse<BoosterDetails>["data"]>(
    API_PATHS.booster.byId,
    payload
  );
  return data;
};

const useGetBoosterDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();
  const navigate = useNavigate();
  const { planId } = useParams();

  return useQuery(
    [API_PATHS.booster.byId, selectedCommunityDocId, planId],
    async () => {
      if (!planId || !selectedCommunityDocId) return;
      const data = await getDetails({
        communityId: selectedCommunityDocId,
        planId,
      });
      if (isSuccessResponse(data)) {
        return data?.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      staleTime: 30 * 60 * 1000,
      retry: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        navigate("/subscription/multiplier-contracts");
      },
    }
  );
};

export default useGetBoosterDetails;
