import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Loader from "../../components/Loader/Loader";
import Empty from "../../components/Empty";
import { useGetAllTokens } from "../../hooks/queries/tokens/state/useGetAllTokens";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { EllipsisTypography } from "../../components/StyledComponents";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Link from "@mui/material/Link";
import ConnectTokenModal from "./ConnectTokenModal";
import { useState } from "react";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import TokenFundModal from "./TokenFundModal";
import TokenDefundModal from "./TokenDefundModal";
import { Token } from "../../types";

const Tokens = () => {
  const { data: tokens, isLoading: tokensLoading } = useGetAllTokens();

  const [connectTokenModalOpen, setConnectTokenModalOpen] = useState(false);
  const [fundTokenModal, setFundTokenModal] = useState<null | Token>(null);
  const [defundTokenModal, setDefundTokenModal] = useState<null | Token>(null);

  const handleConnectTokenModalOpen = () => {
    setConnectTokenModalOpen(true);
  };

  const handleConnectTokenModalHide = () => {
    setConnectTokenModalOpen(false);
  };

  const handleFundTokenModalHide = () => {
    setFundTokenModal(null);
  };

  const handleDefundTokenModalHide = () => {
    setDefundTokenModal(null);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <Typography variant="h5" fontWeight={700} mb={1}>
          Tokens
        </Typography>
        <Button
          startIcon={<DataSaverOnRoundedIcon />}
          variant="base"
          onClick={handleConnectTokenModalOpen}
        >
          Connect token
        </Button>
      </Stack>
      {tokensLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : tokens?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
        >
          <Empty text="You don't have any token" />
        </Stack>
      ) : (
        <Grid container spacing={1.5}>
          {tokens
            ?.sort((a, b) => b.balance - a.balance)
            ?.map((token) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={token?.tokenId}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        alt={token?.TokenName}
                        src={token?.tokenMetadata?.uri}
                        variant="rounded"
                      >
                        <InsertPhotoRoundedIcon />
                      </Avatar>
                    }
                    title={
                      <Typography fontWeight={700}>
                        ${token?.tokenMetadata?.symbol}
                      </Typography>
                    }
                    subheader={
                      <Link
                        component="a"
                        href={`https://solscan.io/token/${token?.tokenMetadata?.address}`}
                        target="_blank"
                        color="text.secondary"
                        sx={{ textDecoration: "none" }}
                      >
                        <EllipsisTypography fontSize="0.675rem">
                          {token?.tokenMetadata?.address}
                        </EllipsisTypography>
                      </Link>
                    }
                    sx={{
                      pb: 0,
                      "& .MuiCardHeader-avatar": { mr: 1 },
                      "& .MuiCardHeader-content": { minWidth: 0 },
                    }}
                  />
                  <CardContent sx={{ p: 1 }}>
                    <Typography
                      variant="h5"
                      textAlign="center"
                      fontWeight={700}
                    >
                      {token?.balance < 0 ? 0 : token?.balance.toLocaleString()}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      size="small"
                      onClick={() => {
                        setFundTokenModal(token);
                      }}
                    >
                      Fund
                    </Button>
                    <Button
                      variant="text"
                      color="error"
                      fullWidth
                      size="small"
                      onClick={() => {
                        setDefundTokenModal(token);
                      }}
                    >
                      Defund
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
      <ConnectTokenModal
        open={connectTokenModalOpen}
        hide={handleConnectTokenModalHide}
      />
      <TokenFundModal token={fundTokenModal} hide={handleFundTokenModalHide} />
      <TokenDefundModal
        token={defundTokenModal}
        hide={handleDefundTokenModalHide}
      />
    </>
  );
};

export default Tokens;
