import { ThemeOptions, createTheme } from "@mui/material/styles";
import { typography } from "./typography";
import { palette } from "./palette";
import {
  MuiAccordion,
  MuiButton,
  MuiDrawer,
  MuiTab,
  MuiTooltip,
  MuiDialog,
  MuiTextField,
  MuiSkeleton,
} from "./components";
import { LinkProps } from "@mui/material/Link";
import LinkBehavior from "../../components/LinkBehavior";

export const baseThemeOptions: ThemeOptions = {
  palette,
  typography,
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButton,
    MuiTooltip,
    MuiDrawer,
    MuiTab,
    MuiAccordion,
    MuiDialog,
    MuiTextField,
    MuiSkeleton,
  },
};

export const MainTheme = createTheme(baseThemeOptions);
