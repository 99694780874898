import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { useWallet } from "@solana/wallet-adapter-react";
import { MutationDefaultResponse, TxRequestRes } from "../../../../types";
import { Transaction } from "@solana/web3.js";
import { API_PATHS } from "../../../../constants";

export function useFund({ onSuccess }: { onSuccess: () => void }) {
  const queryClient = useQueryClient();
  const { publicKey, signTransaction } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  const fund = async (tokenId: string, amount: number) => {
    const { data: txRequestData } = await axios.post<TxRequestRes>(
      API_PATHS.tokens.fund.request,
      {
        communityId: selectedCommunityDocId,
        tokenId,
        fromWalletAddress: publicKey?.toString(),
        amount,
      }
    );
    if (!txRequestData?.success) {
      throw new Error(
        `${txRequestData?.error?.title}\n${txRequestData?.error?.description}`
      );
    }

    const transaction = Transaction.from(
      txRequestData?.data?.rawTransaction?.data
    );
    if (!signTransaction) {
      throw new Error("Can't create transaction");
    }
    const signedTransaction = await signTransaction(transaction);
    const { data: txProccessData } = await axios.post<
      MutationDefaultResponse["data"]
    >(API_PATHS.tokens.fund.process, {
      communityId: selectedCommunityDocId,
      rawTransaction: Object.values(signedTransaction.serialize()),
      requestId: txRequestData?.data?.requestId,
    });
    if (!txProccessData?.success) {
      throw new Error(
        `${txProccessData?.error?.title}\n${txProccessData?.error?.description}`
      );
    }
    return txProccessData;
  };

  return useMutation(
    async ({ tokenId, amount }: { tokenId: string; amount: number }) => {
      const data = await fund(tokenId, amount);
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.tokens.list, selectedCommunityDocId],
        exact: true,
        refetchType: "active",
      });
      return data;
    },
    {
      onSuccess: () => {
        onSuccess();
        enqueueSnackbar({
          variant: "success",
          message: "Fund completed successfully",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign in. Try again!",
        });
      },
    }
  );
}
