import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import ModalTransition from "../../helpers/ModalTransition";
import { SearchCommunity } from "../../types";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { EllipsisTypography } from "../../components/StyledComponents";
import IconButton from "@mui/material/IconButton";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import TokensDelegationSearchCommunity from "./TokensDelegationSearchCommunity";
import { useEffect, useState } from "react";
import useAddCommunityToCampaign from "../../hooks/queries/token-campaign/mutations/useAddCommunityToCampaign";
import CloseIcon from "@mui/icons-material/Close";

export interface Props {
  open: boolean;
  hide: () => void;
}

const TokensDelegationAddCommunityModal = ({ open, hide }: Props) => {
  const { mutateAsync: addCommunity, isLoading: addCommunityLoading } =
    useAddCommunityToCampaign();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCommunity, setSelectedCommunity] = useState<SearchCommunity>();

  const handleAddCommunity = async () => {
    if (selectedCommunity) {
      await addCommunity(selectedCommunity);
      hide();
    }
  };

  useEffect(() => {
    if (!open) {
      setSelectedCommunity(undefined);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={addCommunityLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      scroll="paper"
      TransitionComponent={ModalTransition}
      sx={{ ".MuiPaper-root, .MuiDialogContent-root": { overflowY: "unset" } }}
    >
      <DialogTitle>Add community</DialogTitle>
      <DialogContent>
        <Alert variant="outlined" severity="warning" sx={{ mb: 2 }}>
          <AlertTitle>Be careful!</AlertTitle>
          <Typography>
            After confirming the addition of a community, the grant will be
            transferred
          </Typography>
        </Alert>
        <Box>
          {selectedCommunity ? (
            <>
              <Typography mb={1}>
                Are you sure you want to add this community?
              </Typography>
              <Stack direction="row" alignItems="center" gap={1} width="100%">
                <Avatar
                  src={selectedCommunity?.image}
                  alt={selectedCommunity?.name}
                  variant="rounded"
                  sx={{ width: 36, height: 36 }}
                >
                  <InsertPhotoRoundedIcon />
                </Avatar>
                <Box minWidth={0}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <EllipsisTypography lineHeight={1} fontWeight={700}>
                      {selectedCommunity?.name}
                    </EllipsisTypography>
                    <IconButton
                      size="small"
                      color="primary"
                      LinkComponent="a"
                      href={`https://anybodies.com/c/${selectedCommunity?.vaultRoute}`}
                      target="_blank"
                    >
                      <OpenInNewRoundedIcon sx={{ fontSize: 14 }} />
                    </IconButton>
                  </Stack>
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    component="div"
                    lineHeight={1}
                  >
                    {selectedCommunity?.communityId}
                  </Typography>
                </Box>
                <IconButton
                  disabled={addCommunityLoading}
                  onClick={() => {
                    setSelectedCommunity(undefined);
                  }}
                  sx={{ ml: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </>
          ) : (
            <TokensDelegationSearchCommunity
              targetCommunities={[]}
              onSelectCommunity={setSelectedCommunity}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="base"
          onClick={hide}
          disabled={addCommunityLoading}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={addCommunityLoading}
          variant="contained"
          disabled={typeof selectedCommunity === "undefined"}
          onClick={handleAddCommunity}
        >
          Add community
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TokensDelegationAddCommunityModal;
