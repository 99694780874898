import { QuestItem } from "../../types";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LinkBehavior from "../../components/LinkBehavior";

type Props = {
  quest: QuestItem;
  handleStatusChange: () => void;
};

const LoyaltyListItem = ({ quest, handleStatusChange }: Props) => {
  return (
    <ListItem color="background.default" disablePadding>
      <ListItemButton
        LinkComponent={LinkBehavior}
        href={`/loyalty/${quest?._id}`}
      >
        <ListItemText primary={quest?.title} secondary={quest?.description} />
      </ListItemButton>
      <Stack direction="row" alignItems="center" spacing={0.5} ml={2}>
        <Chip
          label={
            <>
              <strong>{quest?.hits}</strong> hits
            </>
          }
        />
        <Tooltip
          title={`${
            quest?.status === "draft" ? "Activate" : "Deactivate"
          } quest`}
          placement="top"
        >
          <Switch
            color="success"
            checked={quest?.status === "live"}
            onChange={handleStatusChange}
          />
        </Tooltip>
      </Stack>
    </ListItem>
  );
};

export default LoyaltyListItem;
