import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import {
  DrawerHeader,
  DrawerListItemButton,
  LiveDot,
  StyledDrawer,
} from "../../../components/StyledComponents";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useApp } from "../../../services/AppService";
import AltRouteRoundedIcon from "@mui/icons-material/AltRouteRounded";
import MenuSelectedCommunity from "./MenuSelectedCommunity";
import Box from "@mui/material/Box";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import LoyaltyRoundedIcon from "@mui/icons-material/LoyaltyRounded";
import BurstModeRoundedIcon from "@mui/icons-material/BurstModeRounded";
import ToysIcon from "@mui/icons-material/Toys";
import Menu from "./Menu";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import CardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import Button from "@mui/material/Button";
import LaunchCommunityDialog from "../../../components/LaunchCommunityDialog";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import VideoLibraryRoundedIcon from "@mui/icons-material/VideoLibraryRounded";
import WebRoundedIcon from "@mui/icons-material/WebRounded";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import RocketLaunchRoundedIcon from "@mui/icons-material/RocketLaunchRounded";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windowProp?: () => Window;
}

const MenuDrawer = (props: Props) => {
  const { windowProp } = props;
  const {
    logout,
    clearSelectedCommunity,
    selectedCommunity,
    mobileOpen,
    setMobileOpen,
  } = useApp();
  const [launchDialogOpen, setLaunchDialogOpen] = useState(false);

  const handleLaunchCommunity = () => {
    setLaunchDialogOpen(true);
  };

  const handleGoToCommunity = () => {
    window.open(
      `https://anybodies.com/c/${selectedCommunity?.VaultRoute}`,
      "_blank"
    );
  };

  const drawerContent = (
    <>
      <DrawerHeader />
      <MenuSelectedCommunity
        selectedCommunity={selectedCommunity}
        handleSwitch={clearSelectedCommunity}
      />
      <Box py={1.5}>
        <Menu list={MENU} />
        {/* <List sx={{ py: 0 }}>
          <ListItem disablePadding>
            <DrawerListItemButton
              href={`https://cms.anybodies.com?refresh_token=${localStorage.getItem(
                "refreshToken"
              )}`}
              target="_blank"
            >
              <ListItemIcon>
                <WebRoundedIcon />
              </ListItemIcon>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                spacing={0.5}
              >
                <Typography>CMS</Typography>
                <OpenInNewRoundedIcon fontSize="small" className="open-blank" />
              </Stack>
            </DrawerListItemButton>
          </ListItem>
        </List> */}
      </Box>
      <Box sx={{ mt: "auto" }}>
        <Divider />
        <List sx={{ pt: 1.5 }}>
          {selectedCommunity?.IsLive ? (
            <Button
              endIcon={<LaunchRoundedIcon />}
              fullWidth
              onClick={handleGoToCommunity}
            >
              Open my community page
            </Button>
          ) : (
            <ListItem disablePadding>
              <Button
                endIcon={<LiveDot sx={{ ml: 0.5 }} />}
                variant="gradient"
                fullWidth
                onClick={handleLaunchCommunity}
              >
                Launch community
              </Button>
            </ListItem>
          )}
          {MENU_BOTTOM_LINKS.map(({ label, Icon, link }) => (
            <ListItem disablePadding key={label}>
              <DrawerListItemButton href={link} target="_blank">
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} />
              </DrawerListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <DrawerListItemButton onClick={logout}>
              <ListItemIcon>
                <LogoutRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </DrawerListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  const container =
    windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <>
      <StyledDrawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          onBackdropClick: () => {
            setMobileOpen(false);
          },
        }}
      >
        {drawerContent}
      </StyledDrawer>
      <StyledDrawer variant="permanent" open>
        {drawerContent}
      </StyledDrawer>
      <LaunchCommunityDialog
        open={launchDialogOpen}
        hide={() => {
          setLaunchDialogOpen(false);
        }}
      />
    </>
  );
};

export default MenuDrawer;

const MENU = [
  {
    label: "Account",
    Icon: AssignmentIndRoundedIcon,
    children: [
      {
        label: "Profile",
        path: "/profile",
      },
      {
        label: "Social Integrations",
        path: "/integrations",
      },
    ],
  },
  {
    label: "Subscription",
    Icon: CardMembershipRoundedIcon,
    children: [
      {
        label: "Membership Contracts",
        path: "/subscription/contracts",
      },
      {
        label: "Booster Contracts",
        path: "/subscription/multiplier-contracts",
      },
      {
        label: "Snapshots",
        path: "/snapshots",
      },
    ],
  },
  {
    label: "Tokens",
    Icon: AccountBalanceRoundedIcon,
    path: "/tokens",
  },
  {
    label: "Token booster",
    Icon: RocketLaunchRoundedIcon,
    path: "/token-booster",
  },
  {
    label: "Rules",
    Icon: AltRouteRoundedIcon,
    path: "/rules",
  },
  {
    label: "Loyalty Program",
    Icon: LoyaltyRoundedIcon,
    path: "/loyalty",
  },
  {
    label: "Rewards",
    Icon: RedeemRoundedIcon,
    path: "/rewards",
  },
  {
    label: "Phygital",
    Icon: ToysIcon,
    path: "/phygital",
  },
  {
    label: "Assets manager",
    Icon: BurstModeRoundedIcon,
    path: "/assets",
  },
];

const MENU_BOTTOM_LINKS = [
  {
    label: "Help center",
    Icon: HelpRoundedIcon,
    link: "https://anybodies-help-center.gitbook.io/help-center/",
  },
  {
    label: "Tutorials",
    Icon: VideoLibraryRoundedIcon,
    link: "https://youtube.com/@Anybodies?si=D4WZeVpfuj2ou2Q5",
  },
];
