import Box from "@mui/material/Box";
import Footer from "../../layouts/base/Footer";
import AuthBox from "./AuthBox/AuthBox";

const Auth = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <style>
        {`
          .notistack-SnackbarContainer .notistack-MuiContent {
            margin-top: 0 !important;
          }
        `}
      </style>
      <Box sx={{ width: 480, maxWidth: "100%", m: "auto" }}>
        <AuthBox />
      </Box>
      <Footer />
    </Box>
  );
};

export default Auth;
