import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { API_PATHS } from "../../../../constants";
import { CreateBoosterPayload, ServerResponse } from "../../../../types";
import { isSuccessResponse } from "../../../../utils";

type Props = {
  onSettled: () => void;
};

const createContract = async (data: CreateBoosterPayload) => {
  return await axios.post<
    ServerResponse<{
      message: string;
      success: true;
    }>["data"]
  >(API_PATHS.booster.create, data);
};

const useCreateBooster = ({ onSettled }: Props) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  return useMutation(
    async (payload: Omit<CreateBoosterPayload, "communityId">) => {
      if (!selectedCommunityDocId) return;
      const { data } = await createContract({
        ...payload,
        communityId: selectedCommunityDocId,
      });
      if (isSuccessResponse(data)) {
        await queryClient.invalidateQueries({
          queryKey: [API_PATHS.booster.list, selectedCommunityDocId],
          exact: true,
          refetchType: "active",
        });
        return data;
      }
      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },

    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Multiplier contract successfully created!",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Something went wrong. Try again!",
        });
      },
      onSettled: () => {
        onSettled();
      },
    }
  );
};

export default useCreateBooster;
