import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../pages/Layout";
import Auth from "../pages/Auth/Auth";
import ProtectedRoute from "./ProtectedRoute";
import LoadingRoutes from "./LoadingRoutes";
import { useApp } from "../services/AppService";
import SelectCommunity from "../pages/SelectCommunity/SelectCommunity";
import Rules from "../pages/Rules/Rules";
import Rule from "../pages/Rule/Rule";
import LoyaltyProgram from "../pages/LoyaltyProgram/LoyaltyProgram";
import Quest from "../pages/Quest/Quest";
import { Integrations } from "../pages/Integrations/Integrations";
import { Profile } from "../pages/Profile/Profile";
import Rewards from "../pages/Rewards/Rewards";
import CampaignConfigurator from "../pages/CampaignConfigurator/CampaignConfigurator";
import Assets from "../pages/Assets/Assets";
import CreateAsset from "../pages/CreateAsset/CreateAsset";
import Phygital from "../pages/Phygital/Phygital";
import CreatePhygital from "../pages/CreatePhygital/CreatePhygital";
import PhygitalSummary from "../pages/PhygitalSummary/PhygitalSummary";
import CreateSubscriptionContract from "../pages/CreateSubscriptionContract/CreateSubscriptionContract";
import SubscriptionContracts from "../pages/SubscriptionContracts/SubscriptionContracts";
import { useAuth } from "../services/AuthService";
import Tokens from "../pages/Tokens/Tokens";
import SubscriptionContract from "../pages/SubscriptionContract/SubscriptionContract";
import SubscriptionMultiplierContracts from "../pages/SubscriptionMultiplierContracts/SubscriptionMultiplierContracts";
import SubscriptionMultiplierContract from "../pages/SubscriptionMultiplierContract/SubscriptionMultiplierContract";
import Snapshots from "../pages/Snapshots/Snapshots";
import TokensDelegationList from "../pages/TokensDelegationList/TokensDelegationList";
import TokensDelegationCreate from "../pages/TokensDelegationCreate/TokensDelegationCreate";
import TokensDelegation from "../pages/TokensDelegation/TokensDelegation";

const Routing = () => {
  const { isAuthorized } = useAuth();
  const { selectedCommunity, communityListLoading } = useApp();

  if (communityListLoading) {
    return <LoadingRoutes />;
  }

  return (
    <Routes>
      <Route
        path="/auth"
        element={
          <ProtectedRoute isAllowed={!isAuthorized} redirectPath="/">
            <Auth />
          </ProtectedRoute>
        }
      />
      <Route
        element={
          <ProtectedRoute isAllowed={isAuthorized} redirectPath="/auth" />
        }
      >
        <Route
          path="/select-community"
          element={
            <ProtectedRoute isAllowed={!selectedCommunity} redirectPath="/">
              <SelectCommunity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute
              isAllowed={!!selectedCommunity}
              redirectPath="/select-community"
            >
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigate to="/profile" />} />
          <Route path="profile" element={<Profile />} />
          <Route
            path="subscription/contracts"
            element={<SubscriptionContracts />}
          />
          <Route
            path="subscription/contracts/:planId"
            element={<SubscriptionContract />}
          />
          <Route
            path="subscription/multiplier-contracts"
            element={<SubscriptionMultiplierContracts />}
          />
          <Route
            path="subscription/multiplier-contracts/:planId"
            element={<SubscriptionMultiplierContract />}
          />
          <Route
            path="subscription/create-contract"
            element={<CreateSubscriptionContract />}
          />
          <Route path="tokens" element={<Tokens />} />
          <Route path="token-booster" element={<TokensDelegationList />} />
          <Route
            path="token-booster/:campaignId"
            element={<TokensDelegation />}
          />
          <Route
            path="token-booster-create"
            element={<TokensDelegationCreate />}
          />
          <Route path="snapshots" element={<Snapshots />} />
          <Route path="rules" element={<Rules />} />
          <Route path="rules/:treeId" element={<Rule />} />
          <Route path="loyalty" element={<LoyaltyProgram />} />
          <Route path="loyalty/:questId" element={<Quest />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="rewards" element={<Rewards />} />
          <Route
            path="rewards/:campaignId"
            element={<CampaignConfigurator />}
          />
          <Route path="assets" element={<Assets />} />
          <Route path="assets/create" element={<CreateAsset />} />
          <Route path="phygital" element={<Phygital />} />
          <Route path="phygital/create" element={<CreatePhygital />} />
          <Route
            path="phygital/details/:claimableId"
            element={<PhygitalSummary />}
          />
        </Route>
      </Route>
      <Route path="*" element={<>404</>} />
    </Routes>
  );
};

export default Routing;
