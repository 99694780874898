import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Alert from "@mui/material/Alert";
import { useState } from "react";
import { useRemoveContractHashlist } from "../../../../hooks/queries/subscriptions/mutations/useRemoveContractHashlist";
import {
  SubscriptionContractType,
  SupportedCollection,
} from "../../../../types";
import { useGetContractDetails } from "../../../../hooks/queries/subscriptions/state/useGetContractDetails";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import SubscriptionCollectionAttributesDialog from "../../SubscriptionCollectionAttributesDialog";
import { useAddContractHashlist } from "../../../../hooks/queries/subscriptions/mutations/useAddContractHashlist";
import UploadSubscriptionContractHashlistDialog from "../../../../components/UploadSubscriptionContractHashlistDialog";
import SetAmountDialog from "./SetAmountDialog";
import AlertTitle from "@mui/material/AlertTitle";

const Collection = () => {
  const { data: contractDetails } = useGetContractDetails();
  const {
    mutateAsync: removeContractHashlist,
    isLoading: removeContractHashlistLoading,
  } = useRemoveContractHashlist({
    onSettled: () => {
      setConfirmChangeStateData(null);
    },
  });

  const [attributesDialogOpen, setAttributesDialogOpen] = useState(false);
  const [addHashlistModalOpen, setAddHashlistModalOpen] = useState(false);
  const [confirmChangeStateData, setConfirmChangeStateData] =
    useState<SupportedCollection | null>(null);
  const [changeDailyDialogData, setChangeDailyDialogData] = useState<
    SupportedCollection | undefined
  >(undefined);

  const handleDelete = (collection: SupportedCollection) => {
    setConfirmChangeStateData(collection);
  };

  const handleChangeDaily = (collection: SupportedCollection) => {
    setChangeDailyDialogData(collection);
  };

  const handleHideChangeDailyDialog = () => {
    setChangeDailyDialogData(undefined);
  };

  const isTokenCampaign =
    contractDetails?.planData?.tokenCampaign ||
    contractDetails?.planData?.bonkCampaign ||
    false;

  return (
    <>
      {contractDetails?.supportedCollections &&
        contractDetails?.supportedCollections?.length > 0 && (
          <Alert severity="warning" variant="filled" sx={{ mb: 2 }}>
            <AlertTitle>Adjusting Daily Rewards</AlertTitle>
            <Typography>
              Modifying your daily rewards will impact all active subscriptions,
              updating their earnings to the new reward structure.
            </Typography>
          </Alert>
        )}
      <Typography color="text.secondary" gutterBottom>
        Please upload the all the sub-collections here.{" "}
        {contractDetails?.planData?.StakingProgram ===
        SubscriptionContractType.Collection
          ? "For each collection you can assign a different daily reward"
          : "All the sub-collections will be mapped together"}
        . After uploading each collection, you will be able to set the daily
        reward amount that any NFT staked will earn per day. If you don't want
        to give any tokens, please set the amount to 0
      </Typography>
      {contractDetails?.supportedCollections?.length === 0 && (
        <Alert severity="warning" variant="filled">
          No sub-collections have been uploaded yet. Currently, this contract
          does not support any NFT
        </Alert>
      )}
      {contractDetails?.supportedCollections &&
        contractDetails?.supportedCollections?.length > 0 && (
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 650,
                "td, th": {
                  borderColor: (theme) => theme.palette.divider,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={700}>Name</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={700}>Total</Typography>
                    <Typography
                      variant="overline"
                      lineHeight={1}
                      color="text.secondary"
                    >
                      NFT
                    </Typography>
                  </TableCell>
                  {contractDetails?.planData?.RewardProgram ===
                    SubscriptionContractType.Collection && (
                    <TableCell align="center">
                      <Typography fontWeight={700}>Daily reward</Typography>
                      <Typography
                        variant="overline"
                        lineHeight={1}
                        color="text.secondary"
                      >
                        ($
                        {contractDetails?.planData?.TokenData?.TokenName})
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {contractDetails?.supportedCollections?.map((contract) => (
                  <TableRow
                    key={contract?.collectionId}
                    hover
                    sx={{
                      "&:last-child td": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>{contract?.name}</TableCell>
                    <TableCell align="center">
                      {contract?.fullHashlist?.length}
                    </TableCell>
                    {contractDetails?.planData?.RewardProgram ===
                      SubscriptionContractType.Collection && (
                      <TableCell align="center">
                        {contract?.amount}
                        <Tooltip title="Change daily reward" placement="top">
                          <IconButton
                            size="small"
                            disabled={isTokenCampaign}
                            onClick={() => {
                              handleChangeDaily(contract);
                            }}
                            sx={{ ml: 1 }}
                          >
                            <EditRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <Tooltip title="Remove hashlist" placement="left">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => {
                            handleDelete(contract);
                          }}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => {
          setAddHashlistModalOpen(true);
        }}
        sx={{ mt: 1 }}
      >
        Add hashlist
      </Button>
      {contractDetails?.planData?.RewardProgram ===
        SubscriptionContractType.Attribute &&
        contractDetails?.supportedCollections &&
        contractDetails?.supportedCollections?.length > 0 && (
          <Button
            fullWidth
            onClick={() => {
              setAttributesDialogOpen(true);
            }}
            sx={{ mt: 2 }}
          >
            Show attribute configurator
          </Button>
        )}
      <SetAmountDialog
        data={changeDailyDialogData}
        hide={handleHideChangeDailyDialog}
      />
      <UploadSubscriptionContractHashlistDialog
        open={addHashlistModalOpen}
        hide={() => {
          setAddHashlistModalOpen(false);
        }}
        uploadHookResult={useAddContractHashlist({
          onSuccess: () => {
            setAddHashlistModalOpen(false);
          },
        })}
      />
      <ConfirmDialog
        data={confirmChangeStateData}
        title="Are you sure?"
        content={
          <>Do you want remove hashlist "{confirmChangeStateData?.name}"?</>
        }
        isLoading={removeContractHashlistLoading}
        hide={() => {
          setConfirmChangeStateData(null);
        }}
        confirm={(data) => {
          if (!confirmChangeStateData) return;
          removeContractHashlist(confirmChangeStateData?.collectionId);
        }}
      />
      {contractDetails?.planData?.RewardProgram ===
        SubscriptionContractType.Attribute && (
        <SubscriptionCollectionAttributesDialog
          open={attributesDialogOpen}
          hide={() => {
            setAttributesDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Collection;
