import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Button from "@mui/material/Button";
import { Fragment, useMemo, useState } from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Loader from "../../components/Loader/Loader";
import Empty from "../../components/Empty";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import Box from "@mui/material/Box";
import useGetBoosters from "../../hooks/queries/boosters/state/useGetBoosters";
import CreateNew from "./CreateNew";
import List from "@mui/material/List";
import SubscriptionMultiplierContractListItem from "./SubscriptionMultiplierContractListItem";
import Divider from "@mui/material/Divider";

type FilterTypes = "all" | "live" | "draft" | "archived";

const SubscriptionMultiplierContracts = () => {
  const { data: boosters, isLoading: boostersLoading } = useGetBoosters();

  const [createOpen, setCreateOpen] = useState(false);
  const [filter, setFilter] = useState<FilterTypes>("all");

  const handleHideCreate = () => {
    setCreateOpen(false);
  };

  const handleChangeFilter = (
    event: React.MouseEvent<HTMLElement>,
    filter: FilterTypes
  ) => {
    if (filter === null) return;
    setFilter(filter);
  };

  const handleCreateNewContract = () => {
    setCreateOpen(true);
  };

  const filteredBoosters = useMemo(
    () =>
      boostersLoading
        ? []
        : boosters
            ?.filter((item) => {
              switch (filter) {
                case "live":
                  return item?.Active;
                case "draft":
                  return !item?.Active && !item?.Archived;
                case "archived":
                  return item?.Archived;
                default:
                  return !item?.Archived;
              }
            })
            .sort((a, b) => {
              if (a?.Active && !b?.Active) return -1;
              if (!a?.Active && b?.Active) return 1;
              return 0;
            }),
    [boosters, boostersLoading, filter]
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <Typography variant="h5" fontWeight={700}>
          Multiplier Contracts
        </Typography>
        <Button
          startIcon={<DataSaverOnRoundedIcon />}
          variant="base"
          onClick={handleCreateNewContract}
        >
          Create contract
        </Button>
      </Stack>
      {!boostersLoading && (
        <ToggleButtonGroup
          color="primary"
          value={filter}
          exclusive
          onChange={handleChangeFilter}
          size="small"
          sx={{ mb: 1.5 }}
        >
          <ToggleButton value="all">All</ToggleButton>
          <ToggleButton value="live">Published</ToggleButton>
          <ToggleButton value="draft">Draft</ToggleButton>
          <ToggleButton value="archived">
            <Inventory2RoundedIcon fontSize="small" sx={{ mr: 1 }} />
            Archived
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {boostersLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : filteredBoosters?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
        >
          <Empty text="Empty" />
        </Stack>
      ) : (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
            borderRadius: "5px",
            overflow: "hidden",
          }}
        >
          <List disablePadding dense>
            {filteredBoosters?.map((b, index) => (
              <Fragment key={b?.id}>
                {index !== 0 && <Divider component="li" />}
                <SubscriptionMultiplierContractListItem data={b} />
              </Fragment>
            ))}
          </List>
        </Box>
      )}
      <CreateNew open={createOpen} hide={handleHideCreate} />
    </>
  );
};

export default SubscriptionMultiplierContracts;
