import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Empty from "../../components/Empty";
import Loader from "../../components/Loader/Loader";
import { useGetAllAssets } from "../../hooks/queries/assets/state/useGetAllAssets";
import AssetsItem from "./AssetsItem";
import LinkBehavior from "../../components/LinkBehavior";

const Assets = () => {
  const { data: allAssets, isLoading: allAssetsLoading } = useGetAllAssets();

  return (
    <>
      <Typography variant="h4" fontWeight={700} mb={1}>
        Assets manager
      </Typography>
      <Typography color="text.secondary" mb={1}>
        The Asset manager is using the standard NFT digital assets protocol to
        mint your NFTs for your rewards campaigns or phygitals. Think of it as a
        library of assets than can be used again and again. No need to waste
        time on recreating the way your coupon looks if you are planning to
        reward your users on a regular basis
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mb={1}
      >
        <Typography variant="h5" fontWeight={700}>
          Assets
        </Typography>
        <Button
          LinkComponent={LinkBehavior}
          href="create"
          startIcon={<DataSaverOnRoundedIcon />}
          variant="base"
        >
          Add asset
        </Button>
      </Stack>
      {allAssetsLoading ? (
        <Stack justifyContent="center" alignItems="center" p={3}>
          <Loader />
        </Stack>
      ) : allAssets?.length === 0 ? (
        <Stack
          justifyContent="center"
          sx={{
            p: { xs: 2, md: 4 },
          }}
        >
          <Empty text="You don't have any asset" />
        </Stack>
      ) : (
        <Box
          sx={{
            columns: "4 300px",
            gap: 2,
          }}
        >
          {!!allAssets &&
            allAssets?.map((asset) => (
              <Box pt={2} key={asset?._id}>
                <AssetsItem asset={asset} />
              </Box>
            ))}
        </Box>
      )}
    </>
  );
};

export default Assets;
