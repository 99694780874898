import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useWallet } from "@solana/wallet-adapter-react";
import { useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { useGetAllAssets } from "../../hooks/queries/assets/state/useGetAllAssets";
import { useGetPhygitalById } from "../../hooks/queries/phygital/state/useGetPhygitalById";
import SummaryAsset from "../../components/SummaryAsset";
import CustomWalletButton from "../../components/CustomWalletButton";
import Loader from "../../components/Loader/Loader";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import ToysIcon from "@mui/icons-material/Toys";
import usePublishPhygital from "../../hooks/queries/phygital/mutations/usePublishPhygital";
import LoadingModal from "../../components/modals/LoadingModal";
import { useApp } from "../../services/AppService";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useState } from "react";
import useGetPhygitalLinks from "../../hooks/queries/phygital/mutations/useGetPhygitalLinks";
import { downloadCSVFile, downloadJSONFile } from "../../utils";
import { EligableTypes } from "../../types";
import EligibleHashlistSummary from "../../components/EligibleHashlist/EligibleHashlistSummary";
import {
  PRICE_PER_PHYGITAL_CREATION,
  PRICE_PER_PHYGITAL_NFT,
} from "../../constants";

const CLAIM_PAGE_LINK = "https://anybodies.com/claim/";

const PhygitalSummary = () => {
  const { publicKey } = useWallet();
  const { claimableId } = useParams();
  const { loadingModal, setLoadingModal } = useApp();
  const { data: phygitalInfo, isLoading: phygitalInfoLoading } =
    useGetPhygitalById();
  const { mutateAsync: publishPhygital, isLoading: publishPhygitalLoading } =
    usePublishPhygital({
      onSuccess: () => {},
    });
  const { mutateAsync: getPhygitalLinks, isLoading: getPhygitalLinksLoading } =
    useGetPhygitalLinks({ onSuccess: () => {} });
  const { data: assetsList } = useGetAllAssets();
  const [fileType, setFileType] = useState<"csv" | "json">("csv");

  const handleFileType = (
    event: React.MouseEvent<HTMLElement>,
    newFileType: "csv" | "json" | null
  ) => {
    if (newFileType !== null) {
      setFileType(newFileType);
    }
  };

  const handlePay = () => {
    if (!claimableId) return;
    publishPhygital(claimableId);
  };

  const assetMetadata = assetsList?.find(
    (el) => el?._id === phygitalInfo?.tokenConfig?.assetMetadataId
  );

  const handleGetLinks = async () => {
    if (!claimableId) return;
    const res = await getPhygitalLinks(claimableId);
    const fileName = `${phygitalInfo?.profile?.title}-links`;
    if (fileType === "csv") {
      downloadCSVFile(
        res?.map((link) => [`${CLAIM_PAGE_LINK}${link}`]),
        `${fileName}.csv`
      );
    } else {
      downloadJSONFile(
        res?.map((link) => `${CLAIM_PAGE_LINK}${link}`),
        `${fileName}.json`
      );
    }
  };

  if (phygitalInfoLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" p={3}>
        <Loader />
      </Stack>
    );
  }

  return (
    <Box textAlign="center" pt={1}>
      <StudioBreadcrumbs
        list={[
          {
            label: "Phygital",
            Icon: ToysIcon,
            path: "/phygital",
          },
          {
            label: phygitalInfo?.profile?.title,
          },
        ]}
      />
      <Typography variant="h6" fontWeight={900} mb={1}>
        Summary phygital info
      </Typography>
      <Box
        textAlign="left"
        sx={{
          overflowX: "auto",
          "& table": {
            tableLayout: "fixed",
            width: "100%",
            tr: {
              td: {
                verticalAlign: "top",
                borderSpacing: 0,
                py: 0.5,
                "&:not(:first-of-type)": { pl: 2 },
                "&:first-of-type": {
                  fontWeight: "bold",
                  width: 200,
                  maxWidth: "100%",
                },
              },
            },
          },
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>{phygitalInfo?.profile?.title}</td>
            </tr>
            <tr>
              <td>Description:</td>
              <td>{phygitalInfo?.profile?.description}</td>
            </tr>
            {phygitalInfo?.claimExpirationDate && (
              <tr>
                <td>Experation date:</td>
                <td>
                  {moment(phygitalInfo?.claimExpirationDate).format(
                    "DD MMMM YYYY"
                  )}
                </td>
              </tr>
            )}
            {phygitalInfo?.eligibleAudience?.ruleTreeId && (
              <tr>
                <td>Rule:</td>
                <td>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography>
                      {phygitalInfo?.eligibleAudience?.ruleTreeDescription}
                    </Typography>
                    <Tooltip title="Go to selected rule configurator">
                      <IconButton
                        size="small"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          window.open(
                            `/rules/${phygitalInfo?.eligibleAudience?.ruleTreeId}`,
                            "_blank"
                          );
                        }}
                      >
                        <OpenInNewRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </td>
              </tr>
            )}
            <tr>
              <td>Number of claims per wallet:</td>
              <td>
                {phygitalInfo?.eligibleAudience?.maxClaimsPerWalletAddress}
              </td>
            </tr>
            <tr>
              <td>Claim manager:</td>
              <td>
                <strong>
                  {phygitalInfo?.eligibleAudience?.type ===
                  EligableTypes.Unlimited
                    ? "Unlimited"
                    : phygitalInfo?.eligibleAudience?.type ===
                      EligableTypes.Hashlist
                    ? "Collectibles list"
                    : "Predefined wallet list"}
                </strong>
                {phygitalInfo?.eligibleAudience?.eligibleHashlists?.map(
                  (data) => (
                    <Box key={data?.hashlistManagerId} mt={1}>
                      <EligibleHashlistSummary
                        key={data?.hashlistManagerId}
                        type={phygitalInfo?.eligibleAudience?.type}
                        data={data}
                      />
                    </Box>
                  )
                )}
              </td>
            </tr>
            <tr>
              <td>Phygital asset:</td>
              <td>
                <SummaryAsset data={assetMetadata} />
              </td>
            </tr>
            {phygitalInfo?.claimingPaymentConfig?.destinationWalletAddress && (
              <tr>
                <td>Cost per claim:</td>
                <td>
                  <strong>Destination wallet address:</strong>
                  <br />
                  <Typography sx={{ wordBreak: "break-all" }}>
                    {
                      phygitalInfo?.claimingPaymentConfig
                        ?.destinationWalletAddress
                    }
                  </Typography>
                  <br />
                  <strong>Amount:</strong>
                  <br />
                  <Typography sx={{ wordBreak: "break-all" }}>
                    {phygitalInfo?.claimingPaymentConfig?.transactions
                      ?.map(
                        (tx) =>
                          `${tx?.amount} in ${
                            tx?.type === "sol" ? "$SOL" : tx?.tokenAddress
                          }`
                      )
                      .join(", ")}
                  </Typography>
                </td>
              </tr>
            )}
            <tr>
              <td>Links:</td>
              <td>{phygitalInfo?.totalClaimablePages}</td>
            </tr>
            <tr>
              <td>Claimables per link:</td>
              <td>{phygitalInfo?.totalClaimablesPerPage}</td>
            </tr>
            {phygitalInfo?.totalAvailableReward && (
              <tr>
                <td>Estimated Campaign Costs:</td>
                <td>
                  <Typography
                    variant="h6"
                    fontWeight={700}
                    color="success.main"
                  >
                    ~
                    {parseFloat(
                      (
                        PRICE_PER_PHYGITAL_CREATION +
                        PRICE_PER_PHYGITAL_NFT *
                          phygitalInfo?.totalAvailableReward
                      ).toFixed(3)
                    )}{" "}
                    $SOL
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography fontWeight={700} variant="h5">
        Woohoo 🎉
      </Typography>
      <Typography fontWeight={700} variant="h6">
        {phygitalInfo?.status === "published"
          ? "Phygital is now published!"
          : "Phygital is ready to publish!"}
      </Typography>
      {phygitalInfo?.status === "draft" ? (
        <>
          <Box textAlign="center" my={5}>
            <Typography variant="h6" mb={1} fontWeight={900}>
              {publicKey ? "Your wallet" : "Please, connect your wallet"}
            </Typography>
            <CustomWalletButton />
          </Box>
          <Box>
            <LoadingButton
              variant="gradient"
              color="secondary"
              disabled={!publicKey}
              loading={publishPhygitalLoading}
              onClick={handlePay}
            >
              Pay and go live!
            </LoadingButton>
          </Box>
        </>
      ) : (
        <>
          <Divider sx={{ my: 2 }} />
          <Box mb={2}>
            <Typography fontWeight={700} mb={1}>
              Choose file type
            </Typography>
            <ToggleButtonGroup
              value={fileType}
              exclusive
              onChange={handleFileType}
              aria-label="text alignment"
              disabled={getPhygitalLinksLoading}
            >
              <ToggleButton value="csv" aria-label="left aligned">
                CSV
              </ToggleButton>
              <ToggleButton value="json" aria-label="centered">
                JSON
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <LoadingButton
            variant="gradient"
            loading={getPhygitalLinksLoading}
            onClick={handleGetLinks}
          >
            Download links
          </LoadingButton>
        </>
      )}
      <LoadingModal
        data={loadingModal}
        hide={() => {
          if (!setLoadingModal) return;
          setLoadingModal(null);
        }}
      />
    </Box>
  );
};

export default PhygitalSummary;
