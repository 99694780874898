import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PhygitalByIdResponse } from "../../../../types";
import axios from "axios";
import { API_PATHS } from "../../../../constants";
import { isSuccessResponse } from "../../../../utils";

const getPhygital = async (claimableId?: string) => {
  const { data } = await axios.post<PhygitalByIdResponse>(
    API_PATHS.phygital.byId,
    {
      data: { claimableId },
    }
  );
  return data;
};

export const useGetPhygitalById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { claimableId } = useParams();

  return useQuery(
    [API_PATHS.phygital.byId, claimableId],
    async () => {
      const { data } = await getPhygital(claimableId);
      if (isSuccessResponse(data)) {
        return data?.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      enabled: !!claimableId,
      staleTime: 5 * 60 * 1000,
      retry: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        navigate("/phygital");
      },
    }
  );
};
