import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import { CommunityRuleType, RuleTarget, RuleType } from "../../../types";
import { useApp } from "../../../services/AppService";

type Props = {
  target?: RuleTarget;
  onClick: (type: RuleType | CommunityRuleType) => void;
};

const TYPES: {
  type: RuleType;
  label: string;
}[] = [
  { type: "token-holder", label: "Fungible token holder" },
  { type: "holder", label: "Holder hashlist" },
  { type: "subscriber", label: "Subscriber" },
  { type: "phygital", label: "Phygital" },
  { type: "discord-role", label: "Discord role" },
];

const COMMUNITY_TYPES: {
  type: CommunityRuleType;
  label: string;
}[] = [
  { type: "minimum-unique-subscribers", label: "Minimum unique subscribers" },
];

const RulesTreeAdd = ({ target, onClick }: Props) => {
  const { selectedCommunity } = useApp();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const list = target === RuleTarget.WalletAddress ? TYPES : COMMUNITY_TYPES;

  return (
    <li>
      <div className="tree-item tree-item__rule">
        <Box mb={5}>
          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            startIcon={<DataSaverOnRoundedIcon />}
            variant="base"
            onClick={handleOpen}
          >
            Add condition
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {list.map(({ type, label }) =>
              type === "discord-role" &&
              !selectedCommunity?.DiscordData ? null : (
                <MenuItem
                  onClick={() => {
                    onClick(type);
                    handleClose();
                  }}
                  key={type}
                >
                  {label}
                </MenuItem>
              )
            )}
          </Menu>
        </Box>
      </div>
    </li>
  );
};
export default RulesTreeAdd;
