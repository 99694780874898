import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { RulesTreeItemProps } from "./types";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Typography from "@mui/material/Typography";
import { ConditionOperator } from "../../../types";
import RulesTreeItemLabel from "./RulesTreeItemLabel";
import { useTheme } from "@mui/material/styles";

const RulesTreeItem = ({
  data,
  level,
  children,
  onConditionChange,
  onRuleRemove,
}: RulesTreeItemProps) => {
  const theme = useTheme();
  const BORDER_COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.info.main,
  ];
  return (
    <li>
      <div className="tree-item">
        {"condition" in data ? (
          <Paper sx={{ p: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography fontWeight={700}>Group with operator</Typography>
              <Box
                sx={{
                  width: 80,
                }}
              >
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ backgroundColor: "background.default" }}
                >
                  <Select
                    value={data?.condition}
                    onChange={(e) => {
                      if (!onConditionChange) return;
                      onConditionChange(e.target.value as ConditionOperator);
                    }}
                    sx={{
                      "& .MuiSelect-select": {
                        p: "5.5px 8px",
                      },
                    }}
                  >
                    <MenuItem value="OR">Or</MenuItem>
                    <MenuItem value="AND">And</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Paper>
        ) : (
          <Paper sx={{ p: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
            >
              <RulesTreeItemLabel rule={data} />
              <Stack direction="row">
                <IconButton color="error" size="small" onClick={onRuleRemove}>
                  <DeleteRoundedIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Paper>
        )}
      </div>
      {children && (
        <ul
          style={
            {
              "--border-color": BORDER_COLORS[level - 1],
            } as React.CSSProperties
          }
        >
          {children}
        </ul>
      )}
    </li>
  );
};

export default RulesTreeItem;
