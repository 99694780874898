import Typography from "@mui/material/Typography";
import StudioBreadcrumbs from "../../components/StudioBreadcrumbs";
import BurstModeRoundedIcon from "@mui/icons-material/BurstModeRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import CreateAssetAttributes from "./CreateAssetAttributes/CreateAssetAttributes";
import InputAdornment from "@mui/material/InputAdornment";
import CreateAssetRoyaltySplit from "./CreateAssetRoyaltySplit/CreateAssetRoyaltySplit";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { Attribute, RoyaltyCreator, UploadedImage } from "../../types";
import CreateAssetPreview from "./CreateAssetPreview/CreateAssetPreview";
import useCreateAsset from "../../hooks/queries/assets/mutations/useCreateAsset";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

type Inputs = {
  assetTitle: string;
  name: string;
  description: string;
  sellerFeeBasisPoints: number;
};

const CreateAsset = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: createAsset, isLoading: createAssetLoading } =
    useCreateAsset();

  const [attrubites, setAttributes] = useState<Attribute[]>([]);
  const [royaltySplit, setRoyaltySplit] = useState<RoyaltyCreator[]>([
    { address: "", share: 100 },
  ]);
  const [assetImage, setAssetImage] = useState<UploadedImage | undefined>();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<Inputs>();
  const formWatcher = watch();

  const handleAddAttr = () => {
    setAttributes((state) => [...state, { trait_type: "", value: "" }]);
  };

  const handleRemoveAttr = (index: number) => {
    setAttributes((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const onAttrFieldChange = (
    index: number,
    key: keyof Attribute,
    value: any
  ) => {
    setAttributes((state) => {
      const newState = [...state];
      newState[index][key] = value;
      return newState;
    });
  };

  const handleAddRoyaltySplit = () => {
    setRoyaltySplit((state) => [...state, { share: 0, address: "" }]);
  };

  const handleRemoveRoyaltySplit = (index: number) => {
    setRoyaltySplit((state) => {
      const newState = [...state];
      newState.splice(index, 1);
      return newState;
    });
  };

  const onRoyaltySplitFieldChange = (
    index: number,
    key: keyof RoyaltyCreator,
    value: any
  ) => {
    setRoyaltySplit((state) => {
      const newState = [...state];
      newState[index][key] = value;
      return newState;
    });
  };

  const onSubmit = async (data: Inputs) => {
    if (typeof assetImage === "undefined") {
      enqueueSnackbar({
        variant: "error",
        message: "Set image for asset please",
      });
      return;
    }
    const res = await createAsset({
      assetTitle: data?.assetTitle,
      filePayload: assetImage,
      assetMetadata: {
        attributes: attrubites,
        creators: royaltySplit,
        name: data?.name,
        description: data?.description,
        sellerFeeBasisPoints: data?.sellerFeeBasisPoints || 0,
      },
    });
    if (res?.success) {
      enqueueSnackbar({
        variant: "success",
        message: `New asset created`,
      });
      navigate("/assets");
    }
  };

  return (
    <>
      <StudioBreadcrumbs
        list={[
          {
            label: "Assets manager",
            Icon: BurstModeRoundedIcon,
            path: "/assets",
          },
          {
            label: "Create asset",
          },
        ]}
      />
      <Typography variant="h5" fontWeight={700} mb={1}>
        Create asset
      </Typography>
      <Alert variant="filled" color="warning" severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>Attention!</AlertTitle>
        <Typography>
          Be careful! After submitting the form, you will not be able to change
          or delete this asset
        </Typography>
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={{ xs: "column-reverse", lg: "row" }} spacing={3}>
          <Box flexBasis="100%">
            <TextField
              {...register("assetTitle", {
                required: "This field is required",
              })}
              required
              fullWidth
              label="Asset title"
              helperText="This is the title of the asset that will only be visible in the studio"
              margin="dense"
              disabled={createAssetLoading}
            />
            <TextField
              {...register("name", {
                required: "This field is required",
              })}
              required
              fullWidth
              label="Name"
              margin="dense"
              disabled={createAssetLoading}
            />
            <TextField
              {...register("description", {
                required: "This field is required",
              })}
              required
              fullWidth
              label="Description"
              margin="dense"
              multiline
              rows={3}
              disabled={createAssetLoading}
            />
            <CreateAssetAttributes
              list={attrubites}
              onAdd={handleAddAttr}
              onRemove={handleRemoveAttr}
              onFieldChange={onAttrFieldChange}
              disabled={createAssetLoading}
            />
            <Typography fontWeight={700} variant="h6" mt={2}>
              Royalty
            </Typography>
            <TextField
              type="number"
              {...register("sellerFeeBasisPoints", {
                valueAsNumber: true,
                min: {
                  value: 0.0001,
                  message: "Minimum royalty value 0.0001",
                },
                max: {
                  value: 100,
                  message: "Maximum royalty value 100",
                },
              })}
              error={!!errors.sellerFeeBasisPoints}
              helperText={
                !!errors.sellerFeeBasisPoints &&
                errors.sellerFeeBasisPoints.message
              }
              label="Percent"
              fullWidth
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Typography fontWeight={700}>%</Typography>
                  </InputAdornment>
                ),
              }}
              disabled={createAssetLoading}
            />
            {!!formWatcher?.sellerFeeBasisPoints && (
              <CreateAssetRoyaltySplit
                list={royaltySplit}
                onAdd={handleAddRoyaltySplit}
                onRemove={handleRemoveRoyaltySplit}
                onFieldChange={onRoyaltySplitFieldChange}
                disabled={createAssetLoading}
              />
            )}
          </Box>
          <Box flexShrink={0}>
            <CreateAssetPreview
              image={assetImage}
              setImage={(data) => {
                setAssetImage(data);
              }}
              formData={formWatcher}
              attributes={attrubites}
              disabled={createAssetLoading}
            />
          </Box>
        </Stack>
        <LoadingButton
          loading={createAssetLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
          type="submit"
        >
          Submit
        </LoadingButton>
      </form>
    </>
  );
};

export default CreateAsset;
