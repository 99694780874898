import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { API_PATHS } from "../../../../constants";
import { UpdateProfilePayload } from "../../../../types";

export function useUpdateProfile() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  const updateProfile = async (data: UpdateProfilePayload) => {
    return await axios.post<any>(API_PATHS.profile.update, {
      ...data,
      communityId: selectedCommunityDocId,
    });
  };

  return useMutation(
    async (profileInfo: UpdateProfilePayload) => {
      const { data } = await updateProfile(profileInfo);
      if (!data?.success) {
        throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
      }
      await queryClient.invalidateQueries({
        queryKey: [API_PATHS.profile.info, selectedCommunityDocId],
        exact: true,
        refetchType: "active",
      });
      return data;
    },

    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Profile successfully updated!",
        });
      },
      onError: (error: any) => {
        enqueueSnackbar({
          variant: "error",
          message: error?.message || "Oops.. Error on sign in. Try again!",
        });
      },
    }
  );
}
