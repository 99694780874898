import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SummaryProps } from "./types";
import usePublishReward from "../../../../hooks/queries/rewards/mutations/usePublishReward";
import CustomWalletButton from "../../../../components/CustomWalletButton";
import { useWallet } from "@solana/wallet-adapter-react";
import { useParams } from "react-router-dom";
import { useGetRewardById } from "../../../../hooks/queries/rewards/state/useGetRewardById";
import Divider from "@mui/material/Divider";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import {
  DistributionTypes,
  EligableTypes,
  RewardFulfillmentTypes,
  RewardSources,
} from "../../../../types";
import FulfillmentUploadCSV from "../Fulfillment/FulfillmentUploadCSV";
import { useGetAllAssets } from "../../../../hooks/queries/assets/state/useGetAllAssets";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";
import Link from "@mui/material/Link";
import {
  PRICE_PER_REWARD_CREATION,
  PRICE_PER_REWARD_NFT,
} from "../../../../constants";
import EligibleHashlistSummary from "../../../../components/EligibleHashlist/EligibleHashlistSummary";
import SummaryAsset from "../../../../components/SummaryAsset";

const Summary = ({ prev }: SummaryProps) => {
  const { publicKey } = useWallet();
  const { campaignId } = useParams();
  const { data: rewardInfo } = useGetRewardById();
  const { mutateAsync: publishReward, isLoading: publishRewardLoading } =
    usePublishReward({
      onSuccess: () => {},
    });
  const { data: assetsList } = useGetAllAssets();

  const handlePay = () => {
    if (!campaignId) return;
    publishReward(campaignId);
  };

  const assetMetadata = assetsList?.find(
    (el) => el?._id === rewardInfo?.tokenConfig?.assetMetadataId
  );
  const redeemedAssetMetadata = assetsList?.find(
    (el) => el?._id === rewardInfo?.tokenConfig?.redeemedAssetMetadataId
  );

  return (
    <Box textAlign="center" pt={1}>
      <Typography variant="h6" fontWeight={900}>
        Campaign info
      </Typography>
      <Box
        textAlign="left"
        sx={{
          overflowX: "auto",
          "& table": {
            tableLayout: "fixed",
            width: "100%",
            tr: {
              td: {
                verticalAlign: "top",
                borderSpacing: 0,
                py: 0.5,
                "&:not(:first-of-type)": { pl: 2 },
                "&:first-of-type": {
                  fontWeight: "bold",
                  width: 200,
                  maxWidth: "100%",
                },
              },
            },
          },
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>{rewardInfo?.profile?.title}</td>
            </tr>
            <tr>
              <td>Description:</td>
              <td>{rewardInfo?.profile?.description}</td>
            </tr>
            <tr>
              <td>Campaign end date:</td>
              <td>
                {moment(rewardInfo?.claimExpirationDate)?.format(
                  "DD MMMM YYYY"
                )}
              </td>
            </tr>
            <tr>
              <td>Category:</td>
              <td>{rewardInfo?.rewardCategory}</td>
            </tr>
            <tr>
              <td>Source:</td>
              <td>
                {rewardInfo?.rewardSource === RewardSources.System
                  ? "Manual"
                  : "Shopify"}
              </td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>
                {rewardInfo?.rewardType === "gift-card"
                  ? "Gift card"
                  : "Discount"}
              </td>
            </tr>
            <tr>
              <td>Eligible countries:</td>
              <td>
                {rewardInfo?.eligibleCountries?.length === 0
                  ? "Worldwide"
                  : rewardInfo?.eligibleCountries
                      ?.map((c) => c?.name)
                      .join(", ")}
              </td>
            </tr>
            <tr>
              <td>Instantly redeem reward:</td>
              <td>{rewardInfo?.autoRedeem ? "Yes" : "No"}</td>
            </tr>
            {rewardInfo?.eligibleAudience?.ruleTreeId && (
              <tr>
                <td>Rule:</td>
                <td>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography>
                      {rewardInfo?.eligibleAudience?.ruleTreeDescription}
                    </Typography>
                    <Tooltip title="Go to selected rule configurator">
                      <IconButton
                        size="small"
                        sx={{ mr: 2 }}
                        onClick={() => {
                          window.open(
                            `/rules/${rewardInfo?.eligibleAudience?.ruleTreeId}`,
                            "_blank"
                          );
                        }}
                      >
                        <OpenInNewRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </td>
              </tr>
            )}
            <tr>
              <td>Number of claims per wallet:</td>
              <td>{rewardInfo?.eligibleAudience?.maxClaimsPerWalletAddress}</td>
            </tr>
            <tr>
              <td>Claim manager:</td>
              <td>
                <strong>
                  {rewardInfo?.eligibleAudience?.type ===
                  EligableTypes.Unlimited
                    ? "Unlimited"
                    : rewardInfo?.eligibleAudience?.type ===
                      EligableTypes.Hashlist
                    ? "Collectibles list"
                    : "Predefined wallet list"}
                </strong>
                {rewardInfo?.eligibleAudience?.eligibleHashlists?.map(
                  (data) => (
                    <Box key={data?.hashlistManagerId} mt={1}>
                      <EligibleHashlistSummary
                        key={data?.hashlistManagerId}
                        type={rewardInfo?.eligibleAudience?.type}
                        data={data}
                      />
                    </Box>
                  )
                )}
              </td>
            </tr>
            <tr>
              <td>Distribution method:</td>
              <td>
                {rewardInfo?.distributionType === DistributionTypes.Giveaway
                  ? "Giveaway - FCFS"
                  : "Raffle"}
              </td>
            </tr>
            <tr>
              <td>Fulfillment type:</td>
              <td>
                {rewardInfo?.rewardConfig?.fulfillmentType ===
                RewardFulfillmentTypes.Upload
                  ? "Upload"
                  : "Scratch"}
              </td>
            </tr>
            <tr>
              <td>Codes:</td>
              <td>
                {rewardInfo?.rewardConfig?.fulfillmentType ===
                RewardFulfillmentTypes.Upload ? (
                  <FulfillmentUploadCSV
                    data={rewardInfo?.rewardConfig}
                    disabled={true}
                  />
                ) : (
                  `${rewardInfo?.rewardConfig?.size} pieces`
                )}
              </td>
            </tr>
            <tr>
              <td>Codes experation date:</td>
              <td>
                {rewardInfo?.redeemExpirationDate
                  ? moment(rewardInfo?.redeemExpirationDate).format(
                      "DD MMMM YYYY"
                    )
                  : "Does not expire"}
              </td>
            </tr>
            <tr>
              <td>Reward asset:</td>
              <td>
                <SummaryAsset data={assetMetadata} />
              </td>
            </tr>
            {redeemedAssetMetadata && (
              <tr>
                <td>Reward asset:</td>
                <td>
                  <SummaryAsset data={redeemedAssetMetadata} />
                </td>
              </tr>
            )}
            <tr>
              <td>Redeem instructions:</td>
              <td>
                <Timeline
                  sx={{
                    m: 0,
                    p: 0,
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {rewardInfo?.redemptionInstructions?.steps?.map(
                    (step, index) => (
                      <TimelineItem key={index} sx={{ minHeight: 0 }}>
                        <TimelineSeparator>
                          <TimelineConnector
                            sx={{
                              ...(index === 0 && {
                                backgroundColor: "transparent",
                              }),
                            }}
                          />
                          <TimelineDot sx={{ my: 0.5 }} />
                          <TimelineConnector
                            sx={{
                              ...(index + 1 ===
                                rewardInfo?.redemptionInstructions?.steps
                                  ?.length && {
                                backgroundColor: "transparent",
                              }),
                            }}
                          />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pr: 0, pl: 1.5, py: 0.5 }}>
                          <Typography variant="subtitle2">{step}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  )}
                </Timeline>
              </td>
            </tr>
            {rewardInfo?.redemptionInstructions?.redemptionUri && (
              <tr>
                <td>Website link:</td>
                <td>
                  <Link
                    component="a"
                    href={rewardInfo?.redemptionInstructions?.redemptionUri}
                    target="_blank"
                  >
                    {rewardInfo?.redemptionInstructions?.redemptionUri}
                  </Link>
                </td>
              </tr>
            )}
            {rewardInfo?.claimingPaymentConfig?.destinationWalletAddress && (
              <tr>
                <td>Cost per claim:</td>
                <td>
                  <strong>Destination wallet address:</strong>
                  <br />
                  <Typography sx={{ wordBreak: "break-all" }}>
                    {
                      rewardInfo?.claimingPaymentConfig
                        ?.destinationWalletAddress
                    }
                  </Typography>
                  <br />
                  <br />
                  <strong>Amount:</strong>
                  <br />
                  <Typography sx={{ wordBreak: "break-all" }}>
                    {rewardInfo?.claimingPaymentConfig?.transactions
                      ?.map(
                        (tx) =>
                          `${tx?.amount} in ${
                            tx?.type === "sol" ? "$SOL" : tx?.tokenAddress
                          }`
                      )
                      .join(", ")}
                  </Typography>
                </td>
              </tr>
            )}
            {rewardInfo?.totalAvailableReward && (
              <tr>
                <td>Estimated Campaign Costs:</td>
                <td>
                  <Typography
                    variant="h6"
                    fontWeight={700}
                    color="success.main"
                  >
                    ~
                    {parseFloat(
                      (
                        PRICE_PER_REWARD_CREATION +
                        PRICE_PER_REWARD_NFT * rewardInfo?.totalAvailableReward
                      ).toFixed(3)
                    )}{" "}
                    $SOL
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography fontWeight={700} variant="h5">
        Woohoo 🎉
      </Typography>
      <Typography fontWeight={700} variant="h6">
        {rewardInfo?.status === "published"
          ? "Campaign is now live!"
          : "Campaign is ready to launch!"}
      </Typography>
      {rewardInfo?.status === "draft" && (
        <>
          <Box textAlign="center" my={5}>
            <Typography variant="h6" mb={1} fontWeight={900}>
              {publicKey ? "Your wallet" : "Please, connect your wallet"}
            </Typography>
            <CustomWalletButton />
          </Box>
          <Box>
            <Button
              variant="base"
              color="secondary"
              sx={{ mr: 1 }}
              onClick={prev}
            >
              Back
            </Button>
            <LoadingButton
              variant="gradient"
              color="secondary"
              disabled={!publicKey}
              loading={publishRewardLoading}
              onClick={handlePay}
            >
              Pay and go live!
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Summary;
