import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useSignUp } from "../../../hooks/queries/auth/useSignUp";
import { SignUpPayload } from "../../../types";

const SignUp = () => {
  const { mutateAsync: signUp, isLoading: signUpLoading } = useSignUp();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<SignUpPayload>();

  const [hadHelp, setHadHelp] = useState(false);

  const onSubmit = (data: SignUpPayload) => {
    signUp(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("projectName", {
          required: "required",
          minLength: {
            value: 2,
            message: "min length is 2",
          },
          maxLength: {
            value: 50,
            message: "max length is 50",
          },
        })}
        error={!!errors.projectName}
        helperText={!!errors.projectName && errors.projectName.message}
        disabled={signUpLoading}
        margin="normal"
        required
        color="primary"
        fullWidth
        label="Project Name"
      />
      <TextField
        {...register("discord", {
          minLength: {
            value: 2,
            message: "min length is 2",
          },
          maxLength: {
            value: 50,
            message: "max length is 50",
          },
        })}
        error={!!errors.discord}
        helperText={!!errors.discord && errors.discord.message}
        disabled={signUpLoading}
        margin="normal"
        color="primary"
        fullWidth
        label="Discord Invite"
      />
      <TextField
        {...register("twitter", {
          minLength: {
            value: 2,
            message: "min length is 2",
          },
          maxLength: {
            value: 50,
            message: "max length is 50",
          },
        })}
        error={!!errors.twitter}
        helperText={!!errors.twitter && errors.twitter.message}
        disabled={signUpLoading}
        margin="normal"
        color="primary"
        fullWidth
        label="Twitter Link"
      />

      <TextField
        {...register("email", {
          required: "required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          },
        })}
        error={!!errors.email}
        helperText={!!errors.email && errors.email.message}
        disabled={signUpLoading}
        margin="normal"
        required
        color="primary"
        fullWidth
        label="Email Address"
        autoComplete="email"
      />
      <TextField
        {...register("password", {
          required: "required",
          minLength: {
            value: 8,
            message: "min length is 8",
          },
        })}
        error={!!errors.password}
        helperText={!!errors.password && errors.password.message}
        disabled={signUpLoading}
        margin="normal"
        color="primary"
        required
        fullWidth
        label="Password"
        type="password"
        autoComplete="current-password"
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => setHadHelp(e.target.checked)}
            value="allowExtraEmails"
            color="primary"
          />
        }
        label="Someone helped me"
      />

      {hadHelp && (
        <TextField
          {...register("affiliateId", {
            required: "required",
            minLength: {
              value: 8,
              message: "Min length is 8",
            },
          })}
          error={!!errors.affiliateId}
          helperText={!!errors.affiliateId && errors.affiliateId.message}
          disabled={signUpLoading || !hadHelp}
          margin="normal"
          color="primary"
          required
          fullWidth
          label="Affiliate ID"
          type="text"
        />
      )}
      <LoadingButton
        loading={signUpLoading}
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        type="submit"
      >
        Sign Up
      </LoadingButton>
    </form>
  );
};

export default SignUp;
