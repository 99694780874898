import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { EllipsisTypography } from "../../../components/StyledComponents";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import Typography from "@mui/material/Typography";
import ProfileLinks from "./ProfileLinks";
import { ChangeEvent, useState } from "react";
import ProfileLogo from "./ProfileLogo";
import UploadPhoto from "../../../components/UploadPhoto";
import { CommunityProfile } from "../../../types";
import Skeleton from "@mui/material/Skeleton";
import { getBase64 } from "../../../utils";
import { useUpdateProfileAsset } from "../../../hooks/queries/profile/mutations/useUpdateProfileAsset";

const ProfilePreview = ({
  data,
  isLoading,
}: {
  data?: CommunityProfile;
  isLoading: boolean;
}) => {
  const { mutateAsync: updateProfileLogo } = useUpdateProfileAsset();
  const [uploadImagesState, setUploadImagesState] = useState({
    cover: false,
    logo: false,
  });

  const handleImageSelected = async (
    e: ChangeEvent<HTMLInputElement>,
    type: "cover" | "logo"
  ) => {
    const image = e?.target?.files?.[0];
    if (!image) {
      return;
    }
    setUploadImagesState((state) => ({ ...state, [type]: true }));
    const fileType = image.type.replace(/(.*)\//g, "");
    const base64 = await getBase64(image);
    e.target.value = "";
    if (fileType && base64) {
      await updateProfileLogo({ type, image: { fileType, base64 } });
    }
    setUploadImagesState((state) => ({ ...state, [type]: false }));
  };

  return (
    <Box>
      <UploadPhoto
        fileName="community banner"
        img={data?.coverImage?.url}
        height={{
          xs: "20vh",
          sm: "25vh",
          md: "30vh",
          lg: "45vh",
        }}
        loading={uploadImagesState.cover}
        onSelected={(e) => handleImageSelected(e, "cover")}
      />
      <Box sx={{ padding: { xs: 1.5, md: 3 } }}>
        <Grid
          container
          columnSpacing={5}
          rowSpacing={2}
          justifyContent="space-between"
        >
          <Grid item xs={12} md={7}>
            <Stack direction="row" spacing={2} mb={2}>
              <ProfileLogo
                img={data?.profileImage?.url}
                loading={uploadImagesState.logo}
                onSelected={(e) => handleImageSelected(e, "logo")}
              />
              <Stack
                justifyContent="space-evenly"
                flexGrow={1}
                width="calc(100% - 96px)"
              >
                {isLoading ? (
                  <>
                    <Skeleton
                      variant="text"
                      height={30}
                      width={200}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      height={27}
                      width={110}
                      animation="wave"
                    />
                  </>
                ) : (
                  <>
                    <Stack direction="row" alignItems="center">
                      <EllipsisTypography
                        variant="h5"
                        fontWeight="bold"
                        color={data?.projectName ? "text.primary" : "error"}
                      >
                        {data?.projectName
                          ? data?.projectName
                          : "COMMUNITY NAME"}
                      </EllipsisTypography>
                      {data?.verified && (
                        <VerifiedRoundedIcon color="primary" sx={{ ml: 1.5 }} />
                      )}
                    </Stack>
                    <Typography
                      variant="gradient"
                      fontSize={18}
                      component="div"
                    >
                      Community
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
            {isLoading
              ? [1, 2].map((i) => (
                  <Skeleton key={i} height="1em" sx={{ mb: 1 }} />
                ))
              : data?.projectDescription && (
                  <Typography>{data.projectDescription}</Typography>
                )}
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <ProfileLinks loading={isLoading} profile={data} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfilePreview;
