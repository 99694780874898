import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { OverridableComponent } from "@mui/material/OverridableComponent";
type Props = {
  list: {
    label?: string;
    path?: string;
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
      muiName: string;
    };
  }[];
};

const StudioBreadcrumbs = ({ list }: Props) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{ mb: 2 }}
    >
      {list?.map((i) => {
        if (!i?.label) return null;
        return i?.path ? (
          <Link
            key={i?.label}
            underline="hover"
            sx={{
              display: "flex",
              alignItems: "center",
              "& > *:first-of-type": {
                mr: 0.5,
                fontSize: "inherit",
              },
            }}
            color="inherit"
            href={i?.path}
          >
            {i?.Icon && <i.Icon />}
            {i?.label}
          </Link>
        ) : (
          <Typography key={i?.label} color="text.primary">
            {i?.label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default StudioBreadcrumbs;
