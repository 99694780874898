import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IntegrationsDiscordHolderVerification } from "./IntegrationsDiscordHolderVerification";
import Divider from "@mui/material/Divider";

const IntegrationsDiscordInfo = ({ serverName }: { serverName: string }) => {
  return (
    <>
      <Alert variant="filled" color="success">
        <strong>Kevin Musk</strong> is now connected to{" "}
        <strong>{serverName}</strong> server
      </Alert>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">Subscriber verification role</Typography>
      <IntegrationsDiscordHolderVerification />

      <Divider sx={{ my: 2 }} />
      <Typography variant="h6" mb={1}>
        Enable Role Managment
      </Typography>
      <Typography mb={1}>
        Please follow these steps to ensure <strong>Kevin Musk</strong> has the
        right permissions:
      </Typography>
      <Box color="text.secondary">
        <ol>
          <li>
            Go on your <strong>Server Settings</strong>.
          </li>
          <li>
            Go to the <strong>Roles</strong> section and find{" "}
            <strong>Kevin Musk</strong>.
          </li>
          <li>
            Hover over him with your mouse and click the <strong>Edit</strong>{" "}
            button.
          </li>
          <li>
            Go to the <strong>Permissions</strong> tab.
          </li>
          <li>
            Make sure <strong>Manage Roles</strong> is active.
          </li>
          <li>Save Changes.</li>
        </ol>
      </Box>
    </>
  );
};

export default IntegrationsDiscordInfo;
