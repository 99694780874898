import ListItem from "@mui/material/ListItem";
import { DrawerListItemButton } from "../../../components/StyledComponents";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { MenuItemType } from "./types";
import { Link } from "react-router-dom";
import { useApp } from "../../../services/AppService";

type Props = {
  selected: boolean;
  path?: string;
  Icon: MenuItemType["Icon"];
  label: string;
};

const MenuItem = ({ selected, path, Icon, label }: Props) => {
  const { setMobileOpen } = useApp();

  const hideMobile = () => {
    setMobileOpen(false);
  };

  return (
    <ListItem disablePadding>
      <DrawerListItemButton
        selected={selected}
        LinkComponent={Link}
        to={path}
        onClick={hideMobile}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
      </DrawerListItemButton>
    </ListItem>
  );
};

export default MenuItem;
