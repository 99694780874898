import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiBox from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import MuiStack from "@mui/material/Stack";

export const drawerWidth = 260;

export const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundImage: "none",
  backgroundColor: theme.palette.background.default,
  boxShadow: "none",
  width: drawerWidth,
  left: 0,
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  "& .MuiToolbar-root": {
    paddingInline: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      paddingInline: theme.spacing(1.5),
      "& .MuiIconButton-root": {
        marginLeft: theme.spacing(-1),
      },
    },
  },
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const StyledMain = styled(MuiBox)(({ theme }) => ({
  flexGrow: 1,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const StyledLogoIcon = styled(MuiBox)(({ theme }) => ({
  width: 30,
  height: 30,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "3px",
}));

export const StyledLogoName = styled(MuiStack)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  alignItems: "flex-start",
  "> div": {
    lineHeight: 1,
    letterSpacing: "0.02em",
    fontWeight: 900,
  },
}));

export const StyledDrawer = styled(MuiDrawer)(({ theme, variant }) => ({
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex: 100,
  display: "none",
  width: drawerWidth,
  "& .MuiDrawer-paper": {
    background: theme.palette.background.default,
    justifyContent: "flex-start",
    boxSizing: "border-box",
    width: drawerWidth,
    backgroundImage: "none",
    padding: theme.spacing(0, 1.5, 1.5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 1, 1),
    },
  },
  ...(variant === "permanent" && {
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  }),
  ...(variant === "temporary" && {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  }),
}));

export const DrawerListItemButton = styled(ListItemButton as any)(
  ({ theme, selected }) => ({
    justifyContent: "initial",
    borderRadius: `${theme.shape.borderRadius}px`,
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: theme.spacing(0, 1.5),
    height: 40,
    color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
    fill: selected ? theme.palette.text.primary : theme.palette.text.secondary,
    ".MuiListItemText-root": {
      margin: 0,
    },
    ".MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: theme.spacing(1.5),
    },
    ".MuiTypography-root": {
      fontWeight: selected ? 500 : 400,
    },
    ".MuiAvatar-root": {
      width: 22.28,
      height: 22.28,
      marginRight: theme.spacing(1.5),
      flexShrink: 0,
      borderRadius: 3,
    },
    ".MuiSvgIcon-root": {
      color: selected
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
    },
    "&.Mui-selected": {
      paddingRight: "17px",
      borderRight: 3,
      borderColor: theme.palette.primary.main,
      cursor: "default",
      background: "rgba(255, 255, 255, 0.08)",
      ":hover": {
        background: "rgba(255, 255, 255, 0.08)",
      },
    },
  })
);

export const DrawerAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "transparent",
  "&::before": {
    content: "none",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: theme.spacing(5),
    paddingLeft: theme.spacing(1.5),
    paddingRight: 0,
    color: theme.palette.text.secondary,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.text.secondary,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1.5),
    },
  },
  "& .MuiAccordionDetails-root": {
    padding: 0,
    "& .MuiList-root": {
      paddingLeft: theme.spacing(5.5),
      marginBottom: theme.spacing(1),
    },
  },
}));

export const EllipsisTypography = styled(Typography)(() => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const LoaderCover = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "rgba(0,0,0,0.7)",
  position: "absolute",
  zIndex: 1,
  borderRadius: `${theme.shape.borderRadius}px`,
  height: "100%",
  width: "100%",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  minHeight: 200,
}));

export const LiveDot = styled(MuiBox)(({ color, theme }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: 8,
  display: "inline-block",
  verticalAlign: "middle",
  backgroundColor:
    color === "default" ? theme.palette.divider : "rgb(0 255 12 / 100%)",
  position: "relative",
  ...(color !== "default" && {
    boxShadow: `0 0 0 rgb(0 255 12 / 50%)`,
    animation: `pulsing 1.5s ${theme.transitions.easing.easeOut} infinite`,
  }),
}));
