import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContentText from "@mui/material/DialogContentText";
import { useConnectToken } from "../../hooks/queries/tokens/mutations/useConnectToken";
import { useEffect } from "react";

export interface Props {
  open: boolean;
  hide: () => void;
}

type Inputs = {
  tokenAddress: string;
};

const ConnectTokenModal = ({ open, hide }: Props) => {
  const { mutateAsync: connectToken, isLoading: connectTokenLoading } =
    useConnectToken({
      onSuccess: () => {
        hide();
      },
    });
  const theme = useTheme();
  const {
    register,
    formState: { errors },
    handleSubmit,
    resetField,
  } = useForm<Inputs>();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    resetField("tokenAddress");
  }, [open, resetField]);

  const onSave = (inputs: Inputs) => {
    connectToken({ tokenAddress: inputs.tokenAddress });
  };

  return (
    <Dialog
      open={open}
      onClose={connectTokenLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <DialogTitle>Connect token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure you enter the correct info
          </DialogContentText>
          <TextField
            {...register("tokenAddress", {
              required: "This field is required",
            })}
            error={!!errors.tokenAddress}
            helperText={!!errors.tokenAddress && errors.tokenAddress.message}
            required
            fullWidth
            label="Token address"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            disabled={connectTokenLoading}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="base"
            onClick={hide}
            disabled={connectTokenLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={connectTokenLoading}
          >
            Connect
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConnectTokenModal;
