import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { RuleTarget } from "../../types";

export type RuleSettings = {
  _id: string;
  name: string;
  description: string;
  target: RuleTarget;
};

export interface ModalLevelsProps {
  data?: RuleSettings;
  loading: boolean;
  hide: () => void;
  save: (data: RuleSettings) => void;
}

type Inputs = {
  name: string;
  description: string;
};

const RuleSettingsModal = ({ data, loading, hide, save }: ModalLevelsProps) => {
  const theme = useTheme();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    values: {
      name: data?.name || "",
      description: data?.description || "",
    },
  });
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onSave = (inputs: Inputs) => {
    if (!data) return;
    save({ ...data, name: inputs.name, description: inputs.description });
  };

  return (
    <Dialog
      open={!!data}
      onClose={loading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      TransitionComponent={ModalTransition}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <DialogTitle>
          {data?._id === "new" ? "Add new" : "Edit"} rule
        </DialogTitle>
        <DialogContent>
          <>
            <TextField
              {...register("name", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3",
                },
              })}
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              required
              fullWidth
              label="Name"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={loading}
            />
            <TextField
              {...register("description", {
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3",
                },
              })}
              error={!!errors.description}
              helperText={!!errors.description && errors.description.message}
              required
              fullWidth
              label="Description"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={3}
              disabled={loading}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="base"
            onClick={hide}
            disabled={loading}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={loading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RuleSettingsModal;
