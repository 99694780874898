import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import ModalTransition from "../../../../helpers/ModalTransition";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import AttributesSetup from "./AttributesSetup";
import FilterInput from "./FilterInput";
import TokensMappingDialog from "./TokensMappingDialog";
import { useGetTokensMetadata } from "../../../../hooks/queries/solana/useGetTokensMetadata";
import {
  SubscriptionBoosterAttributeCountMap,
  SubscriptionBoosterAttributeMap,
} from "../../../../types";
import useGetBoosterDetails from "../../../../hooks/queries/boosters/state/useGetBoosterDetails";
import { useSetContractAttributes } from "../../../../hooks/queries/boosters/mutations/useSetContractAttributes";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BOOSTER_TARGET_TYPES } from "../../../../constants";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";

type Props = { open: boolean; hide: () => void };

const AttributesDialog: React.FC<Props> = ({ open, hide }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const { data: contractDetails } = useGetBoosterDetails();
  const { mutateAsync: getTokensMetadata } = useGetTokensMetadata();
  const {
    mutateAsync: setContractAttributes,
    isLoading: setContractAttributesLoading,
  } = useSetContractAttributes();

  const [filterOption, setFilterOption] = useState<"all" | "attribute">("all");
  const [filter, setFilter] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [totalLoaded, setTotalLoaded] = useState(0);
  const [supportedTokens, setsupportedTokens] = useState<string[]>([]);
  const [busyMapping, setBusyMapping] = useState(false);
  const [attributeMap, setAttributeMap] =
    useState<SubscriptionBoosterAttributeMap>({});
  const [attributeCountMap, setAttributeCountMap] =
    useState<SubscriptionBoosterAttributeCountMap>({});

  const handleTypeChange = (e: SelectChangeEvent<string>) => {
    setHasChanges(true);
    setFilterOption(e.target.value as "all" | "attribute");
  };

  const handleCustomChanged = useCallback(
    (key: string, trait: string, status: boolean) => {
      setHasChanges(true);
      setAttributeMap((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [trait]: {
            ...prevState[key][trait],
            supported: status,
          },
        },
      }));
    },
    []
  );

  useEffect(() => {
    if (!contractDetails) {
      return;
    }
    setHasChanges(false);
    const tokesAddresses: string[] = [];
    contractDetails?.boostableCollections?.forEach((item) => {
      tokesAddresses.push(...item?.fullHashlist);
    });
    const supportedTokens = tokesAddresses.filter(
      (v, i, a) => a.indexOf(v) === i
    );
    setsupportedTokens(supportedTokens);
    if (contractDetails?.AttributeMap && contractDetails?.AttributeCountMap) {
      setAttributeMap(contractDetails?.AttributeMap);
      setAttributeCountMap(contractDetails?.AttributeCountMap);
    } else {
      setAttributeMap({});
      setAttributeCountMap({});
    }
    setFilterOption(contractDetails?.FilterOption);
  }, [contractDetails]);

  const refreshMap = async () => {
    if (supportedTokens.length <= 0) {
      return;
    }
    setBusyMapping(true);
    getTokensAttributeMap();
  };

  const getTokensAttributeMap = useCallback(async () => {
    setTotalLoaded(0);
    const arrays: string[][] = [];
    const size = 10;
    const tempAttributeMap: SubscriptionBoosterAttributeMap = {};
    const tempAttributeCountMap: SubscriptionBoosterAttributeCountMap = {};
    const supportedTokensCopy = JSON.parse(JSON.stringify(supportedTokens));

    while (supportedTokensCopy.length > 0)
      arrays.push(supportedTokensCopy.splice(0, size));

    await Promise.all(
      arrays.map(async (tokenArray) => {
        const tokenData = await getTokensMetadata(tokenArray);
        if (tokenData?.length <= 0) {
          return;
        }
        tokenData.forEach((data) => {
          if (data?.attributes) {
            for (const { trait_type, value } of data.attributes) {
              if (trait_type === "Sequence") continue;
              if (!tempAttributeCountMap[trait_type]) {
                tempAttributeCountMap[trait_type] = {
                  amount: 0,
                };
              }
              tempAttributeCountMap[trait_type].amount++;

              if (!tempAttributeMap?.[trait_type])
                tempAttributeMap[trait_type] = {};
              const traitValue = value.length > 0 ? value : "_None_";
              if (!tempAttributeMap[trait_type]?.[traitValue]) {
                const existingTraitValueConfig = attributeMap?.[trait_type]?.[
                  traitValue
                ]
                  ? JSON.parse(
                      JSON.stringify(attributeMap?.[trait_type]?.[traitValue])
                    )
                  : undefined;
                tempAttributeMap[trait_type][traitValue] =
                  existingTraitValueConfig || {
                    count: 0,
                    supported: false,
                  };
                if (existingTraitValueConfig) {
                  tempAttributeMap[trait_type][traitValue].count = 0;
                }
              }
              tempAttributeMap[trait_type][traitValue].count++;
            }
          }
        });
        setTotalLoaded((state) => (state += tokenData?.length));
      })
    );

    try {
      await setContractAttributes({
        filterOption: "attribute",
        attributeMap: tempAttributeMap,
        attributeCountMap: tempAttributeCountMap,
      });
      setAttributeMap(tempAttributeMap);
      setAttributeCountMap(tempAttributeCountMap);
    } catch (error) {
    } finally {
      setBusyMapping(false);
    }
  }, [supportedTokens, setContractAttributes, getTokensMetadata, attributeMap]);

  const saveChanges = async () => {
    if (filterOption === "all") {
      setContractAttributes({
        filterOption,
      });
    } else if (attributeMap && attributeCountMap) {
      setContractAttributes({
        filterOption,
        attributeMap,
        attributeCountMap,
      });
    }
  };

  const filteredAttributeKeys = useMemo(() => {
    return Object.keys(attributeMap)
      .sort((a, b) => a.localeCompare(b))
      .filter((key) => filter.includes(key) || filter.length === 0);
  }, [attributeMap, filter]);

  return (
    <Dialog
      open={open}
      onClose={hide}
      TransitionComponent={ModalTransition}
      maxWidth="xl"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>Targets configurator</DialogTitle>
      <DialogContent>
        <FormControl
          fullWidth
          required
          margin="dense"
          disabled={setContractAttributesLoading}
        >
          <InputLabel>Type</InputLabel>
          <Select
            value={filterOption}
            onChange={handleTypeChange}
            variant="outlined"
            label="Type"
            defaultValue=""
          >
            {BOOSTER_TARGET_TYPES.map(({ id, label, description }) => (
              <MenuItem key={id} value={id}>
                <Box width="100%">
                  <Typography fontWeight={700} lineHeight={1}>
                    {label}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    component="div"
                    mt={0.5}
                    lineHeight={1.15}
                  >
                    {description}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Of the supported OGs, you can also provide additional attribute
            requirements to use a multiplier.
            <br />
            E.g. - Only NFTs with a blue background can use this multiplier.
          </FormHelperText>
        </FormControl>
        {filterOption === "attribute" && (
          <>
            <Divider>
              <Typography variant="overline" color="text.secondary">
                Attributes configurator
              </Typography>
            </Divider>
            {supportedTokens?.length > 0 &&
              Object.keys(attributeMap).length === 0 && (
                <Box textAlign="center">
                  <Typography mb={1} fontWeight={700}>
                    Need to get information about all attributes from your
                    hashlists
                  </Typography>
                  <Button
                    color="secondary"
                    onClick={refreshMap}
                    disabled={setContractAttributesLoading}
                  >
                    Click here to map attributes
                  </Button>
                </Box>
              )}
            <TokensMappingDialog
              busyMapping={busyMapping}
              loaded={totalLoaded}
              total={supportedTokens.length}
            />
            {Object.keys(attributeMap).length > 0 && (
              <FilterInput
                filter={filter}
                setFilter={setFilter}
                attributeMap={attributeMap}
                disabled={setContractAttributesLoading}
              />
            )}
            <Box
              sx={{
                position: "relative",
                overflowX: "auto",
              }}
            >
              <Stack
                direction="row"
                alignItems="flex-start"
                spacing={1}
                sx={{
                  "&::before,&::after": {
                    content: '""',
                    margin: "auto",
                  },
                }}
              >
                {filteredAttributeKeys.map((key) => (
                  <AttributesSetup
                    name={key}
                    attributeMap={attributeMap}
                    attributeCountMap={attributeCountMap}
                    handleCustomChanged={handleCustomChanged}
                    key={key}
                  />
                ))}
              </Stack>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box>
          {filterOption === "attribute" &&
            Object.keys(attributeMap).length > 0 && (
              <Button
                color="secondary"
                onClick={refreshMap}
                disabled={setContractAttributesLoading}
              >
                Refresh attributes
              </Button>
            )}
        </Box>
        <Stack spacing={1} direction="row">
          <Button
            variant="base"
            color="secondary"
            onClick={hide}
            disabled={setContractAttributesLoading}
          >
            Close
          </Button>
          <LoadingButton
            variant="contained"
            loading={setContractAttributesLoading}
            disabled={!hasChanges}
            onClick={saveChanges}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AttributesDialog;
