import Box from "@mui/material/Box";
import Logo from "../../components/images/Logo";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import DiscordIcon from "../../components/images/Discord";
import Typography from "@mui/material/Typography";

const Footer = () => {
  return (
    <footer style={{ marginTop: "auto" }}>
      <Box
        mt={3}
        py={2}
        mx={{ xs: 1.5, md: 3 }}
        borderTop={1}
        borderColor="divider"
      >
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="row" alignItems="center">
            <Box mr={1}>
              <Logo height="1em" fill="#ffe000" />
            </Box>
            <Typography
              lineHeight="1.5em"
              textAlign={{ xs: "center", md: "left" }}
              color="text.secondary"
              variant="caption"
            >
              Copyright © {new Date().getFullYear()} Anybodies inc. All rights
              reserved.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            {/* #0277b5 */}
            <IconButton
              LinkComponent="a"
              href="https://bit.ly/3nB5EdF"
              target="_blank"
            >
              <LinkedInIcon />
            </IconButton>
            {/* #1ea1f1 */}
            <IconButton
              LinkComponent="a"
              href="https://bit.ly/3iRGCV6"
              target="_blank"
            >
              <TwitterIcon />
            </IconButton>
            {/* #5462eb */}
            <IconButton
              LinkComponent="a"
              href="https://discord.gg/hbjXv8fd3X"
              target="_blank"
            >
              <DiscordIcon width="1em" fill="#FFF" />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </footer>
  );
};

export default Footer;
