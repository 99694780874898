import { ChangeEvent } from "react";
import { RuleTarget, ServerResponse, SuccessResponse } from "./types";
import { PublicKey } from "@solana/web3.js";

export const downloadJSONFile = (data: any, fileName: string) => {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: "application/json" });
  const href = URL.createObjectURL(blob);

  // create "a" HTLM element with href to file
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const generateRuleLink = (ruleId?: string, target?: RuleTarget) => {
  return `https://us-central1-nft-anybodies.cloudfunctions.net/RULES_executeValidation?ruleId=${ruleId}&${
    target === RuleTarget.WalletAddress
      ? "walletAddress=[WALLET_ADDRESS]"
      : "contractId=[CONTRACT_ID]"
  }`;
};

export const getBase64 = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve((reader.result as string)?.split(",")[1]);
    };
    reader.onerror = reject;
  });

export const parseDataFromJSON = (e: ChangeEvent<HTMLInputElement>) =>
  new Promise<{ arr: string[]; fileName: string }>((resolve, reject) => {
    const file = e?.target?.files?.[0];
    if (!file) {
      reject();
    }
    const reader = new FileReader();
    reader.onload = (res) => {
      if (!res?.target?.result) {
        reject();
      }
      const obj = JSON.parse(res?.target?.result as string);
      if (obj.length === undefined || obj.length === 0) {
        console.log("Not an array");
        // Fuck
        reject();
      }
      if (obj.filter((item: any) => typeof item !== "string").length > 0) {
        console.log("Not a string");
        // Bad Apple
        reject();
      }

      resolve({ arr: obj, fileName: file?.name || "" });
    };
    reader.readAsText(file as Blob);
  });

export const parseDataFromCSV = (e: ChangeEvent<HTMLInputElement>) =>
  new Promise<{ arr: string[][]; fileName: string }>((resolve, reject) => {
    const file = e?.target?.files?.[0];
    if (!file) {
      reject();
    }
    const reader = new FileReader();
    reader.onload = (res) => {
      if (!res?.target?.result) {
        reject();
      }
      const obj = csvToArray(res?.target?.result as string);
      if (obj.length === undefined || obj.length === 0) {
        console.log("Not an array");
        // Fuck
        reject();
      }
      resolve({ arr: obj, fileName: file?.name || "" });
    };
    reader.readAsText(file as Blob);
  });

function csvToArray(str: string, delimiter = ",", omitFirstRow = false) {
  return str
    .slice(omitFirstRow ? str.indexOf("\r\n") + 1 : 0)
    .split("\r\n")
    .filter((v) => v.trim().length > 1)
    .map((v) => v.split(delimiter));
}

export const deepEqual = (a: any, b: any) => {
  if (a === b) {
    return true;
  }
  if (
    a === null ||
    b === null ||
    typeof a !== "object" ||
    typeof b !== "object"
  ) {
    return false;
  }
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  for (const key of aKeys) {
    if (!bKeys.includes(key) || !deepEqual(a[key], b[key])) {
      return false;
    }
  }
  return true;
};

export const downloadCSVFile = (data: string[][], fileName: string) => {
  const csvContent = `data:text/csv;charset=utf-8,${data
    .map((e) => e.join(","))
    .join("\r\n")}`;
  const encodedUri = encodeURI(csvContent);

  // create "a" HTLM element with href to file
  const link = document.createElement("a");
  link.href = encodedUri;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(encodedUri);
};

export const getRewardProgram = (rewardProgram: string) => {
  switch (rewardProgram) {
    case "collection":
      return "Collection based";
    case "static":
      return "Fixed";
    case "attribute-collection":
    case "attribute":
      return "Attribute based";
    default:
      break;
  }
};

export const validateLinkRegExp = new RegExp(
  "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export function isSuccessResponse<T>(
  response: ServerResponse<T>["data"]
): response is SuccessResponse<T> {
  return response.success === true;
}

export const validateSolanaAddress = (addr: string) => {
  try {
    const publicKey = new PublicKey(addr);
    return PublicKey.isOnCurve(publicKey.toBytes());
  } catch (err) {
    return false;
  }
};

export const cryptoAmountToView = (amount: number = 0) => {
  return amount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 20,
  });
};
