import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useApp } from "../../../../services/AppService";
import { CommunityProfileResponse } from "../../../../types";
import { API_PATHS } from "../../../../constants";

export const useGetProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId } = useApp();

  async function getProfile() {
    const { data } = await axios.post<CommunityProfileResponse>(
      API_PATHS.profile.info,
      {
        communityId: selectedCommunityDocId,
      }
    );
    if (data?.success) {
      return data.data;
    }
    enqueueSnackbar({
      variant: "error",
      message: `${data?.error?.title}\n${data?.error?.description}`,
    });
  }

  return useQuery(
    [API_PATHS.profile.info, selectedCommunityDocId],
    getProfile,
    {
      staleTime: 30 * 60 * 1000,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};
