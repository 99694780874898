import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { UpdateBoosterInfoPayload } from "../../../types";
import { useEffect } from "react";
import useGetBoosterDetails from "../../../hooks/queries/boosters/state/useGetBoosterDetails";
import useUpdateBoosterProfile from "../../../hooks/queries/boosters/mutations/useUpdateBoosterProfile";

type Inputs = Omit<UpdateBoosterInfoPayload, "communityId" & "planId">;

const Info = () => {
  const { data: contractDetails } = useGetBoosterDetails();
  const { mutateAsync: updateProfile, isLoading: updateProfileLoading } =
    useUpdateBoosterProfile();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<Inputs>();

  useEffect(() => {
    if (!contractDetails) return;
    const newStructure = {
      planTitle: contractDetails?.PlanTitle,
      planDescription: contractDetails?.PlanDescription,
    };
    reset(newStructure);
  }, [contractDetails, reset]);

  const onSubmit = async (data: Inputs) => {
    updateProfile(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register("planTitle", {
          required: "This field is required",
          minLength: {
            value: 3,
            message: "Minimum length is 3",
          },
          maxLength: {
            value: 50,
            message: "Max length is 50",
          },
        })}
        error={!!errors.planTitle}
        helperText={!!errors.planTitle && errors.planTitle.message}
        required
        fullWidth
        label="Title"
        margin="dense"
        disabled={updateProfileLoading}
      />
      <TextField
        {...register("planDescription", {
          required: "This field is required",
          minLength: {
            value: 3,
            message: "Minimum length is 3",
          },
          maxLength: {
            value: 150,
            message: "Max length is 150",
          },
        })}
        error={!!errors.planDescription}
        helperText={
          errors.planDescription
            ? errors.planDescription.message
            : "Make sure to give as much information about the plan in this field"
        }
        required
        fullWidth
        label="Description"
        multiline
        rows={3}
        margin="dense"
        disabled={updateProfileLoading}
      />
      <LoadingButton
        variant="contained"
        type="submit"
        loading={updateProfileLoading}
        sx={{ mt: 1 }}
      >
        Save
      </LoadingButton>
    </form>
  );
};

export default Info;
