import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { DiscordRolesResponse } from "../../../../types";
import { useApp } from "../../../../services/AppService";
import { API_PATHS } from "../../../../constants";
import axios from "axios";
import { isSuccessResponse } from "../../../../utils";

const getDiscordRoles = async (communityId: string | null) => {
  const { data } = await axios.post<DiscordRolesResponse>(
    API_PATHS.integrations.discord.roles,
    {
      data: { communityId },
    }
  );
  return data;
};

export const useGetDiscordRoles = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCommunityDocId, selectedCommunity } = useApp();

  return useQuery(
    [API_PATHS.integrations.discord.roles, selectedCommunityDocId],
    async () => {
      const { data } = await getDiscordRoles(selectedCommunityDocId);
      if (isSuccessResponse(data)) {
        return data?.data;
      }

      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    },
    {
      enabled: !!selectedCommunity?.DiscordData,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};
